import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import LeftBar from "../components/LeftBar";
import Topbar from "../components/Topbar";
import Noteedit from "./Noteedit";
import RightSidebar from "../components/RightSidebar";
import Bottombar from "../components/Bottombar";
import ZoomControls from "../components/ZoomControls";
import Toolbar from "../components/Toolbar";
import "../css/LeftBar.css";
import "../css/rightsidebar.css";
import "../css/bottombar.css";
import "../css/toolbar.css";
import { ToolbarProvider } from "../contexts/ToolbarContext";
import { LayoutProvider, useLayout } from "../contexts/LayoutContext";
import debounce from "lodash/debounce";
import { useCurriculumState } from "../hooks/useCurriculumState";
import { ImageProvider } from "../contexts/ImageContext";
import { db, auth } from "../firebase/firebase";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
  deleteField,
  writeBatch,
  deleteDoc,
} from "firebase/firestore";
import eventEmitter from "../utils/eventEmitter";
import SearchResultModal from "../components/SearchResultModal";
import { useTable } from "../contexts/TableContext";
import { TableProvider } from "../contexts/TableContext";

const Unitnote = () => {
  const { courseId, courseName, unitId, subunitId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    searchAllTableData,
    isSearchModalOpen,
    setIsSearchModalOpen,
    searchResults,
  } = useTable();

  const {
    curriculum,
    setCurriculum,
    currentUnitId,
    setCurrentUnitId,
    currentSubunit,
    setCurrentSubunit,
    currentSubunitName,
    setCurrentSubunitName,
    handleSubunitNameChange,
  } = useCurriculumState({
    unitId: unitId,
    subunitId: subunitId,
  });

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [scale, setScale] = useState(1.5);
  const { layout, setLayout } = useLayout();
  const [insertedImages, setInsertedImages] = useState([]);
  const [insertedAudios, setInsertedAudios] = useState([]);
  const [insertedVideos, setInsertedVideos] = useState([]);
  const contentRef = useRef(null);
  const leftBarRef = useRef(null);
  const [reminderListItems, setReminderListItems] = useState([]);
  const [vocabularyListItems, setVocabularyListItems] = useState([]);
  const [isPenMode, setIsPenMode] = useState(false);
  const [penColor, setPenColor] = useState("#000000");
  const [leftBarWidth, setLeftBarWidth] = useState(350);
  const [subunitContents, setSubunitContents] = useState({});
  const [penSize, setPenSize] = useState(2);
  const [isEraserMode, setIsEraserMode] = useState(false);
  const [eraserSize, setEraserSize] = useState(10);
  const [lineStyle, setLineStyle] = useState("solid");
  const [penOpacity, setPenOpacity] = useState(1);
  const [showLayoutWarning, setShowLayoutWarning] = useState(false);
  const [pendingLayout, setPendingLayout] = useState(null);
  const [stampNames, setStampNames] = useState(["1", "2", "3", "4", "5"]);
  const [showSections, setShowSections] = useState(() => {
    return (
      location.state?.showSections ??
      JSON.parse(localStorage.getItem("showSections") || "true")
    );
  });
  const [showChapters, setShowChapters] = useState(() => {
    return (
      location.state?.showChapters ??
      JSON.parse(localStorage.getItem("showChapters") || "true")
    );
  });
  const [lineColor, setLineColor] = useState("#000000");
  const [subunitLayouts, setSubunitLayouts] = useState({});
  const [tableData, setTableData] = useState({});
  const [insertedYoutubeVideos, setInsertedYoutubeVideos] = useState([]);
  const [textBoxes, setTextBoxes] = useState({});
  const [stamps, setStamps] = useState({});
  const pendingUpdates = useRef(false);
  const [noteEditKey, setNoteEditKey] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const hasUnsavedChanges = useRef(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [pendingSubunitChange, setPendingSubunitChange] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleSectionVisibilityChange = useCallback((isVisible) => {
    setShowSections(isVisible);
    localStorage.setItem("showSections", JSON.stringify(isVisible));
  }, []);

  const handleChapterVisibilityChange = useCallback((isVisible) => {
    setShowChapters(isVisible);
    localStorage.setItem("showChapters", JSON.stringify(isVisible));
  }, []);
  const handleNoteEditLoadComplete = useCallback((isLoaded) => {
    setIsDataLoaded(isLoaded);
  }, []);

  useEffect(() => {
    if (subunitId && currentSubunit?.id !== subunitId) {
      if (isTransitioning) {
        return;
      }

      setIsTransitioning(true);
      setPendingSubunitChange(subunitId);
      // 로드 상태 초기화
      setIsDataLoaded(false);
    }
  }, [subunitId, currentSubunit?.id, isTransitioning]);

  useEffect(() => {
    if (isDataLoaded && pendingSubunitChange) {
      setCurrentSubunit({
        id: pendingSubunitChange,
        name:
          curriculum.subunits[currentUnitId]?.find(
            (s) => s.id === pendingSubunitChange
          )?.name || "",
      });

      setPendingSubunitChange(null);
      setIsTransitioning(false);
    }
  }, [isDataLoaded, pendingSubunitChange]);

  useEffect(() => {
    if (currentSubunit.id) {
      setSubunitContents((prevContents) => ({
        ...prevContents,
        [currentSubunit.id]: prevContents[currentSubunit.id] || {
          mainContent: "",
          images: [],
          audios: [],
          videos: [],
        },
      }));
    }
  }, [currentSubunit.id]);

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
    const newWidth = isOpen ? 350 : 50;
    setLeftBarWidth(newWidth);
    if (leftBarRef.current) {
      leftBarRef.current.style.width = `${newWidth}px`;
    }
    if (contentRef.current) {
      contentRef.current.style.marginLeft = `${newWidth}px`;
    }
  };

  const handleZoomIn = () => {
    setScale((prevScale) => {
      const newScale = Math.min(prevScale + 0.1, 2);
      return newScale;
    });
  };
  const handleZoomOut = () => {
    setScale((prevScale) => {
      const newScale = Math.max(prevScale - 0.1, 1.5);
      return newScale;
    });
  };

  const handleSelectLayout = (newLayout) => {
    // 현재 레이아웃과 같으면 무시
    if (layout === newLayout) {
      return;
    }

    if (["layout7", "layout8"].includes(newLayout)) {
      setShowLayoutWarning(true);
      setPendingLayout(newLayout);
    } else {
      saveAndApplyLayout(newLayout);
    }
  };

  const saveAndApplyLayout = useCallback(
    async (newLayout) => {
      try {
        // 1. 로컬 상태 업데이트
        setLayout(newLayout);

        // 2. Firebase 저장
        if (auth.currentUser && courseId && currentSubunit.id) {
          const layoutRef = doc(
            db,
            "users",
            auth.currentUser.uid,
            "courses",
            courseId,
            "curriculum",
            "layout",
            "subunits",
            currentSubunit.id
          );

          await setDoc(
            layoutRef,
            {
              layout: newLayout,
              updatedAt: serverTimestamp(),
            },
            { merge: true }
          );
        }
      } catch (error) {
        // 실패 시 이전 레이아웃으로 복구
        setLayout(layout);
      }
    },
    [courseId, currentSubunit.id, layout]
  );

  const confirmLayoutChange = () => {
    saveAndApplyLayout(pendingLayout);
    setShowLayoutWarning(false);
    setPendingLayout(null);
  };

  const cancelLayoutChange = () => {
    setShowLayoutWarning(false);
    setPendingLayout(null);
  };

  const handleInsertImage = (imageData) => {
    setInsertedImages((prev) => [...prev, imageData]);
  };

  const handleInsertAudio = (audio) => {
    setInsertedAudios((prevAudios) => [...prevAudios, audio]);
  };

  const handleInsertVideo = (video) => {
    setInsertedVideos((prevVideos) => [...prevVideos, video]);
  };

  useEffect(() => {
    setCurrentUnitId(unitId);
  }, [unitId]);

  useEffect(() => {
    const handleWheel = (event) => {
      if (event.ctrlKey) {
        event.preventDefault();
        if (event.deltaY < 0) {
          handleZoomIn();
        } else {
          setScale((prevScale) => {
            const newScale = Math.max(prevScale - 0.1, 1.5);
            return newScale;
          });
        }
      }
    };

    const handleScroll = () => {
      const content = contentRef.current;
      if (content && content.scrollLeft < 10) {
        content.scrollLeft = 10;
      }
    };

    const handleTouchMove = (event) => {
      if (scale > 1) {
        event.preventDefault();
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });
    window.addEventListener("touchmove", handleTouchMove, { passive: false });
    if (contentRef.current) {
      contentRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("touchmove", handleTouchMove);
      if (contentRef.current) {
        contentRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scale]);

  const handleAddSubunit = useCallback(
    (unitId, newSubunit) => {
      setCurriculum((prevCurriculum) => {
        const newCurriculum = { ...prevCurriculum };
        if (!newCurriculum.subunits[unitId]) {
          newCurriculum.subunits[unitId] = [];
        }
        newCurriculum.subunits[unitId].push(newSubunit);
        return newCurriculum;
      });
    },
    [curriculum]
  );

  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const handleMoveSubunit = (unitName, subunitIndex, direction) => {
    setCurriculum((prevCurriculum) => {
      const newCurriculum = { ...prevCurriculum };
      const subunits = [...newCurriculum.subunits[unitName]];
      const [movedSubunit] = subunits.splice(subunitIndex, 1);
      const newIndex = direction === "up" ? subunitIndex - 1 : subunitIndex + 1;
      subunits.splice(newIndex, 0, movedSubunit);
      newCurriculum.subunits[unitName] = subunits;
      return newCurriculum;
    });
  };

  const handleChangeSubunitUnit = (fromUnitName, subunitIndex, toUnitName) => {
    setCurriculum((prevCurriculum) => {
      const newCurriculum = { ...prevCurriculum };
      const [movedSubunit] = newCurriculum.subunits[fromUnitName].splice(
        subunitIndex,
        1
      );
      if (!newCurriculum.subunits[toUnitName]) {
        newCurriculum.subunits[toUnitName] = [];
      }
      newCurriculum.subunits[toUnitName].push(movedSubunit);
      return newCurriculum;
    });
  };

  const handleReorderSubunits = (unitName, startIndex, endIndex) => {
    setCurriculum((prevCurriculum) => {
      const newCurriculum = { ...prevCurriculum };
      const subunits = [...newCurriculum.subunits[unitName]];
      const [reorderedItem] = subunits.splice(startIndex, 1);
      subunits.splice(endIndex, 0, reorderedItem);
      newCurriculum.subunits[unitName] = subunits;
      return newCurriculum;
    });
  };

  const handlePenModeChange = (newPenMode) => {
    setIsPenMode(newPenMode);
  };

  const handlePenColorChange = (newColor) => {
    setPenColor(newColor);
  };

  const handlePenSizeChange = (newSize) => {
    setPenSize(newSize);
  };

  const handleLeftBarResize = (newWidth) => {
    setLeftBarWidth(newWidth);
    if (contentRef.current) {
      contentRef.current.style.marginLeft = `${newWidth}px`;
    }
  };

  useEffect(() => {
    const resizeBar = document.createElement("div");
    resizeBar.style.width = "10px";
    resizeBar.style.height = "100%";
    resizeBar.style.position = "absolute";
    resizeBar.style.right = "0";
    resizeBar.style.top = "0";
    resizeBar.style.cursor = "col-resize";
    resizeBar.style.zIndex = "1000";

    leftBarRef.current.appendChild(resizeBar);

    const resize = (e) => {
      const newWidth = e.clientX;
      if (newWidth >= 200 && newWidth <= 600) {
        setLeftBarWidth(newWidth);
        if (contentRef.current) {
          contentRef.current.style.marginLeft = `${newWidth}px`;
        }
      }
    };

    const stopResize = () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResize);
    };

    const initResize = () => {
      window.addEventListener("mousemove", resize);
      window.addEventListener("mouseup", stopResize);
    };

    resizeBar.addEventListener("mousedown", initResize);

    return () => {
      resizeBar.removeEventListener("mousedown", initResize);
    };
  }, []);

  useEffect(() => {
    const handleDrawingMode = (e) => {
      if (e.detail.isDrawingMode) {
        document.body.classList.add("drawing-mode");
      } else {
        document.body.classList.remove("drawing-mode");
      }
    };

    window.addEventListener("drawingModeChange", handleDrawingMode);

    return () => {
      window.removeEventListener("drawingModeChange", handleDrawingMode);
    };
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.classList.toggle("pen-mode", isPenMode);
    }
  }, [isPenMode]);

  const handleEraserModeChange = (newEraserMode) => {
    setIsEraserMode(newEraserMode);
    setIsPenMode(false);
  };

  const handleEraserSizeChange = (newSize) => {
    setEraserSize(newSize);
  };

  const handleLineWidthChange = (newWidth) => {};

  const handleLineStyleChange = (newStyle) => {
    setLineStyle(newStyle);
  };

  const handlePenOpacityChange = (newOpacity) => {
    setPenOpacity(newOpacity);
  };

  const handleLineColorChange = (newColor) => {
    setLineColor(newColor);
  };

  const handleStampNamesChange = (newNames) => {
    setStampNames(newNames);
  };

  const handleContentChange = useCallback(() => {
    hasUnsavedChanges.current = true;
  }, []);

  useEffect(() => {
    const applyInitialFont = () => {
      const unitName = document.querySelector(".unit-name");
      if (unitName) {
        unitName.style.fontFamily = "TTAgainSea";
      }
    };

    applyInitialFont();
  }, []);

  const handleSubunitClick = useCallback(
    (unit, subunit) => {
      // 이동할 URL 미리 준비
      const to = `/courses/${courseId}/curriculum/${courseName}/unit/${unit.unitId}/subunit/${subunit.id}`;

      // 컴포넌트에 이동 예정 URL 저장
      setPendingNavigation(to);
    },
    [courseId, courseName, currentSubunit]
  );

  // 저장 완료 이벤트 리스너
  useEffect(() => {
    const handleSaveComplete = (event) => {
      if (event.detail?.status === "complete" && pendingNavigation) {
        navigate(pendingNavigation);
        setPendingNavigation(null);
      }
    };

    window.addEventListener("saveComplete", handleSaveComplete);
    return () => window.removeEventListener("saveComplete", handleSaveComplete);
  }, [navigate, pendingNavigation]);

  useEffect(() => {
    if (
      location.state?.subunitId &&
      location.state?.unitId &&
      location.state?.subunitName
    ) {
      setCurrentUnitId(location.state.unitId);
      setCurrentSubunit({
        id: location.state.subunitId,
        name: location.state.subunitName,
      });
      setCurrentSubunitName(location.state.subunitName);
    }
  }, [location.state]);

  const handleBackgroundChange = useCallback(
    async (subunitId, backgroundImage) => {
      if (!subunitId || !currentUnitId || !courseId) {
        return;
      }

      try {
        const user = auth.currentUser;
        if (!user) {
          return;
        }

        const layoutRef = doc(
          db,
          "users",
          user.uid,
          "courses",
          courseId,
          "curriculum",
          "layout",
          "subunits",
          subunitId
        );

        await setDoc(
          layoutRef,
          {
            backgroundImage: backgroundImage,
            updatedAt: serverTimestamp(),
          },
          { merge: true }
        );

        setBackgroundImage(backgroundImage);

        setCurriculum((prev) => {
          const newCurriculum = { ...prev };
          if (newCurriculum.subunits[currentUnitId]) {
            newCurriculum.subunits[currentUnitId] = newCurriculum.subunits[
              currentUnitId
            ].map((subunit) =>
              subunit.id === subunitId
                ? { ...subunit, backgroundImage: backgroundImage }
                : subunit
            );
          }

          return newCurriculum;
        });
      } catch (error) {
        setBackgroundImage(null);
      }
    },
    [currentUnitId, courseId, subunitId]
  );

  useEffect(() => {
    // 배경 이미지 상태 초기화
    setBackgroundImage(null);

    const fetchSubunitData = async () => {
      if (!subunitId || !courseId || !auth.currentUser) return;

      try {
        const layoutRef = doc(
          db,
          "users",
          auth.currentUser.uid,
          "courses",
          courseId,
          "curriculum",
          "layout",
          "subunits",
          subunitId
        );

        const layoutDoc = await getDoc(layoutRef);
        if (layoutDoc.exists()) {
          const data = layoutDoc.data();

          // 레이아웃 데이터가 있으면 적용
          if (data.layout) {
            setLayout(data.layout);
          } else {
            // 새 서브유닛이면 기본 레이아웃 적용
            setLayout("layout1");
          }

          // 배경 이미지가 있는 경우에만 설정
          if (data.backgroundImage) {
            setBackgroundImage(data.backgroundImage);
          }
        } else {
          setLayout("layout1"); // 새 서브유닛이면 기본 레이아웃
        }
      } catch (error) {
        setLayout("layout1"); // 에러 시에도 기본 레이아웃
      }
    };

    fetchSubunitData();
  }, [subunitId, courseId, currentUnitId]);

  useEffect(() => {
    const applyBackground = () => {
      const mainContent = document.querySelector(".main-content");
      if (!mainContent) return;

      if (backgroundImage) {
        mainContent.style.backgroundImage = `url(${backgroundImage})`;
        mainContent.style.backgroundSize = "cover";
        mainContent.style.backgroundPosition = "center";
        mainContent.style.backgroundRepeat = "no-repeat";
      } else {
        mainContent.style.backgroundImage = "none";
        mainContent.style.backgroundColor = "#ffffff";
      }
    };

    applyBackground();
  }, [backgroundImage, subunitId]);

  useEffect(() => {
    if (curriculum.subunits && currentUnitId && subunitId) {
      const subunits = curriculum.subunits[currentUnitId];
      if (subunits?.length) {
        const targetSubunit = subunits.find((s) => s.id === subunitId);

        if (targetSubunit) {
          setCurrentSubunit({ id: targetSubunit.id, name: targetSubunit.name });
          setCurrentSubunitName(targetSubunit.name);
        }
      }
    }
  }, [curriculum.subunits, currentUnitId, subunitId]);

  useEffect(() => {
    if (curriculum.subunits && currentUnitId && currentSubunit.id) {
      const subunit = curriculum.subunits[currentUnitId]?.find(
        (s) => s.id === currentSubunit.id
      );

      if (subunit && subunit.name !== currentSubunitName) {
        setCurrentSubunitName(subunit.name);
      }
    }
  }, [
    curriculum.subunits,
    currentUnitId,
    currentSubunit.id,
    currentSubunitName,
  ]);
  const handleLayoutChange = useCallback(
    async (subunitId, newLayout) => {
      if (!subunitId || !currentUnitId || !courseId) {
        return;
      }

      try {
        const user = auth.currentUser;
        if (!user) {
          return;
        }

        const layoutRef = doc(
          db,
          "users",
          user.uid,
          "courses",
          courseId,
          "curriculum",
          "layout",
          "subunits",
          subunitId
        );

        await setDoc(
          layoutRef,
          {
            layout: newLayout,
            updatedAt: serverTimestamp(),
          },
          { merge: true }
        );
      } catch (error) {}
    },
    [currentUnitId, courseId, currentSubunit.id, layout]
  );

  const handleDeleteSubunit = useCallback(
    async (unitId, subunitId) => {
      try {
        // 1. Curriculum 상태 업데이트
        setCurriculum((prevCurriculum) => {
          const newCurriculum = { ...prevCurriculum };
          if (newCurriculum.subunits[unitId]) {
            newCurriculum.subunits[unitId] = newCurriculum.subunits[
              unitId
            ].filter((subunit) => subunit.id !== subunitId);
          }
          return newCurriculum;
        });

        // 2. Firebase에서 관련 데이터 삭제
        if (!auth.currentUser) return;

        const batch = writeBatch(db);

        // 기본 컬렉션 삭제 (subunits 아래 문서들)
        const subunitCollections = ["canvas", "textbox", "stamps"];
        for (const collection of subunitCollections) {
          const docRef = doc(
            db,
            "users",
            auth.currentUser.uid,
            "courses",
            courseId,
            "curriculum",
            collection,
            "subunits",
            subunitId
          );
          batch.delete(docRef);
        }

        // layout 문서에서 해당 subunit 필드 삭제
        const layoutRef = doc(
          db,
          "users",
          auth.currentUser.uid,
          "courses",
          courseId,
          "curriculum",
          "layout"
        );
        batch.update(layoutRef, {
          [subunitId]: deleteField(),
          lastModified: serverTimestamp(),
        });

        // 일괄 삭제 실행
        await batch.commit();

        // 3. 네비게이션 처리
        if (currentSubunit.id === subunitId) {
          const currentUnitSubunits = curriculum.subunits[unitId] || [];
          if (currentUnitSubunits.length > 0) {
            const nextSubunit = currentUnitSubunits[0];
            navigate(
              `/courses/${courseId}/curriculum/${courseName}/unit/${unitId}/subunit/${nextSubunit.id}`
            );
          } else {
            navigate(`/courses/${courseId}/curriculum/${courseName}`);
          }
        }
      } catch (error) {
        console.error("서브유닛 삭제 실패:", error);
      }
    },
    [courseId, currentUnitId, currentSubunit.id, curriculum, navigate]
  );

  const handleTableDataChange = useCallback(
    (unitId, subunitId, newTableData) => {
      setCurriculum((prevCurriculum) => {
        const newCurriculum = { ...prevCurriculum };
        if (newCurriculum.subunits[unitId]) {
          newCurriculum.subunits[unitId] = newCurriculum.subunits[unitId].map(
            (subunit) =>
              subunit.id === subunitId
                ? {
                    ...subunit,
                    tableStructure: {
                      ...subunit.tableStructure,
                      tableData: newTableData.tableData,
                      columnWidths: newTableData.columnWidths,
                      editorWidths: newTableData.editorWidths,
                    },
                  }
                : subunit
          );
        }
        return newCurriculum;
      });
    },
    []
  );

  // Function to save canvas data to Firebase
  const saveCanvasData = async (canvasData) => {
    try {
      if (!auth.currentUser || !currentSubunit.id) {
        // 초기 로딩 중이므로 조용히 리턴
        return;
      }

      const canvasRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "canvas",
        "subunits",
        currentSubunit.id
      );

      await setDoc(canvasRef, {
        data: canvasData,
        lastModified: serverTimestamp(),
      });
    } catch (error) {
      // 에러 발생 시 조용히 처리
    }
  };

  // 디바운스된 캔버스 저장 함수
  const debouncedSaveCanvas = useCallback(
    debounce(async (canvasData) => {
      try {
        await saveCanvasData(canvasData);
      } catch (error) {
        // 에러 발생 시 조용히 처리
      }
    }, 1000),
    [courseId, currentSubunit.id]
  );

  // handleCanvasDataChange 수정
  const handleCanvasDataChange = useCallback(
    async (unitId, subunitId, canvasData) => {
      // 이전 상태와 비교하여 변경사항이 있을 때만 업데이트
      setCurriculum((prev) => {
        const currentSubunit = prev.subunits[unitId]?.find(
          (s) => s.id === subunitId
        );
        const currentCanvasData = currentSubunit?.canvasData;

        if (JSON.stringify(currentCanvasData) === JSON.stringify(canvasData)) {
          return prev;
        }

        const newCurriculum = { ...prev };
        if (newCurriculum.subunits[unitId]) {
          const updatedSubunits = newCurriculum.subunits[unitId].map(
            (subunit) =>
              subunit.id === subunitId
                ? {
                    ...subunit,
                    canvasData: {
                      ...canvasData,
                      lastModified: new Date().toISOString(),
                    },
                  }
                : subunit
          );
          return {
            ...newCurriculum,
            subunits: {
              ...newCurriculum.subunits,
              [unitId]: updatedSubunits,
            },
          };
        }
        return newCurriculum;
      });

      debouncedSaveCanvas(canvasData);
    },
    [debouncedSaveCanvas]
  );

  // Firebase에서 캔버스 데이터 불러오는 함수 수정
  const loadCanvasData = async () => {
    try {
      if (!auth.currentUser) {
        throw new Error("로그인이 필요합니다");
      }

      // 문서 경로 수정
      const canvasRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "canvas",
        "subunits",
        currentSubunit.id
      );

      const canvasDoc = await getDoc(canvasRef);

      if (canvasDoc.exists()) {
        return canvasDoc.data().data;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  // 컴포넌트 마운트/언마운트 시 처리
  useEffect(() => {
    let isSubscribed = true; // 컴포넌트 마운트 상태 추적

    if (currentSubunit.id && courseId) {
      loadCanvasData().then((canvasData) => {
        if (canvasData && isSubscribed) {
          setCurriculum((prev) => {
            if (!prev.subunits) {
              prev.subunits = {};
            }
            if (!prev.subunits[currentUnitId]) {
              prev.subunits[currentUnitId] = [];
            }

            const subunitIndex = prev.subunits[currentUnitId].findIndex(
              (s) => s.id === currentSubunit.id
            );

            const newSubunits = [...prev.subunits[currentUnitId]];

            if (subunitIndex === -1) {
              newSubunits.push({
                id: currentSubunit.id,
                name: currentSubunit.name,
                canvasData: canvasData,
              });
            } else {
              newSubunits[subunitIndex] = {
                ...newSubunits[subunitIndex],
                canvasData: canvasData,
              };
            }

            const newState = {
              ...prev,
              subunits: {
                ...prev.subunits,
                [currentUnitId]: newSubunits,
              },
            };

            return newState;
          });
        }
      });
    }

    return () => {
      isSubscribed = false;
    };
  }, [currentSubunit.id, courseId, currentUnitId]);

  const handleInsertYoutubeVideo = useCallback((videoUrl) => {
    setInsertedYoutubeVideos((prev) => [
      ...prev,
      {
        url: videoUrl,
        id: `video-${Date.now()}`,
        position: { x: 0, y: 0 },
        size: { width: 560, height: 315 },
        rotation: 0,
      },
    ]);
  }, []);

  const handleYoutubeVideoAdd = (videoData) => {
    setInsertedYoutubeVideos((prev) => [...prev, videoData]);
  };

  const handleYoutubeVideoPositionUpdate = useCallback((videoId, transform) => {
    const matrix = new DOMMatrix(transform);
    const newPosition = {
      x: Math.round(matrix.e),
      y: Math.round(matrix.f),
    };
    const newRotation = Math.round(matrix.rotation);

    setInsertedYoutubeVideos((prev) =>
      prev.map((video) =>
        video.id === videoId
          ? {
              ...video,
              position: newPosition,
              rotation: newRotation,
            }
          : video
      )
    );
  }, []);

  const handleYoutubeVideoDelete = useCallback((videoId) => {
    setInsertedYoutubeVideos((prev) =>
      prev.filter((video) => video.id !== videoId)
    );
  }, []);
  // 텍스트박스 저장 함수 수정
  const saveTextBoxData = async (textBoxData) => {
    try {
      if (!auth.currentUser) {
        throw new Error("사용자 인증 필요");
      }

      const textBoxRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "textbox",
        "subunits",
        currentSubunit.id
      );

      await setDoc(textBoxRef, {
        data: textBoxData,
        updatedAt: serverTimestamp(),
      });

      return true;
    } catch (error) {
      throw error;
    }
  };

  // 텍스트박스 로드 함수 수정
  const loadTextBoxData = async () => {
    try {
      if (!auth.currentUser) {
        throw new Error("로그인이 필요니다");
      }

      const textBoxRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "textbox",
        "subunits",
        currentSubunit.id
      );

      const textBoxDoc = await getDoc(textBoxRef);
      if (textBoxDoc.exists()) {
        return textBoxDoc.data().data;
      }
      return [];
    } catch (error) {
      throw error;
    }
  };

  // 디바운스된 저장 함수
  const debouncedSaveTextBox = useCallback(
    debounce(async (textBoxData) => {
      try {
        await saveTextBoxData(textBoxData);
      } catch (error) {}
    }, 500),
    [courseId, currentSubunit.id]
  );
  // 텍스트박스 데이터 변경 핸들러 수정
  const handleTextBoxDataChange = useCallback(
    async (textBoxData) => {
      // 로컬 상태 업데이트
      setTextBoxes((prev) => {
        const newState = {
          ...prev,
          [currentSubunit.id]: textBoxData,
        };
        return newState;
      });

      // Firebase 저장
      try {
        await debouncedSaveTextBox(textBoxData);
      } catch (error) {
        console.error("텍스트박스 저장 중 오류 발생:", error);
      }
    },
    [currentSubunit.id, debouncedSaveTextBox]
  );

  // 컴포넌트 언마운트 시 저장되지 않은 경사항 처리
  useEffect(() => {
    return () => {
      debouncedSaveTextBox.flush();
    };
  }, [debouncedSaveTextBox]);

  // 컴포넌트 마운트 시 텍스트박스 데이터 로드
  useEffect(() => {
    if (currentSubunit.id && courseId) {
      setIsLoading(true);
      loadTextBoxData()
        .then((textBoxData) => {
          if (textBoxData) {
            setTextBoxes((prev) => ({
              ...prev,
              [currentSubunit.id]: textBoxData,
            }));
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [currentSubunit.id, courseId]);

  // 스탬프 저장 함수 수정
  const saveStampData = async (stampData) => {
    try {
      if (!auth.currentUser) {
        throw new Error("로그인이 필요합니다");
      }

      const stampRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "stamps",
        "subunits",
        currentSubunit.id
      );

      await setDoc(stampRef, {
        data: stampData,
        lastModified: serverTimestamp(),
      });
    } catch (error) {
      console.error("❌ 스탬프 데이터 저장 실패:", error);
    }
  };

  // 스탬프 로드 함수 수정
  const loadStampData = async () => {
    try {
      if (!auth.currentUser || !courseId || !currentSubunit.id) {
        return;
      }

      const stampRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "stamps",
        "subunits",
        currentSubunit.id
      );

      const stampDoc = await getDoc(stampRef);
      if (stampDoc.exists()) {
        const stampData = stampDoc.data();

        // stamps 상태 업데이트
        setStamps((prevStamps) => ({
          ...prevStamps,
          [currentSubunit.id]: stampData.data || [],
        }));

        return stampData.data || [];
      }
      return [];
    } catch (error) {
      return [];
    }
  };

  // 스탬프 추가 핸들러 수정
  const handleStampAdd = useCallback(
    async (stampData) => {
      try {
        // 중복 체크를 위해 현재 스탬프 목록 가져오기
        const currentStamps = stamps[currentSubunit.id] || [];

        // 새로운 스탬프가 배열인지 단일 객체인지 확인
        const newStamps = Array.isArray(stampData) ? stampData : [stampData];

        // 중복 제거하며 스탬프 추가
        const uniqueStamps = [...currentStamps];
        newStamps.forEach((newStamp) => {
          if (!uniqueStamps.some((stamp) => stamp.id === newStamp.id)) {
            uniqueStamps.push(newStamp);
          }
        });

        // 상태 업데이트
        setStamps((prev) => ({
          ...prev,
          [currentSubunit.id]: uniqueStamps,
        }));

        // Firebase에 저장
        try {
          await saveStampData(uniqueStamps);
        } catch (error) {
          console.error("❌ 스탬프 저장 실패:", error);
        }
      } catch (error) {
        console.error("스탬프 추가 실패:", error);
      }
    },
    [currentSubunit.id, courseId, stamps]
  );

  const handleAddToReminderList = (text) => {
    setReminderListItems((prev) => [...prev, text]);
  };

  const handleAddToVocabulary = (text) => {
    setVocabularyListItems([
      ...vocabularyListItems,
      { word: text, meaning: "" },
    ]);
  };

  const handleUpdateImage = useCallback((imageId, updateData) => {
    setInsertedImages((prev) =>
      prev.map((image) => {
        if (image.id === imageId) {
          return { ...image, ...updateData };
        }
        return image;
      })
    );
  }, []);

  const handleDeleteImage = useCallback(
    async (imageId) => {
      try {
        const userId = auth.currentUser?.uid;
        if (!userId) return;

        // Firestore에서 이미지 참조 삭제
        const courseDocRef = doc(db, `users/${userId}/courses/${courseId}`);
        const courseDoc = await getDoc(courseDocRef);

        if (courseDoc.exists()) {
          const courseData = courseDoc.data();

          // curriculum.images.subunits 경로의 데이터 구조 확인 및 업데이트
          if (courseData.curriculum?.images?.subunits?.[subunitId]) {
            const currentImages =
              courseData.curriculum.images.subunits[subunitId];
            const updatedImages = currentImages.filter(
              (img) => Number(img.id) !== Number(imageId)
            );

            // 전체 curriculum 구조를 유지하면서 해당 subunit의 이미지만 업데이트
            await updateDoc(courseDocRef, {
              [`curriculum.images.subunits.${subunitId}`]: updatedImages,
            });
          }
        }
      } catch (error) {
        console.error("이미지 삭제 실패:", error);
      }
    },
    [courseId, subunitId]
  );

  const debouncedUpdate = useCallback(
    debounce(async (updates) => {
      try {
        pendingUpdates.current = false;
      } catch (error) {
        console.error("데이터 저장  오류:", error);
      }
    }, 1000),
    [courseId]
  );
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const lastSaved = sessionStorage.getItem("lastSaved");
      const timeSinceLastSave = lastSaved
        ? Date.now() - parseInt(lastSaved)
        : Infinity;

      // 마지막 저장 후 30초가 지났거나 저장 기록이 없는 경우에만 저장 진행
      if (!lastSaved || timeSinceLastSave > 30000) {
        e.preventDefault();
        e.returnValue = "";
        window.dispatchEvent(new Event("saveAllData"));
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const customNavigate = useCallback(
    (to) => {
      if (pendingUpdates.current) {
        debouncedUpdate.flush();
        Promise.resolve().then(() => navigate(to));
      } else {
        navigate(to);
      }
    },
    [navigate, debouncedUpdate]
  );

  useEffect(() => {
    if (currentSubunit.id && courseId) {
      loadStampData();
    }
  }, [currentSubunit.id, courseId]);

  const currentSubunitData = useMemo(() => {
    return curriculum.subunits?.[currentUnitId]?.find(
      (s) => s.id === currentSubunit.id
    );
  }, [curriculum.subunits, currentUnitId, currentSubunit.id]);

  const getCurrentSubunitCanvasData = useCallback(() => {
    if (!currentSubunitData) return null;

    return (
      currentSubunitData.canvasData?.canvasData || currentSubunitData.canvasData
    );
  }, [currentSubunitData]);

  const handleSubunitDeleted = useCallback(async ({ courseId, subunitId }) => {
    if (!auth.currentUser) return;

    try {
      const collections = ["canvas", "textbox", "stamps"];

      for (const collection of collections) {
        const docRef = doc(
          db,
          "users",
          auth.currentUser.uid,
          "courses",
          courseId,
          "curriculum",
          collection,
          "subunits",
          subunitId
        );

        await deleteDoc(docRef);
      }
    } catch (error) {}
  }, []);

  const handleToggleRedText = useCallback((isActive) => {
    if (isActive === undefined) {
      console.warn("⚠️ isActive가 undefined로 전달됨");
      return;
    }

    const event = new CustomEvent("toggleRedText", {
      detail: { isActive },
    });
    window.dispatchEvent(event);

    setNoteEditKey((prev) => prev + 1);
  }, []);
  const handleSearch = async (searchTerm) => {
    if (!searchTerm.trim()) {
      return;
    }

    try {
      await searchAllTableData(courseId, searchTerm);
      setIsSearchModalOpen(true); // 명시적으로 모달 열기
    } catch (error) {}
  };

  const handleSearchResultClick = (result) => {
    // 현재 URL 패턴을 유지하면서 subunitId만 변경
    const newPath = `/courses/${courseId}/curriculum/${courseName}/unit/${currentUnitId}/subunit/${result.subunitId}`;

    navigate(newPath, {
      state: {
        subunitId: result.subunitId,
        highlightText: result.matchText,
      },
    });
  };

  // 스탬프 삭제 핸들러 수정
  const handleStampDelete = useCallback(
    async (stampId) => {
      try {
        if (!auth.currentUser || !courseId || !currentSubunit.id) {
          console.error("필수 데이터 누락");
          return;
        }

        // 1. 로컬 상태 업데이트
        setStamps((prev) => {
          const currentStamps = prev[currentSubunit.id] || [];
          const updatedStamps = currentStamps.filter(
            (stamp) => stamp.id !== stampId
          );

          return {
            ...prev,
            [currentSubunit.id]: updatedStamps,
          };
        });

        // 2. Firebase에서 삭제
        const stampRef = doc(
          db,
          "users",
          auth.currentUser.uid,
          "courses",
          courseId,
          "curriculum",
          "stamps",
          "subunits",
          currentSubunit.id
        );

        // 현재 스탬프 데이터 가져오기
        const stampDoc = await getDoc(stampRef);
        if (stampDoc.exists()) {
          const currentData = stampDoc.data().data || [];
          const updatedData = currentData.filter(
            (stamp) => stamp.id !== stampId
          );

          // 업데이트된 데이터 저장
          await setDoc(stampRef, {
            data: updatedData,
            lastModified: serverTimestamp(),
          });
        }
      } catch (error) {
        console.error("스탬프 삭제 실패:", error);
      }
    },
    [courseId, currentSubunit.id]
  );

  // 컴포넌트 마운트/언마운트 시 상태 유지를 위한 useEffect 추가
  useEffect(() => {
    if (!initialLoad) return;

    const savedRedTextState = localStorage.getItem(
      `redTextState-${currentSubunit.id}`
    );
    if (savedRedTextState !== null) {
      const isActive = JSON.parse(savedRedTextState);
      handleToggleRedText(isActive);
    }
    setInitialLoad(false);
  }, [currentSubunit.id, handleToggleRedText, initialLoad]);

  // subunit이 변경될 때 초기화
  useEffect(() => {
    setInitialLoad(true);
  }, [currentSubunit.id]);

  // 초기 진입 시 subunitId 처리 로직 추가
  useEffect(() => {
    if (!subunitId && curriculum.subunits && currentUnitId) {
      const firstSubunit = curriculum.subunits[currentUnitId]?.[0];

      if (firstSubunit) {
        navigate(
          `/courses/${courseId}/curriculum/${courseName}/unit/${currentUnitId}/subunit/${firstSubunit.id}`
        );
      }
    }
  }, [subunitId, curriculum.subunits, currentUnitId, courseId, courseName]);

  // beforeunload 이벤트 핸들러를 Unitnote로 이동
  useEffect(() => {
    const handleBeforeUnload = () => {
      window.dispatchEvent(new Event("saveAllData"));
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <TableProvider>
      <ToolbarProvider>
        <div
          className="unitnote-page"
          style={{ backgroundColor: "#000000" }}
          translate="no"
        >
          <Topbar onSearch={handleSearch} />
          <SearchResultModal
            onResultClick={handleSearchResultClick}
            isSearchModalOpen={isSearchModalOpen}
            searchResults={searchResults}
            setIsSearchModalOpen={setIsSearchModalOpen}
          />
          <LeftBar
            showSections={showSections}
            showChapters={showChapters}
            ref={leftBarRef}
            onToggle={handleSidebarToggle}
            isOpen={isSidebarOpen}
            currentUnitId={currentUnitId}
            currentSubunit={currentSubunit}
            onWidthChange={handleLeftBarResize}
            style={{
              transition: "width 0.1s ease-out",
              width: `${leftBarWidth}px`,
            }}
            onSectionVisibilityChange={handleSectionVisibilityChange}
            onChapterVisibilityChange={handleChapterVisibilityChange}
            onSubunitClick={(unit, subunit) =>
              handleSubunitClick(unit, subunit)
            }
            onDeleteSubunit={handleDeleteSubunit}
          />
          <Toolbar
            onPenModeChange={handlePenModeChange}
            onPenColorChange={handlePenColorChange}
            onPenSizeChange={handlePenSizeChange}
            onEraserModeChange={handleEraserModeChange}
            onEraserSizeChange={handleEraserSizeChange}
            onLineWidthChange={handleLineWidthChange}
            onLineStyleChange={handleLineStyleChange}
            onPenOpacityChange={handlePenOpacityChange}
            onLineColorChange={handleLineColorChange}
            onCanvasDataChange={handleCanvasDataChange}
            unitId={currentUnitId}
            subunitId={currentSubunit.id}
            stampNames={stampNames}
            onYoutubeVideoAdd={handleYoutubeVideoAdd}
            insertedYoutubeVideos={insertedYoutubeVideos}
            onVideoPositionUpdate={handleYoutubeVideoPositionUpdate}
            onYoutubeVideoDelete={handleYoutubeVideoDelete}
            onTextBoxDataChange={handleTextBoxDataChange}
            initialTextBoxes={textBoxes[currentSubunit.id] || []}
            onStampAdd={handleStampAdd}
            initialStamps={stamps[currentSubunit.id] || []}
            setScale={setScale}
            onStampDelete={handleStampDelete}
            courseId={courseId}
            currentSubunitId={currentSubunit.id}
            layout={layout} // 레이아웃 정보 추가
          />
          <div
            ref={contentRef}
            className={`content ${
              isSidebarOpen ? "sidebar-open" : "sidebar-closed"
            }`}
            style={{
              transform: `scale(${scale})`,
              transformOrigin: "left top",
              marginLeft: `${leftBarWidth}px`,
              transition: "margin-left 0.1s ease-out",
              backgroundColor: "#000000",
              height: `${100 / scale}vh`,
              width: `${100 / scale}vw`,
              textRendering: "optimizeLegibility",
              WebkitFontSmoothing: "antialiased",
              MozOsxFontSmoothing: "grayscale",
            }}
          >
            <Noteedit
              key={`${currentUnitId}-${subunitId}-${noteEditKey}`}
              unitId={currentUnitId}
              subunitName={
                currentSubunitName || currentSubunit?.name || "Subunit Name"
              }
              subunitId={subunitId}
              layout={layout}
              insertedImages={insertedImages}
              insertedAudios={insertedAudios}
              insertedVideos={insertedVideos}
              content={subunitContents[currentSubunit.id]?.mainContent || ""}
              onContentChange={handleContentChange}
              isPenMode={isPenMode}
              isEraserMode={isEraserMode}
              penColor={penColor}
              penSize={penSize}
              eraserSize={eraserSize}
              lineStyle={lineStyle}
              penOpacity={penOpacity}
              onAddToReminderList={handleAddToReminderList}
              onAddToVocabulary={(text) => handleAddToVocabulary(text)}
              courseId={courseId}
              onTableDataChange={handleTableDataChange}
              tableData={tableData[currentSubunit.id] || []}
              backgroundImage={currentSubunit.background}
              onCanvasDataChange={handleCanvasDataChange}
              initialCanvasData={getCurrentSubunitCanvasData()}
              onStampAdd={handleStampAdd}
              initialStamps={stamps[currentSubunit.id] || []}
              onUpdateImage={handleUpdateImage}
              onDeleteImage={handleDeleteImage}
              onStampDelete={handleStampDelete}
              textBoxes={textBoxes[currentSubunit.id] || []}
              onTextBoxesChange={handleTextBoxDataChange}
              onLoadComplete={handleNoteEditLoadComplete}
              disabled={isTransitioning}
            />
          </div>
          <RightSidebar
            onSelectLayout={handleSelectLayout}
            onInsertImage={handleInsertImage}
            onInsertAudio={handleInsertAudio}
            onInsertVideo={handleInsertVideo}
            reminderListItems={reminderListItems}
            vocabularyListItems={vocabularyListItems}
            courseId={courseId}
            currentSubunitId={currentSubunit.id}
            onBackgroundChange={handleBackgroundChange}
            onLayoutChange={handleLayoutChange}
          />
          <Bottombar>
            <ZoomControls onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />
          </Bottombar>
        </div>
      </ToolbarProvider>
    </TableProvider>
  );
};

export default Unitnote;
