import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { db, auth } from "../firebase/firebase";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import debounce from "lodash/debounce";
import { onAuthStateChanged } from "firebase/auth";
import eventEmitter from "../utils/eventEmitter";
import { deleteSubunitData } from "../utils/subunitDeleteHelper";

const CurriculumContext = createContext();

// Utility functions
const generateUniqueName = (baseName, existingNames) => {
  let newName = baseName;
  let counter = 1;
  while (existingNames.includes(newName)) {
    newName = `${baseName} ${counter}`;
    counter++;
  }
  return newName;
};

const initialCurriculumState = {
  sections: [],
  chapters: {},
  units: {},
  subunits: {},
  toggleState: {
    showSections: true,
    showChapters: true,
  },
};

export const CurriculumProvider = ({ children }) => {
  const [curriculum, setCurriculum] = useState(initialCurriculumState);
  const [isLoading, setIsLoading] = useState(true);
  const [courseId, setCourseId] = useState(null);
  const courseIdRef = useRef(null);

  // courseId setting function
  const handleSetCourseId = useCallback((id) => {
    setCourseId(id);
    courseIdRef.current = id;
  }, []);

  // Curriculum load function
  const loadCurriculum = useCallback(async () => {
    const user = auth.currentUser;
    const currentCourseId = courseIdRef.current;

    if (user && currentCourseId) {
      try {
        setIsLoading(true);
        const curriculumRef = doc(
          db,
          "users",
          user.uid,
          "courses",
          currentCourseId,
          "curriculum",
          "main"
        );

        const curriculumDoc = await getDoc(curriculumRef);

        if (curriculumDoc.exists()) {
          const data = curriculumDoc.data();
          setCurriculum({
            sections: data.sections || [],
            chapters: data.chapters || {},
            units: data.units || {},
            subunits: data.subunits || {},
          });
        }
      } catch (error) {
        console.error("🚨 CurriculumContext - Error loading curriculum", error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  // Extract courseId from URL and set it
  useEffect(() => {
    const params = new URLSearchParams(window.location.pathname);
    const currentCourseId =
      params.get("courseId") || window.location.pathname.split("/")[2];

    if (currentCourseId) {
      handleSetCourseId(currentCourseId);
    }
  }, []);

  // Detect Firebase auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && courseIdRef.current) {
        loadCurriculum();
      }
    });

    return () => unsubscribe();
  }, [loadCurriculum]);

  // Firebase update function modification
  const [error, setError] = useState(null);

  const updateFirebase = useCallback(async (newCurriculum, path = null) => {
    try {
      if (!auth.currentUser) throw new Error("Login required");
      if (!courseIdRef.current) throw new Error("No courseId");

      const user = auth.currentUser;
      const currentCourseId = courseIdRef.current;

      if (user && currentCourseId) {
        const curriculumRef = doc(
          db,
          "users",
          user.uid,
          "courses",
          currentCourseId,
          "curriculum",
          "main"
        );

        if (path) {
          // Update specific path
          await updateDoc(curriculumRef, {
            [path]: newCurriculum[path],
            lastUpdated: serverTimestamp(),
          });
        } else {
          // Update entire curriculum
          await setDoc(curriculumRef, {
            ...newCurriculum,
            lastUpdated: serverTimestamp(),
          });
        }
      }
    } catch (error) {
      setError(error.message);
      throw error;
    }
  }, []);

  const debouncedUpdateFirebase = useCallback(
    debounce((newCurriculum) => {
      updateFirebase(newCurriculum);
    }, 1000),
    []
  );

  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      debouncedUpdateFirebase.cancel();
    };
  }, [debouncedUpdateFirebase]);

  // Section related functions
  const addSection = useCallback(() => {
    setCurriculum((prev) => {
      const newCurriculum = { ...prev };
      const newSectionIndex = newCurriculum.sections.length;
      const newSectionName = generateUniqueName(
        "New Section",
        newCurriculum.sections
      );

      newCurriculum.sections.push(newSectionName);
      newCurriculum.chapters[newSectionIndex] = ["New Chapter"];

      const unitId = uuidv4();
      newCurriculum.units[`${newSectionIndex}-0`] = [
        {
          unitId,
          unitName: "New Unit",
          planDate: null,
          lastReviewDate: null,
          reviewCount: 0,
          isCompleted: false,
        },
      ];

      newCurriculum.subunits[unitId] = [];

      // Firebase update
      debouncedUpdateFirebase(newCurriculum);
      return newCurriculum;
    });
  }, [courseIdRef.current]);

  const deleteSection = useCallback(
    (sectionIndex) => {
      let subunitsToDeleteData = []; // 서브유닛 데이터 삭제를 위한 배열

      setCurriculum((prev) => {
        const newCurriculum = { ...prev };

        // 1. Collect all unit IDs and subunit IDs to delete
        const unitsToDelete = [];
        const subunitIdsToDelete = [];

        prev.chapters[sectionIndex]?.forEach((_, chapterIndex) => {
          const key = `${sectionIndex}-${chapterIndex}`;
          const units = prev.units[key] || [];

          units.forEach((unit) => {
            unitsToDelete.push(unit.unitId);
            const subunits = prev.subunits[unit.unitId] || [];
            subunits.forEach((subunit) => {
              subunitIdsToDelete.push(subunit.id);
              subunitsToDeleteData.push(subunit.id); // 데이터 삭제용 배열에 추가
            });
          });
        });

        subunitIdsToDelete.forEach((subunitId) => {
          eventEmitter.emit("subunitDeleted", {
            courseId: courseIdRef.current,
            subunitId,
          });
        });

        // 3. Delete section
        newCurriculum.sections.splice(sectionIndex, 1);

        // 4. Create new objects for index reordering
        const newChapters = {};
        const newUnits = {};

        // 5. Reorder indexes for remaining sections
        newCurriculum.sections.forEach((section, newIndex) => {
          if (newIndex >= sectionIndex) {
            // Move data after deleted section
            if (prev.chapters[newIndex + 1]) {
              newChapters[newIndex] = prev.chapters[newIndex + 1];

              // Move unit data for chapters in this section
              prev.chapters[newIndex + 1].forEach((_, chapterIndex) => {
                const oldKey = `${newIndex + 1}-${chapterIndex}`;
                const newKey = `${newIndex}-${chapterIndex}`;
                if (prev.units[oldKey]) {
                  newUnits[newKey] = prev.units[oldKey];
                }
              });
            }
          } else {
            // Keep data before deleted section unchanged
            newChapters[newIndex] = prev.chapters[newIndex];
            prev.chapters[newIndex]?.forEach((_, chapterIndex) => {
              const key = `${newIndex}-${chapterIndex}`;
              if (prev.units[key]) {
                newUnits[key] = prev.units[key];
              }
            });
          }
        });

        // 6. Update with reorganized data
        newCurriculum.chapters = newChapters;
        newCurriculum.units = newUnits;

        // 7. Remove subunits of deleted units
        unitsToDelete.forEach((unitId) => {
          delete newCurriculum.subunits[unitId];
        });

        debouncedUpdateFirebase(newCurriculum);
        return newCurriculum;
      });

      // 서브유닛 데이터 삭제 추가
      const user = auth.currentUser;
      if (user && subunitsToDeleteData.length > 0) {
        subunitsToDeleteData.forEach(async (subunitId) => {
          await deleteSubunitData(user.uid, courseIdRef.current, subunitId);
        });
      }
    },
    [courseIdRef.current]
  );

  const handleSectionNameChange = useCallback((sectionIndex, value) => {
    setCurriculum((prev) => {
      // Create list of other section names
      const otherSectionNames = prev.sections.filter(
        (_, idx) => idx !== sectionIndex
      );

      // Generate unique name if duplicate
      const uniqueName = generateUniqueName(
        value || "New Section",
        otherSectionNames
      );

      const updatedSections = [...prev.sections];
      updatedSections[sectionIndex] = uniqueName;

      const newCurriculum = { ...prev, sections: updatedSections };
      debouncedUpdateFirebase(newCurriculum);
      return newCurriculum;
    });
  }, []);

  // Chapter related functions
  const addChapter = useCallback(
    (sectionIndex) => {
      setCurriculum((prev) => {
        const newCurriculum = { ...prev };

        // 1. Initialize chapter array if not exists
        if (!newCurriculum.chapters[sectionIndex]) {
          newCurriculum.chapters[sectionIndex] = [];
        }

        // 2. Generate new chapter name (prevent duplicates)
        const chapterIndex = newCurriculum.chapters[sectionIndex].length;
        const newChapterName = generateUniqueName(
          "New Chapter",
          newCurriculum.chapters[sectionIndex]
        );

        // 3. Add chapter
        newCurriculum.chapters[sectionIndex].push(newChapterName);

        // 4. Add default unit for new chapter
        const unitId = uuidv4();
        const key = `${sectionIndex}-${chapterIndex}`;
        newCurriculum.units[key] = [
          {
            unitId,
            unitName: "New Unit",
            planDate: null,
            lastReviewDate: null,
            reviewCount: 0,
            isCompleted: false,
          },
        ];

        // 5. Initialize empty subunits array for unit
        newCurriculum.subunits[unitId] = [];

        // 6. Firebase update
        debouncedUpdateFirebase(newCurriculum);

        return newCurriculum;
      });
    },
    [courseIdRef.current]
  );

  const handleChapterNameChange = useCallback(
    (sectionIndex, chapterIndex, value) => {
      setCurriculum((prev) => {
        const updatedChapters = { ...prev.chapters };
        if (!updatedChapters[sectionIndex]) {
          updatedChapters[sectionIndex] = [];
        }

        // Get other chapter names in same section
        const otherChapterNames = updatedChapters[sectionIndex].filter(
          (_, idx) => idx !== chapterIndex
        );

        // Generate unique name if duplicate
        const uniqueName = generateUniqueName(value || " ", otherChapterNames);

        updatedChapters[sectionIndex][chapterIndex] = uniqueName;
        const newCurriculum = { ...prev, chapters: updatedChapters };

        debouncedUpdateFirebase(newCurriculum);
        return newCurriculum;
      });
    },
    []
  );

  // Unit related functions
  const handleUnitChange = useCallback(
    (sectionIndex, chapterIndex, unitIndex, field, value) => {
      const sanitizedValue =
        value instanceof Date
          ? value.toISOString()
          : value === undefined
          ? ""
          : value;

      setCurriculum((prev) => {
        const updatedUnits = { ...prev.units };
        const key = `${sectionIndex}-${chapterIndex}`;
        updatedUnits[key][unitIndex][field] = sanitizedValue;
        const newCurriculum = { ...prev, units: updatedUnits };

        debouncedUpdateFirebase(newCurriculum);

        return newCurriculum;
      });
    },
    [debouncedUpdateFirebase]
  );

  // Add review count change function
  const handleReviewChange = useCallback(
    (sectionIndex, chapterIndex, unitIndex, increment) => {
      if (!courseId) {
        console.warn("No courseId");
        return;
      }

      setCurriculum((prev) => {
        const updatedUnits = { ...prev.units };
        const key = `${sectionIndex}-${chapterIndex}`;
        const currentCount = updatedUnits[key][unitIndex].reviewCount;
        const newCount = Math.max(0, currentCount + increment);
        updatedUnits[key][unitIndex].reviewCount = newCount;

        const newCurriculum = { ...prev, units: updatedUnits };

        // Firebase update
        debouncedUpdateFirebase(newCurriculum);

        return newCurriculum;
      });
    },
    [courseId]
  );

  // Detect courseId changes with useEffect
  useEffect(() => {}, [courseId]);

  const addUnit = useCallback((sectionIndex, chapterIndex) => {
    setCurriculum((prev) => {
      const updatedUnits = { ...prev.units };
      const existingUnitNames = Object.values(updatedUnits)
        .flat()
        .map((unit) => unit.unitName);
      const newUnitName = generateUniqueName("New Unit", existingUnitNames);
      const newUnitId = uuidv4();

      const key = `${sectionIndex}-${chapterIndex}`;
      if (!updatedUnits[key]) {
        updatedUnits[key] = [];
      }

      const newUnit = {
        unitId: newUnitId,
        unitName: newUnitName,
        planDate: null,
        lastReviewDate: null,
        reviewCount: 0,
        isCompleted: false,
      };

      updatedUnits[key] = [...updatedUnits[key], newUnit];

      const newCurriculum = {
        ...prev,
        units: updatedUnits,
        subunits: {
          ...prev.subunits,
          [newUnitId]: [],
        },
      };

      // Firebase update
      debouncedUpdateFirebase(newCurriculum);

      return newCurriculum;
    });
  }, []);

  const deleteUnit = useCallback(
    async (sectionIndex, chapterIndex, unitIndex) => {
      try {
        const unitKey = `${sectionIndex}-${chapterIndex}`;
        const unitToDelete = curriculum.units[unitKey][unitIndex];

        if (!unitToDelete || !unitToDelete.unitId) {
          return;
        }

        const subunitsToDelete = curriculum.subunits[unitToDelete.unitId] || [];

        // 서브유닛 데이터 직접 삭제
        const user = auth.currentUser;
        if (user) {
          for (const subunit of subunitsToDelete) {
            await deleteSubunitData(user.uid, courseIdRef.current, subunit.id);
          }
        }

        // 커리큘럼 상태 업데이트
        setCurriculum((prevCurriculum) => {
          const newCurriculum = { ...prevCurriculum };

          // Remove unit from array
          newCurriculum.units[unitKey] = newCurriculum.units[unitKey].filter(
            (_, index) => index !== unitIndex
          );

          // Remove unit's subunits data
          delete newCurriculum.subunits[unitToDelete.unitId];

          // Firebase update
          debouncedUpdateFirebase(newCurriculum, {
            [`units.${unitKey}`]: newCurriculum.units[unitKey],
            [`subunits.${unitToDelete.unitId}`]: null,
          });

          return newCurriculum;
        });
      } catch (error) {}
    },
    [curriculum, courseIdRef.current]
  );

  // Add new function for order changes
  const handleReorderCurriculum = useCallback(
    (newCurriculum) => {
      // Start with deep copy
      const reorderedCurriculum = JSON.parse(JSON.stringify(newCurriculum));

      // 1. Reconstruct chapter data
      const oldChapters = { ...reorderedCurriculum.chapters };
      const newChapters = {};

      // 2. Reconstruct unit data
      const oldUnits = { ...reorderedCurriculum.units };
      const newUnits = {};

      // 3. Remap data according to section order
      reorderedCurriculum.sections.forEach((section, newIndex) => {
        // Find previous index (position in original curriculum)
        const oldIndex = curriculum.sections.indexOf(section);

        // Move chapters
        if (oldChapters[oldIndex]) {
          newChapters[newIndex] = oldChapters[oldIndex];
        }

        // Move units
        Object.keys(oldUnits).forEach((key) => {
          const [sectionIdx, chapterIdx] = key.split("-");
          if (parseInt(sectionIdx) === oldIndex) {
            newUnits[`${newIndex}-${chapterIdx}`] = oldUnits[key];
          }
        });
      });

      // 4. Construct final curriculum
      const finalCurriculum = {
        ...reorderedCurriculum,
        chapters: newChapters,
        units: newUnits,
      };

      // 5. Update Firebase and state
      debouncedUpdateFirebase(finalCurriculum);
      return finalCurriculum;
    },
    [curriculum]
  );

  // Add toggle state update function
  const updateToggleState = useCallback(async (showSections, showChapters) => {
    setCurriculum((prev) => {
      const newCurriculum = {
        ...prev,
        toggleState: {
          showSections,
          showChapters,
        },
      };

      debouncedUpdateFirebase(newCurriculum);
      return newCurriculum;
    });
  }, []);
  // Keep existing addSubunit (for initial creation)
  const addSubunit = useCallback((unitId) => {
    return new Promise((resolve) => {
      setCurriculum((prev) => {
        if (!prev.subunits[unitId] || prev.subunits[unitId].length === 0) {
          const newSubunit = {
            id: uuidv4(),
            name: "New Subunit",
          };

          const newCurriculum = {
            ...prev,
            subunits: {
              ...prev.subunits,
              [unitId]: [newSubunit],
            },
          };

          debouncedUpdateFirebase(newCurriculum);
          resolve({ newSubunit });
          return newCurriculum;
        }
        resolve(null);
        return prev;
      });
    });
  }, []);

  // Add new function (for manual addition)
  const addNewSubunit = useCallback((unitId) => {
    setCurriculum((prev) => {
      const existingSubunits = prev.subunits[unitId] || [];
      const newSubunit = {
        id: uuidv4(),
        name: "New Subunit",
      };

      // Emit new subunit creation event
      eventEmitter.emit("subunitCreated", {
        courseId: courseIdRef.current,
        subunitId: newSubunit.id,
      });

      const newCurriculum = {
        ...prev,
        subunits: {
          ...prev.subunits,
          [unitId]: [...existingSubunits, newSubunit],
        },
      };

      debouncedUpdateFirebase(newCurriculum);
      return newCurriculum;
    });
  }, []);
  // ... existing code ...

  const deleteSubunit = useCallback(
    async (unitId, subunitId) => {
      try {
        const user = auth.currentUser;
        if (!user || !courseIdRef.current) return;

        // 1. 로컬 상태 즉시 업데이트
        setCurriculum((prev) => {
          const updatedSubunits = { ...prev.subunits };
          if (updatedSubunits[unitId]) {
            updatedSubunits[unitId] = updatedSubunits[unitId].filter(
              (subunit) => subunit.id !== subunitId
            );
            if (updatedSubunits[unitId].length === 0) {
              delete updatedSubunits[unitId];
            }
          }
          return {
            ...prev,
            subunits: updatedSubunits,
          };
        });

        // 2. 백그라운드에서 데이터 삭제 처리
        Promise.all([
          // Firebase curriculum 업데이트
          updateDoc(
            doc(
              db,
              "users",
              user.uid,
              "courses",
              courseIdRef.current,
              "curriculum",
              "main"
            ),
            {
              [`subunits.${unitId}`]: null,
              lastUpdated: serverTimestamp(),
            }
          ),
          // 관련 데이터 삭제
          deleteSubunitData(user.uid, courseIdRef.current, subunitId),
        ]).catch((error) => {
          console.error("백그라운드 데이터 삭제 실패:", error);
        });

        return true; // 즉시 성공 반환
      } catch (error) {
        console.error("서브유닛 삭제 실패:", error);
        throw error;
      }
    },
    [courseIdRef.current]
  );

  const reorderSubunits = useCallback(
    (sourceUnitId, destUnitId, sourceIndex, destIndex) => {
      setCurriculum((prev) => {
        const updatedSubunits = { ...prev.subunits };

        // Move within same unit
        if (sourceUnitId === destUnitId) {
          const subunits = [...(updatedSubunits[sourceUnitId] || [])];
          const [removed] = subunits.splice(sourceIndex, 1);
          subunits.splice(destIndex, 0, removed);
          updatedSubunits[sourceUnitId] = subunits;
        } else {
          // Move between different units
          const sourceSubunits = [...(updatedSubunits[sourceUnitId] || [])];
          const destSubunits = [...(updatedSubunits[destUnitId] || [])];
          const [removed] = sourceSubunits.splice(sourceIndex, 1);
          destSubunits.splice(destIndex, 0, removed);
          updatedSubunits[sourceUnitId] = sourceSubunits;
          updatedSubunits[destUnitId] = destSubunits;
        }

        const newCurriculum = {
          ...prev,
          subunits: updatedSubunits,
        };

        debouncedUpdateFirebase(newCurriculum);
        return newCurriculum;
      });
    },
    []
  );

  const handleSubunitNameChange = useCallback((unitId, subunitId, newName) => {
    setCurriculum((prev) => {
      const updatedSubunits = { ...prev.subunits };
      if (updatedSubunits[unitId]) {
        updatedSubunits[unitId] = updatedSubunits[unitId].map((subunit) =>
          subunit.id === subunitId ? { ...subunit, name: newName } : subunit
        );
      }

      const newCurriculum = {
        ...prev,
        subunits: updatedSubunits,
      };

      debouncedUpdateFirebase(newCurriculum);
      return newCurriculum;
    });
  }, []);

  // Add moveUnitToAnotherChapter function
  const moveUnitToAnotherChapter = (
    prevCurriculum,
    sectionIndex,
    chapterIndex,
    unitIndex,
    direction
  ) => {
    const updatedUnits = { ...prevCurriculum.units };
    const currentKey = `${sectionIndex}-${chapterIndex}`;
    const unit = updatedUnits[currentKey][unitIndex];

    updatedUnits[currentKey].splice(unitIndex, 1);

    let newChapterIndex =
      direction === "up" ? chapterIndex - 1 : chapterIndex + 1;
    let newSectionIndex = sectionIndex;

    if (newChapterIndex < 0) {
      newSectionIndex--;
      if (newSectionIndex >= 0) {
        newChapterIndex = prevCurriculum.chapters[newSectionIndex].length - 1;
      }
    } else if (
      newChapterIndex >= prevCurriculum.chapters[sectionIndex].length
    ) {
      newSectionIndex++;
      if (newSectionIndex < prevCurriculum.sections.length) {
        newChapterIndex = 0;
      }
    }

    if (
      newSectionIndex >= 0 &&
      newSectionIndex < prevCurriculum.sections.length
    ) {
      const newKey = `${newSectionIndex}-${newChapterIndex}`;
      if (!updatedUnits[newKey]) {
        updatedUnits[newKey] = [];
      }

      const insertIndex = direction === "up" ? updatedUnits[newKey].length : 0;
      updatedUnits[newKey].splice(insertIndex, 0, unit);

      return { ...prevCurriculum, units: updatedUnits };
    }

    return prevCurriculum;
  };

  // Modify handleMoveUnit function
  const handleMoveUnit = useCallback(
    (sectionIndex, chapterIndex, unitIndex, direction) => {
      setCurriculum((prev) => {
        const updatedUnits = { ...prev.units };
        const currentKey = `${sectionIndex}-${chapterIndex}`;
        const units = [...updatedUnits[currentKey]];

        if (direction === "up" || direction === "down") {
          const newIndex = direction === "up" ? unitIndex - 1 : unitIndex + 1;

          if (newIndex >= 0 && newIndex < units.length) {
            [units[unitIndex], units[newIndex]] = [
              units[newIndex],
              units[unitIndex],
            ];
            updatedUnits[currentKey] = units;

            const newCurriculum = { ...prev, units: updatedUnits };
            debouncedUpdateFirebase(newCurriculum);
            return newCurriculum;
          } else {
            const newCurriculum = moveUnitToAnotherChapter(
              prev,
              sectionIndex,
              chapterIndex,
              unitIndex,
              direction
            );
            debouncedUpdateFirebase(newCurriculum);
            return newCurriculum;
          }
        }
        return prev;
      });
    },
    [debouncedUpdateFirebase]
  );

  const deleteChapter = useCallback(
    (sectionIndex, chapterIndex) => {
      let subunitsToDeleteData = []; // 서브유닛 데이터 삭제를 위한 배열

      setCurriculum((prev) => {
        const newCurriculum = { ...prev };
        const key = `${sectionIndex}-${chapterIndex}`;

        // 1. 현재 챕터의 유닛들만 수집
        const unitsToDelete = prev.units[key] || [];
        const subunitIdsToDelete = [];

        unitsToDelete.forEach((unit) => {
          const subunits = prev.subunits[unit.unitId] || [];
          subunits.forEach((subunit) => {
            subunitIdsToDelete.push(subunit.id);
            subunitsToDeleteData.push(subunit.id); // 데이터 삭제용 배열에 추가
          });
        });

        subunitIdsToDelete.forEach((subunitId) => {
          eventEmitter.emit("subunitDeleted", {
            courseId: courseIdRef.current,
            subunitId,
          });
        });

        // 3. 챕터 삭제
        newCurriculum.chapters[sectionIndex].splice(chapterIndex, 1);

        // 4. 유닛 데이터 재구성
        const updatedUnits = {};
        Object.keys(prev.units).forEach((unitKey) => {
          const [currSectionIndex, currChapterIndex] = unitKey
            .split("-")
            .map(Number);

          if (currSectionIndex === sectionIndex) {
            if (currChapterIndex < chapterIndex) {
              // 삭제된 챕터 이전의 챕터들은 그대로 유지
              updatedUnits[unitKey] = prev.units[unitKey];
            } else if (currChapterIndex > chapterIndex) {
              // 삭제된 챕터 이후의 챕터들은 인덱스를 하나씩 앞으로 당김
              const newKey = `${sectionIndex}-${currChapterIndex - 1}`;
              updatedUnits[newKey] = prev.units[unitKey];
            }
          } else {
            // 다른 섹션의 유닛들은 그대로 유지
            updatedUnits[unitKey] = prev.units[unitKey];
          }
        });

        newCurriculum.units = updatedUnits;

        // 5. 삭제된 유닛의 서브유닛 제거
        unitsToDelete.forEach((unit) => {
          delete newCurriculum.subunits[unit.unitId];
        });

        debouncedUpdateFirebase(newCurriculum);
        return newCurriculum;
      });

      // 서브유닛 데이터 삭제 추가
      const user = auth.currentUser;
      if (user && subunitsToDeleteData.length > 0) {
        subunitsToDeleteData.forEach(async (subunitId) => {
          await deleteSubunitData(user.uid, courseIdRef.current, subunitId);
        });
      }
    },
    [courseIdRef.current]
  );
  // Add getSubunitNameById function
  const getSubunitNameById = useCallback(
    (subunitId) => {
      if (!subunitId) return "";

      for (const unitId in curriculum.subunits) {
        const subunit = curriculum.subunits[unitId]?.find(
          (sub) => sub.id === subunitId
        );
        if (subunit) {
          return subunit.name;
        }
      }
      return "";
    },
    [curriculum.subunits]
  );

  const handleUnitCompletion = useCallback(
    (sectionIndex, chapterIndex, unitIndex, isCompleted) => {
      setCurriculum((prev) => {
        const unitKey = `${sectionIndex}-${chapterIndex}`;
        const updatedUnits = { ...prev.units };
        const currentDate = new Date().toISOString();

        // 유닛 완료 상태 업데이트
        updatedUnits[unitKey][unitIndex] = {
          ...updatedUnits[unitKey][unitIndex],
          isCompleted: isCompleted,
          completedDate: isCompleted ? currentDate : null,
          lastReviewDate: isCompleted ? currentDate : null,
          reviewCount: isCompleted ? 1 : 0,
        };

        const newCurriculum = {
          ...prev,
          units: updatedUnits,
        };

        // 진행률 계산
        const allUnits = Object.values(updatedUnits).flat();
        const completedUnits = allUnits.filter(
          (unit) => unit.isCompleted
        ).length;
        const progress =
          allUnits.length > 0
            ? Math.round((completedUnits / allUnits.length) * 100)
            : 0;

        // Firebase에 커리큘럼과 진행률 업데이트
        const user = auth.currentUser;
        if (user && courseIdRef.current) {
          const batch = writeBatch(db);

          // 커리큘럼 업데이트
          const curriculumRef = doc(
            db,
            "users",
            user.uid,
            "courses",
            courseIdRef.current,
            "curriculum",
            "main"
          );
          batch.set(curriculumRef, newCurriculum);

          // 코스의 진행률 업데이트
          const courseRef = doc(
            db,
            "users",
            user.uid,
            "courses",
            courseIdRef.current
          );
          batch.update(courseRef, { progress });

          batch.commit().catch(console.error);
        }

        return newCurriculum;
      });
    },
    [courseIdRef, debouncedUpdateFirebase]
  );

  // 완료율 계산 함수 추가
  const calculateProgress = useCallback(() => {
    if (!curriculum?.units) return 0;

    const totalUnits = Object.values(curriculum.units).flat().length;
    const completedUnits = Object.values(curriculum.units)
      .flat()
      .filter((unit) => unit.isCompleted).length;

    return totalUnits > 0 ? Math.round((completedUnits / totalUnits) * 100) : 0;
  }, [curriculum.units]);
  const getSubunitName = useCallback(
    (subunitId) => {
      if (!curriculum?.subunits) {
        return null;
      }

      for (const [unitId, unitSubunits] of Object.entries(
        curriculum.subunits
      )) {
        const foundSubunit = unitSubunits.find((sub) => sub.id === subunitId);

        if (foundSubunit) {
          return foundSubunit.name;
        }
      }

      return null;
    },
    [curriculum]
  );

  // Include all state change functions in value
  const value = useMemo(
    () => ({
      curriculum,
      setCurriculum,
      courseId,
      setCourseId: handleSetCourseId,
      isLoading,
      setIsLoading,
      addSection,
      deleteSection,
      handleSectionNameChange,
      addChapter,
      handleChapterNameChange,
      handleUnitChange,
      handleReviewChange,
      addUnit,
      deleteUnit,
      reorderCurriculumIndexes,
      debouncedUpdateFirebase,
      handleReorderCurriculum,
      updateToggleState,
      addSubunit,
      addNewSubunit,
      deleteSubunit,
      reorderSubunits,
      handleSubunitNameChange,
      handleMoveUnit,
      loadCurriculum,
      deleteChapter,
      getSubunitNameById,
      handleUnitCompletion,
      calculateProgress,
      getSubunitName,
    }),
    [
      curriculum,
      courseId,
      isLoading,
      updateToggleState,
      addSubunit,
      addNewSubunit,
      handleSubunitNameChange,
      loadCurriculum,
      deleteChapter,
      getSubunitNameById,
      handleUnitCompletion,
      calculateProgress,
      getSubunitName,
    ]
  );

  // Detect Firebase auth state changes
  useEffect(() => {
    const handleSubunitDeleted = async ({ courseId, subunitId }) => {
      const user = auth.currentUser;
      if (!user) {
        return;
      }

      try {
        await deleteSubunitData(user.uid, courseId, subunitId);
      } catch (error) {}
    };

    eventEmitter.on("subunitDeleted", handleSubunitDeleted);

    return () => {
      eventEmitter.off("subunitDeleted", handleSubunitDeleted);
    };
  }, []);

  return (
    <CurriculumContext.Provider value={value}>
      {children}
    </CurriculumContext.Provider>
  );
};

export const useCurriculum = () => {
  const context = useContext(CurriculumContext);
  if (!context) {
    throw new Error("useCurriculum must be used within a CurriculumProvider");
  }
  return context;
};

// 인덱스 재정렬 헬퍼 함수
const reorderCurriculumIndexes = (curriculum) => {
  const newChapters = {};
  const newUnits = {};

  curriculum.sections.forEach((section, newSectionIndex) => {
    if (curriculum.chapters[newSectionIndex]) {
      newChapters[newSectionIndex] = curriculum.chapters[newSectionIndex];

      curriculum.chapters[newSectionIndex].forEach((_, chapterIndex) => {
        const oldKey = `${newSectionIndex}-${chapterIndex}`;
        const newKey = `${newSectionIndex}-${chapterIndex}`;
        if (curriculum.units[oldKey]) {
          newUnits[newKey] = curriculum.units[oldKey];
        }
      });
    }
  });

  return {
    ...curriculum,
    chapters: newChapters,
    units: newUnits,
  };
};
