// src/pages/UpdateSubscription.js
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { checkSubscriptionStatus } from "../utils/subscription";
import "../css/UpdateSubscription.css";

const UpdateSubscription = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isChecking, setIsChecking] = useState(true);
  const { customerPortalUrl } = location.state || {};

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const subscription = await checkSubscriptionStatus();

        if (subscription && subscription.status !== "expired") {
          navigate("/dashboard");
          return;
        }

        setIsChecking(false);
      } catch (error) {
        setIsChecking(false);
      }
    };

    checkStatus();
    // 30초마다 구독 상태 체크
    const intervalId = setInterval(checkStatus, 30000);
    return () => clearInterval(intervalId);
  }, [navigate]);

  if (isChecking) {
    return (
      <div className="loading-overlay">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <div className="update-subscription-container">
      <div className="update-subscription-content">
        <h1 className="update-subscription-title">
          Your subscription has expired
        </h1>
        <p className="update-subscription-message">
          Please renew your subscription to continue using the service.
        </p>
        <div className="subscription-actions">
          <a
            href={customerPortalUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="renew-subscription-button"
          >
            Renew Subscription
          </a>
          <Link to="/" className="update-subscription-back-button">
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UpdateSubscription;
