// src/contexts/CalendarContext.js
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { db } from "../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { useAuth } from "./AuthContext"; // Auth 컨텍스트도 필요합니다

const CalendarContext = createContext();

export const CalendarProvider = ({ children }) => {
  const [monthlyContents, setMonthlyContents] = useState({});
  const [weeklyContents, setWeeklyContents] = useState({});
  const [year12Contents, setYear12Contents] = useState({});
  const { user } = useAuth();
  const prevUserRef = useRef(null); // 이전 사용자 추적을 위한 ref 추가

  // 사용자 변경 감지 및 데이터 초기화
  useEffect(() => {
    // 사용자가 변경되었을 때만 초기화
    if (prevUserRef.current?.uid !== user?.uid) {
      setMonthlyContents({});
      setWeeklyContents({});
      setYear12Contents({});

      // 현재 사용자 정보 업데이트
      prevUserRef.current = user;
    }
  }, [user]);

  // Firebase 구독 설정
  useEffect(() => {
    if (!user) return;

    const monthlyRef = doc(db, `users/${user.uid}/Calendar/monthly`);

    const unsubscribe = onSnapshot(monthlyRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data() || {};
        setMonthlyContents((prev) => {
          const mergedData = { ...prev, ...data };
          return mergedData;
        });
      }
    });

    return () => unsubscribe();
  }, [user]);

  // 12개월 뷰 데이터 실시간 감지 수정
  useEffect(() => {
    if (!user) return;

    const year12Ref = doc(db, `users/${user.uid}/Calendar/year12`);

    const unsubscribe = onSnapshot(year12Ref, (doc) => {
      if (doc.exists()) {
        // 기존 데이터를 유지하면서 새 데이터 병합
        setYear12Contents((prev) => ({
          ...prev,
          ...(doc.data()?.year12CellContents || {}),
        }));
      }
    });

    return () => unsubscribe();
  }, [user]);

  const updateMonthlyContents = (day, content) => {
    if (day === null) {
      setMonthlyContents((prev) => {
        const newData = { ...prev, ...content };
        return newData;
      });
    } else {
      setMonthlyContents((prev) => {
        const newData = { ...prev, [day]: content };
        return newData;
      });
    }
  };

  const updateWeeklyContents = (day, content) => {
    if (day === null) {
      setWeeklyContents(content);
    } else {
      setWeeklyContents((prev) => ({
        ...prev,
        [day]: content,
      }));
    }
  };

  // year12Contents 업데이트 함수 수정
  const updateYear12Contents = (yearMonth, content) => {
    setYear12Contents((prev) => {
      // content가 빈 문자열이나 null이 아닐 때만 업데이트
      if (content && content !== "<br>") {
        return {
          ...prev,
          [yearMonth]: content,
        };
      }
      // 기존 데이터 유지
      return prev;
    });
  };

  return (
    <CalendarContext.Provider
      value={{
        monthlyContents,
        weeklyContents,
        year12Contents,
        updateMonthlyContents,
        updateWeeklyContents,
        updateYear12Contents,
        cellContents: monthlyContents, // MiniCalendar용 cellContents 추가
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

export const useCalendar = () => {
  const context = useContext(CalendarContext);
  if (!context) {
    throw new Error("useCalendar must be used within a CalendarProvider");
  }
  return context;
};
