import React, { useState, useEffect, useCallback } from "react";
import { db } from "../firebase/firebase";
import "./HabitTrackerComponent.css";
import { collection, doc, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import ContextMenu from "../components/ContextMenu";
import LoadingSpinner from "../components/LoadingSpinner";
import debounce from "lodash/debounce";

const DEFAULT_HABITS = [
  { name: "Habit 1", completed: Array(365).fill(false), gridSize: 365 },
  { name: "Habit 2", completed: Array(365).fill(false), gridSize: 365 },
  { name: "Habit 3", completed: Array(365).fill(false), gridSize: 365 },
  { name: "Habit 4", completed: Array(365).fill(false), gridSize: 365 },
];

const HabitTrackerComponent = ({ year }) => {
  const { user } = useAuth();
  const [showGrid, setShowGrid] = useState(true);
  const [selectedHabit, setSelectedHabit] = useState(0);
  const [habits, setHabits] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [selectedDays, setSelectedDays] = useState(Array(365).fill(false));
  const [habitName, setHabitName] = useState("");
  const [gridState, setGridState] = useState(Array(365).fill(false));
  const [newHabitValue, setNewHabitValue] = useState("");
  const [editingValue, setEditingValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [contextMenu, setContextMenu] = useState(null);
  const [gridSize, setGridSize] = useState(365);
  const [showPeriodSettings, setShowPeriodSettings] = useState(false);

  useEffect(() => {
    const loadHabits = async () => {
      if (!user) return;
      setLoading(true);

      try {
        const docRef = doc(db, `users/${user.uid}/Calendar/habitTracker`);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists() && docSnap.data()[year]) {
          const yearData = docSnap.data()[year];
          const names = yearData.names || [];
          const completed = yearData.completed || [];
          const gridSizes = yearData.gridSizes || [];

          const combinedHabits = names.map((name, index) => ({
            name,
            completed: Array(365)
              .fill(false)
              .map((_, i) => {
                if (completed[index] && typeof completed[index] === "object") {
                  return completed[index][i.toString()] || false;
                }
                return completed[index]?.[i] || false;
              }),
            gridSize: gridSizes[index] || 365,
          }));

          setHabits(combinedHabits);

          // 초기 로딩 시 첫 번째 습관의 실제 데이터로 상태 설정
          if (combinedHabits.length > 0) {
            const firstHabit = combinedHabits[0];
            setSelectedHabit(0);
            setHabitName(firstHabit.name);
            setGridState(firstHabit.completed);
            setGridSize(firstHabit.gridSize);

            // Firebase에서 첫 번째 습관의 완료 데이터 가져오기
            if (yearData.completed && yearData.completed[0]) {
              const completedArray = Array(365)
                .fill(false)
                .map((_, i) => yearData.completed[0][i.toString()] || false);
              setGridState(completedArray);
            }
          }
        }
      } catch (error) {
        console.error("Error loading habits:", error);
      } finally {
        setLoading(false);
      }
    };

    loadHabits();
  }, [year, user]); // selectedHabit 의존성 제거

  useEffect(() => {
    if (habits.length > 0 && selectedHabit === null) {
      setSelectedHabit(0);
      setHabitName(habits[0].name);
      setGridState(habits[0].completed || Array(365).fill(false));
    }
  }, [year]);

  useEffect(() => {
    const initializeHabits = async () => {
      if (!user) return;

      try {
        const docRef = doc(db, `users/${user.uid}/Calendar/habitTracker`);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists() && docSnap.data()[year]) {
          return;
        }

        const completedData = DEFAULT_HABITS.map((habit) =>
          Object.fromEntries(
            habit.completed.map((value, index) => [index.toString(), value])
          )
        );

        await setDoc(
          docRef,
          {
            [year]: {
              names: DEFAULT_HABITS.map((habit) => habit.name),
              completed: completedData,
            },
          },
          { merge: true }
        );
      } catch (error) {
        console.error("Error initializing default habits:", error);
      }
    };

    initializeHabits();
  }, [year, user]);

  const saveHabits = async (updatedHabits) => {
    if (!user) return;

    try {
      const docRef = doc(db, `users/${user.uid}/Calendar/habitTracker`);
      await setDoc(
        docRef,
        {
          [year]: { habits: updatedHabits },
        },
        { merge: true }
      );
    } catch (error) {
      console.error("Error saving habits:", error);
    }
  };

  const handleHabitClick = async (index) => {
    console.log("Habit clicked:", index);
    console.log("Previous selectedHabit:", selectedHabit);

    if (!user) {
      console.error("User not authenticated");
      return;
    }

    setSelectedHabit(index);
    setShowGrid(true);
    setHabitName(habits[index].name);
    setGridState(habits[index].completed || Array(365).fill(false));
    setGridSize(habits[index].gridSize || 365);

    console.log("New selectedHabit set to:", index);

    try {
      const docRef = doc(db, `users/${user.uid}/Calendar/habitTracker`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists() && docSnap.data()[year]) {
        const yearData = docSnap.data()[year];
        if (yearData.completed && yearData.completed[index]) {
          const completedArray = Array(365)
            .fill(false)
            .map((_, i) => yearData.completed[index][i.toString()] || false);
          setGridState(completedArray);
        }
      }
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  const handleHabitChange = async (habitIndex, dayIndex) => {
    const newHabits = [...habits];
    newHabits[habitIndex].completed[dayIndex] =
      !newHabits[habitIndex].completed[dayIndex];

    try {
      const docRef = doc(db, `users/${user.uid}/Calendar/habitTracker`);

      await setDoc(
        docRef,
        {
          [year]: {
            completed: newHabits.map((habit) => habit.completed),
          },
        },
        { merge: true }
      );

      setHabits(newHabits);
    } catch (error) {
      console.error("Error saving habit completion:", error);
    }
  };

  const handleHabitNameChange = async (index, newName) => {
    const newHabits = [...habits];
    newHabits[index] = {
      ...newHabits[index],
      name: newName,
    };

    try {
      const docRef = doc(db, `users/${user.uid}/Calendar/habitTracker`);

      const updateData = {
        [year]: {
          names: newHabits.map((habit) => habit.name),
        },
      };

      await setDoc(docRef, updateData, { merge: true });

      setHabits(newHabits);
      setIsEditing(null);
      setEditingValue("");
      if (index === selectedHabit) {
        setHabitName(newName);
      }
    } catch (error) {
      console.error("Error changing habit name:", error);
    }
  };

  const handleHabitDoubleClick = (index) => {
    setIsEditing(index);
    setEditingValue(habits[index].name);
  };

  const handleAddHabit = async () => {
    if (!user || !newHabitValue.trim()) return;

    try {
      const newHabit = {
        name: newHabitValue,
        completed: Array(365).fill(false),
        gridSize: 365,
      };

      const updatedHabits = [...habits, newHabit];

      // Firebase 업데이트
      const docRef = doc(db, `users/${user.uid}/Calendar/habitTracker`);
      await setDoc(
        docRef,
        {
          [year]: {
            names: updatedHabits.map((habit) => habit.name),
            completed: [...updatedHabits.map((habit) => ({})), {}],
            gridSizes: updatedHabits.map((habit) => habit.gridSize),
          },
        },
        { merge: true }
      );

      // 상태 업데이트
      setHabits(updatedHabits);
      setNewHabitValue("");

      // 새로 추가된 습관으로 자동 선택
      const newIndex = updatedHabits.length - 1;
      setSelectedHabit(newIndex);
      setHabitName(newHabitValue);
      setGridState(Array(365).fill(false));
      setGridSize(365);
    } catch (error) {
      console.error("Error adding habit:", error);
    }
  };

  const handleGridClick = async (index) => {
    if (!user || !habitName) {
      console.error("User authentication required or no habit selected");
      return;
    }

    const updatedDays = [...gridState];
    updatedDays[index] = !updatedDays[index];
    setGridState(updatedDays);

    try {
      const docRef = doc(db, `users/${user.uid}/Calendar/habitTracker`);

      const docSnap = await getDoc(docRef);
      const currentData = docSnap.exists() ? docSnap.data() : {};
      const yearData = currentData[year] || {};
      const currentCompleted = Array.isArray(yearData.completed)
        ? yearData.completed
        : [];

      const updatedCompleted = [...currentCompleted];
      if (!updatedCompleted[selectedHabit]) {
        updatedCompleted[selectedHabit] = {};
      }
      updatedCompleted[selectedHabit] = Object.fromEntries(
        updatedDays.map((value, idx) => [idx.toString(), value])
      );

      await setDoc(
        docRef,
        {
          [year]: {
            ...yearData,
            completed: updatedCompleted,
          },
        },
        { merge: true }
      );
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleContextMenu = (e, index) => {
    e.preventDefault();
    setContextMenu({
      x: e.clientX,
      y: e.clientY,
      habitIndex: index,
    });
  };

  const handleDeleteHabit = async (index) => {
    if (!user) return;

    try {
      const newHabits = habits.filter((_, i) => i !== index);
      setHabits(newHabits);

      if (selectedHabit === index) {
        if (newHabits.length > 0) {
          setSelectedHabit(0);
          setHabitName(newHabits[0].name);
          setGridState(newHabits[0].completed || Array(365).fill(false));
          setGridSize(newHabits[0].gridSize || 365);
        } else {
          setSelectedHabit(null);
          setHabitName("");
          setGridState(Array(365).fill(false));
          setGridSize(365);
        }
      } else if (selectedHabit > index) {
        setSelectedHabit(selectedHabit - 1);
      }

      const docRef = doc(db, `users/${user.uid}/Calendar/habitTracker`);
      const docSnap = await getDoc(docRef);
      const currentData = docSnap.exists() ? docSnap.data() : {};
      const yearData = currentData[year] || {};

      const updatedNames = yearData.names.filter((_, i) => i !== index);
      const updatedCompleted = yearData.completed.filter((_, i) => i !== index);
      const updatedGridSizes = yearData.gridSizes?.filter(
        (_, i) => i !== index
      );

      await setDoc(
        docRef,
        {
          [year]: {
            ...yearData,
            names: updatedNames,
            completed: updatedCompleted,
            gridSizes: updatedGridSizes,
          },
        },
        { merge: true }
      );
    } catch (error) {
      console.error("Error deleting habit:", error);
      const rollbackHabits = [...habits];
      setHabits(rollbackHabits);

      if (selectedHabit === index) {
        setHabitName(rollbackHabits[index].name);
        setGridState(rollbackHabits[index].completed);
        setGridSize(rollbackHabits[index].gridSize);
      }
    }
  };

  const handleGridSizeChange = useCallback(
    debounce(async (newSize) => {
      if (!user || selectedHabit === null) return;

      try {
        const updatedHabits = habits.map((habit, index) => {
          if (index === selectedHabit) {
            return { ...habit, gridSize: newSize };
          }
          return habit;
        });

        // Firebase 업데이트
        const docRef = doc(db, `users/${user.uid}/Calendar/habitTracker`);
        await setDoc(
          docRef,
          {
            [year]: {
              gridSizes: updatedHabits.map((habit) => habit.gridSize),
            },
          },
          { merge: true }
        );

        setHabits(updatedHabits);
        setGridSize(newSize);
      } catch (error) {
        console.error("Error saving grid size:", error);
      }
    }, 300),
    [habits, selectedHabit, user, year]
  );

  const calculateCompletionRate = () => {
    if (!gridState || gridSize === 0) return 0;
    const completedDays = gridState
      .slice(0, gridSize)
      .filter((day) => day).length;
    return Math.round((completedDays / gridSize) * 100);
  };

  if (loading) return <LoadingSpinner />;

  return (
    <div className="habit-tracker" style={{ color: "white" }}>
      <div className="habit-tracker-list">
        <div className="add-habit">
          <input
            type="text"
            value={newHabitValue}
            onChange={(e) => setNewHabitValue(e.target.value)}
            placeholder="Add New Habit"
            onKeyDown={(e) => {
              if (e.key === "Enter" && newHabitValue.trim()) {
                e.preventDefault(); // 이벤트 파 방지
                handleAddHabit();
              }
            }}
            style={{
              backgroundColor: "rgba(70, 70, 70, 0.8)",
              color: "rgba(255, 255, 255, 0.9)",
              border: "1px solid rgba(255, 255, 255, 0.3)",
              borderRadius: "5px",
              padding: "5px",
              width: "100%",
              outline: "none",
              height: "30px",
            }}
          />
          <button
            onClick={handleAddHabit}
            style={{
              marginLeft: "10px",
              backgroundColor: "rgba(100, 100, 100, 0.8)",
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "5px 10px",
              cursor: "pointer",
              transition: "background-color 0.3s",
              height: "30px",
              width: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor =
                "rgba(150, 150, 150, 0.9)")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor =
                "rgba(100, 100, 100, 0.8)")
            }
          >
            +
          </button>
        </div>
        {habits.map((habit, index) => (
          <div
            key={index}
            className={`habit-tracker-item ${
              selectedHabit === index ? "selected" : ""
            }`}
            onClick={() => handleHabitClick(index)}
            onDoubleClick={() => handleHabitDoubleClick(index)}
            onContextMenu={(e) => handleContextMenu(e, index)}
          >
            {isEditing === index ? (
              <input
                type="text"
                value={editingValue}
                onChange={(e) => setEditingValue(e.target.value)}
                onBlur={() => handleHabitNameChange(index, editingValue)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleHabitNameChange(index, editingValue);
                  }
                }}
                autoFocus
                style={{
                  backgroundColor: "rgba(70, 70, 70, 0.8)",
                  color: "rgba(255, 255, 255, 0.9)",
                  border: "1px solid rgba(255, 255, 255, 0.3)",
                  borderRadius: "5px",
                  padding: "5px",
                  width: "100%",
                  outline: "none",
                }}
              />
            ) : (
              <span>{habit.name}</span>
            )}
          </div>
        ))}
      </div>
      {showGrid && (
        <>
          <div className="habit-tracker-grid">
            <div className="habit-tracker-header">
              <h3>{habitName}</h3>
              <div className="completion-rate">
                Completion Rate: {calculateCompletionRate()}%
              </div>
              <button
                className="period-settings-button"
                onClick={() => setShowPeriodSettings(!showPeriodSettings)}
              >
                <img
                  src="/toolbaricons/inuse/setting.svg"
                  alt="Period Settings"
                  className="settings-icon"
                />
                Period Settings
              </button>
            </div>
            <div className="habit-tracker-grid-container">
              {Array.from({ length: gridSize }, (_, index) => (
                <div
                  className="habit-tracker-cell"
                  key={index}
                  onClick={() => handleGridClick(index)}
                  style={{
                    backgroundColor: gridState[index]
                      ? "rgba(255, 215, 0, 0.8)"
                      : "transparent",
                  }}
                >
                  {gridState[index] ? null : index + 1}
                </div>
              ))}
            </div>
          </div>

          {showPeriodSettings && (
            <div className="period-settings-popup">
              <div className="period-settings-content">
                <div className="period-settings-header">
                  <h3>Period Settings</h3>
                  <button
                    className="close-button"
                    onClick={() => setShowPeriodSettings(false)}
                  >
                    ✕
                  </button>
                </div>
                <div className="grid-size-input">
                  <input
                    type="range"
                    min="1"
                    max="365"
                    value={gridSize}
                    onChange={(e) => {
                      const newSize = Number(e.target.value);
                      setGridSize(newSize);
                      handleGridSizeChange(newSize);
                    }}
                  />
                  <input
                    type="number"
                    min="1"
                    max="365"
                    value={gridSize}
                    onChange={(e) => {
                      const newSize = Math.min(
                        365,
                        Math.max(1, Number(e.target.value))
                      );
                      setGridSize(newSize);
                      handleGridSizeChange(newSize);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        const newSize = Math.min(
                          365,
                          Math.max(1, Number(e.target.value))
                        );
                        setGridSize(newSize);
                        handleGridSizeChange(newSize);
                      }
                    }}
                    style={{
                      width: "60px",
                      marginLeft: "10px",
                      padding: "5px",
                      backgroundColor: "rgba(70, 70, 70, 0.8)",
                      color: "rgba(255, 255, 255, 0.9)",
                      border: "1px solid rgba(255, 255, 255, 0.3)",
                      borderRadius: "5px",
                      outline: "none",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  />
                  <span
                    style={{
                      marginLeft: "8px",
                      color: "rgba(255, 255, 255, 0.9)",
                      fontSize: "14px",
                    }}
                  >
                    days
                  </span>
                </div>
                <div className="quick-select-buttons">
                  <button onClick={() => handleGridSizeChange(7)}>
                    1 Week
                  </button>
                  <button onClick={() => handleGridSizeChange(30)}>
                    1 Month
                  </button>
                  <button onClick={() => handleGridSizeChange(90)}>
                    3 Months
                  </button>
                  <button onClick={() => handleGridSizeChange(365)}>
                    1 Year
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {contextMenu && (
        <ContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          onClose={() => setContextMenu(null)}
          menuItems={[
            {
              label: "Delete",
              onClick: () => {
                handleDeleteHabit(contextMenu.habitIndex);
                setContextMenu(null);
              },
            },
          ]}
        />
      )}
    </div>
  );
};

export default HabitTrackerComponent;
