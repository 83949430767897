import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTable } from "../contexts/TableContext";
import { useCurriculum } from "../contexts/CurriculumContext";
import "../css/SearchResultModal.css";

const SearchResultModal = ({
  onResultClick,
  isSearchModalOpen,
  searchResults,
  setIsSearchModalOpen,
}) => {
  const navigate = useNavigate();
  const { courseId, courseName } = useParams();
  const { getSubunitNameById } = useCurriculum();

  const stripHtmlTags = (html) => {
    const tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  if (!isSearchModalOpen) {
    return null;
  }

  const handleResultClick = (result) => {
    onResultClick(result);
    setIsSearchModalOpen(false);
  };

  return (
    <div className="search-modal-overlay">
      <div className="search-modal">
        <div className="search-modal-header">
          <h3>Search Results ({searchResults?.length || 0})</h3>
          <button onClick={() => setIsSearchModalOpen(false)}>✕</button>
        </div>
        <div className="search-modal-content">
          {searchResults && searchResults.length > 0 ? (
            searchResults.map((result, index) => (
              <div
                key={index}
                className="search-result-item"
                onClick={() => handleResultClick(result)}
              >
                <p className="search-result-content">
                  {stripHtmlTags(result.content)}
                </p>
                <span className="search-result-location">
                  Subunit: {getSubunitNameById(result.subunitId)}
                </span>
              </div>
            ))
          ) : (
            <p className="no-results">No search results found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchResultModal;
