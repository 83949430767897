import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { db, auth } from "../firebase/firebase";
import {
  doc,
  setDoc,
  getDoc,
  serverTimestamp,
  deleteDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import EventEmitter from "../utils/eventEmitter";

const TableContext = createContext();
const eventEmitter = EventEmitter;

const initialState = {
  tableData: [],
  columnWidths: { col1: 20, col2: 80 },
  editorWidths: {},
};

export const TableProvider = ({ children }) => {
  const [tableData, setTableData] = useState(null);
  const [columnWidths, setColumnWidths] = useState(initialState.columnWidths);
  const [editorWidths, setEditorWidths] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentCourseId, setCurrentCourseId] = useState(null);
  const [currentSubunitId, setCurrentSubunitId] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const loadTableData = async (subunitId) => {
    if (isLoading) {
      return null;
    }

    if (!currentUser) {
      return null;
    }

    if (!currentCourseId || !subunitId) {
      return null;
    }

    try {
      const docRef = doc(
        db,
        "users",
        currentUser.uid,
        "courses",
        currentCourseId,
        "curriculum/tableData/subunits",
        subunitId
      );

      const docSnap = await getDoc(docRef);
      const data = docSnap.data();

      if (!data?.tableData) {
        const defaultData = {
          tableData: {},
          columnWidths: { col1: 20, col2: 80 },
        };
        setColumnWidths(defaultData.columnWidths);
        return defaultData;
      }

      const transformedData = {
        tableData: data.tableData,
        columnWidths: data.columnWidths || columnWidths,
      };

      setColumnWidths(transformedData.columnWidths);

      return transformedData;
    } catch (error) {
      throw error;
    }
  };

  const updateEditorData = async (subunitId, colId, rowIndex, data) => {
    if (!currentUser || !currentCourseId || !subunitId) {
      return;
    }

    try {
      const tempKey = `temp_${currentUser.uid}_${currentCourseId}_${subunitId}_${colId}_${rowIndex}`;
      localStorage.setItem(tempKey, JSON.stringify(data));

      const tableRef = doc(
        db,
        "users",
        currentUser.uid,
        "courses",
        currentCourseId,
        "curriculum",
        "tableData",
        "subunits",
        subunitId
      );

      const tableDoc = await getDoc(tableRef);
      let currentData = tableDoc.exists() ? tableDoc.data() : { tableData: [] };

      if (!currentData.tableData || currentData.tableData.length === 0) {
        currentData.tableData = Array(29)
          .fill()
          .map(() => ({
            col1: [{ content: "", width: 100 }],
            col2: [{ content: "", width: 100 }],
          }));
      }

      currentData.tableData[rowIndex][colId] = data.editors;

      await setDoc(tableRef, {
        ...currentData,
        lastUpdated: serverTimestamp(),
      });

      localStorage.removeItem(tempKey);
      return true;
    } catch (error) {
      return false;
    }
  };

  const recoverUnsavedData = async () => {
    if (!currentUser) return;

    try {
      const tempDataKeys = Object.keys(localStorage).filter((key) =>
        key.startsWith(`temp_${currentUser.uid}`)
      );

      for (const key of tempDataKeys) {
        const [_, uid, courseId, subunitId, colId, rowIndex] = key.split("_");
        const data = JSON.parse(localStorage.getItem(key));

        if (data && data.editors) {
          await updateEditorData(subunitId, colId, parseInt(rowIndex), data);
        }

        localStorage.removeItem(key);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (currentUser && !isLoading) {
      recoverUnsavedData();
    }
  }, [currentUser, isLoading]);

  const setCurrentIds = useCallback((courseId, subunitId) => {
    setCurrentCourseId(courseId);
    setCurrentSubunitId(subunitId);
  }, []);

  const updateColumnWidths = async (courseId, subunitId, newColumnWidths) => {
    if (!currentUser || !courseId || !subunitId) return false;

    try {
      const tableRef = doc(
        db,
        "users",
        currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "tableData",
        "subunits",
        subunitId
      );

      await setDoc(
        tableRef,
        {
          columnWidths: newColumnWidths,
          lastUpdated: serverTimestamp(),
        },
        { merge: true }
      );

      setColumnWidths(newColumnWidths);
      return true;
    } catch (error) {
      return false;
    }
  };

  const updateMultipleColumnWidths = async (
    courseId,
    subunitId,
    columnUpdates
  ) => {
    if (!currentUser || !courseId || !subunitId) return false;

    try {
      const tableRef = doc(
        db,
        "users",
        currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "tableData",
        "subunits",
        subunitId
      );

      const newColumnWidths = { ...columnWidths, ...columnUpdates };

      await setDoc(
        tableRef,
        {
          columnWidths: newColumnWidths,
          lastUpdated: serverTimestamp(),
        },
        { merge: true }
      );

      setColumnWidths(newColumnWidths);
      return true;
    } catch (error) {
      return false;
    }
  };

  const updateEditorDivider = async (
    subunitId,
    colId,
    rowIndex,
    dividerIndex
  ) => {
    if (!currentUser || !currentCourseId || !subunitId) return;

    try {
      const tableRef = doc(
        db,
        "users",
        currentUser.uid,
        "courses",
        currentCourseId,
        "curriculum",
        "tableData",
        "subunits",
        subunitId
      );

      const tableDoc = await getDoc(tableRef);
      const currentData = tableDoc.exists()
        ? tableDoc.data()
        : { tableData: [] };

      if (!currentData.tableData[rowIndex]) {
        currentData.tableData[rowIndex] = {};
      }

      if (!currentData.tableData[rowIndex][colId]) {
        return;
      }

      const editors = currentData.tableData[rowIndex][colId];

      if (editors.length <= dividerIndex + 1) return;

      const removedEditorWidth = editors[dividerIndex + 1].width;
      editors[dividerIndex].width += removedEditorWidth;

      editors.splice(dividerIndex + 1, 1);

      editors.forEach((editor, idx) => {
        editor.index = idx;
      });

      await setDoc(tableRef, {
        ...currentData,
        lastUpdated: serverTimestamp(),
      });

      setTableData(currentData.tableData);
      return true;
    } catch (error) {
      return false;
    }
  };

  const swapRows = async (subunitId, sourceRowIndex, targetRowIndex, colId) => {
    if (!currentUser || !currentCourseId || !subunitId) return;

    try {
      const tableRef = doc(
        db,
        "users",
        currentUser.uid,
        "courses",
        currentCourseId,
        "curriculum",
        "tableData",
        "subunits",
        subunitId
      );

      const tableDoc = await getDoc(tableRef);
      const currentData = tableDoc.exists()
        ? tableDoc.data()
        : { tableData: [] };

      if (!currentData.tableData) return;

      const sourceData = currentData.tableData[sourceRowIndex]?.[colId] || [];
      const targetData = currentData.tableData[targetRowIndex]?.[colId] || [];

      if (!currentData.tableData[sourceRowIndex])
        currentData.tableData[sourceRowIndex] = {};
      if (!currentData.tableData[targetRowIndex])
        currentData.tableData[targetRowIndex] = {};

      currentData.tableData[sourceRowIndex] = {
        ...currentData.tableData[sourceRowIndex],
        [colId]: targetData,
      };
      currentData.tableData[targetRowIndex] = {
        ...currentData.tableData[targetRowIndex],
        [colId]: sourceData,
      };

      await setDoc(tableRef, {
        ...currentData,
        lastUpdated: serverTimestamp(),
      });

      return true;
    } catch (error) {
      return false;
    }
  };

  const insertEmptyRow = async (subunitId, targetRow, colId) => {
    if (!currentUser || !currentCourseId || !subunitId) {
      return;
    }

    try {
      const tableRef = doc(
        db,
        "users",
        currentUser.uid,
        "courses",
        currentCourseId,
        "curriculum",
        "tableData",
        "subunits",
        subunitId
      );

      const tableDoc = await getDoc(tableRef);
      const currentData = tableDoc.exists()
        ? tableDoc.data()
        : { tableData: [] };

      if (!currentData.tableData) currentData.tableData = [];

      for (let i = currentData.tableData.length - 2; i >= targetRow; i--) {
        if (currentData.tableData[i] && currentData.tableData[i][colId]) {
          if (!currentData.tableData[i + 1]) {
            currentData.tableData[i + 1] = {};
          }
          currentData.tableData[i + 1][colId] = currentData.tableData[i][colId];
        }
      }

      if (!currentData.tableData[targetRow]) {
        currentData.tableData[targetRow] = {};
      }
      currentData.tableData[targetRow][colId] = [{ content: "", width: 100 }];

      await setDoc(tableRef, {
        ...currentData,
        lastUpdated: serverTimestamp(),
      });

      return true;
    } catch (error) {
      return false;
    }
  };

  const searchAllTableData = async (courseId, searchTerm) => {
    if (!currentUser || !searchTerm.trim()) {
      return;
    }

    try {
      const results = [];
      const subunitsRef = collection(
        db,
        "users",
        currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "tableData",
        "subunits"
      );

      const querySnapshot = await getDocs(subunitsRef);

      querySnapshot.forEach((doc) => {
        const tableData = doc.data().tableData || [];

        tableData.forEach((row, rowIndex) => {
          Object.entries(row).forEach(([colId, editors]) => {
            editors.forEach((editor) => {
              const content = editor.content || "";
              if (content.toLowerCase().includes(searchTerm.toLowerCase())) {
                results.push({
                  subunitId: doc.id,
                  content: content,
                  matchText: content,
                  rowIndex,
                  colId,
                });
              }
            });
          });
        });
      });

      setSearchResults(results);
      setIsSearchModalOpen(true);

      return results;
    } catch (error) {
      return [];
    }
  };

  const highlightSearchResult = useCallback((text) => {}, []);
  const saveAllTableData = async (subunitId, tableData, columnWidths) => {
    if (!currentUser || !currentCourseId || !subunitId) {
      return false;
    }

    try {
      const tableRef = doc(
        db,
        "users",
        currentUser.uid,
        "courses",
        currentCourseId,
        "curriculum",
        "tableData",
        "subunits",
        subunitId
      );

      await setDoc(tableRef, {
        tableData: tableData,
        columnWidths: columnWidths,
        lastUpdated: serverTimestamp(),
      });

      window.dispatchEvent(
        new CustomEvent("saveComplete", {
          detail: { status: "complete" },
        })
      );
      return true;
    } catch (error) {
      window.dispatchEvent(
        new CustomEvent("saveComplete", {
          detail: { status: "error", error },
        })
      );
      return false;
    }
  };

  const value = useMemo(
    () => ({
      tableData,
      columnWidths,
      editorWidths,
      isLoading,
      loadTableData,
      updateEditorData,
      setTableData,
      setColumnWidths,
      setEditorWidths,
      currentCourseId,
      currentSubunitId,
      setCurrentIds,
      updateColumnWidths,
      updateMultipleColumnWidths,
      updateEditorDivider,
      swapRows,
      insertEmptyRow,
      searchResults,
      isSearchModalOpen,
      setIsSearchModalOpen,
      searchAllTableData,
      highlightSearchResult,
      saveAllTableData,
    }),
    [
      tableData,
      columnWidths,
      editorWidths,
      isLoading,
      currentCourseId,
      currentSubunitId,
      setCurrentIds,
      updateColumnWidths,
      updateMultipleColumnWidths,
      updateEditorDivider,
      swapRows,
      insertEmptyRow,
      searchResults,
      isSearchModalOpen,
      searchAllTableData,
      highlightSearchResult,
      saveAllTableData,
    ]
  );

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
};

export const useTable = () => {
  const context = useContext(TableContext);
  if (!context) {
    throw new Error("useTable must be used within a TableProvider");
  }
  return context;
};
