import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../css/Calendar.css";
import Moveable from "react-moveable";

import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  batch,
  onSnapshot,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useUnitCard } from "../contexts/UnitCardContext";
import CalendarTodoSidebar from "../components/CalendarTodoSidebar";
import { debounce } from "lodash";
import HabitTrackerComponent from "./HabitTrackerComponent";
import { isEqual } from "lodash";

let pendingSaves = [];

const db = getFirestore();
const auth = getAuth();
// Firebase에서 데이터 가져오기
export const fetchCellContentsFromFirebase = async (view) => {
  const user = auth.currentUser;
  if (!user) return {};

  const path = `users/${user.uid}/Calendar/${view}`;

  try {
    const docRef = doc(db, path);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      return data;
    }
    return {};
  } catch (error) {
    return {};
  }
};

// Firebase에 데이터 저장하기
export const saveCellContentsToFirebase = async (contents, view) => {
  const user = auth.currentUser;
  if (!user) return;

  const path = `users/${user.uid}/Calendar/${view}`;

  try {
    // 빈 내용 필터링
    const filteredContents = Object.fromEntries(
      Object.entries(contents).filter(([_, value]) => {
        return value && value !== "<p><br></p>" && value !== "<p></p>";
      })
    );

    // 날짜를 UTC로 변환하여 저장
    const utcContents = Object.fromEntries(
      Object.entries(filteredContents).map(([key, value]) => {
        const date = new Date(key);
        const utcDate = date.toISOString(); // UTC 형식으로 변환
        return [utcDate, value]; // UTC 형식으로 변환
      })
    );

    const docRef = doc(db, path);
    await setDoc(docRef, utcContents, { merge: true });

    // 저장된 데이터 확인
    const savedDocSnap = await getDoc(docRef);
  } catch (error) {
    throw error;
  }
};

const fetchCellContentsForPeriod = async (startDate, endDate) => {
  const user = auth.currentUser;
  if (!user) return {};

  const docRef = doc(db, `users/${user.uid}/Calendar/month1andweek1`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const allData = docSnap.data();
    return Object.fromEntries(
      Object.entries(allData).filter(([key]) => {
        const date = new Date(key);
        return date >= startDate && date <= endDate;
      })
    );
  } else {
    return {};
  }
};

// months 배열을 컴포넌트 외부에서 정의합니다.
const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const springColors = [
  { name: "Spring 1", color: "rgba(241, 223, 213, 0.2)" },
  { name: "Spring 2", color: "rgba(227, 226, 224, 0.2)" },
  { name: "Spring 3", color: "rgba(223, 233, 235, 0.2)" },
  { name: "Spring 4", color: "rgba(241, 238, 233, 0.2)" },
  { name: "Spring 5", color: "rgba(194, 183, 177, 0.2)" },
  { name: "Spring 6", color: "rgba(225, 215, 205, 0.2)" },
  { name: "Spring 7", color: "rgba(212, 226, 239, 0.2)" },
];

const summerColors = [
  { name: "Summer 1", color: "rgba(251, 211, 212, 0.2)" },
  { name: "Summer 2", color: "rgba(254, 236, 194, 0.2)" },
  { name: "Summer 3", color: "rgba(252, 220, 197, 0.2)" },
  { name: "Summer 4", color: "rgba(243, 178, 156, 0.2)" },
  { name: "Summer 5", color: "rgba(195, 221, 196, 0.2)" },
  { name: "Summer 6", color: "rgba(203, 238, 240, 0.2)" },
  { name: "Summer 7", color: "rgba(221, 220, 239, 0.2)" },
];

const autumnColors = [
  { name: "Autumn 1", color: "rgba(220, 196, 142, 0.2)" },
  { name: "Autumn 2", color: "rgba(234, 239, 211, 0.2)" },
  { name: "Autumn 3", color: "rgba(179, 192, 164, 0.2)" },
  { name: "Autumn 4", color: "rgba(80, 81, 104, 0.2)" },
  { name: "Autumn 5", color: "rgba(39, 35, 58, 0.2)" },
  { name: "Autumn 6", color: "rgba(136, 90, 90, 0.2)" },
  { name: "Autumn 7", color: "rgba(53, 58, 71, 0.2)" },
];

const winterColors = [
  { name: "Winter 1", color: "rgba(35, 50, 83, 0.2)" },
  { name: "Winter 2", color: "rgba(155, 169, 208, 0.2)" },
  { name: "Winter 3", color: "rgba(199, 216, 232, 0.2)" },
  { name: "Winter 4", color: "rgba(222, 232, 242, 0.2)" },
  { name: "Winter 5", color: "rgba(217, 220, 237, 0.2)" },
  { name: "Winter 6", color: "rgba(198, 200, 215, 0.2)" },
  { name: "Winter 7", color: "rgba(246, 245, 250, 0.2)" },
];

// Firebase에 week2 색상 데이터 저장하기
const saveWeek2ColorsToFirebase = async (colors) => {
  const user = auth.currentUser;
  if (!user) return;

  const docRef = doc(db, `users/${user.uid}/Calendar/week2`);
  await setDoc(docRef, { timeBlockColors: colors }, { merge: true });
};

// Firebase에서 week2 색상 데이터 가져오기
const fetchWeek2ColorsFromFirebase = async () => {
  const user = auth.currentUser;
  if (!user) return {};

  const docRef = doc(db, `users/${user.uid}/Calendar/week2`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().timeBlockColors || {};
  } else {
    return {};
  }
};

// 초기 색상 이름 설정
const defaultColorNames = {
  "#9DB4C0": "Subject 1", // 부드러운 블루 그레이
  "#C2B0C9": "Subject 2", // 라벤더 퍼플
  "#86A69D": "Subject 3", // 세이지 그린
  "#C9A9A6": "Subject 4", // 로즈 톤
  "#A4BE7B": "Subject 5", // 올리브 그린
  "#DEB6AB": "Subject 6", // 피치 톤
  "#B5C0D0": "Subject 7", // 스틸 블루
  transparent: "Remove Background",
};

// Firebase에 색상 이름 저장하기
const saveColorNamesToFirebase = async (names) => {
  const user = auth.currentUser;
  if (!user) return;

  const docRef = doc(db, `users/${user.uid}/Calendar/week2`);
  await setDoc(docRef, { colorNames: names }, { merge: true });
};

// Firebase에서 색상 이름 가져오기
const fetchColorNamesFromFirebase = async () => {
  const user = auth.currentUser;
  if (!user) return defaultColorNames;

  const docRef = doc(db, `users/${user.uid}/Calendar/week2`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().colorNames || defaultColorNames;
  } else {
    return defaultColorNames;
  }
};

const saveDayCellContentsToFirebase = async (key, content) => {
  const user = auth.currentUser;
  if (!user) return;

  const docRef = doc(db, `users/${user.uid}/Calendar/day1`);
  const docSnap = await getDoc(docRef);
  const existingData = docSnap.exists() ? docSnap.data() : {};

  const newContents = { ...existingData, [key]: content };

  await setDoc(docRef, newContents, { merge: true });
};

const saveDayCellBackgroundColorsToFirebase = async (colors) => {
  const user = auth.currentUser;
  if (!user) return;

  const path = `users/${user.uid}/Calendar/day1`;

  try {
    const docRef = doc(db, path);
    await setDoc(docRef, { dayCellBackgroundColors: colors }, { merge: true });
  } catch (error) {
    throw error;
  }
};

const saveDayColumnWidthsToFirebase = async (widths) => {
  const user = auth.currentUser;
  if (!user) return;

  const docRef = doc(db, `users/${user.uid}/Calendar/day1`);
  const docSnap = await getDoc(docRef);
  const existingData = docSnap.exists() ? docSnap.data() : {};

  const newWidths = { ...existingData, dayColumnWidths: widths };
  await setDoc(docRef, newWidths, { merge: true });
};

// Firebase에서 배경색 데이터 가져오기
const fetchDayCellBackgroundColorsFromFirebase = async () => {
  const user = auth.currentUser;
  if (!user) return {};

  const path = `users/${user.uid}/Calendar/day1`;

  try {
    const docRef = doc(db, path);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists() && docSnap.data().dayCellBackgroundColors) {
      return docSnap.data().dayCellBackgroundColors;
    } else {
      return {};
    }
  } catch (error) {
    return {};
  }
};

// Firebase 저장/불러오기 함수 추가
const saveYearCellContentsToFirebase = async (yearMonth, content) => {
  const user = auth.currentUser;
  if (!user) return;

  try {
    const docRef = doc(db, `users/${user.uid}/Calendar/year`);

    // 기존 데이터 가져오기
    const docSnap = await getDoc(docRef);
    const existingData = docSnap.exists() ? docSnap.data() : {};

    // 새 데이터로 업데이트
    const newData = {
      ...existingData,
      yearCellContents: {
        ...(existingData.yearCellContents || {}),
        [yearMonth]: content,
      },
    };

    await setDoc(docRef, newData);
  } catch (error) {
    throw error;
  }
};

// Firebase에서 데이터 불러오기 함수 수정
const fetchYearCellContentsFromFirebase = async () => {
  const user = auth.currentUser;
  if (!user) return {};

  try {
    const docRef = doc(db, `users/${user.uid}/Calendar/year`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data().yearCellContents || {};
      return data;
    }
    return {};
  } catch (error) {
    throw error;
  }
};

// 일간 뷰 데이터 fetch 함수 추가
const fetchDayCellContentsFromFirebase = async (date) => {
  const user = auth.currentUser;
  if (!user) return {};

  const docRef = doc(db, `users/${user.uid}/Calendar/day1`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  }
  return {};
};

// Firebase 저장/불러오기 함수 추가
const saveFontSettingToFirebase = async (selectedFont) => {
  const user = auth.currentUser;
  if (!user) return;

  const docRef = doc(db, `users/${user.uid}/Calendar/settings`);
  await setDoc(docRef, { font: selectedFont }, { merge: true });
};

// 폰트 설정 불러오기 함수
const fetchFontSettingFromFirebase = async () => {
  const user = auth.currentUser;
  if (!user) return null;

  const docRef = doc(db, `users/${user.uid}/Calendar/settings`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists() && docSnap.data().font) {
    return docSnap.data().font;
  }
  return null;
};

// 기본 글꼴 상수 추가
const DEFAULT_FONT = "TTPrincessLike";

// ReactQuill 컴포넌트 사용 시 공통 props 추가
const quillProps = {
  spellCheck: false,
  "data-gramm_editor": "false",
  "data-enable-grammarly": "false",
};

const Calendar = ({ isSidebarMinimized, setIsSidebarMinimized }) => {
  const [monthlyContents, setMonthlyContents] = useState({});
  const [weeklyContents, setWeeklyContents] = useState({});

  const updateMonthlyContents = useCallback(
    (day, content, trigger = "자동 로드") => {
      if (typeof content === "object" && content !== null) {
        setMonthlyContents(content);
      } else {
        setMonthlyContents((prev) => ({
          ...prev,
          [day]: content,
        }));
      }
    },
    []
  );

  const updateWeeklyContents = useCallback((day, content) => {
    if (day === null && typeof content === "object") {
      setWeeklyContents(content);
    } else {
      setWeeklyContents((prev) => ({
        ...prev,
        [day]: content,
      }));
    }
  }, []);
  const [columnWidths, setColumnWidths] = useState([
    16.67, 16.67, 16.67, 16.67, 16.67, 16.67,
  ]);
  const [isResizing, setIsResizing] = useState(false);
  const resizeIndex = useRef(null);
  const today = new Date();
  const [selectedMonth, setSelectedMonth] = useState(
    months[today.getMonth()] || months[0]
  );
  const [isWeeklyDataLoading, setIsWeeklyDataLoading] = useState(false);
  const weekDataCache = useRef(new Map()); // 주별 데이터

  const [selectedYear, setSelectedYear] = useState(today.getFullYear());
  const [rowCount, setRowCount] = useState(6);
  const [view, setView] = useState("month"); // 새로운 상태 추가
  const [selectedWeek, setSelectedWeek] = useState(() => {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), 1);
    const firstDay = start.getDay();
    const week = Math.floor(
      (now.getDate() + (firstDay === 0 ? 6 : firstDay - 1)) / 7
    );
    return Math.min(5, week);
  });
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [editingCell, setEditingCell] = useState(null);
  const [font, setFont] = useState(DEFAULT_FONT);
  const quillRefs = useRef({});

  const [yearCellContents, setYearCellContents] = useState({});
  const [editingYearCell, setEditingYearCell] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dayCellContents, setDayCellContents] = useState({});
  const [selectedTimeBlocks, setSelectedTimeBlocks] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [showMiniToolbar, setShowMiniToolbar] = useState(false);
  const [miniToolbarPosition, setMiniToolbarPosition] = useState({
    x: 0,
    y: 0,
  });
  const [timeBlockColors, setTimeBlockColors] = useState({});
  const [colorNames, setColorNames] = useState(defaultColorNames);
  const { fetchUnitCardsByPeriod } = useUnitCard();
  const [monthlyUnitCards, setMonthlyUnitCards] = useState({});
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // 사이드바 상태 추가
  const [dragStart, setDragStart] = useState(null); // 드래그 시작 위치 상태 추가
  const [isTodoSidebarOpen, setIsTodoSidebarOpen] = useState(false); // 새로운 상태 추가
  const fontOptionsRef = useRef(null); // 추가: 폰트 옵션 메뉴의 ref

  // fonts 배열 추가
  const fonts = [
    { name: "THEGaeideuk", url: "/fonts/THEGaeideuk.ttf" },
    { name: "TTAgainSea", url: "/fonts/TTAgainSea.ttf" },
    { name: "TTARainyDayBK", url: "/fonts/TTARainyDayBK.ttf" },
    { name: "TTEveryDayThank", url: "/fonts/TTEveryDayThank.ttf" },

    { name: "TTLovelySSong", url: "/fonts/TTLovelySSong.ttf" },
    { name: "TTOhilyeoJoha", url: "/fonts/TTOhilyeoJoha.ttf" },
    { name: "TTOnABetterDay", url: "/fonts/TTOnABetterDay.ttf" },
    { name: "TTPenguinHeart", url: "/fonts/TTPenguinHeart.ttf" },
    { name: "TTPenWorkBook", url: "/fonts/TTPenWorkBook.ttf" },
    { name: "TTPrincessLike", url: "/fonts/TTPrincessLike.ttf" },

    { name: "TTTodayGulimM", url: "/fonts/TTTodayGulimM.ttf" },
  ];
  const [showFontOptions, setShowFontOptions] = useState(false);
  const [showFontColors, setShowFontColors] = useState(false);
  const [fontColor, setFontColor] = useState("#e1e1e1");
  const fontColorRef = useRef(null);

  const handleViewChange = async (newView) => {
    try {
      await Promise.all(pendingSaves);
      setView(newView);
    } catch (error) {}
  };
  const handleFontColorChange = (color) => {
    setFontColor(color);
    applyStyle("color", color);
    setShowFontColors(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        fontColorRef.current &&
        !fontColorRef.current.contains(event.target)
      ) {
        setShowFontColors(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const daysInMonth = getDaysInMonth(
      selectedYear,
      months.indexOf(selectedMonth)
    );
    const firstDay = getFirstDayOfMonth(
      selectedYear,
      months.indexOf(selectedMonth)
    );
    const totalDays = firstDay + daysInMonth;
    const newRowCount = Math.ceil(totalDays / 7);
    setRowCount(newRowCount);
  }, [selectedMonth, selectedYear]);

  const handlePrevYear = () => {
    setSelectedYear((prev) => {
      const newYear = prev - 1;
      return newYear;
    });
    setSelectedDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setFullYear(newDate.getFullYear() - 1);
      return newDate;
    });
  };

  const handleNextYear = () => {
    setSelectedYear((prev) => {
      const newYear = prev + 1;
      return newYear;
    });
    setSelectedDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setFullYear(newDate.getFullYear() + 1);
      return newDate;
    });
  };

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    const firstDay = new Date(year, month, 1).getDay();
    return firstDay === 0 ? 6 : firstDay - 1; // 일요일(0)을 6으로 변경
  };

  const getCalendarCellStyle = (isToday) => {
    return isToday
      ? {
          // backgroundColor: "#f7f5f4", // 오늘 날짜의 배경색 제거
        }
      : {};
  };

  const [selectedCell, setSelectedCell] = useState(null);
  const [cellBackgroundColors, setCellBackgroundColors] = useState({});
  const [selectedColorPalette, setSelectedColorPalette] =
    useState(springColors);

  const handleCellClick = (day) => {
    setEditingCell(day);
    setSelectedCell(day);
    // 이전에 선택된 의 'selected' 클래스 제거
    const prevSelectedCell = document.querySelector(".calendar-cell.selected");
    if (prevSelectedCell) {
      prevSelectedCell.classList.remove("selected");
    }
    // 새로 선택된 셀에 'selected' 클래스 추가
    const newSelectedCell = document.querySelector(
      `.calendar-cell[data-day="${day}"]`
    );
    if (newSelectedCell) {
      newSelectedCell.classList.add("selected");
    }
  };

  const [showBackgroundColors, setShowBackgroundColors] = useState(false);
  const backgroundColorRef = useRef(null);
  const handleBackgroundColorChange = async (color) => {
    if (!auth.currentUser) return;

    try {
      if (selectedCell) {
        const newColors = { ...cellBackgroundColors, [selectedCell]: color };
        setCellBackgroundColors(newColors);
        await saveDayCellBackgroundColorsToFirebase(newColors);
      } else if (selectedDayCell) {
        const newColors = {
          ...dayCellBackgroundColors,
          [selectedDayCell]: color,
        };
        setDayCellBackgroundColors(newColors);
        await saveDayCellBackgroundColorsToFirebase(newColors);
      }
      setShowBackgroundColors(false);
    } catch (error) {
      console.error("Error saving background color:", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        backgroundColorRef.current &&
        !backgroundColorRef.current.contains(event.target)
      ) {
        setShowBackgroundColors(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderBackgroundColorOptions = () => {
    const darkModePastelColors = [
      { name: "Lavender", color: "rgba(187, 173, 255, 0.15)" },
      { name: "Rose", color: "rgba(255, 173, 173, 0.15)" },
      { name: "Mint", color: "rgba(173, 255, 201, 0.15)" },
      { name: "Sky", color: "rgba(173, 217, 255, 0.15)" },
      { name: "Peach", color: "rgba(255, 214, 173, 0.15)" },
      { name: "Lilac", color: "rgba(226, 173, 255, 0.15)" },
      { name: "Sage", color: "rgba(200, 255, 173, 0.15)" },
      { name: "Ocean", color: "rgba(173, 255, 247, 0.15)" },
      { name: "Coral", color: "rgba(255, 173, 201, 0.15)" },
      { name: "Periwinkle", color: "rgba(173, 181, 255, 0.15)" },
      { name: "Honeydew", color: "rgba(236, 255, 173, 0.15)" },
      { name: "Azure", color: "rgba(173, 247, 255, 0.15)" },
      { name: "Blush", color: "rgba(255, 173, 173, 0.15)" },
      { name: "Clear", color: "#1e1e1e" },
    ];

    return (
      <div className="background-colors">
        {darkModePastelColors.map((colorOption) => (
          <button
            key={colorOption.name}
            className="background-color-button"
            style={{ backgroundColor: colorOption.color }}
            onClick={() => handleBackgroundColorChange(colorOption.color)}
            title={colorOption.name}
          />
        ))}
      </div>
    );
  };

  // 재시도 로직을 위한 공통 함수
  const retrySync = async (
    viewType,
    key,
    content,
    maxRetries = 3,
    delay = 5000
  ) => {
    const pendingKey = `calendar_pending_${viewType}_${key}`;
    const pending = JSON.parse(localStorage.getItem(pendingKey) || "{}");

    if (pending.retryCount >= maxRetries) {
      console.error(`최대 재시도 횟수 초과: ${viewType} ${key}`);
      return;
    }

    try {
      await new Promise((resolve) => setTimeout(resolve, delay));

      // 뷰 타입에 따른 저장 함수 선택
      switch (viewType) {
        case "monthly":
          const monthlyDocRef = doc(
            db,
            `users/${auth.currentUser.uid}/Calendar/monthly`
          );
          await setDoc(monthlyDocRef, { [key]: content }, { merge: true });
          updateMonthlyContents(key, content);
          break;

        case "weekly":
          const weeklyDocRef = doc(
            db,
            `users/${auth.currentUser.uid}/Calendar/week1`
          );
          await setDoc(weeklyDocRef, { [key]: content }, { merge: true });
          updateWeeklyContents(key, content);
          break;

        case "daily":
          await saveDayCellContentsToFirebase(key, content);
          setDayCellContents((prev) => ({ ...prev, [key]: content }));
          break;

        case "year":
          await saveYearCellContentsToFirebase(key, content);
          setYearCellContents((prev) => ({ ...prev, [key]: content }));
          break;

        case "year12":
          await saveYear12ContentsToFirebase(key, content);
          setYear12Contents((prev) => ({ ...prev, [key]: content }));
          break;
      }

      // 성공 시 pending 항목 제거
      localStorage.removeItem(pendingKey);
    } catch (error) {
      // 재시도 횟수 증가
      localStorage.setItem(
        pendingKey,
        JSON.stringify({
          content,
          timestamp: Date.now(),
          retryCount: (pending.retryCount || 0) + 1,
        })
      );

      // 다시 재시도
      if ((pending.retryCount || 0) < maxRetries - 1) {
        retrySync(viewType, key, content, maxRetries, delay * 2);
      }
    }
  };
  const handleCellContentChange = useCallback(
    debounce(async (day, content) => {
      if (!auth.currentUser) return;

      const previousContent = monthlyContents[day];

      try {
        // Firebase 저장만 처리 (UI는 이미 업데이트 됨)
        const docRef = doc(
          db,
          `users/${auth.currentUser.uid}/Calendar/monthly`
        );
        await setDoc(docRef, { [day]: content }, { merge: true });

        // 로컬 백업
        localStorage.setItem(
          `calendar_backup_monthly_${day}`,
          JSON.stringify({
            content,
            timestamp: Date.now(),
          })
        );
      } catch (error) {
        console.error("❌ 저장 실패:", error);
        // 에러 시 UI 롤백
        setMonthlyContents((prev) => ({ ...prev, [day]: previousContent }));
        // ... 나머지 에러 처리
      }
    }, 500),
    []
  );

  const handleYearCellContentChange = useCallback(
    debounce(async (yearMonth, content) => {
      if (!auth.currentUser) return;

      // 이전 상태 백업
      const previousContent = yearCellContents[yearMonth];

      try {
        // 1. UI 업데이트 (기존과 동일)
        setYearCellContents((prev) => ({
          ...prev,
          [yearMonth]: content,
        }));

        // 2. Firebase 저장 (기존과 동일)
        await saveYearCellContentsToFirebase(yearMonth, content);

        // 3. 성공 시 로컬 백업
        localStorage.setItem(
          `calendar_backup_year_${yearMonth}`,
          JSON.stringify({
            content,
            timestamp: Date.now(),
          })
        );
      } catch (error) {
        console.error("저장 실패:", error);

        // 4. 실패 시 복구 작업
        setYearCellContents((prev) => ({
          ...prev,
          [yearMonth]: previousContent,
        })); // UI 롤백
        localStorage.setItem(
          `calendar_pending_year_${yearMonth}`,
          JSON.stringify({
            content,
            timestamp: Date.now(),
            retryCount: 0,
          })
        );
        retrySync("year", yearMonth, content); // 자동 재시도
      }
    }, 500),
    []
  );

  const handleWeeklyCellContentChange = useCallback(
    debounce(async (day, content) => {
      if (!auth.currentUser) return;

      const previousContent = weeklyContents[day];

      try {
        // Firebase 저장만 처리 (UI는 이미 업데이트 됨)
        const docRef = doc(db, `users/${auth.currentUser.uid}/Calendar/week1`);
        await setDoc(docRef, { [day]: content }, { merge: true });

        // 로컬 백업
        localStorage.setItem(
          `calendar_backup_weekly_${day}`,
          JSON.stringify({
            content,
            timestamp: Date.now(),
          })
        );
      } catch (error) {
        console.error("❌ 저장 실패:", error);
        // 에러 시 UI 롤백
        setWeeklyContents((prev) => ({ ...prev, [day]: previousContent }));
        // ... 나머지 에러 처리
      }
    }, 500),
    []
  );

  const handleDayCellContentChange = useCallback(
    debounce(async (key, content) => {
      if (!auth.currentUser) return;

      // 이전 상태 백업
      const previousContent = dayCellContents[key];

      try {
        // 1. UI 업데이트 (기존과 동일)
        setDayCellContents((prev) => ({
          ...prev,
          [key]: content,
        }));

        // 2. Firebase 저장 (기존과 동일)
        await saveDayCellContentsToFirebase(key, content);

        // 3. 성공 시 로컬 백업
        localStorage.setItem(
          `calendar_backup_daily_${key}`,
          JSON.stringify({
            content,
            timestamp: Date.now(),
          })
        );
      } catch (error) {
        console.error("일간 뷰 저장 실패:", error);

        // 4. 실패 시 복구 작업
        setDayCellContents((prev) => ({
          ...prev,
          [key]: previousContent,
        })); // UI 롤백
        localStorage.setItem(
          `calendar_pending_daily_${key}`,
          JSON.stringify({
            content,
            timestamp: Date.now(),
            retryCount: 0,
          })
        );
        retrySync("daily", key, content); // 자동 재시도
      }
    }, 500),
    []
  );

  const applyStyle = useCallback(
    (style, value) => {
      if (quillRefs.current[editingCell]) {
        const editor = quillRefs.current[editingCell].getEditor();
        const selection = editor.getSelection();
        if (selection) {
          switch (style) {
            case "bold":
              editor.format("bold", !editor.getFormat(selection).bold);
              break;
            case "italic":
              editor.format("italic", !editor.getFormat(selection).italic);
              break;
            case "underline":
              editor.format(
                "underline",
                !editor.getFormat(selection).underline
              );
              break;
            case "strike":
              editor.format("strike", !editor.getFormat(selection).strike);
              break;
            case "color":
              editor.format("color", value);
              break;
          }
        }
      }
    },
    [editingCell]
  );

  const handleColorChange = (e) => {
    applyStyle("color", e.target.value);
  };

  const renderCalendarDays = () => {
    const days = [];
    const daysInMonth = getDaysInMonth(
      selectedYear,
      months.indexOf(selectedMonth)
    );
    const firstDay = getFirstDayOfMonth(
      selectedYear,
      months.indexOf(selectedMonth)
    );

    // 달력 날짜 채우기
    for (let i = 0; i < firstDay; i++) {
      days.push(null);
    }
    for (let day = 1; day <= daysInMonth; day++) {
      days.push(day);
    }

    return days.map((day, index) => {
      if (day === null)
        return <div key={index} className="calendar-cell empty" />;

      const dateStr = `${selectedYear}-${selectedMonth}-${day}`;
      const isToday =
        day === today.getDate() &&
        selectedMonth === months[today.getMonth()] &&
        selectedYear === today.getFullYear();

      const cellStyle = {
        ...getCalendarCellStyle(isToday),
        backgroundColor: cellBackgroundColors[dateStr] || "transparent",
      };

      return (
        <div
          key={index}
          className={`calendar-cell ${isToday ? "today" : ""}`}
          style={cellStyle}
          data-day={dateStr}
          onClick={() => handleCellClick(dateStr)}
        >
          <div
            className="date-display"
            style={{ color: isToday ? "#FFCD29" : "inherit" }}
          >
            {day}
          </div>
          <div className="cell-content">
            <ReactQuill
              key={dateStr}
              ref={(el) => (quillRefs.current[dateStr] = el)}
              value={monthlyContents[dateStr] || ""}
              onChange={(content) => {
                updateMonthlyContents(dateStr, content, "직접 입력"); // 여기에 trigger 추가
                handleCellContentChange(dateStr, content);
              }}
              onFocus={() => setEditingCell(dateStr)}
              modules={{
                toolbar: false,
              }}
              formats={[
                "bold",
                "italic",
                "underline",
                "strike",
                "color",
                "size",
              ]}
            />
          </div>
          {renderUnitCard(
            new Date(selectedYear, months.indexOf(selectedMonth), day)
          )}
        </div>
      );
    });
  };

  const renderWeekCalendar1 = () => {
    const startOfWeek = new Date(
      selectedYear,
      months.indexOf(selectedMonth),
      1 + selectedWeek * 7 - 1
    );
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1);
    const days = [];

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startOfWeek);
      currentDate.setDate(startOfWeek.getDate() + i);
      const isToday = currentDate.toDateString() === today.toDateString();
      const day = `${currentDate.getFullYear()}-${
        months[currentDate.getMonth()]
      }-${currentDate.getDate()}`;
      const isEditing = editingCell === day;
      const cellStyle = getCalendarCellStyle(isToday);

      days.push(
        <div
          key={i}
          className={`calendar-cell ${isToday ? "today" : ""} ${
            isEditing ? "editing" : ""
          } ${selectedCell === day ? "selected" : ""}`}
          style={cellStyle}
          onClick={() => handleCellClick(day)}
        >
          <div>{currentDate.getDate()}</div>
          {renderUnitCard(currentDate)}
          <div className="cell-content">
            <ReactQuill
              key={day}
              ref={(el) => (quillRefs.current[day] = el)}
              value={weeklyContents[day] || ""}
              onChange={(content) => {
                // 먼저 UI 업데이트
                updateWeeklyContents(day, content);
                // 그 다음 Firebase 저장 (debounce 됨)
                handleWeeklyCellContentChange(day, content);
              }}
              onFocus={() => setEditingCell(day)}
              modules={{
                toolbar: false,
              }}
              formats={[
                "bold",
                "italic",
                "underline",
                "strike",
                "color",
                "size",
              ]}
            />
          </div>
        </div>
      );
    }

    return days;
  };

  const handleTimeBlockMouseDown = (day, hour, minute) => {
    setIsDragging(true);
    const timeKey = `${day}-${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;
    setDragStart({
      day,
      hour,
      minute,
      timeKey,
    });
    setSelectedTimeBlocks([timeKey]);
  };

  const handleTimeBlockMouseEnter = (day, hour, minute) => {
    if (!isDragging || !dragStart) return;

    const currentTimeKey = `${day}-${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;

    // 같은 날짜 내에서만 드래그 가능하도록 제한
    if (day !== dragStart.day) return;

    // 시작 시간과 현재 시간 사이의 모든 블록 선택
    const startHour = Math.min(dragStart.hour, hour);
    const endHour = Math.max(dragStart.hour, hour);
    const startMinute = startHour === dragStart.hour ? dragStart.minute : 0;
    const endMinute = endHour === hour ? minute : 50;

    const selectedBlocks = [];

    for (let h = startHour; h <= endHour; h++) {
      const minStart = h === startHour ? startMinute : 0;
      const minEnd = h === endHour ? endMinute : 50;

      for (let m = minStart; m <= minEnd; m += 10) {
        const timeKey = `${day}-${h.toString().padStart(2, "0")}:${m
          .toString()
          .padStart(2, "0")}`;
        selectedBlocks.push(timeKey);
      }
    }

    setSelectedTimeBlocks(selectedBlocks);
  };

  const handleTimeBlockMouseUp = (e) => {
    setIsDragging(false);
    setShowMiniToolbar(true);
    setMiniToolbarPosition({
      x: window.innerWidth / 2,
      y: window.innerHeight - 100,
    });
  };

  const hexToRGBA = (hex, alpha = 1) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const handleColorSelect = (color) => {
    if (color === "transparent") {
      setSelectedTimeBlocks((prevSelected) => {
        const newColors = { ...timeBlockColors };
        prevSelected.forEach((timeKey) => {
          delete newColors[timeKey];
        });
        setTimeBlockColors(newColors);

        saveWeek2ColorsToFirebase(newColors);

        return [];
      });
    } else {
      const colorWithOpacity = hexToRGBA(color, 0.5);
      setSelectedTimeBlocks((prevSelected) => {
        const newColors = { ...timeBlockColors };
        prevSelected.forEach((timeKey) => {
          newColors[timeKey] = colorWithOpacity;
        });
        setTimeBlockColors(newColors);

        saveWeek2ColorsToFirebase(newColors);

        return [];
      });
    }
    setShowMiniToolbar(false);
  };

  const handleColorNameChange = (color, newName) => {
    // transparent 색상의 이름은 변경할 수 없도록 함
    if (color === "transparent") return;

    setColorNames((prevNames) => {
      const newNames = { ...prevNames, [color]: newName };
      saveColorNamesToFirebase(newNames);
      return newNames;
    });
  };

  const [isWeek2Loading, setIsWeek2Loading] = useState(false);

  useEffect(() => {
    const loadWeek2Data = async () => {
      if (isWeek2Loading) {
        return;
      }

      try {
        setIsWeek2Loading(true);

        const [colors, names] = await Promise.all([
          fetchWeek2ColorsFromFirebase(),
          fetchColorNamesFromFirebase(),
        ]);

        if (colors) {
          setTimeBlockColors(colors);
        }

        if (names) {
          setColorNames(names);
        }
      } catch (error) {
        console.error("❌ Week2 데이터 로딩 중 오류:", error);
      } finally {
        setIsWeek2Loading(false);
      }
    };

    loadWeek2Data();
  }, []);

  const calculateStudyTime = (day, color) => {
    let count = 0;
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 10) {
        const timeKey = `${day}-${hour.toString().padStart(2, "0")}:${minute
          .toString()
          .padStart(2, "0")}`;

        const expectedColor = hexToRGBA(color, 0.5);
        if (timeBlockColors[timeKey] === expectedColor) {
          count++;
        }
      }
    }

    const totalMinutes = count * 10;
    return totalMinutes;
  };

  const formatStudyTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    if (hours > 0) {
      return `${hours}hr ${mins}min`;
    }
    return `${mins}min`;
  };

  const renderWeekCalendar2 = () => {
    const startOfWeek = new Date(
      selectedYear,
      months.indexOf(selectedMonth),
      1 + selectedWeek * 7 - 1
    );
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1);
    const days = [];

    days.push(
      <div
        key="time-column"
        className="week2-time-column"
        style={{ width: "20px" }}
      >
        <div className="week2-time-cells-container">
          {Array.from({ length: 24 }, (_, i) => (
            <div key={`time-${i}`} className="week2-time-cell">
              {i.toString().padStart(2, "0")}
            </div>
          ))}
        </div>
      </div>
    );

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startOfWeek);
      currentDate.setDate(startOfWeek.getDate() + i);
      const isToday = currentDate.toDateString() === today.toDateString();
      const day = `${currentDate.getFullYear()}-${
        months[currentDate.getMonth()]
      }-${currentDate.getDate()}`;
      const isEditing = editingCell === day;
      const cellStyle = getCalendarCellStyle(isToday);
      cellStyle.className += " week2-cell";

      const timeBlocks = [];
      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 10) {
          const timeKey = `${day}-${hour.toString().padStart(2, "0")}:${minute
            .toString()
            .padStart(2, "0")}`;
          timeBlocks.push(
            <div
              key={timeKey}
              className={`time-block week2-cell ${
                selectedTimeBlocks.includes(timeKey) ? "selected" : ""
              }`}
              style={{ backgroundColor: timeBlockColors[timeKey] || "" }}
              onMouseDown={() => handleTimeBlockMouseDown(day, hour, minute)}
              onMouseEnter={() => handleTimeBlockMouseEnter(day, hour, minute)}
              onMouseUp={handleTimeBlockMouseUp}
            ></div>
          );
        }
      }

      // 각 색상별 공부시간 계산
      const studyTimes = [
        "#9DB4C0",
        "#C2B0C9",
        "#86A69D",
        "#C9A9A6",
        "#A4BE7B",
        "#DEB6AB",
        "#B5C0D0",
      ]
        .map((color) => ({
          color,
          time: calculateStudyTime(day, color),
          name: colorNames[color],
        }))
        .filter((item) => item.time > 0); // 공부시간이 0보다 큰 것만 필터링

      // 총 공부시간 계산
      const totalStudyTime = studyTimes.reduce(
        (total, item) => total + item.time,
        0
      );

      days.push(
        <div
          key={i}
          className={`calendar-cell week2-cell ${isToday ? "today" : ""} ${
            isEditing ? "editing" : ""
          } ${selectedCell === day ? "selected" : ""}`}
          style={cellStyle}
          onClick={() => handleCellClick(day)}
        >
          <div className="week2-date">
            <span className="week2-day-name">
              {
                ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"][
                  currentDate.getDay() === 0 ? 6 : currentDate.getDay() - 1
                ]
              }
            </span>
            <span className="week2-day-number">{currentDate.getDate()}</span>
          </div>
          <div className="time-blocks-container">{timeBlocks}</div>
          {/* 색상 박스 컨테이너 추가 */}
          <div className="week2-color-boxes">
            {studyTimes.map(({ color, time, name }) => (
              <div key={`${day}-${color}`} className="week2-color-box">
                <div
                  className="color-indicator"
                  style={{ backgroundColor: color }}
                />
                <span className="color-name">
                  {name} ({formatStudyTime(time)})
                </span>
              </div>
            ))}
            {totalStudyTime > 0 && (
              <div className="week2-total-study-time">
                Total: {formatStudyTime(totalStudyTime)}
              </div>
            )}
          </div>
        </div>
      );
    }

    return days;
  };

  const renderYearCalendar = () => {
    const yearCalendar = [];

    const monthHeaders = months.map((month) => (
      <div key={`header-${month}`} className="year-month-header">
        {month}
      </div>
    ));
    yearCalendar.push(
      <div key="month-headers" className="year-row month-headers">
        {monthHeaders}
      </div>
    );

    for (let day = 1; day <= 31; day++) {
      const dayRow = months.map((month, monthIndex) => {
        const daysInMonth = getDaysInMonth(selectedYear, monthIndex);
        const isValidDate = day <= daysInMonth;
        const date = new Date(selectedYear, monthIndex, day);
        const dayOfWeek = date.getDay();
        const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
        const cellKey = `${selectedYear}-${month}-${day}`;
        const isEditing = editingCell === cellKey;

        return (
          <div
            key={`cell-${month}-${day}`}
            className={`year-cell ${isValidDate ? "" : "invalid"} ${
              isWeekend ? "weekend" : ""
            } ${selectedCell === cellKey ? "selected" : ""}`}
            onClick={() => isValidDate && handleCellClick(cellKey)}
            style={{
              backgroundColor: cellBackgroundColors[cellKey] || "transparent",
            }}
          >
            {isValidDate && (
              <>
                <span className="day-number">{day}</span>
                <ReactQuill
                  key={cellKey}
                  ref={(el) => (quillRefs.current[cellKey] = el)}
                  value={yearCellContents[cellKey] || ""}
                  onChange={(content) => {
                    handleYearCellContentChange(cellKey, content);
                  }}
                  onFocus={() => setEditingCell(cellKey)}
                  modules={{
                    toolbar: false,
                  }}
                  formats={[
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "color",
                    "size",
                  ]}
                />
              </>
            )}
          </div>
        );
      });
      yearCalendar.push(
        <div key={`row-${day}`} className="year-row">
          {dayRow}
        </div>
      );
    }

    return yearCalendar;
  };

  const [selectedDayCell, setSelectedDayCell] = useState(null);
  const [dayCellBackgroundColors, setDayCellBackgroundColors] = useState({});
  const [rowColumnWidths, setRowColumnWidths] = useState({});
  const [dayColumnWidths, setDayColumnWidths] = useState(() => {
    const savedWidths = localStorage.getItem("dayColumnWidths");
    return savedWidths ? JSON.parse(savedWidths) : {};
  });

  const renderDayCalendar = () => {
    const timeSlots = [];
    for (let hour = 4; hour < 28; hour++) {
      const displayHour = hour % 24;
      timeSlots.push(`${displayHour.toString().padStart(2, "0")}:00`);
    }

    const dateKey = selectedDate.toISOString().split("T")[0];
    const dayWidths = dayColumnWidths[dateKey] || {};

    return (
      <div className="day-grid">
        <div className="daytable-header">
          <div className="time-label"></div>
          {[10, 20, 30, 40, 50, 60].map((num) => (
            <div key={`header-${num}`} className="header-column">
              {num}
            </div>
          ))}
        </div>

        {timeSlots.map((time) => {
          const key = `${selectedDate.toISOString().split("T")[0]}-${time}`;
          return (
            <div key={time} className="day-cell">
              <div className="time-label">{time}</div>
              <div className="time-content">
                {[0, 1, 2, 3, 4, 5].map((column) => {
                  const columnKey = `${key}-column-${column}`;
                  const columnWidth = dayWidths[time]?.[column] || 100 / 6;
                  return (
                    <div
                      key={columnKey}
                      className="time-column"
                      onClick={() => handleDayCellClick(columnKey)}
                      style={{
                        backgroundColor:
                          dayCellBackgroundColors[columnKey] || "transparent",
                        width: `${columnWidth}%`,
                      }}
                    >
                      <ReactQuill
                        ref={(el) => (quillRefs.current[columnKey] = el)}
                        value={dayCellContents[columnKey] || ""}
                        onChange={(content) =>
                          handleDayCellContentChange(columnKey, content)
                        }
                        onFocus={() => setEditingCell(columnKey)}
                        modules={{
                          toolbar: false,
                        }}
                        formats={[
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "color",
                          "size",
                        ]}
                        style={{ height: "100%" }}
                        className="no-spellcheck"
                      />
                      {column < 5 && (
                        <div
                          className="calendar-resize-handle"
                          onMouseDown={(e) => handleResize(e, time, column)}
                        ></div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleDayCellClick = (cellKey) => {
    setSelectedDayCell(cellKey);
    setSelectedCell(null); // 월간 뷰의 선택된 셀 초기화
  };

  const handlePrevWeek = () => {
    setSelectedWeek((prev) => Math.max(0, prev - 1));
  };

  const handleNextWeek = () => {
    setSelectedWeek((prev) => Math.min(5, prev + 1));
  };

  const handleFontChange = async (newFont) => {
    setFont(newFont);
    document.documentElement.style.setProperty("--calendar-font", newFont);
    await saveFontSettingToFirebase(newFont);
  };

  useEffect(() => {
    const loadFontSetting = async () => {
      const savedFont = await fetchFontSettingFromFirebase();
      if (savedFont) {
        setFont(savedFont);
        document.documentElement.style.setProperty(
          "--calendar-font",
          savedFont
        );
      }
    };

    if (auth.currentUser) {
      loadFontSetting();
    }
  }, [auth.currentUser]);

  useEffect(() => {
    document.documentElement.style.setProperty("--calendar-font", font);
    const fontToLoad = fonts.find((f) => f.name === font);
    if (fontToLoad && fontToLoad.url) {
      const fontFace = new FontFace(font, `url(${fontToLoad.url})`);
      fontFace
        .load()
        .then((loadedFont) => {
          document.fonts.add(loadedFont);
          document.body.style.fontFamily = font;
        })
        .catch((error) => {});
    } else {
      document.body.style.fontFamily = font;
    }
  }, [font]);

  const miniToolbarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showMiniToolbar) {
        const isTimeBlock = event.target.closest(".time-block");
        const isMiniToolbar = event.target.closest(".mini-toolbar");

        if (!isTimeBlock && !isMiniToolbar) {
          setShowMiniToolbar(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMiniToolbar]);

  useEffect(() => {
    const dayCalendar = document.querySelector(".day-calendar");
    if (dayCalendar && view === "day") {
      const handleScroll = () => {
        document.documentElement.style.setProperty(
          "--scroll-top",
          `${-dayCalendar.scrollTop}px`
        );
      };
      dayCalendar.addEventListener("scroll", handleScroll);
      return () => dayCalendar.removeEventListener("scroll", handleScroll);
    }
  }, [view]);
  const handleMonthChange = async (month) => {
    const currentData = { ...weeklyContents };
    setSelectedMonth(month);

    try {
      const newData = await fetchWeek1ContentsFromFirebase();
      const mergedData = {
        ...newData,
        ...currentData,
      };
      updateWeeklyContents(null, mergedData);
    } catch (error) {
      console.error("❌ 월 변경 중 오류 발생:", error);
    }
  };
  // Firebase에서 데이터 불러오기 함수 수정
  const fetchWeek1ContentsFromFirebase = async () => {
    const user = auth.currentUser;
    if (!user) return {};

    try {
      const docRef = doc(db, `users/${user.uid}/Calendar/week1`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data();
      }
      return {};
    } catch (error) {
      console.error("데이터 로딩 실패:", error);
      return {};
    }
  };
  // useEffect 수정
  useEffect(() => {
    const loadViewData = async () => {
      if (!auth.currentUser || view !== "week1") return;

      // 현재 주의 키 생성
      const weekKey = `${selectedYear}-${selectedMonth}-W${selectedWeek}`;

      // 이미 로딩 중이거나 캐시에 있는 데이터면 스킵
      if (isWeeklyDataLoading || weekDataCache.current.has(weekKey)) return;

      try {
        setIsWeeklyDataLoading(true);
        const weeklyData = await fetchWeek1ContentsFromFirebase();

        // 데이터 검증 및 캐시 저장
        if (weeklyData && typeof weeklyData === "object") {
          weekDataCache.current.set(weekKey, weeklyData);

          // view가 여전히 week1인 경우에만 업데이트
          if (view === "week1") {
            updateWeeklyContents(null, (prevContents) => ({
              ...prevContents,
              ...weeklyData,
            }));
          }
        }
      } catch (error) {
        console.error("주간 데이터 로딩 중 오류:", error);
      } finally {
        setIsWeeklyDataLoading(false);
      }
    };

    loadViewData();
  }, [view, selectedYear, selectedMonth, selectedWeek, auth.currentUser]);

  // renderMonthTabs 함수 수정
  const renderMonthTabs = () => {
    return (
      <div className="month-tabs">
        {/* Year selector를 월 탭 앞쪽으로 이동 */}
        <div className="year-selector">
          <button className="year-button" onClick={handlePrevYear}>
            &lt;
          </button>
          <span className="year-display">{selectedYear}</span>
          <button className="year-button" onClick={handleNextYear}>
            &gt;
          </button>
        </div>

        {months.map((month) => (
          <button
            key={month}
            className={`month-tab ${selectedMonth === month ? "active" : ""}`}
            onClick={() => handleMonthChange(month)} // 여기를 수정! setSelectedMonth -> handleMonthChange
          >
            {month}
          </button>
        ))}
      </div>
    );
  };

  const handleResize = (e, time, column) => {
    const isFirstColumn = column === 0;

    const startX = e.clientX;
    const startWidth = e.target.parentElement.getBoundingClientRect().width;
    const containerWidth =
      e.target.parentElement.parentElement.getBoundingClientRect().width;

    const onMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - startX;

      // 첫 째 열의 리사이저인 경우 최대 너비를 99.8%로 변경
      let newPercentage;
      if (isFirstColumn) {
        newPercentage = Math.min(
          99.8, // 최대값을 99.8%로 수정
          Math.max(5, ((startWidth + deltaX) / containerWidth) * 100)
        );
      } else {
        newPercentage = Math.max(
          5,
          ((startWidth + deltaX) / containerWidth) * 100
        );
      }

      setDayColumnWidths((prevWidths) => {
        const dateKey = selectedDate.toISOString().split("T")[0];
        const currentWidths = prevWidths[dateKey]?.[time] || {};

        // 첫 번째 열의 리사이저인 경우 특별한 분배 로직 적용
        if (isFirstColumn) {
          const remainingWidth = 100 - newPercentage;
          const remainingColumns = 5;
          const widthPerColumn = remainingWidth / remainingColumns;

          const columnWidths = {
            0: newPercentage,
          };

          // 나머지 열들의 너비를 균등하게 분배
          for (let i = 1; i < 6; i++) {
            columnWidths[i] = widthPerColumn;
          }

          const newWidths = {
            ...prevWidths,
            [dateKey]: {
              ...(prevWidths[dateKey] || {}),
              [time]: columnWidths,
            },
          };

          localStorage.setItem("dayColumnWidths", JSON.stringify(newWidths));
          saveDayColumnWidthsToFirebase(newWidths);
          return newWidths;
        } else {
          // 다른 열들은 기 로직 유지
          const newWidths = {
            ...prevWidths,
            [dateKey]: {
              ...(prevWidths[dateKey] || {}),
              [time]: {
                ...currentWidths,
                [column]: newPercentage,
              },
            },
          };

          localStorage.setItem("dayColumnWidths", JSON.stringify(newWidths));
          saveDayColumnWidthsToFirebase(newWidths);
          return newWidths;
        }
      });
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const handleDayCellBackgroundColorChange = (newColors) => {
    setDayCellBackgroundColors(newColors);

    saveDayCellBackgroundColorsToFirebase(newColors).catch((error) =>
      console.error("색 저장 실패:", error)
    );
  };

  // view가 변경될 때마다 해당 뷰의 데이터 새로 로딩
  useEffect(() => {
    const loadViewSpecificData = async () => {
      if (!auth.currentUser) return;

      try {
        switch (view) {
          case "week1":
            // week1 데이터 로딩 추가
            const week1Contents = await fetchCellContentsFromFirebase("week1");
            updateWeeklyContents(null, week1Contents);
            break;
          case "week2":
            const week2Colors = await fetchWeek2ColorsFromFirebase();
            setTimeBlockColors(week2Colors);
            const names = await fetchColorNamesFromFirebase();
            setColorNames(names);
            break;
        }
      } catch (error) {
        console.error(`${view} 뷰 데이터 로딩 중 오류:`, error);
      }
    };

    loadViewSpecificData();
  }, [view, auth.currentUser]);

  // selectedDate가 변경될 때마다 일간 뷰 데이터 로드
  useEffect(() => {
    const loadDayViewData = async () => {
      if (view === "day" && auth.currentUser) {
        try {
          const dayData = await fetchDayCellContentsFromFirebase(selectedDate);
          // 배경색과 컨텐츠 데이터 설정
          setDayCellBackgroundColors(dayData.dayCellBackgroundColors || {});
          setDayCellContents(
            Object.fromEntries(
              Object.entries(dayData).filter(
                ([key]) => !key.includes("dayCellBackgroundColors")
              )
            )
          );

          // 컬럼 너비 데이터가 있다면 설정
          if (dayData.dayColumnWidths) {
            setDayColumnWidths(dayData.dayColumnWidths);
          }
        } catch (error) {
          console.error("일간 뷰 데이터 로딩 중 오류:", error);
        }
      }
    };

    loadDayViewData();
  }, [selectedDate, view, auth.currentUser]);
  // 월별 유닛 카드 데이터 로드
  useEffect(() => {
    const loadMonthlyUnitCards = async () => {
      if (!auth.currentUser) {
        return;
      }

      const startDate = new Date(
        selectedYear,
        months.indexOf(selectedMonth),
        1
      );
      const endDate = new Date(
        selectedYear,
        months.indexOf(selectedMonth) + 1,
        0
      );

      const unitCards = await fetchUnitCardsByPeriod(startDate, endDate);
      setMonthlyUnitCards(unitCards);
    };

    loadMonthlyUnitCards();
  }, [selectedYear, selectedMonth]);

  // 컴포넌트 언마운트 시 저장을 위한 useEffect
  useEffect(() => {
    return () => {
      // 현재 편집 중인 내용이 있다면 강제로 저장
      if (weeklyContents) {
        Object.entries(weeklyContents).forEach(([day, content]) => {
          if (content) {
            handleCellContentChange.flush(); // 대기 중인 디바운스 작업 즉시 실행
          }
        });
      }
    };
  }, [weeklyContents, handleCellContentChange]);

  // 유닛 카드 렌더링 함수
  const renderUnitCard = (date) => {
    const dateStr = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    )
      .toISOString()
      .split("T")[0];

    const unitEvents = monthlyUnitCards[dateStr];

    if (!unitEvents || !Array.isArray(unitEvents) || unitEvents.length === 0) {
      return null;
    }

    return (
      <div className="unit-cards-container">
        {unitEvents.map((event) => (
          <div
            key={event.unitId}
            className="unit-card"
            style={{
              border: `1px solid ${event.color}`,
              color: event.color,
              backgroundColor: "transparent",
              padding: "2px 8px",
              margin: "2px 0",
              borderRadius: "4px",
              fontSize: "1.4em",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {event.unitName}
          </div>
        ))}
      </div>
    );
  };

  // CSS 업데이트
  const styles = `
  .unit-cards-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-height: 60px;
    overflow-y: auto;
    padding: 2px;
  }

  .unit-cards-container::-webkit-scrollbar {
    width: 4px;
  }

  .unit-cards-container::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 2px;
  }

  .unit-card {
    transition: all 0.2s ease;
  }

  .unit-card:hover {
    transform: translateY(-1px);
  }
  `;

  // 페이지 진입 시 사이드바 닫기
  useEffect(() => {
    setIsSidebarMinimized(true);
  }, []); // 빈 의존성 배열로 컴포넌트 마운트 시에만 실행

  // 반응형 처리를 위한 리사이즈 이벤트
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setIsSidebarMinimized(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setIsSidebarMinimized]);

  const handleTodayClick = () => {
    const today = new Date();
    setSelectedYear(today.getFullYear());
    setSelectedMonth(months[today.getMonth()]);

    // 주간 뷰를 위한 현재 주 계산
    const start = new Date(today.getFullYear(), today.getMonth(), 1);
    const firstDay = start.getDay();
    const currentWeek = Math.floor(
      (today.getDate() + (firstDay === 0 ? 6 : firstDay - 1)) / 7
    );
    setSelectedWeek(currentWeek);

    // 일간 뷰를 위한 날짜 설정
    setSelectedDate(today);

    // 해당 날짜의 셀 선택
    const todayKey = `${today.getFullYear()}-${
      months[today.getMonth()]
    }-${today.getDate()}`;
    setSelectedCell(todayKey);
  };

  useEffect(() => {
    const handleMouseUp = () => {
      setIsDragging(false);
      setDragStart(null);
    };

    document.addEventListener("mouseup", handleMouseUp);
    return () => document.removeEventListener("mouseup", handleMouseUp);
  }, []);

  // Firebase 저장 함수 추가
  const saveYear12ContentsToFirebase = async (yearMonth, content) => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      const docRef = doc(db, `users/${user.uid}/Calendar/year12`);

      // 기존 데이터 가져오기
      const docSnap = await getDoc(docRef);
      const existingData = docSnap.exists() ? docSnap.data() : {};

      // 새 데이터로 업데이트
      const newData = {
        ...existingData,
        year12CellContents: {
          ...(existingData.year12CellContents || {}),
          [yearMonth]: content,
        },
      };

      await setDoc(docRef, newData);
    } catch (error) {
      console.error("Error saving year12 contents:", error);
    }
  };

  // 컨텐츠 변경 핸들러
  const handleYear12ContentChange = useCallback(
    debounce(async (yearMonth, content) => {
      if (!auth.currentUser) return;

      // 이전 상태 백업
      const previousContent = year12Contents[yearMonth];

      try {
        // 1. UI 업데이트
        setYear12Contents((prev) => ({
          ...prev,
          [yearMonth]: content,
        }));

        // 2. Firebase 저장
        await saveYear12ContentsToFirebase(yearMonth, content);

        // 3. 로컬 백업
        localStorage.setItem(
          `calendar_backup_year12_${yearMonth}`,
          JSON.stringify({
            content,
            timestamp: Date.now(),
          })
        );
      } catch (error) {
        console.error("❌ 저장 실패:", error);
        // 4. 실패 시 복구 작업
        setYear12Contents((prev) => ({
          ...prev,
          [yearMonth]: previousContent,
        }));
        // ... 나머지 에러 처리
      }
    }, 300),
    []
  );

  // Firebase에서 12개월 뷰 데이터 불러오기 함수 추가
  const fetchYear12ContentsFromFirebase = async () => {
    const user = auth.currentUser;
    if (!user) return {};

    try {
      const docRef = doc(db, `users/${user.uid}/Calendar/year12`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data().year12CellContents || {};
        return data;
      }
      return {};
    } catch (error) {
      console.error("Error fetching year12 contents:", error);
      return {};
    }
  };

  // Year12 데이터 로딩 useEffect 수정
  useEffect(() => {
    const loadYear12Data = async () => {
      if (view === "year12" && auth.currentUser) {
        try {
          const year12Data = await fetchYear12ContentsFromFirebase();
          // 기존 데이터를 유지하면서 새 데이터 병합
          setYear12Contents((prev) => ({
            ...prev,
            ...year12Data,
          }));
        } catch (error) {
          console.error("Error loading year12 data:", error);
        }
      }
    };

    loadYear12Data();
  }, [view, auth.currentUser]); // selectedYear 의존성 제거

  // render12MonthsView 함수 수정
  const render12MonthsView = () => {
    return (
      <div className="year12-calendar">
        <div className="year12-grid">
          {months.map((month) => {
            const yearMonth = `${selectedYear}-${month}`;

            return (
              <div
                key={yearMonth}
                className="month-container"
                // 이벤트 버블링 방지
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  className="month-header"
                  onClick={(e) => e.stopPropagation()}
                >
                  {month}
                </div>
                <div
                  className="month-content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <ReactQuill
                    key={`quill-${yearMonth}`}
                    value={year12Contents[yearMonth] || ""}
                    onChange={(newContent) => {
                      handleYear12ContentChange(yearMonth, newContent);
                    }}
                    // 포커스 관련 props 추가
                    onFocus={() => setEditingCell(yearMonth)} // 추가: onFocus 핸들러
                    preserveWhitespace={true}
                    bounds=".month-content"
                    modules={{
                      toolbar: false,
                      clipboard: { matchVisual: false },
                      keyboard: {
                        bindings: {
                          tab: false,
                          "indent-1": false,
                        },
                      },
                    }}
                    formats={["bold", "italic", "underline", "strike", "color"]}
                    ref={(el) => (quillRefs.current[yearMonth] = el)} // 추가: ref 설정
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  // 편집 관련 stat
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingText, setEditingText] = useState("");

  // 주간 단위로 유닛카드를 가져오도록 수정
  useEffect(() => {
    const loadWeeklyUnitCards = async () => {
      if (!auth.currentUser || view !== "week1") {
        return;
      }

      // 선택된 주의 시작일과 종료일 계산
      const startOfWeek = new Date(
        selectedYear,
        months.indexOf(selectedMonth),
        1 + selectedWeek * 7 - 1
      );
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1);

      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);

      const unitCards = await fetchUnitCardsByPeriod(startOfWeek, endOfWeek);
      setMonthlyUnitCards(unitCards);
    };

    loadWeeklyUnitCards();
  }, [selectedYear, selectedMonth, selectedWeek, view]);

  // 추가: 클릭 이벤트 핸들러
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        fontOptionsRef.current &&
        !fontOptionsRef.current.contains(event.target)
      ) {
        setShowFontOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // 사용자 변경 시 상태 초기화 로직 수정
  useEffect(() => {
    const currentUser = auth.currentUser;

    if (prevUserRef.current?.uid !== currentUser?.uid) {
      // 1. 즉시 UI 상태 초기화
      const resetStates = () => {
        setDayCellBackgroundColors({});
        setDayCellContents({});
        setCellBackgroundColors({});
        setYearCellContents({});
        setMonthlyUnitCards({});
        setTimeBlockColors({});
        setColorNames(defaultColorNames);
        updateWeeklyContents(null, {});
      };

      // 3. 백그라운드 데이터 로드 (캐싱 추가)
      const loadRemainingData = async () => {
        if (!currentUser) return;

        const cacheKey = `${currentUser.uid}_background`;
        const cachedData = sessionStorage.getItem(cacheKey);

        if (cachedData) {
          const { colors, yearContents } = JSON.parse(cachedData);
          setDayCellBackgroundColors(colors);
          setCellBackgroundColors(colors);
          setYearCellContents(yearContents);
          return;
        }

        try {
          const [colors, yearContents] = await Promise.all([
            fetchDayCellBackgroundColorsFromFirebase(),
            fetchYearCellContentsFromFirebase(),
          ]);

          setDayCellBackgroundColors(colors);
          setCellBackgroundColors(colors);
          setYearCellContents(yearContents);

          sessionStorage.setItem(
            cacheKey,
            JSON.stringify({ colors, yearContents })
          );
        } catch (error) {
          console.error("백그라운드 데이터 로드 실패:", error);
        }
      };

      // 실행
      resetStates();
      prevUserRef.current = currentUser;

      if (currentUser) {
        // loadPriorityData 제거하고 loadRemainingData만 실행
        loadRemainingData().catch((error) => {
          console.error("데이터 로드 중 오류:", error);
        });
      }
    }
  }, [auth.currentUser, view, selectedDate]);

  // 데이터 로드 함수 분리
  const loadInitialData = async (user) => {
    try {
      const [colors, contents, yearContents, weeklyContents] =
        await Promise.all([
          fetchDayCellBackgroundColorsFromFirebase(),
          fetchCellContentsFromFirebase("monthly"),
          fetchYearCellContentsFromFirebase(),
          fetchCellContentsFromFirebase("week1"),
        ]);

      // 상태 한번에 업데이트
      requestAnimationFrame(() => {
        setDayCellBackgroundColors(colors);
        setCellBackgroundColors(colors);
        updateMonthlyContents(null, contents);
        updateWeeklyContents(null, weeklyContents);
        setYearCellContents(yearContents);
      });
    } catch (error) {
      console.error("데이터 로딩 중 오류:", error);
    }
  };

  const prevUserRef = useRef(null); // 이전 사용자 추적을 위한 ref 추가

  // 캐싱 로직 추가
  const cache = new Map();

  const fetchWithCache = async (key, fetchFn) => {
    if (cache.has(key)) {
      return cache.get(key);
    }
    const data = await fetchFn();
    cache.set(key, data);
    return data;
  };

  // 선택적 데이터 로딩
  const [isViewDataLoading, setIsViewDataLoading] = useState(false);
  const viewDataCache = useRef(new Map());

  // loadMonthlyData와 loadViewData를 통합
  useEffect(() => {
    const loadMonthlyData = async () => {
      const user = auth.currentUser;
      if (!user || isViewDataLoading || view !== "month") return;

      // 이미 데이터가 있는지 확인

      try {
        setIsViewDataLoading(true);
        const monthlyData = await fetchCellContentsFromFirebase("monthly");
        updateMonthlyContents(null, monthlyData);
      } catch (error) {
        console.error("❌ 데이터 로딩 실패:", error);
      } finally {
        setIsViewDataLoading(false);
      }
    };

    loadMonthlyData();
  }, [view, selectedMonth, selectedYear]); // 필요한 의존성만 포함

  // year12Contents state 추가
  const [year12Contents, setYear12Contents] = useState({});

  const loadMonthlyData = async () => {
    if (!auth.currentUser) return;

    try {
      const data = await fetchCellContentsFromFirebase("monthly");

      // 기존 데이터 유지하면서 새 데이터 병합
      updateMonthlyContents(null, {
        ...monthlyContents,
        ...data,
      });
    } catch (error) {
      console.error("월별 데이터 로드 실패:", error);
    }
  };

  // 월간 뷰 컨텐츠 변경 핸들러
  const handleMonthlyContentChange = useCallback(
    debounce(async (day, content) => {
      if (!auth.currentUser) return;

      try {
        const docRef = doc(
          db,
          `users/${auth.currentUser.uid}/Calendar/monthly`
        );

        // 로컬 상태 업데이트는 이미 onChange에서 수행됨
        await setDoc(docRef, { [day]: content }, { merge: true });
      } catch (error) {
        console.error("월간 저장 실패:", error);
      }
    }, 500),
    []
  );

  // 월 변경 시 데이터 로딩 관련 로그
  useEffect(() => {
    // 선택된 날짜의 일(day)을 유지하면서 월만 변경
    const newDate = new Date(
      selectedYear,
      months.indexOf(selectedMonth),
      selectedDate.getDate()
    );

    // 만약 새로운 날짜가 유효하지 않다면, 해당 월의 마지막 날로 설정
    if (newDate.getMonth() !== months.indexOf(selectedMonth)) {
      newDate.setDate(0); // 마지막 날로 설정
    }

    setSelectedDate(newDate); // 선택된 날짜 업데이트
  }, [selectedMonth, selectedYear, view]);

  // Calendar 컴포넌트 내부에 추가
  const [sidebarWidth, setSidebarWidth] = useState(400); // 기본 사이드바 너비

  useEffect(() => {
    const updateSidebarWidth = () => {
      const sidebar = document.querySelector(".cal-todo-sidebar-container");
      if (sidebar) {
        const width = sidebar.offsetWidth;
        setSidebarWidth(width);
      }
    };

    // 초기 너비 설정
    updateSidebarWidth();

    // resize 이벤트 리스너 추가
    window.addEventListener("resize", updateSidebarWidth);

    return () => {
      window.removeEventListener("resize", updateSidebarWidth);
    };
  }, []);

  // 토글 버튼 렌더링 부분 수정
  <button
    className="todo-toggle-button"
    onClick={() => setIsTodoSidebarOpen(!isTodoSidebarOpen)}
    style={{
      position: "fixed",
      right: isTodoSidebarOpen ? `${sidebarWidth}px` : "0",
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 1001,
      padding: "8px",
      backgroundColor: "#1a1a1a",
      border: "none",
      borderRadius: "4px 0 0 4px",
      cursor: "pointer",
      transition: "right 0.3s ease",
    }}
  >
    <img
      src="/toolbaricons/inuse/checklist.svg"
      alt="Toggle Todo"
      style={{ width: "24px", height: "24px", filter: "invert(1)" }}
    />
  </button>;

  // 렌더링 부분
  return (
    <div
      className={`calendar-page ${isTodoSidebarOpen ? "sidebar-open" : ""}`}
      style={{ fontFamily: font || DEFAULT_FONT }}
      data-view={view}
      onMouseUp={() => setIsDragging(false)}
    >
      {renderMonthTabs()}
      <div className="calendar-wrapper">
        <div className="calendar-header">
          <div className="view-selector">
            <button onClick={handleTodayClick} className="today-button">
              Today
            </button>
            <div className="view-tooltip">
              <button onClick={() => handleViewChange("month")}>
                <img src="/toolbaricons/inuse/monthly.svg" alt="Monthly" />
              </button>
              <span className="view-tooltiptext">Monthly</span>
            </div>
            <div className="view-tooltip">
              <button onClick={() => handleViewChange("week1")}>
                <img src="/toolbaricons/inuse/weekly.svg" alt="Weekly 1" />
              </button>
              <span className="view-tooltiptext">Weekly</span>
            </div>
            <div className="view-tooltip">
              <button onClick={() => handleViewChange("week2")}>
                <img src="/toolbaricons/inuse/week2.svg" alt="Weekly 2" />
              </button>
              <span className="view-tooltiptext">Timetracker</span>
            </div>
            <div className="view-tooltip">
              <button onClick={() => handleViewChange("day")}>
                <img src="/toolbaricons/inuse/daily.svg" alt="Daily" />
              </button>
              <span className="view-tooltiptext">Daily</span>
            </div>
            <div className="view-tooltip">
              <button onClick={() => handleViewChange("year")}>
                <img src="/toolbaricons/inuse/yearly.svg" alt="Yearly" />
              </button>
              <span className="view-tooltiptext">Yearly</span>
            </div>
            <div className="view-tooltip">
              <button onClick={() => handleViewChange("year12")}>
                <img
                  src="/toolbaricons/inuse/12months.svg"
                  alt="12 Months View"
                />
              </button>
              <span className="view-tooltiptext">12 Months</span>
            </div>
            <div className="view-tooltip">
              <button onClick={() => handleViewChange("habitTracker")}>
                <img src="/toolbaricons/inuse/habit.svg" alt="Habit Tracker" />
              </button>
              <span className="view-tooltiptext">Habit Tracker</span>
            </div>
          </div>

          {/* week-navigation을 header 내부로 이동 */}
          {(view === "week1" || view === "week2") && (
            <div className="week-navigation">
              <button onClick={handlePrevWeek}>&lt;</button>
              <span>Week {selectedWeek + 1}</span>
              <button onClick={handleNextWeek}>&gt;</button>
            </div>
          )}
        </div>
        <div className="calendar-content">
          <div className="calendar-container" style={{ position: "relative" }}>
            {view === "month" && (
              <div className={`calendar-grid rows-${rowCount}`}>
                <div className="weekday-cell">MON</div>
                <div className="weekday-cell">TUE</div>
                <div className="weekday-cell">WED</div>
                <div className="weekday-cell">THU</div>
                <div className="weekday-cell">FRI</div>
                <div className="weekday-cell">SAT</div>
                <div className="weekday-cell">SUN</div>
                {renderCalendarDays()}
              </div>
            )}
            {view === "week1" && (
              <div className="calendar-grid rows-1 week-view">
                <div className="weekday-cell">MON</div>
                <div className="weekday-cell">TUE</div>
                <div className="weekday-cell">WED</div>
                <div className="weekday-cell">THU</div>
                <div className="weekday-cell">FRI</div>
                <div className="weekday-cell">SAT</div>
                <div className="weekday-cell">SUN</div>
                {renderWeekCalendar1()}
              </div>
            )}
            {view === "week2" && (
              <div className="calendar-grid rows-1 week-view week2-view">
                {renderWeekCalendar2()}
              </div>
            )}
            {view === "day" && (
              <div className="day-calendar">
                <div className="day-header">
                  <input
                    type="date"
                    value={selectedDate.toISOString().split("T")[0]}
                    onChange={(e) => {
                      const newDate = new Date(e.target.value);
                      setSelectedDate(newDate);
                    }}
                  />
                </div>
                <div className="day-container">{renderDayCalendar()}</div>
              </div>
            )}
            {view === "year" && (
              <div className="year-calendar">
                <div className="year-grid">{renderYearCalendar()}</div>
              </div>
            )}
            {view === "year12" && render12MonthsView()}
            {view === "habitTracker" && (
              <div className="habit-tracker-container">
                <HabitTrackerComponent year={selectedYear} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mini-bar">
        <button onClick={() => applyStyle("bold")}>
          <img
            src="/toolbaricons/inuse/bold.svg"
            alt="Bold"
            className="mini-bar-icon"
          />
        </button>
        <button onClick={() => applyStyle("italic")}>
          <img
            src="/toolbaricons/inuse/italic.svg"
            alt="Italic"
            className="mini-bar-icon"
          />
        </button>
        <button onClick={() => applyStyle("underline")}>
          <img
            src="/toolbaricons/inuse/underline.svg"
            alt="Underline"
            className="mini-bar-icon"
          />
        </button>
        <button onClick={() => applyStyle("strike")}>
          <img
            src="/toolbaricons/inuse/cancleline.svg"
            alt="Strike"
            className="mini-bar-icon"
          />
        </button>
        <div className="font-color-container" ref={fontColorRef}>
          <button
            onClick={() => setShowFontColors(!showFontColors)}
            style={{
              backgroundColor: fontColor,
              width: "20px",
              height: "20px",
            }}
            title="Change Font Color"
          />
          {showFontColors && (
            <div className="font-colors">
              {[
                "#e1e1e1",
                "#F24722",
                "#FFA629",
                "#FFCD29",
                "#15AE5C",
                "#0D99FF",
                "#9747FF",
                "#757575",
              ].map((color) => (
                <button
                  key={color}
                  className="font-color-button"
                  style={{ backgroundColor: color }}
                  onClick={() => handleFontColorChange(color)}
                />
              ))}
            </div>
          )}
        </div>
        {view !== "week1" && view !== "week2" && (
          <div className="background-color-container" ref={backgroundColorRef}>
            <button
              onClick={() => setShowBackgroundColors(!showBackgroundColors)}
              style={{
                backgroundColor: selectedCell
                  ? cellBackgroundColors[selectedCell] || "transparent"
                  : selectedDayCell
                  ? dayCellBackgroundColors[selectedDayCell] || "transparent"
                  : "transparent",
                width: "24px",
                height: "24px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
              title="Change Background Color"
            />
            {showBackgroundColors && renderBackgroundColorOptions()}
          </div>
        )}
        <div className="sidebar-font-selector">
          <button
            onClick={() => setShowFontOptions(!showFontOptions)}
            title="Select Font"
            className="font-select-button"
          >
            <span className="font-name">{font || DEFAULT_FONT}</span>
            <span className="font-select-arrow">▼</span>
          </button>
          {showFontOptions && (
            <ul className="sidebar-font-options" ref={fontOptionsRef}>
              {" "}
              {fonts.map((fontOption) => (
                <li
                  key={fontOption.name}
                  onClick={() => {
                    handleFontChange(fontOption.name);
                    setShowFontOptions(false);
                  }}
                  className="font-option"
                >
                  <span style={{ fontFamily: fontOption.name }}>
                    {fontOption.name}
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {showMiniToolbar && (
        <div
          ref={miniToolbarRef}
          className="mini-toolbar"
          style={{
            position: "fixed",
            left: miniToolbarPosition.x,
            top: miniToolbarPosition.y,
          }}
        >
          {Object.entries(colorNames)
            .sort(([colorA], [colorB]) => {
              const colorOrder = [
                "rgba(157, 180, 192, 0.08)",
                "rgba(194, 176, 201, 0.08)",
                "rgba(134, 166, 157, 0.08)",
                "rgba(201, 169, 166, 0.08)",
                "rgba(164, 190, 123, 0.08)",
                "rgba(222, 182, 171, 0.08)",
                "rgba(181, 192, 208, 0.08)",
                "transparent",
              ];
              return colorOrder.indexOf(colorA) - colorOrder.indexOf(colorB);
            })
            .map(([color, name]) => (
              <div key={color} className="calendar-color-option">
                <button
                  style={{
                    backgroundColor: color === "transparent" ? "white" : color,
                    width: "30px",
                    height: "30px",
                    border: color === "transparent" ? "1px solid #ccc" : "none",
                  }}
                  onClick={() => handleColorSelect(color)}
                />
                <input
                  type="text"
                  value={name}
                  onChange={(e) => handleColorNameChange(color, e.target.value)}
                  className="color-name-input"
                />
              </div>
            ))}
        </div>
      )}

      <button
        className="todo-toggle-button"
        onClick={() => setIsTodoSidebarOpen(!isTodoSidebarOpen)}
        style={{
          position: "fixed",
          right: isTodoSidebarOpen ? `${sidebarWidth}px` : "0",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1001,
          padding: "8px",
          backgroundColor: "#1a1a1a",
          border: "none",
          borderRadius: "4px 0 0 4px",
          cursor: "pointer",
          transition: "right 0.3s ease",
        }}
      >
        <img
          src="/toolbaricons/inuse/checklist.svg"
          alt="Toggle Todo"
          style={{ width: "24px", height: "24px", filter: "invert(1)" }}
        />
      </button>

      <CalendarTodoSidebar
        isOpen={isTodoSidebarOpen}
        onClose={() => setIsTodoSidebarOpen(false)}
      />

      <style>
        {`
     /* 모든 캘린더 에디터에 대한 맞춤법 검사 비활성화 스타일 */
          .calendar-page .ql-editor,
          .calendar-page .ql-editor *,
          .calendar-page [contenteditable],
          .calendar-page [contenteditable] * {
            -webkit-spell-check: false !important;
            -moz-spell-check: false !important;
            spellcheck: false !important;
            -webkit-spelling-error: none !important;
            -moz-spelling-error: none !important;
            spelling-error: none !important;
            text-decoration-skip-ink: none !important;

           
            -ms-spelling-error: none !important;
            border-bottom: none !important;
            background-image: none !important;
           
            
          }

          /* MS Word 스타일 맞춤법 검사 비활성화 */
          .calendar-page .ql-editor::spelling-error,
          .calendar-page .ql-editor::grammar-error {
            text-decoration: none !important;
            border-bottom: none !important;
          }

          /* Grammarly 비활성화 */
          .calendar-page grammarly-extension {
            display: none !important;
          }

          /* 추가적인 맞춤법 검사 관련 스타일 무효화 */
          .calendar-page .ql-editor p,
          .calendar-page .ql-editor span {
            border-bottom: none !important;
            text-decoration: none !important;
          }

          /* MS Word 스타일 맞춤법 검사 비활성화 */
          .calendar-page .ql-editor [class*="Proofing_"] {
            display: none !important;
          }

          
        `}
      </style>
    </div>
  );
};

export default Calendar;
