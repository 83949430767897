import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../css/curriculum.css";
import pencilIcon from "../assets/icons/inuse/pencil.svg";
import deleteIcon from "../assets/icons/inuse/delete.svg";
import calendarIcon from "../assets/icons/inuse/calendar.svg";
import upArrowIcon from "../assets/icons/inuse/arrow-collapse-up.svg";
import downArrowIcon from "../assets/icons/inuse/arrow-collapse-down.svg";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import LoadingSpinner from "../components/LoadingSpinner";
import { useCurriculum } from "../contexts/CurriculumContext";
import { auth } from "../firebase/firebase";
import { db } from "../firebase/firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { useUnitCard } from "../contexts/UnitCardContext";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

// 날짜 유효성 검사 및 안전한 변환 함수
const safelyParseDate = (dateString) => {
  if (!dateString) return null;
  const parsedDate = new Date(dateString);
  return isNaN(parsedDate.getTime()) ? null : parsedDate;
};

// 복습 간격 상수 정의
const REVIEW_INTERVALS = [
  { day: 1, label: "D1" },
  { day: 3, label: "D3" },
  { day: 7, label: "D7" },
  { day: 14, label: "D14" },
  { day: 30, label: "D30" },
];

const Curriculum = ({ isSidebarMinimized, setIsSidebarMinimized }) => {
  const { courseId: urlCourseId, courseName } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    curriculum,
    setCurriculum,
    setCourseId,
    addSection,
    deleteSection,
    addChapter,
    handleSectionNameChange,
    handleChapterNameChange,
    handleUnitChange,
    handleReviewChange,
    isLoading,
    setIsLoading,
    addUnit,
    deleteUnit,
    reorderCurriculumIndexes,
    debouncedUpdateFirebase,
    handleReorderCurriculum,
    addSubunit,
    handleMoveUnit,
    loadCurriculum,
    deleteChapter,
    handleUnitCompletion,
    calculateProgress,
  } = useCurriculum();

  const [editingItem, setEditingItem] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [addButtonLabel, setAddButtonLabel] = useState("새 섹션 추가");
  const [deletingSectionIndex, setDeletingSectionIndex] = useState(null);
  const [deletingItemType, setDeletingItemType] = useState(null);
  const [expandedUnits, setExpandedUnits] = useState({});
  const { saveUnitCard, deleteUnitFromCalendar, updateUnitNameInCalendar } =
    useUnitCard();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isDeleting, setIsDeleting] = useState(false);
  // useEffect 수정 - 기본적으로 모든 챕터를 닫힌 상태로 설정
  useEffect(() => {
    if (curriculum.chapters) {
      setExpandedUnits((prev) => {
        const newExpandedState = { ...prev };
        // 모든 챕터를 닫힌 상태로 설정
        Object.keys(curriculum.chapters).forEach((sectionIndex) => {
          curriculum.chapters[sectionIndex].forEach((_, chapterIndex) => {
            // 이전 상태를 유지하되, 없는 경우에만 false로 설정
            if (!(`${sectionIndex}-${chapterIndex}` in prev)) {
              newExpandedState[`${sectionIndex}-${chapterIndex}`] = false;
            }
          });
        });
        return newExpandedState;
      });
    }
  }, [curriculum.chapters]);

  const progress = calculateProgress();

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setIsLoading(true);
        setCurriculum({
          sections: [],
          chapters: {},
          units: {},
          subunits: {},
        });

        const user = auth.currentUser;
        if (user && urlCourseId) {
          const curriculumRef = doc(
            db,
            "users",
            user.uid,
            "courses",
            urlCourseId,
            "curriculum",
            "main"
          );
          const curriculumDoc = await getDoc(curriculumRef);
          if (curriculumDoc.exists()) {
            const data = curriculumDoc.data();
            setCurriculum({
              ...data,
              toggleState: data.toggleState || {
                showSections: true,
                showChapters: true,
              },
            });
          }
        }
      } finally {
        setIsLoading(false);
      }
    };

    loadInitialData();
  }, [urlCourseId, setCurriculum]);

  const normalizeUnitName = (name) => {
    return name.toLowerCase().replace(/\s+/g, "");
  };

  const handleSectionNameBlur = (sectionIndex, e) => {
    const newName = e.target.value.trim();
    if (newName) {
      handleSectionNameChange(sectionIndex, newName);
    }
    setEditingItem(null);
  };

  const handleSectionNameKeyDown = (e, sectionIndex) => {
    if (e.key === "Enter" && !e.nativeEvent.isComposing) {
      e.preventDefault();
      const newName = e.target.value.trim();
      if (newName) {
        handleSectionNameChange(sectionIndex, newName);
      }
      setEditingItem(null);
    }
  };

  const generateUniqueName = (baseName, existingNames) => {
    let newName = baseName;
    let counter = 1;
    while (existingNames.includes(newName)) {
      newName = `${baseName} ${counter}`;
      counter++;
    }
    return newName;
  };

  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const handleAddSectionClick = async () => {
    const newSection = {
      id: uuidv4(),
      name: `새 섹션 ${sections.length + 1}`,
    };

    await addSection(newSection);

    // 새로운 섹션이 추가된 후 모든 챕터를 열린 상태로 설정
    setExpandedUnits((prev) => {
      const newExpandedState = { ...prev };
      Object.keys(curriculum.chapters).forEach((sectionIndex) => {
        curriculum.chapters[sectionIndex].forEach((_, chapterIndex) => {
          newExpandedState[`${sectionIndex}-${chapterIndex}`] = true;
        });
      });
      return newExpandedState;
    });
  };

  const handleChapterNameKeyDown = (e, sectionIndex, chapterIndex) => {
    if (e.key === "Enter") {
      handleSaveItem();
    }
  };

  const handleAddUnit = (sectionIndex, chapterIndex) => {
    addUnit(sectionIndex, chapterIndex);
  };

  const handleUnitNameKeyDown = (e, sectionIndex, chapterIndex, unitIndex) => {
    if (e.nativeEvent.isComposing) {
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();
      handleSaveItem();
      setEditingItem(null);
    }
  };

  const handleDeleteUnit = (sectionIndex, chapterIndex, unitIndex) => {
    if (isDeleting) return;
    setDeleteConfirmation({ sectionIndex, chapterIndex, unitIndex });
  };

  const confirmDeleteUnit = async () => {
    if (!deleteConfirmation || isDeleting) return;

    try {
      setIsDeleting(true);
      setIsLoading(true);

      const { sectionIndex, chapterIndex, unitIndex } = deleteConfirmation;
      const unitKey = `${sectionIndex}-${chapterIndex}`;
      const units = curriculum.units[unitKey];

      if (!units || !units[unitIndex]) {
        console.error("삭제할 유닛을 찾을 수 없습니다.");
        return;
      }

      const unitToDelete = units[unitIndex];

      if (!unitToDelete || !unitToDelete.unitId) {
        console.error("유효하지 않은 유닛 데이터입니다.");
        return;
      }

      await deleteUnit(sectionIndex, chapterIndex, unitIndex);
    } catch (error) {
      console.error("유닛 삭제 중 오류 발생:", error);
    } finally {
      setIsDeleting(false);
      setIsLoading(false);
      setDeleteConfirmation(null);
    }
  };

  const cancelDeleteUnit = () => {
    setDeleteConfirmation(null);
    setIsDeleting(false);
  };

  const handleEditItem = (
    type,
    sectionIndex,
    chapterIndex = null,
    unitIndex = null
  ) => {
    setEditingItem({
      type,
      sectionIndex: type === "section" ? sectionIndex : sectionIndex,
      chapterIndex,
      unitIndex,
    });
  };

  const handleSaveItem = debounce(() => {
    setEditingItem(null);
  }, 1000);

  const handleUnitClick = async (sectionIndex, chapterIndex, unitIndex) => {
    const units = curriculum.units[`${sectionIndex}-${chapterIndex}`];
    if (!units || !units[unitIndex]) return;

    const unit = units[unitIndex];
    const unitId = unit.unitId;

    if (
      !curriculum.subunits[unitId] ||
      curriculum.subunits[unitId].length === 0
    ) {
      const result = await addSubunit(unitId);
      if (result && result.newSubunit) {
        navigate(
          `/courses/${urlCourseId}/curriculum/${courseName}/unit/${unitId}/subunit/${result.newSubunit.id}`,
          {
            state: {
              subunitId: result.newSubunit.id,
              unitId: unitId,
              currentSubunit: result.newSubunit,
            },
          }
        );
        return;
      }
    }

    navigate(
      `/courses/${urlCourseId}/curriculum/${courseName}/unit/${unitId}/subunit/${curriculum.subunits[unitId]?.[0]?.id}`,
      {
        state: {
          curriculum,
        },
      }
    );
  };

  const handleEditUnit = (sectionIndex, chapterIndex, unitIndex) => {
    setEditingItem({ type: "unit", sectionIndex, chapterIndex, unitIndex });
  };

  const moveUnitToAnotherChapter = (
    prevCurriculum,
    sectionIndex,
    chapterIndex,
    unitIndex,
    direction
  ) => {
    const updatedUnits = { ...prevCurriculum.units };
    const currentKey = `${sectionIndex}-${chapterIndex}`;
    const unit = updatedUnits[currentKey][unitIndex];

    updatedUnits[currentKey].splice(unitIndex, 1);

    let newChapterIndex =
      direction === "up" ? chapterIndex - 1 : chapterIndex + 1;
    let newSectionIndex = sectionIndex;

    if (newChapterIndex < 0) {
      newSectionIndex--;
      if (newSectionIndex >= 0) {
        newChapterIndex = prevCurriculum.chapters[newSectionIndex].length - 1;
      }
    } else if (
      newChapterIndex >= prevCurriculum.chapters[sectionIndex].length
    ) {
      newSectionIndex++;
      if (newSectionIndex < prevCurriculum.sections.length) {
        newChapterIndex = 0;
      }
    }

    if (
      newSectionIndex >= 0 &&
      newSectionIndex < prevCurriculum.sections.length
    ) {
      const newKey = `${newSectionIndex}-${newChapterIndex}`;
      if (!updatedUnits[newKey]) {
        updatedUnits[newKey] = [];
      }

      const insertIndex = direction === "up" ? updatedUnits[newKey].length : 0;
      updatedUnits[newKey].splice(insertIndex, 0, unit);

      return { ...prevCurriculum, units: updatedUnits };
    }

    return prevCurriculum;
  };

  const handleUnitCheckboxChange = async (
    sectionIndex,
    chapterIndex,
    unitIndex,
    isChecked
  ) => {
    // 유닛 완료 상태 업데이트
    handleUnitCompletion(sectionIndex, chapterIndex, unitIndex, isChecked);

    // 기존 유닛 데이터 가져오기
    const unitKey = `${sectionIndex}-${chapterIndex}`;
    const unit = curriculum.units[unitKey][unitIndex];

    if (isChecked && unit.unitId) {
      // 유닛이 완료되면 캘린더에서 해당 유닛카드 삭제
      try {
        await deleteUnitFromCalendar(unit.unitId);
      } catch (error) {
        console.error("캘린더에서 유닛카드 삭제 실패:", error);
      }
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    setCurriculum((prev) => {
      // 깊은 복사로 시작
      const newCurriculum = JSON.parse(JSON.stringify(prev));

      // 1. 섹션 이동
      const [movedSection] = newCurriculum.sections.splice(sourceIndex, 1);
      newCurriculum.sections.splice(destinationIndex, 0, movedSection);

      // 2. Context의 handleReorderCurriculum 호출하여 전체 데이터 재정렬
      return handleReorderCurriculum(newCurriculum);
    });
  };

  const handleDeleteSection = (sectionIndex) => {
    setDeletingSectionIndex(sectionIndex);
    setDeletingItemType("section");
  };

  const handleDeleteChapter = (sectionIndex) => {
    setDeletingSectionIndex(sectionIndex);
    setDeletingItemType("chapter");
  };

  const confirmDeleteSection = () => {
    if (deletingItemType === "section") {
      deleteSection(deletingSectionIndex);
    } else if (deletingItemType === "chapter") {
      deleteChapter(deletingSectionIndex.section, deletingSectionIndex.chapter);
    }
    setDeletingSectionIndex(null);
    setDeletingItemType(null);
  };

  const cancelDeleteSection = () => {
    setDeletingSectionIndex(null);
    setDeletingItemType(null);
  };

  const deleteCourse = async (courseId) => {
    // Firebase 관련 코드 제거
  };

  const handleAddChapter = (sectionIndex) => {
    // 챕터 추가
    addChapter(sectionIndex);

    // 모든 챕터를 열린 상태로 유지
    setExpandedUnits((prev) => {
      const newExpandedState = { ...prev };
      if (curriculum.chapters[sectionIndex]) {
        curriculum.chapters[sectionIndex].forEach((_, chapterIndex) => {
          newExpandedState[`${sectionIndex}-${chapterIndex}`] = true;
        });
      }
      return newExpandedState;
    });
  };

  const handleUnitNameComplete = async (
    sectionIndex,
    chapterIndex,
    unitIndex,
    value
  ) => {
    // 즉시 UI 업데이트
    handleUnitChange(sectionIndex, chapterIndex, unitIndex, "unitName", value);
    setEditingItem(null);

    // 현재 유닛의 정보 가져오기
    const unitKey = `${sectionIndex}-${chapterIndex}`;
    const unit = curriculum.units[unitKey][unitIndex];

    // 유닛에 계획된 날짜가 있는 경우에만 캘린더 업데이트
    if (unit.planDate) {
      try {
        await saveUnitCard(new Date(unit.planDate), {
          unitId: unit.unitId,
          unitName: value,
          courseId: urlCourseId,
        });
      } catch (error) {
        console.error("캘린더 업데이트 실패:", error);
      }
    }
  };

  const toggleUnits = (sectionIndex, chapterIndex) => {
    setExpandedUnits((prev) => ({
      ...prev,
      [`${sectionIndex}-${chapterIndex}`]:
        !prev[`${sectionIndex}-${chapterIndex}`],
    }));
  };

  const sections = curriculum?.sections || [];
  const chapters = curriculum?.chapters || {};
  const units = curriculum?.units || {};

  useEffect(() => {
    if (urlCourseId) {
      setCourseId(urlCourseId);
    }
  }, [urlCourseId, setCourseId]);

  const handlePlanDateChange = async (
    date,
    sectionIndex,
    chapterIndex,
    unitIndex
  ) => {
    const units = curriculum.units[`${sectionIndex}-${chapterIndex}`];
    const unit = units[unitIndex];
    const previousDate = unit.planDate;

    // 먼저 Curriculum의 상태 업데이트
    handleUnitChange(sectionIndex, chapterIndex, unitIndex, "planDate", date);

    // date가 null이면 (삭제 버튼 클릭 시) 캘린더에서도 유닛 카드 삭제
    if (!date) {
      try {
        await deleteUnitFromCalendar(unit.unitId);
      } catch (error) {
        console.error("캘린더에서 유닛 카드 삭제 실패:", error);
      }
      return;
    }

    // 새로운 날짜가 있는 경우 캘린더에 유닛 카드 저장
    const result = await saveUnitCard(
      date,
      {
        unitId: unit.unitId,
        unitName: unit.unitName,
        courseId: urlCourseId,
      },
      previousDate
    );

    if (!result) {
      console.error("유닛 계획 저장 실패");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setIsSidebarMinimized(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setIsSidebarMinimized]);

  useEffect(() => {
    const fetchData = async () => {
      if (!auth.currentUser || !urlCourseId) return;

      const courseDocRef = doc(
        db,
        `users/${auth.currentUser.uid}/courses/${urlCourseId}`
      );
      const courseDoc = await getDoc(courseDocRef);

      if (courseDoc.exists()) {
        const data = courseDoc.data();
      }
    };

    fetchData();
  }, [urlCourseId]);

  // 날짜 차이를 계산하는 함수 추가
  const calculateDaysDifference = (reviewDate) => {
    if (!reviewDate) return null;
    const today = new Date();
    const review = new Date(reviewDate);
    const diffTime = Math.abs(today - review);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  // 기존 테이블의 날짜 관련 컬럼들을 제거하고 복습 체크박스 컬럼으로 대체
  const renderReviewCheckboxes = (
    sectionIndex,
    chapterIndex,
    unitIndex,
    unit
  ) => {
    return REVIEW_INTERVALS.map((interval, index) => {
      return (
        <div
          key={index}
          className="review-checkbox-container"
          title={
            unit.reviews?.[index]?.completed
              ? `${interval.label} Review Complete: ${new Date(
                  unit.reviews[index].completedAt
                ).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}`
              : `${interval.label} Review`
          }
        >
          <input
            type="checkbox"
            checked={unit.reviews?.[index]?.completed || false}
            onChange={() =>
              handleReviewCheck(sectionIndex, chapterIndex, unitIndex, index)
            }
            className="review-checkbox"
          />
          <span className="review-label">{interval.label}</span>
        </div>
      );
    });
  };

  const handleReviewCheck = async (
    sectionIndex,
    chapterIndex,
    unitIndex,
    reviewIndex
  ) => {
    const unitKey = `${sectionIndex}-${chapterIndex}`;
    const unit = curriculum.units[unitKey][unitIndex];

    // 현재 유닛의 reviews 배열 가져오기 (없으면 빈 배열 생성)
    const reviews = unit.reviews || [];

    // 현재 리뷰 상태 업데이트
    const updatedReviews = [...reviews];
    updatedReviews[reviewIndex] = {
      completed: !reviews[reviewIndex]?.completed,
      completedAt: new Date().toISOString(),
      interval: REVIEW_INTERVALS[reviewIndex].day,
    };

    try {
      // 로컬 상태 업데이트
      handleUnitChange(
        sectionIndex,
        chapterIndex,
        unitIndex,
        "reviews",
        updatedReviews
      );

      // Firebase에 저장
      const user = auth.currentUser;
      if (user && urlCourseId) {
        const curriculumRef = doc(
          db,
          "users",
          user.uid,
          "courses",
          urlCourseId,
          "curriculum",
          "main"
        );

        // 현재 커리큘럼 데이터를 복사하고 업데이트
        const updatedCurriculum = { ...curriculum };
        updatedCurriculum.units[unitKey][unitIndex].reviews = updatedReviews;

        await setDoc(curriculumRef, updatedCurriculum, { merge: true });
      }
    } catch (error) {
      console.error("체크박스 상태 저장 중 오류 발생:", error);
      // 에러 발생 시 로컬 상태를 원래대로 되돌리기
      handleUnitChange(
        sectionIndex,
        chapterIndex,
        unitIndex,
        "reviews",
        reviews
      );
    }
  };

  return (
    <div className="curriculum-container">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="curriculum-header">
            <h1>{courseName}</h1>
          </div>
          {sections.length === 0 ? (
            <div className="empty-curriculum">
              <h2>Curriculum is empty</h2>
              <button
                onClick={handleAddSectionClick}
                className="empty-curriculum-button"
              >
                Add New Section
              </button>
            </div>
          ) : (
            <>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="sections">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {sections.map((section, sectionIndex) => (
                        <Draggable
                          key={section.id || `section-${sectionIndex}`}
                          draggableId={section.id || `section-${sectionIndex}`}
                          index={sectionIndex}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <table className="section-table">
                                <thead>
                                  <tr>
                                    <th className="section-title" colSpan="5">
                                      <div className="section-title-container">
                                        {editingItem?.type === "section" &&
                                        editingItem?.sectionIndex ===
                                          sectionIndex ? (
                                          <input
                                            type="text"
                                            defaultValue={section}
                                            onBlur={(e) =>
                                              handleSectionNameBlur(
                                                sectionIndex,
                                                e
                                              )
                                            }
                                            onKeyDown={(e) =>
                                              handleSectionNameKeyDown(
                                                e,
                                                sectionIndex
                                              )
                                            }
                                            autoFocus
                                            className="section-name-input"
                                          />
                                        ) : (
                                          <div
                                            className="editable-text section-title"
                                            onClick={() =>
                                              handleEditItem(
                                                "section",
                                                sectionIndex
                                              )
                                            }
                                          >
                                            {section}
                                          </div>
                                        )}
                                        <div className="icon-group">
                                          <img
                                            src="/toolbaricons/inuse/delete.svg"
                                            alt="Delete section"
                                            className="action-icon delete-icon"
                                            onClick={() =>
                                              handleDeleteSection(sectionIndex)
                                            }
                                          />
                                          <img
                                            src="/toolbaricons/inuse/menu.svg"
                                            alt="More options"
                                            className="action-icon more-icon"
                                            {...provided.dragHandleProps}
                                          />
                                        </div>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {chapters[sectionIndex] &&
                                    chapters[sectionIndex].map(
                                      (chapter, chapterIndex) => (
                                        <tr key={chapterIndex}>
                                          <td colSpan="5">
                                            <table className="chapter-table">
                                              <tbody>
                                                <tr>
                                                  <td className="chapter-name">
                                                    {editingItem &&
                                                    editingItem.type ===
                                                      "chapter" &&
                                                    editingItem.sectionIndex ===
                                                      sectionIndex &&
                                                    editingItem.chapterIndex ===
                                                      chapterIndex ? (
                                                      <input
                                                        type="text"
                                                        value={chapter}
                                                        onChange={(e) =>
                                                          handleChapterNameChange(
                                                            sectionIndex,
                                                            chapterIndex,
                                                            e.target.value
                                                          )
                                                        }
                                                        onBlur={handleSaveItem}
                                                        onKeyDown={(e) =>
                                                          handleChapterNameKeyDown(
                                                            e,
                                                            sectionIndex,
                                                            chapterIndex
                                                          )
                                                        }
                                                        autoFocus
                                                        onClick={(e) =>
                                                          e.stopPropagation()
                                                        }
                                                      />
                                                    ) : (
                                                      <div
                                                        className="chapter-name-container"
                                                        onClick={() =>
                                                          toggleUnits(
                                                            sectionIndex,
                                                            chapterIndex
                                                          )
                                                        }
                                                      >
                                                        <span
                                                          className={`curriculum-toggle-icon ${
                                                            expandedUnits[
                                                              `${sectionIndex}-${chapterIndex}`
                                                            ]
                                                              ? "open"
                                                              : ""
                                                          }`}
                                                        >
                                                          {expandedUnits[
                                                            `${sectionIndex}-${chapterIndex}`
                                                          ]
                                                            ? "▼"
                                                            : "▶"}
                                                        </span>
                                                        <span className="editable-text chapter-name no-cursor">
                                                          {chapter ||
                                                            "Click to enter chapter name"}
                                                        </span>
                                                        <div
                                                          className="icon-group"
                                                          onClick={(e) =>
                                                            e.stopPropagation()
                                                          }
                                                        >
                                                          <img
                                                            src="/toolbaricons/inuse/pencil.svg"
                                                            alt="Edit chapter"
                                                            className="action-icon edit-icon"
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                              handleEditItem(
                                                                "chapter",
                                                                sectionIndex,
                                                                chapterIndex
                                                              );
                                                            }}
                                                          />
                                                          <img
                                                            src="/toolbaricons/inuse/delete.svg"
                                                            alt="Delete chapter"
                                                            className="action-icon delete-icon"
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                              setDeletingItemType(
                                                                "chapter"
                                                              );
                                                              setDeletingSectionIndex(
                                                                {
                                                                  section:
                                                                    sectionIndex,
                                                                  chapter:
                                                                    chapterIndex,
                                                                }
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    )}
                                                  </td>
                                                </tr>
                                                {expandedUnits[
                                                  `${sectionIndex}-${chapterIndex}`
                                                ] === true && (
                                                  <tr>
                                                    <td>
                                                      {/* 유닛 테이블 렌더링 */}
                                                      <table className="unit-table">
                                                        <thead>
                                                          <tr>
                                                            <th className="unit-name-column">
                                                              Unit Name
                                                            </th>
                                                            <th className="date-column">
                                                              Due Date
                                                            </th>
                                                            <th className="reviews-column">
                                                              Review Count
                                                            </th>
                                                            <th className="actions-column">
                                                              Actions
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {units[
                                                            `${sectionIndex}-${chapterIndex}`
                                                          ] &&
                                                            units[
                                                              `${sectionIndex}-${chapterIndex}`
                                                            ].map(
                                                              (
                                                                unit,
                                                                unitIndex
                                                              ) => (
                                                                <tr
                                                                  key={
                                                                    unitIndex
                                                                  }
                                                                >
                                                                  <td className="unit-name-column">
                                                                    <div className="unit-name-wrapper">
                                                                      <input
                                                                        type="checkbox"
                                                                        className="unit-checkbox"
                                                                        checked={
                                                                          unit.isCompleted ||
                                                                          false
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleUnitCheckboxChange(
                                                                            sectionIndex,
                                                                            chapterIndex,
                                                                            unitIndex,
                                                                            e
                                                                              .target
                                                                              .checked
                                                                          )
                                                                        }
                                                                      />
                                                                      {editingItem &&
                                                                      editingItem.type ===
                                                                        "unit" &&
                                                                      editingItem.sectionIndex ===
                                                                        sectionIndex &&
                                                                      editingItem.chapterIndex ===
                                                                        chapterIndex &&
                                                                      editingItem.unitIndex ===
                                                                        unitIndex ? (
                                                                        <input
                                                                          type="text"
                                                                          value={
                                                                            unit.unitName
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) =>
                                                                            handleUnitChange(
                                                                              sectionIndex,
                                                                              chapterIndex,
                                                                              unitIndex,
                                                                              "unitName",
                                                                              e
                                                                                .target
                                                                                .value
                                                                            )
                                                                          }
                                                                          onBlur={(
                                                                            e
                                                                          ) =>
                                                                            handleUnitNameComplete(
                                                                              sectionIndex,
                                                                              chapterIndex,
                                                                              unitIndex,
                                                                              e
                                                                                .target
                                                                                .value
                                                                            )
                                                                          }
                                                                          onKeyDown={(
                                                                            e
                                                                          ) => {
                                                                            if (
                                                                              e.key ===
                                                                                "Enter" &&
                                                                              !e
                                                                                .nativeEvent
                                                                                .isComposing
                                                                            ) {
                                                                              e.preventDefault();
                                                                              handleUnitNameComplete(
                                                                                sectionIndex,
                                                                                chapterIndex,
                                                                                unitIndex,
                                                                                e
                                                                                  .target
                                                                                  .value
                                                                              );
                                                                            }
                                                                          }}
                                                                          autoFocus
                                                                          className="unit-name-input"
                                                                        />
                                                                      ) : (
                                                                        <>
                                                                          <span
                                                                            className="curriculumunitname"
                                                                            onClick={() =>
                                                                              handleUnitClick(
                                                                                sectionIndex,
                                                                                chapterIndex,
                                                                                unitIndex
                                                                              )
                                                                            }
                                                                          >
                                                                            {
                                                                              unit.unitName
                                                                            }
                                                                          </span>
                                                                          <div className="icon-group">
                                                                            <img
                                                                              src={
                                                                                pencilIcon
                                                                              }
                                                                              alt="Edit"
                                                                              className="edit-unit-icon"
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                e.stopPropagation();
                                                                                handleEditUnit(
                                                                                  sectionIndex,
                                                                                  chapterIndex,
                                                                                  unitIndex
                                                                                );
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  </td>
                                                                  <td className="date-column">
                                                                    <div className="date-picker-container">
                                                                      <DatePicker
                                                                        selected={safelyParseDate(
                                                                          unit.planDate
                                                                        )}
                                                                        onChange={(
                                                                          date
                                                                        ) =>
                                                                          handlePlanDateChange(
                                                                            date,
                                                                            sectionIndex,
                                                                            chapterIndex,
                                                                            unitIndex
                                                                          )
                                                                        }
                                                                        dateFormat="yyyy-MM-dd"
                                                                        customInput={
                                                                          <img
                                                                            src={
                                                                              calendarIcon
                                                                            }
                                                                            alt="Select Plan Date"
                                                                            className="calendar-icon"
                                                                          />
                                                                        }
                                                                      />
                                                                      {unit.planDate && (
                                                                        <>
                                                                          <span className="selected-date">
                                                                            {new Date(
                                                                              unit.planDate
                                                                            ).toLocaleDateString()}
                                                                          </span>
                                                                          <img
                                                                            src={
                                                                              deleteIcon
                                                                            }
                                                                            alt="Clear Date"
                                                                            className="clear-date-icon"
                                                                            onClick={() =>
                                                                              handlePlanDateChange(
                                                                                null,
                                                                                sectionIndex,
                                                                                chapterIndex,
                                                                                unitIndex
                                                                              )
                                                                            }
                                                                          />
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  </td>
                                                                  <td className="reviews-column">
                                                                    <div className="review-checkboxes">
                                                                      {renderReviewCheckboxes(
                                                                        sectionIndex,
                                                                        chapterIndex,
                                                                        unitIndex,
                                                                        unit
                                                                      )}
                                                                    </div>
                                                                  </td>
                                                                  <td className="actions-column">
                                                                    <img
                                                                      src={
                                                                        deleteIcon
                                                                      }
                                                                      alt="Delete"
                                                                      className="action-icon delete-unit-icon"
                                                                      onClick={() =>
                                                                        handleDeleteUnit(
                                                                          sectionIndex,
                                                                          chapterIndex,
                                                                          unitIndex
                                                                        )
                                                                      }
                                                                    />
                                                                    <img
                                                                      src={
                                                                        upArrowIcon
                                                                      }
                                                                      alt="Move Up"
                                                                      className="action-icon move-unit-icon"
                                                                      onClick={() =>
                                                                        handleMoveUnit(
                                                                          sectionIndex,
                                                                          chapterIndex,
                                                                          unitIndex,
                                                                          "up"
                                                                        )
                                                                      }
                                                                      style={{
                                                                        opacity:
                                                                          unitIndex ===
                                                                            0 &&
                                                                          chapterIndex ===
                                                                            0 &&
                                                                          sectionIndex ===
                                                                            0
                                                                            ? 0.5
                                                                            : 1,
                                                                      }}
                                                                    />
                                                                    <img
                                                                      src={
                                                                        downArrowIcon
                                                                      }
                                                                      alt="Move Down"
                                                                      className="action-icon move-unit-icon"
                                                                      onClick={() =>
                                                                        handleMoveUnit(
                                                                          sectionIndex,
                                                                          chapterIndex,
                                                                          unitIndex,
                                                                          "down"
                                                                        )
                                                                      }
                                                                      style={{
                                                                        opacity:
                                                                          unitIndex ===
                                                                            curriculum
                                                                              .units[
                                                                              `${sectionIndex}-${chapterIndex}`
                                                                            ]
                                                                              .length -
                                                                              1 &&
                                                                          chapterIndex ===
                                                                            curriculum
                                                                              .chapters[
                                                                              sectionIndex
                                                                            ]
                                                                              .length -
                                                                              1 &&
                                                                          sectionIndex ===
                                                                            curriculum
                                                                              .sections
                                                                              .length -
                                                                              1
                                                                            ? 0.5
                                                                            : 1,
                                                                      }}
                                                                    />
                                                                  </td>
                                                                </tr>
                                                              )
                                                            )}
                                                          <tr>
                                                            <td colSpan="5">
                                                              <button
                                                                className="add-unit-button"
                                                                onClick={() =>
                                                                  handleAddUnit(
                                                                    sectionIndex,
                                                                    chapterIndex
                                                                  )
                                                                }
                                                              >
                                                                <img
                                                                  src="/toolbaricons/inuse/add.svg"
                                                                  alt="Add Unit"
                                                                  width="24"
                                                                  height="24"
                                                                  style={{
                                                                    marginRight:
                                                                      "8px",
                                                                  }}
                                                                />
                                                                New Unit
                                                              </button>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                )}
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  <tr>
                                    <td colSpan="5">
                                      <button
                                        onClick={() =>
                                          handleAddChapter(sectionIndex)
                                        }
                                        className="add-chapter-button"
                                      >
                                        <img
                                          src="/toolbaricons/inuse/add.svg"
                                          alt="Add Unit"
                                          width="24"
                                          height="24"
                                          style={{
                                            marginRight: "8px",
                                          }}
                                        />
                                        New Chapter
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="add-buttons-group">
                <button
                  onClick={handleAddSectionClick}
                  className="add-section-button"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path
                      d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"
                      fill="currentColor"
                    />
                  </svg>
                  New Section
                </button>
              </div>
              {deletingSectionIndex !== null && (
                <div className="delete-confirmation-modal">
                  <div className="delete-confirmation-content">
                    <p>
                      {deletingItemType === "section"
                        ? "This section and all its chapters, units and subunits will be deleted.\nDo you want to continue?"
                        : "This chapter and all its units and subunits will be deleted.\nDo you want to continue?"}
                    </p>
                    <div className="delete-confirmation-buttons">
                      <button
                        className="modal-button confirm-button"
                        onClick={confirmDeleteSection}
                      >
                        Delete
                      </button>
                      <button
                        className="modal-button cancel-button"
                        onClick={cancelDeleteSection}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {deleteConfirmation && (
                <div className="delete-confirmation-modal">
                  <div className="delete-confirmation-content">
                    <p>
                      This unit and all sub-units will be deleted.
                      <br />
                      Do you want to continue?
                    </p>
                    <div className="delete-confirmation-buttons">
                      <button
                        className="modal-button confirm-button"
                        onClick={confirmDeleteUnit}
                        disabled={isDeleting} // Disable button while deleting
                      >
                        {isDeleting ? "Deleting..." : "Delete"}
                      </button>
                      <button
                        className="modal-button cancel-button"
                        onClick={cancelDeleteUnit}
                        disabled={isDeleting} // Disable button while deleting
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Curriculum;
