import React, { useState } from "react";
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import { auth } from "../firebase/firebase";
import "../css/LoginPage.css";
import jumpingImage from "../assets/img/Yuppies - Chat.svg";
import logo from "../assets/img/landinglogo.png";
import { checkSubscriptionStatus } from "../utils/subscription";
import { db } from "../firebase/firebase";
import googleIcon from "../assets/img/google.svg";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [showResendButton, setShowResendButton] = useState(false);
  const handleLogin = async (e, email, password) => {
    if (e) e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      if (!user.emailVerified) {
        setError(`Email (${email}) is not verified.`);
        setShowResendButton(true);
        setLoading(false);
        return;
      }

      const currentUser = auth.currentUser;
      await currentUser.reload();

      if (currentUser.emailVerified) {
        sessionStorage.setItem("userSession", Date.now().toString());
        sessionStorage.setItem("lastActivityTime", Date.now().toString());
        navigate("/dashboard");
      } else {
        navigate("/email-verified");
      }
    } catch (error) {
      // Firebase error code to English error message mapping
      switch (error.code) {
        case "auth/invalid-credential":
          setError("Invalid email or password.");
          break;
        case "auth/user-not-found":
          setError("Email not registered. Please sign up.");
          break;
        case "auth/wrong-password":
          setError("Incorrect password.");
          break;
        case "auth/invalid-email":
          setError("Invalid email format.");
          break;
        case "auth/too-many-requests":
          setError("Too many login attempts. Please try again later.");
          break;
        case "auth/network-request-failed":
          setError("Please check your network connection.");
          break;
        default:
          setError(`An error occurred during login. (${error.code})`);
      }
    }
    setLoading(false);
  };

  const handleResendVerification = async () => {
    try {
      setLoading(true);
      const currentUser = auth.currentUser;

      if (!currentUser) {
        return;
      }

      await sendEmailVerification(currentUser);
      await currentUser.reload();

      setError("Verification email has been resent. Please check your email.");
      setShowResendButton(false);
    } catch (error) {
      setError("Failed to resend email. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);

      sessionStorage.setItem("userSession", Date.now().toString());
      sessionStorage.setItem("lastActivityTime", Date.now().toString());

      navigate("/dashboard");
    } catch (error) {
      console.error("Google sign in error:", error);
      setError("Google sign in failed. Please try again.");
    }
  };

  return (
    <div
      className="login-container"
      style={{
        minHeight: "100vh",
        position: "relative",
        backgroundColor: "#feefd8",
      }}
    >
      <Link
        to="/"
        style={{
          position: "absolute",
          top: "20px",
          left: "20px",
          color: "#414042",
          fontSize: "24px",
          fontFamily: "Dela Gothic One",
          lineHeight: "31px",
          cursor: "pointer",
          textDecoration: "none",
        }}
      >
        RedoNote
      </Link>

      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
      <div className="login-image">
        <img src={jumpingImage} alt="Jumping Character" />
      </div>
      <div className="login-form-container">
        <div className="login-box">
          <Link
            to="/"
            style={{ position: "absolute", top: "20px", left: "20px" }}
          ></Link>
          <h1 className="login-title">Login</h1>
          <form
            onSubmit={(e) => handleLogin(e, email, password)}
            className="login-form"
          >
            <div className="input-group">
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="input-group">
              <div
                className="password-input-wrapper"
                style={{ position: "relative" }}
              >
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                    padding: "5px",
                  }}
                >
                  <img
                    src={
                      showPassword
                        ? `${process.env.PUBLIC_URL}/toolbaricons/inuse/eye-off.svg`
                        : `${process.env.PUBLIC_URL}/toolbaricons/inuse/eye.svg`
                    }
                    alt={showPassword ? "Hide password" : "Show password"}
                    style={{ width: "20px", height: "20px" }}
                  />
                </button>
              </div>
            </div>
            {error && (
              <div className="error-container">
                <p className="error-message">{error}</p>
                {showResendButton && (
                  <button
                    type="button"
                    onClick={handleResendVerification}
                    className="resend-button"
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#231c16",
                      border: "1px solid #231c16",
                      borderRadius: "24px",
                      padding: "8px 16px",
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: loading ? "not-allowed" : "pointer",
                      marginTop: "10px",
                    }}
                    disabled={loading}
                  >
                    Resend Verification Email
                  </button>
                )}
              </div>
            )}
            <button
              type="submit"
              className="login-button"
              disabled={loading}
              style={{
                backgroundColor: "#faaf3d",
                color: "#231c16",
                border: "1px solid #231c16",

                padding: "10px",
                fontSize: "16px",
                fontWeight: "500",
                cursor: loading ? "not-allowed" : "pointer",
                width: "100%",
                transition: "background-color 0.2s ease",
                opacity: loading ? 0.7 : 1,
              }}
            >
              {loading ? "Logging in..." : "Login"}
            </button>
          </form>
          <div className="login-options">
            <div className="social-login">
              <button
                onClick={signInWithGoogle}
                className="google-login-button"
              >
                <img
                  src={googleIcon}
                  alt="Google"
                  style={{ width: "20px", height: "20px" }}
                />
                Continue with Google
              </button>
            </div>
            <div className="divider">
              <span>or</span>
            </div>
            <Link to="/forgot-password" className="forgot-password">
              Forgot Password?
            </Link>
            <p className="signup-prompt">
              Don't have an account? <Link to="/signup">Sign Up</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
