import React, { createContext, useContext, useState, useEffect } from "react";
import { db, auth } from "../firebase/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { useCurriculum } from "./CurriculumContext";

const ProgressContext = createContext();

export const ProgressProvider = ({ children }) => {
  const [progress, setProgress] = useState(0);
  const { curriculum, courseId } = useCurriculum();

  useEffect(() => {
    if (!curriculum?.units) return;

    const allUnits = Object.values(curriculum.units).flat();
    if (allUnits.length === 0) {
      setProgress(0);
      return;
    }

    const completedUnits = allUnits.filter(
      (unit) => unit.isCompleted === true
    ).length;
    const newProgress = Math.round((completedUnits / allUnits.length) * 100);

    setProgress(newProgress);

    // Firestore 진행률 업데이트
    if (auth.currentUser && courseId) {
      const courseRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "courses",
        courseId
      );
      updateDoc(courseRef, { progress: newProgress }).catch(console.error);
    }
  }, [curriculum.units, courseId]);

  return (
    <ProgressContext.Provider value={{ progress }}>
      {children}
    </ProgressContext.Provider>
  );
};

export const useProgress = () => {
  const context = useContext(ProgressContext);
  if (!context) {
    throw new Error("useProgress must be used within a ProgressProvider");
  }
  return context;
};
