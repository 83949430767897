import React, { useState, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import Dashboard from "./pages/Dashboard";
import Courses from "./pages/Courses";
import CourseDetail from "./pages/CourseDetail";
import Curriculum from "./pages/Curriculum";
import Flashcards from "./pages/Flashcards";
import Calendar from "./pages/Calendar";
import ToDoList from "./pages/ToDoList";
import Settings from "./pages/Settings";
import Unitnote from "./pages/Unitnote";
import "./styles.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FileProvider } from "./contexts/FileContext";
import { FontProvider } from "./contexts/FontContext";
import { TaskProvider } from "./contexts/TaskContext";
import { CourseProvider } from "./contexts/CourseContext";
import { CalendarProvider } from "./contexts/CalendarContext";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import LandingPage from "./pages/LandingPage";
import { LayoutProvider } from "./contexts/LayoutContext";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import { UserProvider } from "./context/UserContext";
import { CurriculumProvider } from "./contexts/CurriculumContext";
import { TableProvider } from "./contexts/TableContext";
import { ImageProvider } from "./contexts/ImageContext";
import { UnitNoteProvider } from "./contexts/UnitNoteContext";
import SubscriptionPage from "./pages/SubscriptionPage";
import VerificationPage from "./pages/VerificationPage";
import EmailVerificationPage from "./pages/EmailVerificationPage";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import LicensingPage from "./pages/LicensingPage";
import NoteTaking from "./pages/NoteTaking";
import ReviewMode from "./pages/ReviewMode";
import Planner from "./pages/Planner";
import LoadingIndicator from "./components/LoadingIndicator";
import { AuthDeleteProvider } from "./context/AuthDeleteContext";
import UpdateSubscription from "./pages/UpdateSubscription";
import MyAccount from "./pages/MyAccount";
import { UnitCardProvider } from "./contexts/UnitCardContext";
import { FileManagerProvider } from "./contexts/FileManagerContext";
import { ProgressProvider } from "./contexts/ProgressContext";

const App = () => {
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(true);
  const [view, setView] = useState("week1");

  return (
    <AuthProvider>
      <AuthDeleteProvider>
        <UserProvider>
          <CourseProvider>
            <CurriculumProvider>
              <ProgressProvider>
                <UnitCardProvider>
                  <TableProvider>
                    <ImageProvider>
                      <UnitNoteProvider>
                        <LayoutProvider>
                          <FontProvider>
                            <FileProvider>
                              <TaskProvider>
                                <CalendarProvider>
                                  <FileManagerProvider>
                                    <Router>
                                      <ToastContainer
                                        position="bottom-right"
                                        autoClose={false}
                                        closeOnClick={false}
                                        pauseOnHover={true}
                                        draggable={true}
                                      />
                                      <Suspense fallback={<LoadingIndicator />}>
                                        <Routes>
                                          <Route
                                            path="/"
                                            element={<LandingPage />}
                                          />
                                          <Route
                                            path="/welcome"
                                            element={<LandingPage />}
                                          />
                                          <Route
                                            path="/login"
                                            element={<LoginPage />}
                                          />
                                          <Route
                                            path="/signup"
                                            element={<SignUpPage />}
                                          />
                                          <Route
                                            path="/forgot-password"
                                            element={<ForgotPasswordPage />}
                                          />
                                          <Route
                                            path="/subscription"
                                            element={
                                              <Suspense
                                                fallback={<LoadingIndicator />}
                                              >
                                                <SubscriptionPage />
                                              </Suspense>
                                            }
                                          />
                                          <Route
                                            path="/verify-email"
                                            element={<VerificationPage />}
                                          />
                                          <Route
                                            path="/email-verified"
                                            element={<EmailVerificationPage />}
                                          />
                                          <Route
                                            path="/terms"
                                            element={<TermsOfUse />}
                                          />
                                          <Route
                                            path="/privacy"
                                            element={<PrivacyPolicy />}
                                          />
                                          <Route
                                            path="/refund-policy"
                                            element={<RefundPolicy />}
                                          />
                                          <Route
                                            path="/about"
                                            element={<AboutPage />}
                                          />
                                          <Route
                                            path="/contact"
                                            element={<ContactPage />}
                                          />
                                          <Route
                                            path="/licensing"
                                            element={<LicensingPage />}
                                          />
                                          <Route
                                            path="/note-taking"
                                            element={<NoteTaking />}
                                          />
                                          <Route
                                            path="/review-mode"
                                            element={<ReviewMode />}
                                          />
                                          <Route
                                            path="/planner"
                                            element={<Planner />}
                                          />
                                          <Route
                                            path="/update-subscription"
                                            element={<UpdateSubscription />}
                                          />
                                          <Route
                                            path="/my-account"
                                            element={<MyAccount />}
                                          />

                                          <Route
                                            element={
                                              <PrivateRoute>
                                                <MainLayout
                                                  isSidebarMinimized={
                                                    isSidebarMinimized
                                                  }
                                                  setIsSidebarMinimized={
                                                    setIsSidebarMinimized
                                                  }
                                                />
                                              </PrivateRoute>
                                            }
                                          >
                                            <Route
                                              path="/dashboard"
                                              element={<Dashboard />}
                                            />
                                            <Route
                                              path="/dashboard/courses"
                                              element={
                                                <Courses
                                                  isSidebarMinimized={
                                                    isSidebarMinimized
                                                  }
                                                  setIsSidebarMinimized={
                                                    setIsSidebarMinimized
                                                  }
                                                />
                                              }
                                            />
                                            <Route
                                              path="/dashboard/calendar"
                                              element={
                                                <Calendar
                                                  isSidebarMinimized={
                                                    isSidebarMinimized
                                                  }
                                                  setIsSidebarMinimized={
                                                    setIsSidebarMinimized
                                                  }
                                                  view={view}
                                                  setView={setView}
                                                />
                                              }
                                            />
                                            <Route
                                              path="/dashboard/todo"
                                              element={
                                                <ToDoList
                                                  isSidebarMinimized={
                                                    isSidebarMinimized
                                                  }
                                                  setIsSidebarMinimized={
                                                    setIsSidebarMinimized
                                                  }
                                                />
                                              }
                                            />
                                            <Route
                                              path="/dashboard/settings"
                                              element={<Settings />}
                                            />
                                            <Route
                                              path="/courses/:courseId"
                                              element={<CourseDetail />}
                                            >
                                              <Route
                                                path="curriculum/:courseName"
                                                element={
                                                  <Curriculum
                                                    isSidebarMinimized={
                                                      isSidebarMinimized
                                                    }
                                                    setIsSidebarMinimized={
                                                      setIsSidebarMinimized
                                                    }
                                                  />
                                                }
                                              />
                                              <Route
                                                path="flashcards"
                                                element={<Flashcards />}
                                              />
                                            </Route>
                                          </Route>
                                          <Route
                                            path="/courses/:courseId/curriculum/:courseName/unit/:unitId"
                                            element={
                                              <PrivateRoute>
                                                <Unitnote />
                                              </PrivateRoute>
                                            }
                                          />
                                          <Route
                                            path="/courses/:courseId/curriculum/:courseName/unit/:unitId/subunit/:subunitId"
                                            element={
                                              <PrivateRoute>
                                                <Unitnote />
                                              </PrivateRoute>
                                            }
                                          />
                                          <Route
                                            path="/*"
                                            element={
                                              <PrivateRoute>
                                                <MainLayout
                                                  isSidebarMinimized={
                                                    isSidebarMinimized
                                                  }
                                                  setIsSidebarMinimized={
                                                    setIsSidebarMinimized
                                                  }
                                                />
                                              </PrivateRoute>
                                            }
                                          />
                                        </Routes>
                                      </Suspense>
                                    </Router>
                                  </FileManagerProvider>
                                </CalendarProvider>
                              </TaskProvider>
                            </FileProvider>
                          </FontProvider>
                        </LayoutProvider>
                      </UnitNoteProvider>
                    </ImageProvider>
                  </TableProvider>
                </UnitCardProvider>
              </ProgressProvider>
            </CurriculumProvider>
          </CourseProvider>
        </UserProvider>
      </AuthDeleteProvider>
    </AuthProvider>
  );
};

export default App;
