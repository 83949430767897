class EventEmitter {
  constructor() {
    this.listeners = {};
    this.deletedSubunits = new Set();
  }

  on(event, callback) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(callback);
  }

  off(event, callback) {
    if (!this.listeners[event]) return;
    this.listeners[event] = this.listeners[event].filter(
      (cb) => cb !== callback
    );
  }

  emit(event, data) {
    if (event === "subunitDeleted" && data?.subunitId) {
      this.deletedSubunits.add(data.subunitId);
    }

    if (!this.listeners[event]) return;
    this.listeners[event].forEach((callback) => callback(data));
  }

  isSubunitDeleted(subunitId) {
    return this.deletedSubunits.has(subunitId);
  }

  clearDeletedSubunit(subunitId) {
    this.deletedSubunits.delete(subunitId);
  }
}

const eventEmitter = new EventEmitter();
export default eventEmitter;
