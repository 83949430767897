import { writeBatch } from "firebase/firestore";
import { db, auth } from "../firebase/firebase";
import {
  doc,
  deleteDoc,
  collection,
  getDocs,
  setDoc,
  getDoc,
} from "firebase/firestore";

const deleteCurriculumCollection = async (
  userId,
  courseId,
  mainColl,
  subColl
) => {
  try {
    const collRef = collection(
      db,
      "users",
      userId,
      "courses",
      courseId,
      "curriculum",
      mainColl,
      subColl
    );

    // 전체 문서 가져오기
    const snapshot = await getDocs(collRef);

    // 문서가 없으면 종료
    if (snapshot.empty) return;

    // Firestore 배치 제한(500)을 고려한 청크 처리
    const chunkSize = 450;
    const docs = snapshot.docs;

    for (let i = 0; i < docs.length; i += chunkSize) {
      const batch = writeBatch(db);
      const chunk = docs.slice(i, i + chunkSize);

      chunk.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // 각 배치 커밋 전 대기
      await batch.commit();
    }

    // 컬렉션 자체의 참조도 삭제
    const parentRef = doc(
      db,
      "users",
      userId,
      "courses",
      courseId,
      "curriculum",
      mainColl
    );
    await deleteDoc(parentRef);
  } catch (error) {
    console.error(`Error deleting ${mainColl}/${subColl}:`, error);
    throw error;
  }
};

export const deleteCourseData = async (courseId) => {
  if (!auth.currentUser) throw new Error("인증되지 않은 사용자입니다.");
  const userId = auth.currentUser.uid;

  try {
    // 1. curriculum 컬렉션 삭제
    const curriculumCollections = [
      ["canvas", "subunits"],
      ["images", "subunits"],
      ["stamps", "subunits"],
      ["tableData", "subunits"],
      ["textbox", "subunits"],
      ["layout", "subunits"],
    ];

    // 각 컬렉션 삭제를 병렬로 처리하되 완료 확인
    await Promise.all(
      curriculumCollections.map(([mainColl, subColl]) =>
        deleteCurriculumCollection(userId, courseId, mainColl, subColl)
      )
    );

    // curriculum 메인 문서들 삭제
    await Promise.all([
      deleteDoc(
        doc(db, "users", userId, "courses", courseId, "curriculum", "structure")
      ),
      deleteDoc(
        doc(db, "users", userId, "courses", courseId, "curriculum", "main")
      ),
    ]);

    // 2. 코스 직속 컬렉션 삭제
    const courseCollections = [
      ["flashcards", "list"],
      ["fonts", "settings"],
      ["stampNames", "labels"],
    ];

    for (const [mainColl, subDoc] of courseCollections) {
      await deleteDoc(
        doc(db, "users", userId, "courses", courseId, mainColl, subDoc)
      );
    }

    // 3. Calendar/events 업데이트
    const calendarUpdate = async () => {
      const calendarRef = doc(db, "users", userId, "Calendar", "events");
      const calendarSnap = await getDoc(calendarRef);
      if (calendarSnap.exists()) {
        const events = calendarSnap.data();
        const updatedEvents = Object.entries(events).reduce(
          (acc, [date, dayEvents]) => {
            const filteredEvents = dayEvents.filter(
              (event) => event.courseId !== courseId
            );
            if (filteredEvents.length > 0) {
              acc[date] = filteredEvents;
            }
            return acc;
          },
          {}
        );
        await setDoc(calendarRef, updatedEvents);
      }
    };

    // 4. reviewStatus 업데이트
    const reviewStatusUpdate = async () => {
      const reviewRef = doc(db, "users", userId, "reviewStatus", "current");
      const reviewSnap = await getDoc(reviewRef);
      if (reviewSnap.exists()) {
        const reviewData = reviewSnap.data();
        const { [courseId]: _, ...restTodayReviews } =
          reviewData.todayReviews || {};
        const { [courseId]: __, ...restOverdueReviews } =
          reviewData.overdueReviews || {};

        await setDoc(reviewRef, {
          todayReviews: restTodayReviews,
          overdueReviews: restOverdueReviews,
          lastUpdated: new Date(),
        });
      }
    };

    // 5. courseColors 업데이트
    const courseColorsUpdate = async () => {
      const courseColorsRef = doc(
        db,
        "users",
        userId,
        "courseColors",
        "colors"
      );
      const courseColorsSnap = await getDoc(courseColorsRef);
      if (courseColorsSnap.exists()) {
        const colors = courseColorsSnap.data();
        delete colors[courseId];
        await setDoc(courseColorsRef, colors);
      }
    };

    // 모든 삭제 작업을 병렬로 실행
    await Promise.all([
      calendarUpdate(),
      reviewStatusUpdate(),
      courseColorsUpdate(),
    ]);

    // 6. 마지막으로 코스 문서 자체 삭제
    await deleteDoc(doc(db, "users", userId, "courses", courseId));

    return true;
  } catch (error) {
    console.error("코스 삭제 중 오류 발생:", error);
    throw error;
  }
};
