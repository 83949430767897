import React, { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  setDoc,
  deleteField,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../firebase/firebase";
import { useCurriculum } from "../contexts/CurriculumContext";
import { useNavigate, useParams } from "react-router-dom";
import ContextMenu from "./ContextMenu";

const FlyoutMenu8 = ({ onClose, courseId, onStampDelete }) => {
  const { courseName } = useParams();
  const [selectedTab, setSelectedTab] = useState("1");
  const [tabNames, setTabNames] = useState(["1", "2", "3", "4", "5"]);
  const [stampedSubunits, setStampedSubunits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [contextMenu, setContextMenu] = useState(null);

  // Get required data from CurriculumContext
  const { curriculum, getSubunitNameById } = useCurriculum();

  // Load stamp names from Firebase
  useEffect(() => {
    const loadStampNames = async () => {
      try {
        const user = auth.currentUser;
        if (!user) return;

        const stampNamesRef = doc(
          db,
          "users",
          user.uid,
          "courses",
          courseId,
          "stampNames",
          "labels"
        );
        const docSnap = await getDoc(stampNamesRef);

        if (docSnap.exists()) {
          setTabNames(docSnap.data().names);
        }
      } catch (error) {
        console.error("Failed to load stamp names:", error);
      }
    };

    loadStampNames();
  }, [courseId]);

  // Handle stamp name change
  const handleTabNameChange = async (index, newName) => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      const newNames = [...tabNames];
      newNames[index] = newName;
      setTabNames(newNames);

      const stampNamesRef = doc(
        db,
        "users",
        user.uid,
        "courses",
        courseId,
        "stampNames",
        "labels"
      );
      await setDoc(stampNamesRef, { names: newNames }, { merge: true });
    } catch (error) {
      console.error("Failed to save stamp name:", error);
    }
  };

  // Find subunits with stamps
  const findSubunitsWithStamp = async (stampId) => {
    setIsLoading(true);
    try {
      const user = auth.currentUser;
      if (!user) return;

      // 배치 사이즈를 증가시켜 데이터베이스 호출 횟수 감소
      const batchSize = 25; // 10에서 25로 증가

      // 메모리에 curriculum 데이터 캐싱
      const allSubunits = Object.entries(curriculum.subunits).reduce(
        (acc, [unitId, subunits]) => {
          return acc.concat(
            subunits.map((subunit) => ({
              id: subunit.id,
              name: subunit.name,
              unitId: unitId,
            }))
          );
        },
        []
      );

      const subunitsWithStamp = [];
      const promises = [];

      // Promise.all을 한 번만 사용하도록 수정
      for (let i = 0; i < allSubunits.length; i += batchSize) {
        const batch = allSubunits.slice(i, i + batchSize);
        batch.forEach((subunit) => {
          promises.push(
            getDoc(
              doc(
                db,
                "users",
                user.uid,
                "courses",
                courseId,
                "curriculum",
                "stamps",
                "subunits",
                subunit.id
              )
            ).then((docSnap) => ({ docSnap, subunit }))
          );
        });
      }

      const results = await Promise.all(promises);

      results.forEach(({ docSnap, subunit }) => {
        const stampsData = docSnap.exists() ? docSnap.data() : null;
        if (stampsData?.data?.some((stamp) => stamp.stampId === stampId)) {
          subunitsWithStamp.push(subunit);
        }
      });

      setStampedSubunits(subunitsWithStamp);
    } catch (error) {
      console.error("❌ Failed to load stamp data:", error);
      setStampedSubunits([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    findSubunitsWithStamp(tab);
  };

  // Navigate to subunit
  const handleSubunitClick = (subunit) => {
    navigate(
      `/courses/${courseId}/curriculum/${courseName}/unit/${subunit.unitId}/subunit/${subunit.id}`,
      {
        state: {
          subunitId: subunit.id,
          unitId: subunit.unitId,
        },
      }
    );
    onClose();
  };

  // Find subunits with stamp 1 on initial load
  useEffect(() => {
    if (curriculum) {
      // Only execute after curriculum is loaded
      findSubunitsWithStamp("1");
    }
  }, [curriculum]); // Run when curriculum changes

  // 컨텍스트 메뉴 핸들러 추가
  const handleContextMenu = (e, subunit) => {
    e.preventDefault();
    setContextMenu({
      x: e.clientX,
      y: e.clientY,
      subunit: subunit,
    });
  };

  // 라벨 삭제 함수 추가
  const handleDeleteLabel = async (subunit) => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      const subunitRef = doc(
        db,
        "users",
        user.uid,
        "courses",
        courseId,
        "curriculum",
        "stamps",
        "subunits",
        subunit.id
      );

      const docSnap = await getDoc(subunitRef);
      if (docSnap.exists()) {
        const stampsData = docSnap.data();
        if (stampsData.data) {
          const updatedStamps = stampsData.data.filter(
            (stamp) => stamp.stampId !== selectedTab
          );

          await setDoc(subunitRef, { data: updatedStamps });

          // Toolbar로 직접 이벤트 발생
          const event = new CustomEvent("stampDeleted", {
            detail: {
              stampId: selectedTab,
              subunitId: subunit.id,
            },
          });
          window.dispatchEvent(event);
        }
      }

      findSubunitsWithStamp(selectedTab);
    } catch (error) {
      console.error("Failed to delete label:", error);
    }
    setContextMenu(null);
  };

  return (
    <div className="flyout-panel">
      <div className="flyout-header">
        <h3>Labels</h3>
        <button className="close-button" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
          </svg>
        </button>
      </div>
      <div
        className="tab-container"
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          width: "90%",
          maxWidth: "400px",
          margin: "20px auto 0",
        }}
      >
        {tabNames.map((name, index) => (
          <button
            key={index + 1}
            className={`tab ${
              selectedTab === (index + 1).toString() ? "active" : ""
            }`}
            onClick={() => handleTabClick((index + 1).toString())}
            style={{
              position: "relative",
              padding: "10px 15px",
              margin: "0 5px",
              border: "none",
              background: "none",
              cursor: "pointer",
              flex: 1,
            }}
          >
            <div className="stamp-container">
              <img
                src={`/img/stamp/stamp${index + 1}.svg`}
                alt={`Stamp ${name}`}
                className="stamp-image-no-filter"
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            </div>
            {selectedTab === (index + 1).toString() && (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "3px",
                  backgroundColor: "#fae150",
                }}
              />
            )}
          </button>
        ))}
      </div>
      <div className="tab-content">
        <p className="label-text">Label {selectedTab} name</p>
        <input
          type="text"
          value={tabNames[parseInt(selectedTab) - 1]}
          onChange={(e) =>
            handleTabNameChange(parseInt(selectedTab) - 1, e.target.value)
          }
          className="label-input"
        />

        {/* List of stamped subunits */}
        <div className="stamped-subunits-list">
          {isLoading ? (
            <div className="loading-container">
              <div className="loading-spinner"></div>
              <p>Loading...</p>
            </div>
          ) : stampedSubunits.length > 0 ? (
            stampedSubunits.map((subunit) => (
              <div
                key={subunit.id}
                className="subunit-item"
                onClick={() => handleSubunitClick(subunit)}
                onContextMenu={(e) => handleContextMenu(e, subunit)}
              >
                {subunit.name}
              </div>
            ))
          ) : (
            <p className="no-results">No subunits found with this label.</p>
          )}
        </div>
      </div>
      <style jsx>{`
        .flyout-panel {
          width: 300px;
          background-color: var(--bg-color);
          border: 1px solid var(--border-color);
          border-radius: 4px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        }
        .flyout-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 15px;
          border-bottom: 1px solid var(--border-color);
        }
        .flyout-header h3 {
          margin: 0;
          font-size: 16px;
          color: #e0e0e0;
        }
        .close-button {
          background: none;
          border: none;
          cursor: pointer;
        }
        .tab-content {
          padding: 15px;
        }
        .label-text {
          color: #e0e0e0;
        }
        .label-input {
          width: 100%;
          padding: 8px;
          margin-top: 5px;
          background-color: var(--input-bg);
          color: #e0e0e0;
          border: 1px solid var(--border-color);
          border-radius: 4px;
        }
        .label-input:focus {
          outline: none;
          box-shadow: none;
          border-color: #fae150 !important;
        }
        .stamped-subunits-list {
          margin-top: 15px;
          max-height: 200px;
          overflow-y: auto;
        }

        .subunit-item {
          padding: 8px;
          margin: 4px 0;
          background-color: var(--item-bg);
          color: #e0e0e0;
          border-radius: 4px;
          cursor: pointer;
          transition: opacity 0.2s;
        }

        .subunit-item:hover {
          opacity: 0.8;
        }
        @media (prefers-color-scheme: dark) {
          :root {
            --bg-color: #1a1a1a;
            --border-color: #333;
            --text-color: #e0e0e0;
            --input-bg: #2a2a2a;
            --item-bg: #2d2d2d;
          }
          .stamp-container {
            background-color: #ffffff;
          }
        }
        @media (prefers-color-scheme: light) {
          :root {
            --bg-color: #ffffff;
            --border-color: #e0e0e0;
            --text-color: #333333;
            --input-bg: #ffffff;
            --item-bg: #f5f5f5;
          }
          .stamp-container {
            background-color: #ffffff;
          }
        }
        .stamp-container {
          width: 40px;
          height: 40px;
          background-color: #ffffff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
        }
        .loading-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 20px;
          color: #e0e0e0;
        }

        .loading-spinner {
          width: 30px;
          height: 30px;
          border: 3px solid var(--border-color);
          border-top: 3px solid #fae150;
          border-radius: 50%;
          animation: spin 1s linear infinite;
          margin-bottom: 10px;
        }

        .no-results {
          text-align: center;
          color: #e0e0e0;
          opacity: 0.7;
          padding: 20px;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        .stamp-image-no-filter {
          filter: none !important;
        }

        /* 기존 active 필터 스타일을 무시 */
        .layout1-button.active .stamp-image-no-filter,
        button.active .stamp-image-no-filter {
          filter: none !important;
        }
      `}</style>
      {/* 컨텍스트 메뉴 추가 */}
      {contextMenu && (
        <ContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          onClose={() => setContextMenu(null)}
          menuItems={[
            {
              label: "Delete Label",
              onClick: () => handleDeleteLabel(contextMenu.subunit),
            },
          ]}
        />
      )}
    </div>
  );
};

export default FlyoutMenu8;
