// src/pages/Settings.js
import React, { useState, useEffect } from "react";
import "./Settings.css";
import { useUser } from "../context/UserContext";
import {
  signOut,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import { auth } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { checkSubscriptionStatus } from "../utils/subscription";
import {
  doc,
  deleteDoc,
  getDocs,
  collection,
  writeBatch,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import "../css/MyAccount.css";
import { storage } from "../firebase/firebase";
import { ref, listAll, deleteObject } from "firebase/storage";

const Settings = ({ isMyAccountPage }) => {
  const [activeTab, setActiveTab] = useState("security");
  const { userEmail } = useUser();
  const navigate = useNavigate();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [deleteEmail, setDeleteEmail] = useState("");
  const [deletePassword, setDeletePassword] = useState("");
  const [deleteError, setDeleteError] = useState("");
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);
  const [showSubscriptionWarningModal, setShowSubscriptionWarningModal] =
    useState(false);
  const [showSubscriptionActiveModal, setShowSubscriptionActiveModal] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const loadSubscriptionData = async () => {
      try {
        setIsLoadingSubscription(true);
        const subscriptionData = await checkSubscriptionStatus();
        setSubscription(subscriptionData);
      } catch (error) {
        console.error("Error loading subscription info:", error);
      } finally {
        setIsLoadingSubscription(false);
      }
    };

    loadSubscriptionData();

    const intervalId = setInterval(loadSubscriptionData, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {}
  };

  const handleDeleteAccountClick = () => {
    if (!subscription) {
      setShowDeleteModal(true);
      return;
    }

    // 취소 안한 경우
    if (!subscription.cancelledAt && subscription.status !== "cancelled") {
      setShowSubscriptionActiveModal(true);
      return;
    }

    const now = new Date();
    const renewsAt = new Date(subscription.renewsAt);
    const trialEndsAt = new Date(subscription.trialEndsAt);

    // 트라이얼인 경우 (trialEndsAt과 renewsAt이 같음)
    if (
      subscription.trialEndsAt &&
      trialEndsAt.getTime() === renewsAt.getTime()
    ) {
      setShowDeleteModal(true);
      return;
    }

    // 일반 구독이고 기간이 남은 경우 (trialEndsAt과 renewsAt이 다른 경우만)
    if (
      subscription.cancelledAt &&
      now < renewsAt &&
      (!subscription.trialEndsAt ||
        trialEndsAt.getTime() !== renewsAt.getTime())
    ) {
      setShowSubscriptionWarningModal(true);
      return;
    }

    setShowDeleteModal(true);
  };

  const handleDeleteAccount = async () => {
    try {
      if (!deleteEmail || !deletePassword) {
        setDeleteError("Please enter both email and password.");
        return;
      }

      setIsDeleting(true);
      const user = auth.currentUser;
      const userId = user.uid;

      // Storage 파일 삭제 로직 추가
      // 2. Storage 파일 삭제
      const deleteStorageFiles = async () => {
        // 사용자 폴더 삭제
        const userStorageRef = ref(storage, `users/${userId}`);
        // 썸네일 폴더 삭제
        const thumbnailsRef = ref(storage, `images/thumbnails/${userId}`);

        try {
          // 두 경로 모두에서 파일 리스트 가져오기
          const [userFiles, thumbnailFiles] = await Promise.all([
            listAll(userStorageRef),
            listAll(thumbnailsRef),
          ]);

          const deletePromises = [
            // 사용자 폴더 파일 삭제
            ...userFiles.items.map((item) => deleteObject(item)),
            // 썸네일 폴더 파일 삭제
            ...thumbnailFiles.items.map((item) => deleteObject(item)),
          ];

          await Promise.all(deletePromises);
        } catch (error) {
          console.error("Storage 파일 삭제 중 오류:", error);
          throw error;
        }
      };

      // Storage 파일 삭제 실행
      await deleteStorageFiles();

      // Firestore 데이터 삭제 로직 수정
      const collectionsToDelete = [
        "subscriptions",
        "todolist",
        "Calendar",
        "courses",
        "ID",
        "settings",
        "preferences",
        "notifications",
        "history",
        "analytics",
        "habits", // 추가됨
      ];

      // 각 컬렉션별로 개별 배치 처리
      for (const collectionName of collectionsToDelete) {
        try {
          const collectionRef = collection(
            db,
            `users/${userId}/${collectionName}`
          );
          const snapshot = await getDocs(collectionRef);

          // 배치 크기 제한을 고려한 청크 처리
          const chunkSize = 400; // 안전한 배치 크기
          const docs = snapshot.docs;

          for (let i = 0; i < docs.length; i += chunkSize) {
            const batch = writeBatch(db);
            const chunk = docs.slice(i, i + chunkSize);

            for (const doc of chunk) {
              // 문서 삭제
              batch.delete(doc.ref);

              // 하위 컬렉션 처리
              const subCollectionsSnapshot = await getDocs(collection(doc.ref));
              subCollectionsSnapshot.forEach((subDoc) => {
                batch.delete(subDoc.ref);
              });
            }

            await batch.commit();
          }
        } catch (error) {
          console.error(`Error deleting collection ${collectionName}:`, error);
          // 개별 컬렉션 삭제 실패 시에도 계속 진행
          continue;
        }
      }

      // 개별 문서 삭제
      try {
        const batch = writeBatch(db);

        const courseColorsRef = doc(db, `users/${userId}/courseColors/colors`);
        batch.delete(courseColorsRef);

        const reviewStatusRef = doc(db, `users/${userId}/reviewStatus/current`);
        batch.delete(reviewStatusRef);

        const userRef = doc(db, `users/${userId}`);
        batch.delete(userRef);

        await batch.commit();
      } catch (error) {
        console.error("Error deleting individual documents:", error);
      }

      // 계정 재인증 및 삭제
      const credential = EmailAuthProvider.credential(
        deleteEmail,
        deletePassword
      );
      await reauthenticateWithCredential(user, credential);
      await user.delete();

      setShowDeleteModal(false);
      navigate("/login");
    } catch (error) {
      setDeleteError(error.message || "계정 삭제 중 오류가 발생했습니다.");
    } finally {
      setIsDeleting(false);
    }
  };

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setError("New passwords do not match.");
      return;
    }

    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );

      // Re-authenticate
      await reauthenticateWithCredential(user, credential);

      // Change password
      await updatePassword(user, newPassword);

      alert("Password changed successfully.");
      setShowPasswordModal(false);
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setError("");
    } catch (error) {
      setError("Current password is incorrect or re-login required.");
    }
  };

  const deleteUserData = async (userId) => {
    try {
      // Delete user document from users collection
      const userRef = doc(db, `users/${userId}`);
      await deleteDoc(userRef);
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className="settings-container">
      {isMyAccountPage && (
        <div className="settings-header">
          <button style={{ color: "#fff" }} onClick={() => navigate("/")}>
            ← Back to Home
          </button>
        </div>
      )}
      <h1 className="settings-title">Settings</h1>
      <nav className="settings-nav">
        <button
          className={`nav-button ${activeTab === "security" ? "active" : ""}`}
          onClick={() => setActiveTab("security")}
        >
          Security
        </button>
        <button
          className={`nav-button ${activeTab === "billing" ? "active" : ""}`}
          onClick={() => setActiveTab("billing")}
        >
          Billing & Subscription
        </button>
      </nav>

      {activeTab === "billing" && (
        <div>
          <h2 className="settings-section-title">
            Billing & Subscription Management
          </h2>
          <div className="billing-section">
            <div className="subscription-status-card">
              <h3>Subscription Status</h3>
              <div className="subscription-info">
                {isLoadingSubscription ? (
                  <div className="loading-subscription">
                    <p>Loading subscription information...</p>
                  </div>
                ) : subscription ? (
                  <>
                    <div className="status-row">
                      <span className="label">Status:</span>
                      <span className={`status-badge ${subscription.status}`}>
                        {subscription.status === "active"
                          ? "Active"
                          : subscription.status === "on_trial"
                          ? "Trial"
                          : subscription.status === "cancelled"
                          ? "Cancelled"
                          : "Inactive"}
                      </span>
                    </div>
                    <div className="plan-row">
                      <span className="label">Current Plan:</span>
                      <span className="value">
                        {subscription.plan === "yearly" ? "Annual" : "Monthly"}{" "}
                        Plan
                      </span>
                    </div>
                    <div className="date-row">
                      <span className="label">Next Payment:</span>
                      <span className="value">
                        {subscription.currentPeriodEnd
                          ? new Date(
                              subscription.currentPeriodEnd
                            ).toLocaleDateString()
                          : "N/A"}
                      </span>
                    </div>
                    {subscription.trialEndsAt && (
                      <div className="trial-row">
                        <span className="label">Trial Ends:</span>
                        <span className="value">
                          {new Date(
                            subscription.trialEndsAt
                          ).toLocaleDateString()}
                        </span>
                      </div>
                    )}
                    <div className="manage-subscription">
                      <a
                        href={subscription.customerPortalUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="manage-button"
                      >
                        Manage Subscription
                      </a>
                    </div>
                  </>
                ) : (
                  <div className="no-subscription">
                    <p>You don't have an active subscription</p>
                    <button
                      onClick={() => navigate("/subscription")}
                      className="subscribe-button"
                    >
                      View Plans
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === "security" && (
        <div>
          <h2 className="settings-section-title">Security Settings</h2>
          <div className="settings-security-section">
            <div className="settings-privacy-section">
              <div className="email-section">
                <h3>Email</h3>
                <div className="email-display">
                  <span className="email-text">{userEmail}</span>
                </div>
              </div>

              <div className="logout-section">
                <button onClick={handleLogout} className="logout-button">
                  <img src="/toolbaricons/inuse/send-out.svg" alt="logout" />
                  Logout
                </button>
              </div>
            </div>

            <div className="settings-password-section">
              <h3 className="settings-section-title">Password Management</h3>
              <div className="security-info-box">
                <div>
                  <p className="security-info-text">
                    Protect your account by regularly changing your password.
                  </p>
                  <p className="security-info-subtext">
                    Last changed: March 15, 2024
                  </p>
                </div>
                <button
                  className="change-password-button"
                  onClick={() => setShowPasswordModal(true)}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path d="M12 2a5 5 0 0 1 5 5v3H7V7a5 5 0 0 1 5-5zm-7 8h14v10a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V10z" />
                  </svg>
                  Change Password
                </button>
              </div>
            </div>

            <div className="settings-delete-account-section">
              <h3 className="settings-section-title">Delete Account</h3>
              <div className="security-danger-box">
                <div className="security-danger-content">
                  <div>
                    <p className="security-danger-text">
                      Deleting your account will permanently remove all data and
                      cannot be recovered.
                    </p>
                    <p className="security-danger-subtext"></p>
                  </div>
                  <button
                    className="delete-account-button"
                    onClick={handleDeleteAccountClick}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                    </svg>
                    Delete Account
                  </button>
                </div>
              </div>
            </div>

            {showPasswordModal && (
              <div
                className="modal-overlay"
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1000,
                }}
              >
                <div
                  className="modal-content"
                  style={{
                    backgroundColor: "white",
                    padding: "30px",
                    borderRadius: "12px",
                    width: "400px",
                    maxWidth: "90%",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h3 style={{ margin: 0, color: "#333" }}>
                      Change Password
                    </h3>
                    <button
                      onClick={() => setShowPasswordModal(false)}
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        padding: "4px",
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#666"
                        strokeWidth="2"
                      >
                        <path d="M18 6L6 18M6 6l12 12" />
                      </svg>
                    </button>
                  </div>

                  {error && (
                    <div
                      style={{
                        padding: "12px",
                        backgroundColor: "#fff5f5",
                        color: "#e03131",
                        borderRadius: "4px",
                        marginBottom: "20px",
                        fontSize: "14px",
                      }}
                    >
                      {error}
                    </div>
                  )}

                  <div style={{ marginBottom: "15px" }}>
                    <label
                      style={{
                        display: "block",
                        marginBottom: "8px",
                        fontSize: "14px",
                        color: "#333",
                      }}
                    >
                      Current Password
                    </label>
                    <input
                      type="password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      style={{
                        width: "100%",
                        padding: "8px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: "15px" }}>
                    <label style={{ display: "block", marginBottom: "5px" }}>
                      New Password
                    </label>
                    <input
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      style={{
                        width: "100%",
                        padding: "8px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <label style={{ display: "block", marginBottom: "5px" }}>
                      Confirm New Password
                    </label>
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      style={{
                        width: "100%",
                        padding: "8px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "10px",
                    }}
                  >
                    <button
                      onClick={() => setShowPasswordModal(false)}
                      style={{
                        padding: "8px 16px",
                        backgroundColor: "#ffffff",
                        color: "#666",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handlePasswordChange}
                      style={{
                        padding: "8px 16px",
                        backgroundColor: "#4a90e2",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Change
                    </button>
                  </div>
                </div>
              </div>
            )}

            {showDeleteModal && (
              <div className="modal-overlay">
                <div className="modal-content">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h3 style={{ margin: 0, color: "#e03131" }}>
                      Confirm Account Deletion
                    </h3>
                    <button
                      onClick={() => setShowDeleteModal(false)}
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        padding: "4px",
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#666"
                        strokeWidth="2"
                      >
                        <path d="M18 6L6 18M6 6l12 12" />
                      </svg>
                    </button>
                  </div>

                  <div
                    style={{
                      backgroundColor: "#fff5f5",
                      border: "1px solid #ffe3e3",
                      borderRadius: "8px",
                      padding: "16px",
                      marginBottom: "20px",
                    }}
                  >
                    <div style={{ marginBottom: "16px", textAlign: "left" }}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="#e03131"
                        style={{
                          marginBottom: "12px",
                          display: "block",
                          margin: "0 auto",
                        }}
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
                      </svg>
                      <h4
                        style={{
                          color: "#e03131",
                          marginBottom: "8px",
                          textAlign: "center",
                        }}
                      >
                        Warning
                      </h4>
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#666",
                          marginBottom: "8px",
                          textAlign: "left",
                        }}
                      >
                        The following data will be permanently deleted when you
                        delete your account:
                      </p>
                      <ul
                        style={{
                          fontSize: "14px",
                          color: "#666",
                          paddingLeft: "20px",
                          textAlign: "left",
                        }}
                      >
                        <li>
                          All course-related materials (notes, curriculum,
                          flashcards)
                        </li>
                        <li>Calendar events and schedules</li>
                        <li>Personal settings and preferences</li>
                        <li>Chat history and interactions</li>
                        <li>Progress tracking and achievements</li>
                        <li>Subscription and payment information</li>
                      </ul>
                    </div>
                    <p
                      style={{
                        fontSize: "13px",
                        color: "#666",
                        textAlign: "left",
                      }}
                    >
                      This action cannot be undone and deleted data cannot be
                      recovered.
                    </p>
                  </div>

                  {deleteError && (
                    <div
                      style={{
                        padding: "12px",
                        backgroundColor: "#fff5f5",
                        color: "#e03131",
                        borderRadius: "4px",
                        marginBottom: "20px",
                        fontSize: "14px",
                      }}
                    >
                      {deleteError}
                    </div>
                  )}

                  <div style={{ marginBottom: "15px" }}>
                    <label
                      style={{
                        display: "block",
                        marginBottom: "8px",
                        fontSize: "14px",
                        color: "#e1e1e1",
                      }}
                    >
                      Confirm Email Address
                    </label>
                    <input
                      type="email"
                      value={deleteEmail}
                      onChange={(e) => setDeleteEmail(e.target.value)}
                      placeholder="Enter your account email"
                      style={{
                        width: "100%",
                        padding: "8px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                      }}
                    />
                  </div>

                  <div style={{ marginBottom: "20px" }}>
                    <label
                      style={{
                        display: "block",
                        marginBottom: "8px",
                        fontSize: "14px",
                        color: "#e1e1e1",
                      }}
                    >
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      value={deletePassword}
                      onChange={(e) => setDeletePassword(e.target.value)}
                      placeholder="Enter your current password"
                      style={{
                        width: "100%",
                        padding: "8px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "12px",
                    }}
                  >
                    <button
                      onClick={() => {
                        setShowDeleteModal(false);
                        setDeleteEmail("");
                        setDeletePassword("");
                        setDeleteError("");
                      }}
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#ffffff",
                        color: "#666",
                        border: "1px solid #ddd",
                        borderRadius: "6px",
                        cursor: isDeleting ? "not-allowed" : "pointer",
                        opacity: isDeleting ? 0.7 : 1,
                      }}
                      disabled={isDeleting}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleDeleteAccount}
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#e03131",
                        color: "white",
                        border: "none",
                        borderRadius: "6px",
                        cursor: isDeleting ? "not-allowed" : "pointer",
                        opacity: isDeleting ? 0.7 : 1,
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                      disabled={isDeleting}
                    >
                      {isDeleting ? (
                        <>
                          <div className="spinner" />
                          Deleting Account...
                        </>
                      ) : (
                        "Delete Account"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}

            {showSubscriptionActiveModal && (
              <div className="modal-overlay">
                <div className="modal-content">
                  <h3 style={{ color: "#ffffff" }}>
                    Subscription Cancellation Required
                  </h3>
                  <p style={{ color: "#e0e0e0" }}>
                    You need to cancel your subscription before deleting your
                    account.
                  </p>
                  <div className="modal-buttons">
                    <button
                      onClick={() => {
                        window.open(subscription.customerPortalUrl, "_blank");
                        setShowSubscriptionActiveModal(false);
                      }}
                      className="primary-button"
                    >
                      Manage Subscription
                    </button>
                    <button
                      onClick={() => setShowSubscriptionActiveModal(false)}
                      className="secondary-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}

            {showSubscriptionWarningModal && (
              <div className="modal-overlay">
                <div className="modal-content">
                  <h3>Subscription Period Remaining</h3>
                  <p>
                    You still have an active subscription period. Do you still
                    want to delete your account?
                  </p>
                  <p className="subscription-info">
                    Subscription Expiry Date:{" "}
                    {new Date(
                      subscription.currentPeriodEnd
                    ).toLocaleDateString()}
                  </p>
                  <div className="modal-buttons">
                    <button
                      onClick={() => {
                        setShowSubscriptionWarningModal(false);
                        setShowDeleteModal(true);
                      }}
                      className="danger-button"
                    >
                      Continue
                    </button>
                    <button
                      onClick={() => setShowSubscriptionWarningModal(false)}
                      className="secondary-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
