import React from "react";

const ZoomControls = ({ onZoomIn, onZoomOut }) => {
  return (
    <div className="zoom-controls">
      <button onClick={onZoomIn}>
        <img src="/toolbaricons/inuse/add.svg" alt="Zoom In" />
      </button>
      <button onClick={onZoomOut}>
        <img src="/toolbaricons/inuse/minus.svg" alt="Zoom Out" />
      </button>
    </div>
  );
};

export default ZoomControls;
