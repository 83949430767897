import { db, auth } from "../firebase/firebase";
import { doc, deleteDoc, writeBatch } from "firebase/firestore";

export const deleteSubunitData = async (userId, courseId, subunitId) => {
  if (!userId || !courseId || !subunitId) {
    throw new Error("필수 매개변수가 누락되었습니다.");
  }

  const batch = writeBatch(db);

  // 삭제해야 할 모든 컬렉션 경로
  const collectionsToDelete = [
    ["curriculum", "canvas", "subunits", subunitId],
    ["curriculum", "tableData", "subunits", subunitId],
    ["curriculum", "images", "subunits", subunitId],
    ["curriculum", "layout", "subunits", subunitId],
    ["curriculum", "stamps", "subunits", subunitId],
    ["curriculum", "textbox", "subunits", subunitId],
  ];

  try {
    // 각 컬렉션의 문서 삭제를 batch에 추가
    collectionsToDelete.forEach((pathSegments) => {
      const docRef = doc(
        db,
        "users",
        userId,
        "courses",
        courseId,
        ...pathSegments
      );
      batch.delete(docRef);
    });

    // batch 실행
    await batch.commit();
    return true;
  } catch (error) {
    throw error;
  }
};
