import React, { createContext, useState, useContext, useCallback } from "react";

const LayoutContext = createContext({
  layout: "layout1",
  setLayout: () => {},
});

export const LayoutProvider = ({ children }) => {
  const [layout, setLayout] = useState("layout1");

  const changeLayout = useCallback((newLayout) => {
    setLayout(newLayout);
  }, []);

  return (
    <LayoutContext.Provider value={{ layout, setLayout: changeLayout }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error("useLayout must be used within a LayoutProvider");
  }
  return context;
};
