// MyAccount.js
import Settings from "./Settings";
import "./Settings.css";
import "../css/MyAccount.css";

const MyAccount = () => {
  return (
    <div className="my-account-container">
      <Settings isMyAccountPage={true} />
    </div>
  );
};

export default MyAccount;
