import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { checkSubscriptionStatus } from "../utils/subscription";
import "../css/PrivacyPolicy.css";

const PrivacyPolicy = () => {
  const { user } = useAuth();
  const [hasValidSubscription, setHasValidSubscription] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkUserSubscription = async () => {
      if (user) {
        const subscription = await checkSubscriptionStatus();
        const isValid =
          subscription &&
          (subscription.status === "active" ||
            subscription.status === "on_trial" ||
            subscription.status === "trialing" ||
            (subscription.status === "cancelled" &&
              new Date(subscription.currentPeriodEnd) > new Date()));
        setHasValidSubscription(isValid);
      }
    };

    checkUserSubscription();
  }, [user]);

  useEffect(() => {
    const checkDevice = () => {
      const mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      const width = window.innerWidth;
      setIsMobile(mobile && width < 768);
    };

    checkDevice();
    window.addEventListener("resize", checkDevice);
    return () => window.removeEventListener("resize", checkDevice);
  }, []);

  return (
    <div className="privacy-container" style={{ backgroundColor: "#ffffff" }}>
      <Link to="/" className="about-logo">
        RedoNote
      </Link>

      <div className="privacy-content">
        <h1>Privacy Policy</h1>
        <section className="legal-section">
          <h2>1. Information We Collect</h2>
          <h3>a. Information You Provide:</h3>
          <ul>
            <li>
              Account Information: Name, email address, password, and other
              details you provide when signing up.
            </li>
            <li>
              Payment Information: Credit card or payment details for
              subscription processing.
            </li>
            <li>
              Content Data: Notes, files, or media uploaded to our service.
            </li>
          </ul>
          <h3>b. Information We Automatically Collect:</h3>
          <ul>
            <li>
              Device Information: IP address, browser type, and operating
              system.
            </li>
            <li>
              Usage Data: Pages you visit, features you use, and time spent on
              the platform.
            </li>
            <li>
              Cookies: Small files stored on your device to enhance user
              experience.
            </li>
          </ul>
        </section>

        <section className="legal-section">
          <h2>2. How We Use Your Information</h2>
          <p>We use your information for the following purposes:</p>
          <ul>
            <li>To provide, operate, and maintain our services.</li>
            <li>To process payments and manage subscriptions.</li>
            <li>To communicate with you about updates, offers, and support.</li>
            <li>
              To improve user experience through analytics and performance
              monitoring.
            </li>
            <li>To comply with legal and regulatory obligations.</li>
          </ul>
        </section>

        <section className="legal-section">
          <h2>3. How We Share Your Information</h2>
          <p>
            We do not sell or rent your personal information. We may share your
            data only in the following cases:
          </p>
          <ul>
            <li>
              Service Providers: With trusted third-party providers who assist
              in payment processing, hosting, or analytics.
            </li>
            <li>
              Legal Compliance: When required by law or in response to valid
              legal processes.
            </li>
            <li>
              Business Transfers: In the event of a merger, acquisition, or sale
              of assets, your data may be transferred.
            </li>
          </ul>
        </section>

        <section className="legal-section">
          <h2>4. Data Retention</h2>
          <p>
            We retain your personal information only as long as necessary to
            provide our services or comply with legal requirements. You may
            request deletion of your account and data by contacting us at
            support@redonote.com.
          </p>
        </section>

        <section className="legal-section">
          <h2>5. Cookies and Tracking Technologies</h2>
          <p>We use cookies and similar technologies to:</p>
          <ul>
            <li>Improve user experience.</li>
            <li>Analyze traffic and usage patterns.</li>
            <li>Store user preferences.</li>
          </ul>
          <p>
            You can manage cookie preferences through your browser settings.
            Disabling cookies may affect the functionality of our services.
          </p>
        </section>

        <section className="legal-section">
          <h2>6. Your Rights</h2>
          <p>You have the following rights regarding your data:</p>
          <ul>
            <li>Access: Request a copy of your personal data.</li>
            <li>Correction: Update or correct your personal information.</li>
            <li>Deletion: Request the deletion of your data.</li>
            <li>
              Data Portability: Obtain your data in a structured,
              machine-readable format.
            </li>
          </ul>
          <p>
            To exercise these rights, please contact us at support@redonote.com.
          </p>
        </section>

        <section className="legal-section">
          <h2>7. Security</h2>
          <p>
            We implement industry-standard security measures to protect your
            information. However, no method of transmission over the internet or
            electronic storage is 100% secure. We cannot guarantee absolute
            security.
          </p>
        </section>

        <section className="legal-section">
          <h2>8. Third-Party Links</h2>
          <p>
            Our service may include links to third-party websites. We are not
            responsible for the privacy practices or content of these websites.
            We encourage you to review their privacy policies.
          </p>
        </section>

        <section className="legal-section">
          <h2>9. Children's Privacy</h2>
          <p>
            Our services are not directed to individuals under the age of 13. We
            do not knowingly collect personal information from children. If we
            become aware that a child under 13 has provided us with personal
            data, we will delete it immediately.
          </p>
        </section>

        <section className="legal-section">
          <h2>10. Changes to This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. We will notify
            you of significant changes by email or through a notice on our
            website.
          </p>
        </section>

        <section className="legal-section">
          <h2>11. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy or how we handle
            your information, please contact us at:
          </p>
          <p>Email: support@redonote.com</p>
          <p>
            Address: 603, 29, Sijang-ro 79beon-gil, Bupyeong-gu, Incheon
            (Bupyeong-dong)
          </p>
        </section>
      </div>

      <footer className="business-info">
        <div className="info-container">
          <div className="footer-menu">
            <Link to="/about" className="footer-link">
              About
            </Link>
            <span className="separator">|</span>
            <Link to="/licensing" className="footer-link">
              Licensing
            </Link>
            <span className="separator">|</span>
            <Link to="/contact" className="footer-link">
              Help Center
            </Link>
            <span className="separator">|</span>
            <Link to="/terms" className="footer-link">
              Terms of Use
            </Link>
            <span className="separator">|</span>
            <Link to="/privacy" className="footer-link">
              Privacy Policy
            </Link>
            <span className="separator">|</span>
            <Link to="/refund-policy" className="footer-link">
              Cancellation Policy
            </Link>
          </div>
          <div className="copyright">
            © 2024 Redo Note. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
