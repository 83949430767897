import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { checkSubscriptionStatus } from "../utils/subscription";
import { auth } from "../firebase/firebase";
import "../css/common.css";

const PrivateRoute = React.memo(({ children }) => {
  const { user, loading } = useAuth();
  const [subscriptionState, setSubscriptionState] = useState({
    checked: false,
    isValid: false,
    subscription: null,
  });
  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {
    const checkSession = async () => {
      if (user) {
        const sessionToken = sessionStorage.getItem("userSession");

        if (!sessionToken) {
          setIsTimeout(true);
          await auth.signOut();
          return <Navigate to="/login" />;
        }

        const lastActivity = sessionStorage.getItem("lastActivityTime");
        const currentTime = new Date().getTime();

        if (
          lastActivity &&
          currentTime - parseInt(lastActivity) > 30 * 60 * 1000
        ) {
          setIsTimeout(true);
          await auth.signOut();
          return <Navigate to="/login" />;
        }

        sessionStorage.setItem("lastActivityTime", currentTime.toString());
      }
    };

    if (user && !sessionStorage.getItem("userSession")) {
      sessionStorage.setItem("userSession", Date.now().toString());
      sessionStorage.setItem("lastActivityTime", Date.now().toString());
    }

    checkSession();
  }, [user]);

  useEffect(() => {
    const updateActivity = () => {
      if (user) {
        sessionStorage.setItem("lastActivityTime", Date.now().toString());
      }
    };

    const events = ["mousedown", "keydown", "scroll", "touchstart"];
    events.forEach((event) => {
      window.addEventListener(event, updateActivity);
    });

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, updateActivity);
      });
    };
  }, [user]);

  useEffect(() => {
    let mounted = true;

    const checkSubscription = async () => {
      if (user && !subscriptionState.checked) {
        const subscription = await checkSubscriptionStatus();

        if (mounted) {
          const isValid =
            subscription &&
            (subscription.status === "active" ||
              subscription.status === "on_trial" ||
              subscription.status === "trialing" ||
              (subscription.status === "cancelled" &&
                new Date(subscription.currentPeriodEnd) > new Date()));

          setSubscriptionState({
            checked: true,
            isValid,
            subscription,
          });
        }
      }
    };

    checkSubscription();
    return () => {
      mounted = false;
    };
  }, [user, subscriptionState.checked]);

  if (!loading && !user) {
    return (
      <Navigate
        to="/login"
        state={{ message: "로그인이 필요한 서비스입니다." }}
      />
    );
  }

  if (loading || !subscriptionState.checked) {
    return (
      <div className="loading-overlay">
        {isTimeout ? (
          <div className="session-timeout-message">
            <p>Session expired. Please login again.</p>
            <Navigate to="/login" />
          </div>
        ) : (
          <div className="loading-spinner"></div>
        )}
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (subscriptionState.subscription?.status === "past_due") {
    const userLang = navigator.language || navigator.userLanguage;
    const message = userLang.startsWith("ko")
      ? "결제가 실패했습니다. 카드 한도 등 결제 수단을 확인해 주세요. 홈으로 돌아가서 '내 계정'에서 결제 설정을 업데이트할 수 있습니다."
      : "Payment failed. Please check your payment method (e.g., card limit). You can update your payment settings in 'My Account' from the home page.";

    return <Navigate to="/subscription" state={{ message }} />;
  }

  if (
    !subscriptionState.subscription ||
    subscriptionState.subscription.status === "inactive"
  ) {
    return <Navigate to="/subscription" />;
  }

  if (!subscriptionState.isValid) {
    return <Navigate to="/subscription" />;
  }

  return children;
});

export default PrivateRoute;
