import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useNavigate, useParams } from "react-router-dom";
import "../css/toolbar.css";
import devideIcon from "../assets/icons/inuse/devide.svg";
import devidecancleIcon from "../assets/icons/inuse/devidecancle.svg";
import each1Icon from "../assets/icons/inuse/each1.svg";
import each2Icon from "../assets/icons/inuse/each2.svg";
import reviewModeIcon from "../assets/icons/inuse/review-mode.svg";
import Moveable from "react-moveable";
import { FontContext } from "../contexts/FontContext";
import stampIcon from "../assets/icons/inuse/rubber-stamp.svg";
import nextlowIcon from "../assets/icons/inuse/nextlow.svg";
import { storage, auth } from "../firebase/config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useTable } from "../contexts/TableContext";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import eventBus from "../utils/eventBus";

const DEFAULT_FONT = "TTAgainSea";

const fonts = [
  { name: "Arial", url: null },
  {
    name: "THEGaeideuk",
    url: "../assets/fonts/THEGaeideuk.woff",
    fallbackUrl: "../assets/fonts/THEGaeideuk.ttf",
  },
  {
    name: "TTAgainSea",
    url: "../assets/fonts/TTAgainSea.woff",
    fallbackUrl: "../assets/fonts/TTAgainSea.ttf",
  },
  {
    name: "TTARainyDayBK",
    url: "../assets/fonts/TTARainyDayBK.woff",
    fallbackUrl: "../assets/fonts/TTARainyDayBK.ttf",
  },
  {
    name: "TTEveryDayThank",
    url: "../assets/fonts/TTEveryDayThank.woff",
    fallbackUrl: "../assets/fonts/TTEveryDayThank.ttf",
  },

  {
    name: "TTJustGift",
    url: "../assets/fonts/TTJustGift.woff",
    fallbackUrl: "../assets/fonts/TTJustGift.ttf",
  },
  {
    name: "TTLovelySSong",
    url: "../assets/fonts/TTLovelySSong.woff",
    fallbackUrl: "../assets/fonts/TTLovelySSong.ttf",
  },
  {
    name: "TTOhilyeoJoha",
    url: "../assets/fonts/TTOhilyeoJoha.woff",
    fallbackUrl: "../assets/fonts/TTOhilyeoJoha.ttf",
  },
  {
    name: "TTOnABetterDay",
    url: "../assets/fonts/TTOnABetterDay.woff",
    fallbackUrl: "../assets/fonts/TTOnABetterDay.ttf",
  },
  {
    name: "TTPenguinHeart",
    url: "../assets/fonts/TTPenguinHeart.woff",
    fallbackUrl: "../assets/fonts/TTPenguinHeart.ttf",
  },
  {
    name: "TTPrincessLike",
    url: "../assets/fonts/TTPrincessLike.woff",
    fallbackUrl: "../assets/fonts/TTPrincessLike.ttf",
  },
  {
    name: "TTSpringSun",
    url: "../assets/fonts/TTSpringSun.woff",
    fallbackUrl: "../assets/fonts/TTSpringSun.ttf",
  },
  {
    name: "TTTodayGulimM",
    url: "../assets/fonts/TTTodayGulimM.woff",
    fallbackUrl: "../assets/fonts/TTTodayGulimM.ttf",
  },
  {
    name: "TTWantToBeHappy",
    url: "../assets/fonts/TTWantToBeHappy.woff",
    fallbackUrl: "../assets/fonts/TTWantToBeHappy.ttf",
  },
];

const Toolbar = ({
  onPenModeChange,
  onEraserModeChange,
  onEraserSizeChange,
  onPenColorChange,
  onPenSizeChange,
  layout,
  stampNames,
  onDrawingChange,
  onCanvasDataChange,
  unitId,
  subunitId,
  initialCanvasData,
  onTextBoxDataChange,
  initialTextBoxes = [],
  onStampAdd,
  initialStamps = [],
  onToggleRedText,
  setScale,
  data,
  visibleEditors,
  setVisibleEditors,
  hasEditorContent,
  onStampDelete,
  onStampLoad,
  stamps,
  courseId,
  currentSubunit,
  onEditorClick,
  ...props
}) => {
  const navigate = useNavigate();
  const { courseId: courseIdFromContext, courseName } = useParams();
  const { font, setFont } = useContext(FontContext);

  const [isStampMode, setIsStampMode] = useState(false);
  const [selectedStampId, setSelectedStampId] = useState(null);
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [startPoint, setStartPoint] = useState(null);

  const noteContentRef = useRef(null);
  const [isPenMode, setIsPenMode] = useState(false);
  const [isEraserMode, setIsEraserMode] = useState(false);
  const [eraserSize, setEraserSize] = useState(10);
  const [penColor, setPenColor] = useState("#000000");
  const [isRemovingSplit, setIsRemovingSplit] = useState(false);
  const [isShowingRowByRow, setIsShowingRowByRow] = useState(false);
  const [isShowingRowByRowCol2, setIsShowingRowByRowCol2] = useState(false);
  const [showHighlightColors, setShowHighlightColors] = useState(false);
  const [showReviewModeOptions, setShowReviewModeOptions] = useState(false);
  const [moveableTargets, setMoveableTargets] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [showStampOptions, setShowStampOptions] = useState(false);
  const [activePopup, setActivePopup] = useState(null);
  const toolbarRef = useRef(null);
  const [highlightNextLow1, setHighlightNextLow1] = useState(false);
  const [highlightNextLow2, setHighlightNextLow2] = useState(false);
  const [isReviewModeActive, setIsReviewModeActive] = useState(false);

  const [showFontDropdown, setShowFontDropdown] = useState(false);
  const [fontDropdownPosition, setFontDropdownPosition] = useState({
    top: 0,
    left: 0,
  });
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [history, setHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [activeMode, setActiveMode] = useState(null);
  const [penSize, setPenSize] = useState(2);

  const [stampCursorPosition, setStampCursorPosition] = useState({
    x: 0,
    y: 0,
    scale: 1,
  });
  const [textColor, setTextColor] = useState("#000000");
  const [showTextColorOptions, setShowTextColorOptions] = useState(false);

  const [initialFont, setInitialFont] = useState(DEFAULT_FONT);
  const { toggleRedText, currentCourseId, currentSubunitId, saveAllTableData } =
    useTable();

  const [isRedTextActive, setIsRedTextActive] = useState(false);

  // 1. 상태 추가
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // 상태 추가
  const [isRedTextMode, setIsRedTextMode] = useState(true);

  // 폰트 드롭다운 ref 추가
  const fontDropdownRef = useRef(null);
  const fontButtonRef = useRef(null);

  // 외부 클릭 감지를 위한 useEffect 추가
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showFontDropdown &&
        fontDropdownRef.current &&
        !fontDropdownRef.current.contains(event.target) &&
        !fontButtonRef.current.contains(event.target)
      ) {
        setShowFontDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFontDropdown]);

  // Firebase에서 폰트 설정을 가져오는 함수
  const loadFontSetting = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      const fontRef = doc(
        db,
        `users/${user.uid}/courses/${courseIdFromContext}/fonts`,
        "settings"
      );
      const fontDoc = await getDoc(fontRef);

      if (fontDoc.exists()) {
        const savedFont = fontDoc.data().fontFamily;
        setFont(savedFont);
        setInitialFont(savedFont);
        applyFont(savedFont);
      } else {
        // 저장된 폰트 설정이 없을 경우 기본값 저장
        await saveFontSetting(DEFAULT_FONT);
        setFont(DEFAULT_FONT);
        setInitialFont(DEFAULT_FONT);
        applyFont(DEFAULT_FONT);
      }
    } catch (error) {
      console.error("폰트 설정을 불러오는 중 오류 발생:", error);
      // 오류 발생 시에도 기본 폰트 적
      setFont(DEFAULT_FONT);
      setInitialFont(DEFAULT_FONT);
      applyFont(DEFAULT_FONT);
    }
  };

  // 폰트 설정을 Firebase에 저장하는 함수
  const saveFontSetting = async (newFont) => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      const fontRef = doc(
        db,
        `users/${user.uid}/courses/${courseIdFromContext}/fonts`,
        "settings"
      );
      await setDoc(fontRef, {
        fontFamily: newFont,
        updatedAt: new Date().toISOString(),
      });
    } catch (error) {}
  };

  useEffect(() => {
    setFont(initialFont);
    applyFont(initialFont);
  }, []);

  const applyFont = (newFont) => {
    // CSS 변수 설정
    document.documentElement.style.setProperty("--editor-font-family", newFont);

    // AnotherComponentVer2의 모든 요소에 폰트 적용
    const anotherComponents = document.querySelectorAll(
      ".another-table-wrapper, .another-quill-editor, .another-quill-editor .ql-editor"
    );
    anotherComponents.forEach((element) => {
      element.style.setProperty("font-family", newFont, "important");
    });

    // 개별 Quill 에디터에 폰트 적용
    const editors = document.querySelectorAll(".another-quill-editor");
    editors.forEach((editor) => {
      const quillInstance = editor.__quill;
      if (quillInstance) {
        quillInstance.root.style.setProperty(
          "font-family",
          newFont,
          "important"
        );
      }
    });

    const mainContent = document.querySelector(".main-content");
    if (mainContent) {
      mainContent.style.fontFamily = newFont;
    }

    const unitName = document.querySelector(".unit-name");
    if (unitName) {
      unitName.style.fontFamily = newFont;
    }

    const inputs = document.querySelectorAll(".resizable-table input");
    inputs.forEach((input) => {
      input.style.fontFamily = newFont;
    });

    const textBoxes = document.querySelectorAll(".movable-textbox");
    textBoxes.forEach((textBox) => {
      textBox.style.fontFamily = newFont;
    });

    // AnotherComponentVer2의 Quill 에디터에 폰트 적용
    const anotherQuillEditors = document.querySelectorAll(
      ".another-quill-editor .ql-editor"
    );
    anotherQuillEditors.forEach((editor) => {
      editor.style.fontFamily = newFont;
    });

    // AnotherComponentVer2의 테이블 전체에도 폰트 적용
    const anotherTable = document.querySelector(".another-table-wrapper table");
    if (anotherTable) {
      anotherTable.style.fontFamily = newFont;
    }
  };

  // 기존 handleFontChange 함수 수정
  const handleFontChange = async (event) => {
    const newFont = event.target.value;
    setFont(newFont);
    applyFont(newFont);

    // 텍스트박스 폰트 업데이트
    const updatedTextBoxes = initialTextBoxes.map((textBox) => ({
      ...textBox,
      style: {
        ...textBox.style,
        fontFamily: newFont,
      },
    }));

    onTextBoxDataChange(updatedTextBoxes);

    // Firebase에 폰트 설정 저장
    await saveFontSetting(newFont);
  };

  const colors = [
    "#000000",
    "#757575",
    "#F24722",
    "#FFA629",
    "#FFCD29",
    "#15AE5C",
    "#0D99FF",
    "#9747FF",
    "#FFFFFF",
  ];

  const textColors = [
    { color: "#000000", icon: "/toolbaricons/textcolor-black.svg" },
    { color: "#757575", icon: "/toolbaricons/textcolor-grey.svg" },
    { color: "#F24722", icon: "/toolbaricons/textcolor-red.svg" },
    { color: "#FFA629", icon: "/toolbaricons/textcolor-orange.svg" },
    { color: "#FFCD29", icon: "/toolbaricons/textcolor-yellow.svg" },
    { color: "#15AE5C", icon: "/toolbaricons/textcolor-green.svg" },
    { color: "#0D99FF", icon: "/toolbaricons/textcolor-blue.svg" },
    { color: "#9747FF", icon: "/toolbaricons/textcolor-purple.svg" },
    { color: "#505BA7", icon: "/toolbaricons/textcolor-navy.svg" },
  ];

  useEffect(() => {
    fonts.forEach((font) => {
      if (font.url) {
        const fontFace = new FontFace(font.name, `url(${font.url})`);
        fontFace
          .load()
          .then((loadedFont) => {
            document.fonts.add(loadedFont);
            setFont((prevFont) => prevFont);
          })
          .catch((error) => {});
      }
    });
  }, [fonts]);

  const handleColorChange = useCallback(
    (color) => {
      setPenColor(color);
      onPenColorChange(color);
    },
    [onPenColorChange]
  );

  const exportToPDF = () => {
    const mainContent = document.querySelector(".main-content");
    if (mainContent) {
      setScale(1);

      const elementsToHide = mainContent.querySelectorAll(
        ".youtube-video, .stamp, .ui-element, .context-menu, .button"
      );
      elementsToHide.forEach((el) => {
        el.style.display = "none";
      });

      setTimeout(() => {
        html2canvas(mainContent, {
          scale: 2,
          useCORS: true,
          logging: false,
          windowWidth: document.documentElement.clientWidth,
          windowHeight: document.documentElement.clientHeight,
          ignoreElements: (element) => {
            return (
              element.classList.contains("ignore-in-pdf") ||
              element.id === "ignore-in-pdf"
            );
          },
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");

          // A4 크기 정확히 맞추기 (단위: mm)
          const pdfWidth = 210; // A4 너비
          const pdfHeight = 297; // A4 높이

          const pdf = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: [pdfWidth, pdfHeight],
            compress: true,
            hotfixes: ["px_scaling"],
          });

          // 이미지를 페이지 전체에 맞추기
          const imgProps = pdf.getImageProperties(imgData);
          const ratio = Math.min(
            pdfWidth / imgProps.width,
            pdfHeight / imgProps.height
          );

          const imgWidth = imgProps.width * ratio;
          const imgHeight = imgProps.height * ratio;

          // 이미지를 페이지 중앙에 배치
          const x = (pdfWidth - imgWidth) / 2;
          const y = (pdfHeight - imgHeight) / 2;

          pdf.addImage(
            imgData,
            "PNG",
            x,
            y,
            imgWidth,
            imgHeight,
            undefined,
            "FAST"
          );

          // PDF 프로퍼티 설정
          pdf.setProperties({
            title: "Document",
            subject: "Print-optimized document",
            creator: "Your Application",
            keywords: "pdf, print, document",
            printScaling: "none",
          });

          // 페이지 크기 및 여백 설정을 위한 뷰어 설정
          pdf.viewerPreferences({
            FitWindow: true,
            PrintScaling: "None",
            PrintPageRange: [[1, 1]],
            NumCopies: 1,
          });

          pdf.save("main-content.pdf");

          elementsToHide.forEach((el) => {
            el.style.display = "";
          });
        });
      }, 100);
    }
  };

  const handleTextColorChange = (color) => {
    setTextColor(color);
    setShowTextColorOptions(false);
  };

  // 메모이제이션된 핸들러들을 컴포넌트 레벨에서 선언
  const handleDrag = useCallback(
    (e, newTextBox, isDragging, startX, startY, mainContent) => {
      if (isDragging) {
        const rect = mainContent.getBoundingClientRect();
        const scaleX = mainContent.offsetWidth / rect.width;
        const scaleY = mainContent.offsetHeight / rect.height;
        const newX = (e.clientX - rect.left) * scaleX - startX;
        const newY = (e.clientY - rect.top) * scaleY - startY;
        newTextBox.style.left = `${newX}px`;
        newTextBox.style.top = `${newY}px`;
      }
    },
    []
  );

  const handleAddTextBox = (color, existingData = null) => {
    // 텍스트박스를 추가한 후 텍스트 색상 옵션 메뉴를 닫습니다
    setShowTextColorOptions(false);

    const mainContent = document.querySelector(".main-content");
    if (!mainContent) {
      return;
    }

    const rect = mainContent.getBoundingClientRect();
    // 스크롤 위치를 고려하여 중앙 위치 계산
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const viewportHeight = window.innerHeight;

    // 뷰포트의 상단에서 약간 아래로 위치하도록 조정
    const centerY = scrollTop + viewportHeight * 0.3; // 뷰포트 높이의 30% 위치
    const centerX = rect.width / 2;

    const newTextBox = document.createElement("div");
    const textBoxId = existingData?.id || `textbox-${Date.now()}`;
    newTextBox.dataset.id = textBoxId;

    // 텍스트 내용을 위한 span 요소 생성
    const textContent = document.createElement("span");
    textContent.className = "textbox-text";
    textContent.contentEditable = true;
    textContent.spellcheck = false;
    textContent.textContent = existingData
      ? existingData.text
      : "Enter text here";
    textContent.style.pointerEvents = "none";
    textContent.style.cursor = "text"; // 텍스트 커서 스타일 추가

    // 위치 설정
    newTextBox.style.left = `${centerX}px`;
    newTextBox.style.top = `${centerY}px`;

    // 삭제 버튼 추가
    const deleteButton = document.createElement("button");
    deleteButton.className = "delete-textbox";
    const deleteIcon = document.createElement("img");
    deleteIcon.src = "/toolbaricons/inuse/cancel.svg";
    deleteIcon.alt = "삭제";
    deleteButton.appendChild(deleteIcon);

    // 삭제 버튼 이벤트 리스너
    deleteButton.addEventListener("click", (e) => {
      e.stopPropagation();
      mainContent.removeChild(newTextBox);

      const remainingTextBoxes = Array.from(
        document.querySelectorAll(".movable-textbox")
      ).map((box) => ({
        id: box.dataset.id,
        text: box.textContent,
        style: {
          left: box.style.left,
          top: box.style.top,
          fontFamily: box.style.fontFamily,
          fontSize: box.style.fontSize,
          color: box.style.color,
        },
      }));

      onTextBoxDataChange(remainingTextBoxes);
    });

    // 기본 스타일 설정 (이전과 동일)
    const defaultStyle = {
      position: "absolute",
      left: `${centerX}px`,
      top: `${centerY}px`,
      fontSize: "12pt",
      fontFamily: font,
      color: color || "#e0e0e0",
      padding: "10px",
      minWidth: "100px",
      backgroundColor: "transparent",
      border: "1px solid transparent",
      zIndex: "100",
    };

    if (existingData) {
      Object.assign(newTextBox.style, {
        left: existingData.style.left,
        top: existingData.style.top,
        fontFamily: existingData.style.fontFamily,
        fontSize: "12pt",
        color: existingData.style.color,
      });
    } else {
      Object.assign(newTextBox.style, defaultStyle);
    }

    // hover 시 삭제 버튼 표시
    newTextBox.addEventListener("mouseenter", () => {
      deleteButton.style.display = "block";
    });

    newTextBox.addEventListener("mouseleave", () => {
      deleteButton.style.display = "none";
    });

    newTextBox.appendChild(textContent);
    newTextBox.className = "movable-textbox";

    // 여기에 더블클릭 이벤트 리스너 추가
    // 여기에 더블클릭 이벤트 리스너 추가
    newTextBox.addEventListener("dblclick", (e) => {
      if (e.target === newTextBox) {
        // newTextBox를 더블클릭했을 때만
        textContent.style.pointerEvents = "auto";
        textContent.focus();
        newTextBox.style.border = "2px solid yellow";
      }
    });

    // 여기에 blur 이벤트 리스너 추가
    textContent.addEventListener("blur", () => {
      textContent.style.pointerEvents = "none";
      newTextBox.style.border = "none";
    });
    // 텍스트박스 이벤트 리스너
    let isDragging = false;
    let startX, startY, initialX, initialY;

    newTextBox.addEventListener("mousedown", (e) => {
      if (e.target === newTextBox) {
        isDragging = true;
        startX = e.clientX;
        startY = e.clientY;
        initialX = newTextBox.offsetLeft;
        initialY = newTextBox.offsetTop;
        newTextBox.style.border = "1px dashed #666"; // 이동 모드 테두리 추가
      }
    });

    newTextBox.addEventListener("input", () => {
      // 텍스트박스 데이터 수 및 저장
      const textBoxes = Array.from(
        document.querySelectorAll(".movable-textbox")
      ).map((box) => ({
        id: box.dataset.id,
        text: box.textContent,
        style: {
          left: box.style.left,
          top: box.style.top,
          fontFamily: box.style.fontFamily,
          fontSize: box.style.fontSize,
          color: box.style.color,
        },
      }));

      onTextBoxDataChange(textBoxes);
    });

    document.addEventListener("mousemove", (e) => {
      if (isDragging) {
        const dx = e.clientX - startX;
        const dy = e.clientY - startY;
        newTextBox.style.left = `${initialX + dx}px`;
        newTextBox.style.top = `${initialY + dy}px`;
      }
    });

    document.addEventListener("mouseup", () => {
      if (isDragging) {
        isDragging = false;
        newTextBox.style.border = "none"; // 드래그 끝나면 테두리 제거
        // 위치 변경 후 데이터 저장
        const textBoxes = Array.from(
          document.querySelectorAll(".movable-textbox")
        ).map((box) => ({
          id: box.dataset.id,
          text: box.textContent,
          style: {
            left: box.style.left,
            top: box.style.top,
            fontFamily: box.style.fontFamily,
            fontSize: box.style.fontSize,
            color: box.style.color,
          },
        }));

        onTextBoxDataChange(textBoxes);
      }
    });

    newTextBox.appendChild(deleteButton);
    mainContent.appendChild(newTextBox);

    // 새 텍스트박스 추가 후 데이터 저장
    const textBoxes = Array.from(
      document.querySelectorAll(".movable-textbox")
    ).map((box) => ({
      id: box.dataset.id,
      text: box.textContent.replace("×", "").trim(), // 삭제 버튼 텍스트 제거
      style: {
        left: box.style.left,
        top: box.style.top,
        fontFamily: box.style.fontFamily,
        fontSize: box.style.fontSize,
        color: box.style.color,
      },
    }));

    onTextBoxDataChange(textBoxes);
  };

  // 디바운스 유틸리티 함수
  const debounce = (fn, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn.apply(this, args), delay);
    };
  };

  // 전역 이벤트 리스너 최적화
  useEffect(() => {
    const memoizedMouseMove = debounce((e) => {
      if (isStampMode && selectedStampId) {
        const mainContent = document.querySelector(".main-content");
        if (mainContent) {
          const rect = mainContent.getBoundingClientRect();
          const scaleX = mainContent.offsetWidth / rect.width;
          const scaleY = mainContent.offsetHeight / rect.height;
          setStampCursorPosition({
            x: e.clientX,
            y: e.clientY,
            scale: Math.min(scaleX, scaleY),
          });
        }
      }
    }, 16); // 약 60fps

    const memoizedKeyDown = (e) => {
      if (e.key === "Escape") {
        resetAllModes();
      }
    };

    window.addEventListener("mousemove", memoizedMouseMove);
    document.addEventListener("keydown", memoizedKeyDown);

    return () => {
      window.removeEventListener("mousemove", memoizedMouseMove);
      document.removeEventListener("keydown", memoizedKeyDown);
    };
  }, [isStampMode, selectedStampId]);

  // 모든 모드 초기화 함수
  const resetAllModes = useCallback(() => {
    setActiveMode(null);
    setIsDrawingMode(false);
    setIsPenMode(false);
    setIsEraserMode(false);
    setIsStampMode(false);
    setIsReviewModeActive(false);
    setStartPoint(null);
    document.body.classList.remove("drawing-mode");
    setShowStampOptions(false);
    setShowReviewModeOptions(false);
    setShowHighlightColors(false);
  }, []);

  const handleBackClick = () => {
    navigate(`/courses/${courseIdFromContext}/curriculum/${courseName}`);
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isStampMode && selectedStampId) {
        const mainContent = document.querySelector(".main-content");
        if (mainContent) {
          const rect = mainContent.getBoundingClientRect();
          const scaleX = mainContent.offsetWidth / rect.width;
          const scaleY = mainContent.offsetHeight / rect.height;
          setStampCursorPosition({
            x: e.clientX,
            y: e.clientY,
            scale: Math.min(scaleX, scaleY),
          });
        }
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isStampMode, selectedStampId]);

  const closeAllPopups = (exceptReviewMode = false) => {
    if (!exceptReviewMode) {
      setActivePopup(null);
    }
    setIsStampMode(false);
    setShowStampOptions(false);
    if (!exceptReviewMode) {
      setShowReviewModeOptions(false);
    }
    setShowFontDropdown(false);
  };

  // activeMode 상태 확인 함수 수정
  const isButtonDisabled = (buttonMode) => {
    // 현재 활성화된 모드가 있고, 그것이 현재 버튼의 모드가 아 경우 비활성화
    return activeMode !== null && activeMode !== buttonMode;
  };

  // 모드 변경 핸들러 수정
  const handleModeChange = (mode) => {
    // 같은 버튼을 다시 클릭하면 모드 해제
    if (activeMode === mode) {
      setActiveMode(null);
      // 모든 모드 상태 초기화
      setIsPenMode(false);
      setIsEraserMode(false);

      setIsStampMode(false);
      setIsReviewModeActive(false);
      document.body.classList.remove("drawing-mode");

      // 모드 해제 시 커서 초기화
      document.body.style.cursor = "default";

      // 콜백 함수 호출하여 모드 해제 알림
      if (mode === "eraser") onEraserModeChange(false);
      if (mode === "pen") onPenModeChange(false);
    } else {
      // 다른 버튼 클릭시 새로운 모드로 변경
      setActiveMode(mode);
      // 해당하는 모드만 활성화하고 나머지는 비활성화
      setIsPenMode(mode === "pen");
      setIsEraserMode(mode === "eraser");

      setIsStampMode(mode === "stamp");
      setIsReviewModeActive(mode === "review");

      // 콜백 함수 호출하여 모드 활성화 알림
      if (mode === "eraser") onEraserModeChange(true);
      if (mode === "pen") onPenModeChange(true);
    }

    // 모든 옵션 패널 닫기

    setShowTextColorOptions(false);
    setShowStampOptions(false);
  };

  const handleEraserClick = () => {
    const newEraserMode = !isEraserMode;
    setIsEraserMode(newEraserMode);
    setIsPenMode(false);
    setActiveMode(newEraserMode ? "eraser" : null);
    onEraserModeChange(newEraserMode);
    updateEraserCursor(newEraserMode ? eraserSize : null);
  };

  const handleEraserSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    setEraserSize(newSize);
    onEraserSizeChange(newSize);
    if (isEraserMode) {
      updateEraserCursor(newSize);
    }
  };

  const updateEraserCursor = (size) => {
    if (size && isEraserMode) {
      // isEraserMode 조건 추가
      const cursorSize = size + 2;
      document.body.style.cursor = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${cursorSize}" height="${cursorSize}" viewBox="0 0 ${cursorSize} ${cursorSize}"><circle cx="${
        cursorSize / 2
      }" cy="${cursorSize / 2}" r="${
        size / 2
      }" fill="rgba(255,255,255,0.5)" stroke="black" stroke-width="1"/></svg>') ${
        cursorSize / 2
      } ${cursorSize / 2}, auto`;
    } else {
      document.body.style.cursor = "default"; // 모드가 해제되면 커서를 기본값으로
    }
  };
  const handlePenClick = () => {
    const newPenMode = !isPenMode;
    setIsPenMode(newPenMode);
    setActiveMode(newPenMode ? "pen" : null);

    if (!newPenMode) {
      if (onPenModeChange) onPenModeChange(newPenMode, true);
    } else {
      if (onPenModeChange) onPenModeChange(newPenMode, false);
    }

    if (!newPenMode) {
      document.body.classList.remove("drawing-mode");
      document.body.style.cursor = "default";
    } else {
      document.body.classList.add("drawing-mode");
      document.body.style.cursor = `url(${process.env.PUBLIC_URL}/assets/icons/cursor-pen.svg) 1 1, auto`;
    }
  };
  const handleReviewModeClick = () => {
    if (isReviewModeActive) {
      setIsReviewModeActive(false);
      setIsSelectMode(false);
      document.body.classList.remove("select-mode-active");

      window.dispatchEvent(new Event("exitReviewMode"));

      window.dispatchEvent(
        new CustomEvent("selectMode", {
          detail: { active: false },
        })
      );
      setIsShowingRowByRow(false);
      setIsShowingRowByRowCol2(false);
    } else {
      setIsReviewModeActive(true);
    }
  };

  const handleStampClick = () => {
    handleModeChange("stamp");
  };

  const handleStampOptionClick = (stampId) => {
    setSelectedStampId(stampId);
  };

  useEffect(() => {
    const handleStampInsert = (e) => {
      if (isStampMode && selectedStampId) {
        const mainContent = document.querySelector(".main-content");
        if (mainContent) {
          const rect = mainContent.getBoundingClientRect();
          const scaleX = mainContent.offsetWidth / rect.width;
          const scaleY = mainContent.offsetHeight / rect.height;
          const x = (e.clientX - rect.left) * scaleX;
          const y = (e.clientY - rect.top) * scaleY;

          const stampId = `stamp-${Date.now()}`;
          const stamp = document.createElement("img");
          stamp.src = `/img/stamp/stamp${selectedStampId}.svg`;
          stamp.className = "stamp";
          stamp.dataset.stampId = stampId;
          stamp.style.position = "absolute";
          stamp.style.left = `${x}px`;
          stamp.style.top = `${y}px`;
          stamp.style.width = "100px";
          stamp.style.height = "100px";
          stamp.style.zIndex = "1000";
          stamp.style.transform = "translate(-50%, -50%)";
          stamp.style.pointerEvents = "auto";
          stamp.style.cursor = "pointer";

          mainContent.appendChild(stamp);

          // Firebase에 스탬프 데이터 저장
          onStampAdd({
            id: stampId,
            stampId: selectedStampId,
            position: { x, y },
          });

          setIsStampMode(false);
          setSelectedStampId(null);
          setActiveMode(null);
          document.body.style.cursor = "default";
        }
      }
    };

    if (isStampMode && selectedStampId) {
      document.addEventListener("click", handleStampInsert);
    }

    return () => {
      document.removeEventListener("click", handleStampInsert);
    };
  }, [isStampMode, selectedStampId]);

  const handleToggleFirstColumnClick = () => {
    const event = new CustomEvent("toggleFirstColumn", {
      detail: { timestamp: Date.now() },
    });
    window.dispatchEvent(event);
  };

  const handleToggleSecondColumnClick = () => {
    const event = new CustomEvent("toggleSecondColumn");
    window.dispatchEvent(event);
  };

  const handleToggleShowRowByRow = () => {
    const newState = !isShowingRowByRow;
    setIsShowingRowByRow(newState);
    setHighlightNextLow1(newState);

    const event = new CustomEvent("toggleShowRowByRow", {
      detail: {
        isActive: newState,
        hideAll: newState,
        showAll: !newState,
      },
    });

    window.dispatchEvent(event);
  };

  const handleShowNextRow = useCallback(
    (event) => {
      if (!event || !event.detail || !event.detail.columnId || !data) return;

      const { columnId } = event.detail;
      const allEditors = [];

      data.forEach((row, rowIndex) => {
        const editors = row[columnId] || [];
        editors.forEach((_, editorIndex) => {
          if (hasEditorContent(rowIndex, columnId, editorIndex)) {
            allEditors.push(`${rowIndex}-${columnId}-${editorIndex}`);
          }
        });
      });

      const currentVisible = visibleEditors[columnId] || [];
      const lastVisibleIndex = Math.max(
        -1,
        ...currentVisible.map((key) => allEditors.indexOf(key))
      );

      if (lastVisibleIndex < allEditors.length - 1) {
        const nextEditor = allEditors[lastVisibleIndex + 1];
        setVisibleEditors((prev) => ({
          ...prev,
          [columnId]: [...(prev[columnId] || []), nextEditor],
        }));
      }
    },
    [data, hasEditorContent, visibleEditors]
  );

  // useEffect에서 이벤트 리스너 등록
  useEffect(() => {
    window.addEventListener("showNextRow", handleShowNextRow);

    return () => {
      window.removeEventListener("showNextRow", handleShowNextRow);
    };
  }, [handleShowNextRow]);

  // 버튼 클릭 핸들러
  const handleNextLowClick = (columnId) => {
    const event = new CustomEvent("showNextRow", {
      detail: { columnId }, // 전달받은 columnId 사용
    });
    window.dispatchEvent(event);
  };

  const handleToggleShowRowByRowCol2Click = () => {
    const newState = !isShowingRowByRowCol2;
    setIsShowingRowByRowCol2(newState);
    setHighlightNextLow2(newState);

    // Hide Rows 버튼을 누를 때는 모든 에디터를 가리고
    // Show Rows 버튼을 누를 때는 모든 에디터를 보이게 함
    const event = new CustomEvent("toggleShowRowByRowCol2", {
      detail: {
        isActive: newState,
        hideAll: newState, // true면 가리기, false면 보이기
        showAll: !newState, // true면 모두 보이기
      },
    });
    window.dispatchEvent(event);
  };

  const handleShowNextRowCol2 = () => {
    const allEditors = [];

    data.forEach((row, rowIndex) => {
      const editors = row.col2 || [];
      editors.forEach((_, editorIndex) => {
        if (hasEditorContent(rowIndex, "col2", editorIndex)) {
          allEditors.push(`${rowIndex}-col2-${editorIndex}`);
        }
      });
    });

    const lastVisibleIndex = Math.max(
      -1,
      ...visibleEditors.col2.map((key) => allEditors.indexOf(key))
    );

    if (lastVisibleIndex < allEditors.length - 1) {
      const nextEditor = allEditors[lastVisibleIndex + 1];
      setVisibleEditors((prev) => ({
        ...prev,
        col2: [...prev.col2, nextEditor],
      }));
    }
  };

  // 플레이어 밖 클릭 처리를 위한 useCallback 훅
  const handleClickOutside = useCallback(
    (e) => {
      if (
        selectedTarget &&
        !document.getElementById(selectedTarget)?.contains(e.target)
      ) {
        setSelectedTarget(null);
      }
    },
    [selectedTarget]
  );

  // 이벤트 리스너 등록 및 해제
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  // ESC 키 벤트 처리를 위 useEffect 수정
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        // 모든 모드 해제
        setActiveMode(null);
        setIsDrawingMode(false);

        setIsPenMode(false);
        setIsEraserMode(false);
        setIsStampMode(false);
        setIsReviewModeActive(false);

        // 시작점과 미리보기 라인 초기화
        setStartPoint(null);

        // drawing-mode 클래스 제거
        document.body.classList.remove("drawing-mode");

        // 든 옵션 메뉴 닫기

        setShowStampOptions(false);
        setShowReviewModeOptions(false);
        setShowHighlightColors(false);
        // 필요한 경우 다른 메뉴들도 여기에 추가

        // 커스텀 이벤트 발생 (필요한 경우)
        window.dispatchEvent(
          new CustomEvent("drawingModeChange", {
            detail: { isDrawingMode: false },
          })
        );
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // 의존성 배열을 비워 마운트 시에만 이벤 리스너를 추가하도록 함

  // 활성 상태 스타일
  const activeButtonStyle = {
    backgroundColor: "#e0e0e0",
    boxShadow: "inset 0 0 5px rgba(0,0,0,0.2)",
  };

  const highlightStyle = {
    backgroundColor: "#ffff99",
    boxShadow: "0 0 5px rgba(0,0,0,0.3)",
    transition: "all 0.3s ease",
  };

  // 폰트 버튼 클릭 핸들러 수정
  const handleFontButtonClick = (event) => {
    const buttonRect = event.currentTarget.getBoundingClientRect();
    setFontDropdownPosition({
      top: buttonRect.bottom,
      left: buttonRect.left,
    });
    closeAllPopups();
    setShowFontDropdown(!showFontDropdown);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      setShowFontDropdown(false);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // handleButtonClick 함수 추
  const handleButtonClick = (callback) => {
    if (activeMode === null) {
      callback();
    }
  };
  const handleSplitColumnClick = () => {
    console.log("Split button clicked in toolbar");
    handleButtonClick(() => {
      console.log("Dispatching splitColumn event");
      const event = new CustomEvent("splitColumn");
      window.dispatchEvent(event);
    });
  };
  const handleRemoveSplitClick = () => {
    handleButtonClick(() => {
      setIsRemovingSplit(true); // 항상 true로 설정

      const event = new CustomEvent("removeSplit", {
        detail: {
          isActive: true, // 항상 true로 설정
          onSplitRemoved: () => {
            setIsRemovingSplit(false);
            const resetEvent = new CustomEvent("resetRemoveSplitButton");
            window.dispatchEvent(resetEvent);
          },
        },
      });
      window.dispatchEvent(event);
    });
  };

  const saveStateToHistory = () => {
    const mainContent = document.querySelector(".main-content");
    if (mainContent) {
      const state = mainContent.innerHTML;
      setHistory((prevHistory) => [
        ...prevHistory.slice(0, historyIndex + 1),
        state,
      ]);
      setHistoryIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePenSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    setPenSize(newSize);
    onPenSizeChange(newSize);
  };

  useEffect(() => {
    const mainContent = document.querySelector(".main-content");
    if (!mainContent || !initialTextBoxes?.length) return;

    // 이미 존재하는 텍스트박스는 다시 생성하지 않음
    const existingTextBoxIds = Array.from(
      mainContent.querySelectorAll(".movable-textbox")
    ).map((box) => box.dataset.id);

    // 새로운 텍스트박스만 생성
    initialTextBoxes.forEach((textBoxData) => {
      if (!existingTextBoxIds.includes(textBoxData.id)) {
        handleAddTextBox(textBoxData.style.color, textBoxData);
      }
    });
  }, [initialTextBoxes]);

  const updateTextBoxData = (textBoxId, newStyles) => {
    const updatedTextBoxes = initialTextBoxes.map((textBox) => {
      if (textBox.id === textBoxId) {
        return {
          ...textBox,
          style: {
            ...textBox.style,
            ...newStyles,
          },
        };
      }
      return textBox;
    });
    onTextBoxDataChange(updatedTextBoxes);
  };

  const handleDeleteTextBox = (textBoxId) => {
    // DOM에서 텍스트박스 제거
    const textBox = document.querySelector(`[data-id="${textBoxId}"]`);
    if (textBox) {
      textBox.remove();
    }

    // 현재 DOM에 있 모든 텍스트박스 정보를 수
    const allTextBoxes = Array.from(
      document.querySelectorAll(".movable-textbox")
    ).map((box) => ({
      id: box.dataset.id,
      text: box.textContent,
      style: {
        fontFamily: box.style.fontFamily,
        fontSize: box.style.fontSize,
        color: box.style.color,
        left: box.style.left,
        top: box.style.top,
      },
    }));

    // Firebase 업데트를 위해 부모 컴포넌트에 알림
    onTextBoxDataChange(allTextBoxes);
  };

  useEffect(() => {
    const mainContent = document.querySelector(".main-content");
    if (mainContent && initialStamps?.length > 0) {
      // 기존 스탬프 제거
      const existingStamps = mainContent.querySelectorAll(".stamp");
      existingStamps.forEach((stamp) => stamp.remove());

      // 새로운 스탬프 렌더링
      initialStamps.forEach((stampData) => {
        const stamp = document.createElement("img");
        stamp.src = `/img/stamp/stamp${stampData.stampId}.svg`;
        stamp.className = "stamp";
        stamp.style.position = "absolute";
        stamp.style.left = `${stampData.position.x}px`;
        stamp.style.top = `${stampData.position.y}px`;
        stamp.style.width = "100px";
        stamp.style.height = "100px";
        stamp.style.zIndex = "1000";
        stamp.style.transform = "translate(-50%, -50%)";
        stamp.style.pointerEvents = "none";
        stamp.style.cursor = "pointer";

        mainContent.appendChild(stamp);
      });
    }
  }, [initialStamps]);

  // 컴포넌트 마운트 시 폰트 설정 로드
  useEffect(() => {
    loadFontSetting();
  }, [courseIdFromContext]); // courseId가 변경될 때마다 폰트 설정을 다시 로드

  // useEffect를 추가하여 isEraserMode 상태 변경 감지
  useEffect(() => {
    if (!isEraserMode) {
      document.body.style.cursor = "default";
    } else if (eraserSize) {
      updateEraserCursor(eraserSize);
    }
  }, [isEraserMode, eraserSize]);

  // Exit 모드 함수 추가
  const handleExitReviewMode = () => {
    // 리뷰 모드 관련 모든 상태 초기화
    setIsReviewModeActive(false);
    setShowReviewModeOptions(false);
    setIsShowingRowByRow(false);
    setIsShowingRowByRowCol2(false);
    setHighlightNextLow1(false);
    setHighlightNextLow2(false);
    setActiveMode(null);

    // 커스텀 이벤트 발생
    const exitEvent = new CustomEvent("exitReviewMode", {
      detail: { complete: true },
    });
    window.dispatchEvent(exitEvent);

    // 모든 열 표시 이벤트 발생
    const showAllEvent = new CustomEvent("showAllColumns");
    window.dispatchEvent(showAllEvent);
  };

  const loadFonts = async () => {
    try {
      const fontLoadPromises = fonts
        .filter((font) => font.url) // url이 있는 폰트만 필터링
        .map(async (font) => {
          try {
            // WOFF 먼저 시도
            const fontFace = new FontFace(font.name, `url(${font.url})`);
            await fontFace.load();
            document.fonts.add(fontFace);
          } catch (error) {
            // WOFF 실패시 TTF 시도
            try {
              const fallbackFontFace = new FontFace(
                font.name,
                `url(${font.fallbackUrl})`
              );
              await fallbackFontFace.load();
              document.fonts.add(fallbackFontFace);
            } catch (fallbackError) {}
          }
        });

      await Promise.all(fontLoadPromises);
    } catch (error) {}
  };

  useEffect(() => {
    loadFonts();
  }, []);

  // 스탬프 데이터 로드
  useEffect(() => {
    const loadStamps = async () => {
      try {
        if (!auth.currentUser || !courseId || !currentSubunit?.id) {
          return;
        }

        const stampRef = doc(
          db,
          "users",
          auth.currentUser.uid,
          "courses",
          courseId,
          "curriculum",
          "stamps",
          "subunits",
          currentSubunit.id
        );

        const stampDoc = await getDoc(stampRef);
        const stampData = stampDoc.exists() ? stampDoc.data().data || [] : [];

        onStampLoad(stampData); // 부모 컴포넌트로 데이터 전달
      } catch (error) {
        console.error("Failed to load stamps:", error);
      }
    };

    loadStamps();
  }, [courseId, currentSubunit?.id, onStampLoad]);

  const handleWhiteTextClick = () => {
    // 현재 활성화된 모든 빨간색 텍스트 스타일을 흰색으로 토글
    const event = new CustomEvent("toggleRedToWhite", {
      detail: { color: "#FFFFFF" },
    });
    window.dispatchEvent(event);
  };
  // 토글 핸들러
  const handleRedTextToggle = () => {
    setIsRedTextMode(!isRedTextMode);
    // CustomEvent 방식으로 변경
    window.dispatchEvent(new CustomEvent("toggleRedText"));
  };

  // layout7, layout8일 때는 2열 버튼을 숨기기 위한 조건
  const showSecondColumnButtons = layout !== "layout7" && layout !== "layout8";

  // 빈 행 삽입 핸들러 추가
  const handleInsertEmptyRow = () => {
    const event = new Event("insertEmptyRow");
    window.dispatchEvent(event);
  };

  const saveButtonRef = useRef(null);

  const [isSelectMode, setIsSelectMode] = useState(false);

  // Select Mode 버튼 클릭 핸들러
  const handleSelectModeClick = () => {
    const newSelectMode = !isSelectMode; // 상태 변경값 미리 저장
    setIsSelectMode(newSelectMode);

    // Select Mode 토글 시 body에 클래스 추가/제거
    if (newSelectMode) {
      // 새로운 상태값으로 체크
      document.body.classList.add("select-mode-active");
    } else {
      document.body.classList.remove("select-mode-active");
    }

    window.dispatchEvent(
      new CustomEvent("selectMode", {
        detail: { active: newSelectMode },
      })
    );
  };

  return (
    <>
      <div className="toolbar" ref={toolbarRef}>
        <div className="toolbar-left">
          <button
            onClick={handleBackClick}
            className="back-button"
            disabled={activeMode !== null}
          >
            <img src="/toolbaricons/inuse/back.svg" alt="Back" />
            <span>Curriculum</span>
          </button>
        </div>
        <div className="toolbar-center">
          <div className="toolbar-tooltip">
            <button
              onClick={() => setShowTextColorOptions(!showTextColorOptions)}
              disabled={activeMode !== null}
            >
              <img src="/toolbaricons/inuse/textbox.svg" alt="Add Text Box" />
            </button>
            <span className="toolbar-tooltiptext">Add Text Box</span>
          </div>
          {showTextColorOptions && (
            <div className="text-color-options">
              {textColors.map(({ color, icon }) => (
                <button
                  key={color}
                  style={{
                    width: "30px",
                    height: "30px",
                    margin: "0 5px",
                    padding: 0,
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => handleAddTextBox(color)}
                >
                  <img
                    src={icon}
                    alt={`Text Color: ${color}`}
                    style={{ width: "100%", height: "100%" }}
                  />
                </button>
              ))}
            </div>
          )}
          <div className="toolbar-tooltip">
            <button
              onClick={handleSplitColumnClick}
              disabled={activeMode !== null}
            >
              <img src={devideIcon} alt="Split Column" />
            </button>
            <span className="toolbar-tooltiptext">Ctrl + K</span>
          </div>
          <div className="toolbar-tooltip">
            <button
              onClick={handleRemoveSplitClick}
              disabled={isButtonDisabled("removeSplit")}
            >
              <img src={devidecancleIcon} alt="Remove Split" />
            </button>
            <span className="toolbar-tooltiptext">Remove Split</span>
          </div>
          <div className="toolbar-tooltip">
            <button
              className="toolbar-button"
              onClick={handleInsertEmptyRow}
              disabled={activeMode !== null}
            >
              <img
                src="/toolbaricons/inuse/insert-row.svg"
                alt="Insert Empty Row"
                className="toolbar-icon"
              />
            </button>
            <span className="toolbar-tooltiptext">Add Row</span>
          </div>
          <div className="toolbar-tooltip">
            <button
              onClick={handleReviewModeClick}
              className={`review-mode-button ${
                isReviewModeActive ? "active" : ""
              }`}
              disabled={isButtonDisabled("review")}
            >
              <img src={reviewModeIcon} alt="Mode" />
              <span>{isReviewModeActive ? "Exit Mode" : "Review Mode"}</span>
            </button>
            <span className="toolbar-tooltiptext">Review Mode</span>
          </div>
          {isReviewModeActive && (
            <div className="review-mode-options">
              {/* Select Mode 버튼 */}
              <button
                onClick={handleSelectModeClick}
                className={isSelectMode ? "active" : ""}
              >
                <img
                  src="/toolbaricons/inuse/select-mode.svg"
                  alt="Select Mode"
                />
                <span>{isSelectMode ? "Exit" : "Select Mode"}</span>
              </button>

              {/* 기존 1열 Hide rows 버튼 */}
              {!isShowingRowByRow && (
                <button onClick={handleToggleShowRowByRow}>
                  <img src={each1Icon} alt="Enable row view (1st col)" />
                  <span>Hide rows (1st col)</span>
                </button>
              )}

              {/* 기존 1열 Next low 버튼 */}
              {isShowingRowByRow && (
                <button
                  onClick={() => handleNextLowClick("col1")}
                  style={{
                    border: "2px solid #FFD700",
                    boxShadow: "0 0 10px rgba(255, 215, 0, 0.5)",
                    animation: "pulse 1.5s infinite",
                  }}
                >
                  <img src={nextlowIcon} alt="Next" />
                  <span>Next low (1st col)</span>
                </button>
              )}

              {/* 기존 2열 버튼들 */}
              {showSecondColumnButtons && !isShowingRowByRowCol2 && (
                <button onClick={handleToggleShowRowByRowCol2Click}>
                  <img src={each2Icon} alt="Enable row view (2nd col)" />
                  <span>Hide rows (2nd col)</span>
                </button>
              )}

              {showSecondColumnButtons && isShowingRowByRowCol2 && (
                <button
                  onClick={() => handleNextLowClick("col2")}
                  style={{
                    border: "2px solid #FFD700",
                    boxShadow: "0 0 10px rgba(255, 215, 0, 0.5)",
                    animation: "pulse 1.5s infinite",
                  }}
                >
                  <img src={nextlowIcon} alt="Next low (2nd col)" />
                  <span>Next low (2nd col)</span>
                </button>
              )}
            </div>
          )}
          <div className="toolbar-tooltip">
            <button
              onClick={handleStampClick}
              disabled={activeMode !== null && activeMode !== "stamp"}
            >
              <img src={stampIcon} alt="Label" />
            </button>
            <span className="toolbar-tooltiptext">Label</span>
          </div>
          {activeMode === "stamp" && (
            <div className="stamp-options">
              {stampNames.map((name, index) => (
                <button
                  key={index + 1}
                  onClick={() => handleStampOptionClick(`${index + 1}`)}
                >
                  <img
                    src={`/img/stamp/stamp${index + 1}.svg`}
                    alt={`Label ${name}`}
                  />
                </button>
              ))}
            </div>
          )}
          <div className="font-selector">
            <div className="toolbar-tooltip">
              <button
                onClick={handleFontButtonClick}
                disabled={activeMode !== null}
                ref={fontButtonRef}
              >
                <span style={{ fontFamily: font }}>{font}</span>
                <img
                  src="/toolbaricons/inuse/dropdown-arrow.svg"
                  alt="Select Font"
                />
              </button>
              <span className="toolbar-tooltiptext">Select Font</span>
            </div>
            {showFontDropdown && (
              <div
                className="font-dropdown"
                style={{
                  position: "fixed",
                  top: `${fontDropdownPosition.top}px`,
                  left: `${fontDropdownPosition.left}px`,
                }}
                ref={fontDropdownRef}
              >
                {fonts.map((fontOption) => (
                  <button
                    key={fontOption.name}
                    onClick={() => {
                      handleFontChange({ target: { value: fontOption.name } });
                      setShowFontDropdown(false);
                    }}
                    style={{ fontFamily: fontOption.name }}
                  >
                    {fontOption.name}
                  </button>
                ))}
              </div>
            )}
          </div>

          <div className="drawing-buttons">
            <div className="toolbar-tooltip">
              <button
                onClick={() => handleModeChange("pen")}
                disabled={isButtonDisabled("pen")}
                className={activeMode === "pen" ? "active" : ""}
              >
                <img src="/toolbaricons/inuse/pen.svg" alt="Pen" />
              </button>
              <span className="toolbar-tooltiptext">Pen</span>
            </div>
            {activeMode === "pen" && (
              <div className="pen-options">
                <div className="pen-size-control">
                  <input
                    type="range"
                    min="2"
                    max="40"
                    value={penSize}
                    onChange={handlePenSizeChange}
                  />
                  <div
                    className="pen-size-preview"
                    style={{
                      width: `${penSize}px`,
                      height: `${penSize}px`,
                      backgroundColor: penColor,
                      borderRadius: "50%",
                      border: "1px solid #000",
                      marginLeft: "10px",
                    }}
                  ></div>
                </div>
                <div className="color-options">
                  {colors.map((color) => (
                    <button
                      key={color}
                      style={{
                        backgroundColor: color,
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        margin: "0 5px",
                        border: penColor === color ? "2px solid black" : "none",
                        padding: 0,
                        cursor: "pointer",
                        display: "inline-block",
                        verticalAlign: "middle",
                        boxShadow:
                          color === "#FFFFFF" ? "0 0 0 1px #000000" : "none",
                      }}
                      onClick={() => handleColorChange(color)}
                    />
                  ))}
                </div>
              </div>
            )}
            <div className="toolbar-tooltip">
              <button
                onClick={() => handleModeChange("eraser")}
                disabled={isButtonDisabled("eraser")}
                className={activeMode === "eraser" ? "active" : ""}
              >
                <img src="/toolbaricons/inuse/eraser.svg" alt="Eraser" />
              </button>
              <span className="toolbar-tooltiptext">Eraser</span>
            </div>
            {activeMode === "eraser" && (
              <div className="eraser-size-control">
                <input
                  type="range"
                  min="1"
                  max="50"
                  value={eraserSize}
                  onChange={handleEraserSizeChange}
                />
              </div>
            )}
          </div>
          <div className="toolbar-tooltip">
            <button
              className={`toolbar-button ${!isRedTextMode ? "active" : ""}`}
              onClick={handleRedTextToggle}
              disabled={activeMode !== null}
            >
              <img
                src="/toolbaricons/inuse/eraser.svg"
                alt="text color toggle"
                className="toolbar-icon"
              />
              <span className="toolbar-text">
                {isRedTextMode ? "Hide " : "Show "}
                <span className="red-text">Texts</span>
              </span>
            </button>
            <span className="toolbar-tooltiptext">
              {isRedTextMode ? "Hide " : "Show "}
              <span className="red-text">Texts</span>
            </span>
          </div>
          <div className="toolbar-tooltip">
            <button
              className="toolbar-button"
              onClick={() => {
                window.dispatchEvent(new CustomEvent("copySelectedCells"));
              }}
              disabled={activeMode !== null}
            >
              <span>Copy</span>
            </button>
            <span className="toolbar-tooltiptext">Copy</span>
          </div>
          <div className="toolbar-tooltip">
            <button
              className="toolbar-button"
              onClick={() => {
                window.dispatchEvent(new CustomEvent("pasteCopiedCells"));
              }}
              disabled={activeMode !== null}
            >
              <span>Paste</span>
            </button>
            <span className="toolbar-tooltiptext">Paste</span>
          </div>
          <div className="toolbar-tooltip">
            <button
              ref={saveButtonRef}
              disabled={activeMode !== null}
              onClick={() => {
                console.log("🔍 디버그: 저장 버튼 클릭 이벤트 발생"); // 가장 먼저 확인
                if (saveButtonRef.current) {
                  try {
                    console.log("💾 저장 시작");
                    saveButtonRef.current.classList.add("save-pulse");

                    window.dispatchEvent(new CustomEvent("saveAllData"));
                    console.log("📤 saveAllData 이벤트 발생됨");

                    // 저장 상태를 세션 스토리지에 기록
                    const currentTime = Date.now();
                    sessionStorage.setItem("lastSaved", currentTime.toString());
                    console.log(
                      "⏱️ 저장 시간 기록:",
                      new Date(currentTime).toLocaleTimeString()
                    );

                    setTimeout(() => {
                      if (saveButtonRef.current) {
                        saveButtonRef.current.classList.remove("save-pulse");
                        console.log("✨ 저장 완료");
                      }
                    }, 300);
                  } catch (error) {
                    console.error("❌ 저장 중 오류 발생:", error);
                  }
                }
              }}
            >
              <img src="/toolbaricons/inuse/save.svg" alt="Save" />
            </button>
            <span className="toolbar-tooltiptext">Save</span>
          </div>
        </div>
      </div>
      {moveableTargets.map((target) => (
        <Moveable
          key={target}
          target={`#${target}`}
          container={null}
          draggable={true}
          resizable={true}
          rotatable={true}
          onDragStart={() => setSelectedTarget(target)}
          onResizeStart={() => setSelectedTarget(target)}
          onRotateStart={() => setSelectedTarget(target)}
          onDrag={(e) => {
            e.target.style.transform = e.transform;
          }}
          onDragEnd={(e) => {
            e.target.style.transform = e.transform;
          }}
          onResize={(e) => {
            e.target.style.width = `${e.width}px`;
            e.target.style.height = `${e.height}px`;
            e.target.style.transform = e.drag.transform;
          }}
          onRotate={(e) => {
            e.target.style.transform = e.drag.transform;
          }}
          onRotateEnd={(e) => {
            e.target.style.transform = e.transform;
          }}
          {...(selectedTarget === target
            ? {}
            : { draggable: false, resizable: false, rotatable: false })}
        />
      ))}
      <style>
        {`
          .moveable-control.moveable-origin {
            display: none !important;
          }
          .review-mode-options button {
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .review-mode-options button img {
            width: 24px;
            height: 24px;
          }
          .review-mode-options button span {
            font-size: 14px;
          }
          .review-mode-button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
          }
          .review-mode-button img {
            width: 24px;
            height: 24px;
          }
          .review-mode-button span {
            font-size: 14px;
          }
          .font-selector {
            position: relative;
            z-index: 1000;
          }

          .font-selector > button {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 5px 10px;
            background-color: #f0f0f0;
            border: 1px solid #ccc;
            border-radius: 4px;
            cursor: pointer;
          }

          .font-dropdown {
            width: 200px;
            max-height: 300px;
            overflow-y: auto;
            background-color: #1a1a1a;
            border: 1px solid #333;
            border-radius: 4px;
            box-shadow: 0 2px 5px rgba(0,0,0,0.3);
            z-index: 9999;
          }

          .font-dropdown button {
            display: block;
            width: 100%;
            padding: 8px 12px;
            text-align: left;
            border: none;
            background: none;
            color: #ffffff;
            cursor: pointer;
          }

          .font-dropdown button:hover {
            background-color: #333333;
          }

      
        
          .drawing-mode {
            cursor: url("../assets/icons/cursor-pen.svg") 1 1, auto !important;
          }
          .pen-options {
            display: flex;
            align-items: center;
            
            border-radius: 5px;
            padding: 5px;
            margin-left: 10px;
          }
          .pen-size-control {
            display: flex;
            align-items: center;
            margin-right: 10px;
          }
          .pen-size-control input[type="range"] {
            width: 100px;
            margin-right: 10px;
          }
          .pen-size-preview {
            
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .color-options {
            display: flex;
            align-items: center;
          }
       
       
          .main-content {
            position: relative;
            overflow: visible !important;
          }

          .stamp {
            position: absolute;
            z-index: 1000;
            pointer-events: none;
            cursor: default;
          }

        

          .text-color-options button {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin: 0 5px;
            border: none;
            cursor: pointer;
            transition: transform 0.2s ease;
          }

          .text-color-options button:hover {
            transform: scale(1.1);
          }

        
        
          
          .toolbar-tooltip {
            position: relative;
            display: inline-block;
          }

          .toolbar-tooltip .toolbar-tooltiptext {
            visibility: hidden;
            width: 120px;
            background-color: #555;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;
            position: absolute;
            z-index: 9999;
            bottom: 125%;
            left: 50%;
            margin-left: -60px;
            opacity: 0;
            transition: opacity 0.3s;
            font-size: 12px;
          }

          .toolbar-tooltip:hover .toolbar-tooltiptext {
            visibility: visible;
            opacity: 1;
          }

          @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.05); }
            100% { transform: scale(1); }
          }

          /* 저장 버튼 애니메이션 */
          @keyframes savePulse {
            0% {
              transform: scale(1);
              opacity: 1;
            }
            50% {
              transform: scale(0.95);
              opacity: 0.8;
            }
            100% {
              transform: scale(1);
              opacity: 1;
            }
          }

          .save-pulse {
            animation: savePulse 0.3s ease-in-out;
          }
        `}
      </style>
      {isStampMode && selectedStampId && (
        <img
          src={`/img/stamp/stamp${selectedStampId}.svg`}
          alt="Stamp Cursor"
          style={{
            position: "fixed",
            left: stampCursorPosition.x,
            top: stampCursorPosition.y,
            width: `${100 / stampCursorPosition.scale}px`,
            height: `${100 / stampCursorPosition.scale}px`,
            pointerEvents: "none",
            zIndex: 9999,
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </>
  );
};

export default Toolbar;
