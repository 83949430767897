import React, { useEffect } from "react";

const ContextMenu = ({ x, y, onClose, menuItems }) => {
  useEffect(() => {
    const handleClick = () => onClose();
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, [onClose]);

  return (
    <div
      className="leftbar-contextmenu"
      style={{
        position: "fixed",
        left: x,
        top: y,
        zIndex: 1000,
        background: "#2c2c2c",
        border: "1px solid #404040",
        borderRadius: "4px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
        padding: "4px 0",
        minWidth: "120px",
      }}
    >
      {menuItems.map((item, index) => (
        <div
          key={index}
          className="leftbar-contextmenu__item"
          style={{
            padding: "10px 12px",
            cursor: "pointer",
            fontSize: "13px",
            color: "#b0b0b0",
            transition: "all 0.2s ease",
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = "var(--dark-surface)";
            e.target.style.color = "var(--accent-yellow)";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "transparent";
            e.target.style.color = "#b0b0b0";
          }}
          onClick={item.onClick}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default ContextMenu;
