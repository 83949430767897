// src/components/FlyoutMenu1.js
import React, { useState, useEffect, useCallback } from "react";
import "../css/FlyoutMenu1.css";
import { useLayout } from "../contexts/LayoutContext";

const FlyoutMenu1 = ({ onClose, onSelectLayout }) => {
  const layoutContext = useLayout();
  const setLayout = layoutContext ? layoutContext.setLayout : () => {};
  const [selectedLayout, setSelectedLayout] = useState(null);
  const [showWarning, setShowWarning] = useState(false);

  const handleSelect = useCallback((layout) => {
    setSelectedLayout(layout);
    if (["layout7", "layout8"].includes(layout)) {
      setShowWarning(true);
    } else {
      applyLayout(layout);
    }
  }, []);

  const applyLayout = useCallback(
    (layout) => {
      setLayout(layout);
      onSelectLayout(layout);
      setShowWarning(false);
      setSelectedLayout(null);
    },
    [setLayout, onSelectLayout]
  );

  useEffect(() => {
    return () => {
      setSelectedLayout(null);
      setShowWarning(false);
    };
  }, []);

  return (
    <div className="flyout-panel flyout-menu1">
      <div className="flyout-header">
        <h3>Layout</h3>
        <button className="close-button" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
          </svg>
        </button>
      </div>
      <div className="flyout-content">
        <div className="layout-buttons">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((num) => (
            <button
              key={num}
              className="layout-button"
              onClick={() => handleSelect(`layout${num}`)}
            >
              <img
                src={`/toolbaricons/layout/layout${num}.svg`}
                alt={`Window Layout ${num}`}
                className="icon"
              />
            </button>
          ))}
        </div>
        {showWarning && (
          <div className="flyout-warning">
            <p>
              Changing the layout may delete your current content. Do you want
              to continue?
            </p>
            <button
              className="flyout-warning-confirm"
              onClick={() => applyLayout(selectedLayout)}
            >
              Confirm
            </button>
            <button
              className="flyout-warning-cancel"
              onClick={() => setShowWarning(false)}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FlyoutMenu1;
