import React, { useState, useEffect, useRef } from "react";
import "../css/ToDoList.css";
import { useTasks } from "../contexts/TaskContext";
import { db } from "../firebase/firebase"; // Firebase 설정 파일 경로
import { doc, setDoc, onSnapshot, getDoc } from "firebase/firestore";
import { auth } from "../firebase/firebase"; // Firebase 인증 모듈
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { onAuthStateChanged } from "firebase/auth";

const ToDoList = ({ isSidebarMinimized, setIsSidebarMinimized }) => {
  const { taskLists, setTaskLists } = useTasks();
  const [newListName, setNewListName] = useState("");
  const [newTasks, setNewTasks] = useState({}); // 리스트별 입력값을 저장할 객체
  const [activeList, setActiveList] = useState(null);
  const [editingListIndex, setEditingListIndex] = useState(null);
  const [editingTaskIndices, setEditingTaskIndices] = useState({});
  const [activeMenu, setActiveMenu] = useState(null);
  const menuRef = useRef(null);
  const [openLists, setOpenLists] = useState([]); // 열린 리스트 상태 관리를 위한 배열

  const todoDocId = "myTodoList"; // 문서 ID를 지정

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        // 로그아웃 시 taskLists 초기화
        setTaskLists([]);
      }
    });

    return () => unsubscribe();
  }, [setTaskLists]);

  useEffect(() => {
    let unsubscribeAuth = null;
    let unsubscribeSnapshot = null;

    const initializeFirebase = async (user) => {
      if (!user) {
        setTaskLists([]); // 사용자가 없을 때도 초기화
        return;
      }

      const docRef = doc(db, `users/${user.uid}/todolist/${todoDocId}`);

      try {
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.taskLists) {
            setTaskLists(data.taskLists);
          }
        } else {
          setTaskLists([{ name: "Today", tasks: [] }]); // 새 사용자의 경우 기본 리스트 생성
        }

        // 5. 실시간 리스너 설정 (연결 유지)
        unsubscribeSnapshot = onSnapshot(
          docRef,
          (snapshot) => {
            if (snapshot.exists()) {
              const data = snapshot.data();
              if (data.taskLists) {
                setTaskLists((prevTasks) => {
                  return JSON.stringify(prevTasks) !==
                    JSON.stringify(data.taskLists)
                    ? data.taskLists
                    : prevTasks;
                });
              }
            }
          },
          (error) => {
            // 에러 발생해도 로컬 데이터 유지
          }
        );
      } catch (error) {
        console.error("Error initializing Firebase:", error);
        setTaskLists([]); // 에러 발생 시 초기화
      }
    };

    unsubscribeAuth = onAuthStateChanged(auth, initializeFirebase);

    return () => {
      if (unsubscribeAuth) unsubscribeAuth();
      if (unsubscribeSnapshot) unsubscribeSnapshot();
    };
  }, []);

  useEffect(() => {
    let timeoutId;

    const saveTaskLists = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const docRef = doc(db, `users/${user.uid}/todolist/${todoDocId}`);
      try {
        await setDoc(docRef, { taskLists }, { merge: true });
      } catch (error) {
        // Firebase 저장 실패 시 로컬 데이터 유지
      }
    };

    // 디바운스 처리로 잦은 저장 방지
    clearTimeout(timeoutId);
    timeoutId = setTimeout(saveTaskLists, 500);

    return () => clearTimeout(timeoutId);
  }, [taskLists]);

  const addTaskList = () => {
    if (newListName.trim()) {
      const newList = { name: newListName, tasks: [] };
      setTaskLists([...taskLists, newList]);
      setNewListName("");
      setActiveList(taskLists.length);
    }
  };

  const addTask = (listIndex) => {
    const taskText = newTasks[listIndex] || "";
    if (taskText.trim()) {
      const updatedLists = [...taskLists];
      const list = updatedLists[listIndex];

      // Today 리스트인 경우에만 우선순위 추가
      if (list.name === "Today") {
        const nextPriority = list.tasks.length + 1;
        updatedLists[listIndex].tasks.push({
          text: taskText,
          completed: false,
          priority: nextPriority,
        });
      } else {
        updatedLists[listIndex].tasks.push({
          text: taskText,
          completed: false,
        });
      }
      setTaskLists(updatedLists);
      // 해당 리스트의 입력값만 초기화
      setNewTasks((prev) => ({
        ...prev,
        [listIndex]: "",
      }));
    }
  };

  const toggleTask = (listIndex, taskIndex) => {
    const updatedLists = [...taskLists];
    updatedLists[listIndex].tasks[taskIndex].completed =
      !updatedLists[listIndex].tasks[taskIndex].completed;
    setTaskLists(updatedLists);
  };

  const toggleList = (index) => {
    setOpenLists((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const handleKeyPress = (event, listIndex) => {
    if (event.key === "Enter") {
      addTask(listIndex);
    }
  };

  const editListName = (listIndex, newName) => {
    const updatedLists = [...taskLists];
    updatedLists[listIndex].name = newName;
    setTaskLists(updatedLists);
  };

  const editTask = (listIndex, taskIndex, newText) => {
    const updatedLists = [...taskLists];
    updatedLists[listIndex].tasks[taskIndex].text = newText;
    setTaskLists(updatedLists);
    setEditingTaskIndices({}); // 편집 모드 종료
  };

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [listToDelete, setListToDelete] = useState(null);

  const deleteList = (listIndex) => {
    setListToDelete(listIndex);
    setShowConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    const updatedLists = taskLists.filter((_, index) => index !== listToDelete);
    setTaskLists(updatedLists);
    setShowConfirmDialog(false);
  };

  const deleteTask = (listIndex, taskIndex) => {
    const updatedLists = [...taskLists];
    updatedLists[listIndex].tasks.splice(taskIndex, 1);
    setTaskLists(updatedLists);
  };

  const toggleMenu = (index, event) => {
    event.stopPropagation();
    setActiveMenu(activeMenu === index ? null : index);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const duplicateList = (listIndex) => {
    const listToDuplicate = taskLists[listIndex];
    const newList = {
      ...listToDuplicate,
      name: `${listToDuplicate.name} (복사)`,
    };
    setTaskLists([...taskLists, newList]);
  };

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    listIndex: null,
    taskIndex: null,
  });

  const handleContextMenu = (e, listIndex, taskIndex) => {
    e.preventDefault();
    setContextMenu({
      visible: true,
      x: e.clientX,
      y: e.clientY,
      listIndex,
      taskIndex,
    });
  };

  const handleDeleteTask = () => {
    if (contextMenu.listIndex !== null && contextMenu.taskIndex !== null) {
      const updatedLists = [...taskLists];
      updatedLists[contextMenu.listIndex].tasks.splice(
        contextMenu.taskIndex,
        1
      );
      setTaskLists(updatedLists);
    }
    setContextMenu({
      visible: false,
      x: 0,
      y: 0,
      listIndex: null,
      taskIndex: null,
    });
  };

  useEffect(() => {
    const handleClick = () =>
      setContextMenu({
        visible: false,
        x: 0,
        y: 0,
        listIndex: null,
        taskIndex: null,
      });
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, []);

  const deleteCheckedTasks = (listIndex) => {
    const updatedLists = [...taskLists];
    updatedLists[listIndex].tasks = updatedLists[listIndex].tasks.filter(
      (task) => !task.completed
    );
    setTaskLists(updatedLists);
    setActiveMenu(null);
  };

  // 리스트 추가를 위한 키 이벤트 핸들러 추가
  const handleListKeyPress = (event) => {
    if (event.key === "Enter") {
      addTaskList();
    }
  };

  // 드래그 시작할 때 호출되는 핸들러
  const handleDragStart = (result) => {
    document.body.classList.add("dragging");
  };

  // 드래그 중에 호출되는 핸들러
  const handleDragUpdate = (result) => {};

  // 드래그 종료 시 호출되는 핸들러
  const handleDragEnd = (result) => {
    const { source, destination, type } = result;

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    if (type === "list") {
      const newLists = Array.from(taskLists);
      const [removed] = newLists.splice(source.index, 1);
      newLists.splice(destination.index, 0, removed);
      setTaskLists(newLists);
      return;
    }

    const sourceListId = parseInt(source.droppableId.split("-")[1]);
    const destListId = parseInt(destination.droppableId.split("-")[1]);

    const newTaskLists = JSON.parse(JSON.stringify(taskLists));

    if (sourceListId === destListId) {
      const list = newTaskLists[sourceListId];
      const [removed] = list.tasks.splice(source.index, 1);
      list.tasks.splice(destination.index, 0, removed);
    } else {
      const sourceList = newTaskLists[sourceListId];
      const destList = newTaskLists[destListId];
      const [removed] = sourceList.tasks.splice(source.index, 1);
      destList.tasks.splice(destination.index, 0, removed);
      // 대상 리스트 자동으로 열기
      setOpenLists((prev) => {
        if (!prev.includes(destListId)) {
          return [...prev, destListId];
        }
        return prev;
      });
    }

    setTaskLists(newTaskLists);
  };

  // Today 리스트 초기화를 위한 useEffect 추가
  useEffect(() => {
    if (taskLists.length === 0) {
      setTaskLists([{ name: "Today", tasks: [] }]);
    }
  }, []);

  // Today 리스트로 이동하는 함수 수정
  const moveTaskToToday = () => {
    if (contextMenu.listIndex === null || contextMenu.taskIndex === null)
      return;

    const updatedLists = [...taskLists];
    const sourceList = updatedLists[contextMenu.listIndex];
    const taskToMove = { ...sourceList.tasks[contextMenu.taskIndex] };

    let todayListIndex = updatedLists.findIndex(
      (list) => list.name === "Today"
    );

    if (todayListIndex === -1) {
      updatedLists.unshift({ name: "Today", tasks: [] });
      todayListIndex = 0;
    }

    const isDuplicate = updatedLists[todayListIndex].tasks.some(
      (task) => task.text === taskToMove.text
    );

    if (!isDuplicate) {
      // Today 리스트로 이동 시 우선순위 추가
      taskToMove.priority = updatedLists[todayListIndex].tasks.length + 1;
      updatedLists[todayListIndex].tasks.push(taskToMove);

      if (contextMenu.listIndex !== todayListIndex) {
        updatedLists[contextMenu.listIndex].tasks.splice(
          contextMenu.taskIndex,
          1
        );
      }

      // 우선순위 재정렬
      updatedLists[todayListIndex].tasks = updatedLists[
        todayListIndex
      ].tasks.map((task, index) => ({
        ...task,
        priority: index + 1,
      }));

      setOpenLists((prev) => {
        if (!prev.includes(todayListIndex)) {
          return [...prev, todayListIndex];
        }
        return prev;
      });

      setTaskLists(updatedLists);
    }

    setContextMenu({
      visible: false,
      x: 0,
      y: 0,
      listIndex: null,
      taskIndex: null,
    });
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setIsSidebarMinimized(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setIsSidebarMinimized]);

  return (
    <div
      className={`todo-container ${isSidebarMinimized ? "sidebar-mode" : ""}`}
    >
      <h1 className="todo-header">To do list</h1>

      <div className={`list-form ${isSidebarMinimized ? "sidebar-form" : ""}`}>
        <input
          type="text"
          value={newListName}
          onChange={(e) => setNewListName(e.target.value)}
          onKeyPress={handleListKeyPress}
          placeholder="New list name"
          className={isSidebarMinimized ? "sidebar-input" : ""}
        />
        <button
          onClick={addTaskList}
          className={isSidebarMinimized ? "sidebar-button" : ""}
          title="Add List"
        >
          <img src="/toolbaricons/inuse/add.svg" alt="Add" />
          {!isSidebarMinimized && "Add List"}
        </button>
      </div>

      <DragDropContext
        onDragStart={handleDragStart}
        onDragUpdate={handleDragUpdate}
        onDragEnd={handleDragEnd}
      >
        <Droppable droppableId="lists" type="list">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="lists-container"
            >
              {taskLists.map((list, listIndex) => (
                <Draggable
                  key={`list-${listIndex}`}
                  draggableId={`list-${listIndex}`}
                  index={listIndex}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className="task-list"
                    >
                      <div
                        className="task-list-header"
                        {...provided.dragHandleProps}
                      >
                        {editingListIndex === listIndex ? (
                          <input
                            type="text"
                            value={list.name}
                            onChange={(e) => {
                              const updatedLists = [...taskLists];
                              updatedLists[listIndex].name = e.target.value;
                              setTaskLists(updatedLists);
                            }}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                setEditingListIndex(null);
                              }
                            }}
                            onBlur={() => setEditingListIndex(null)}
                            autoFocus
                          />
                        ) : (
                          <h2
                            onDoubleClick={() => setEditingListIndex(listIndex)}
                          >
                            {list.name}
                          </h2>
                        )}
                        <div className="header-buttons">
                          <span onClick={() => toggleList(listIndex)}>
                            {openLists.includes(listIndex) ? "▼" : "▶"}
                          </span>
                          <img
                            src="/toolbaricons/inuse/ellipsis-vertical.svg"
                            alt="메뉴"
                            className="todo-menu-icon"
                            onClick={(e) => toggleMenu(listIndex, e)}
                          />
                          {activeMenu === listIndex && (
                            <div className="list-menu" ref={menuRef}>
                              <button
                                onClick={() => deleteList(listIndex)}
                                className="todo-list-menu-button"
                              >
                                <img
                                  src="/toolbaricons/inuse/menu.svg"
                                  alt="Delete List"
                                />
                                Delete List
                              </button>
                              <button
                                onClick={() => deleteCheckedTasks(listIndex)}
                                className="todo-list-menu-button"
                              >
                                <img
                                  src="/toolbaricons/inuse/checkbox-active.svg"
                                  alt="Delete Checked Tasks"
                                />
                                Delete Checked Tasks
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <Droppable droppableId={`tasks-${listIndex}`} type="task">
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={`task-list-content ${
                              openLists.includes(listIndex) ? "active" : ""
                            } ${snapshot.isDraggingOver ? "drag-over" : ""}`}
                          >
                            {openLists.includes(listIndex) && (
                              <>
                                <ul>
                                  {list.tasks.map((task, taskIndex) => (
                                    <Draggable
                                      key={`task-${listIndex}-${taskIndex}`}
                                      draggableId={`task-${listIndex}-${taskIndex}`}
                                      index={taskIndex}
                                      isDragDisabled={
                                        editingTaskIndices[
                                          `${listIndex}-${taskIndex}`
                                        ]
                                      }
                                    >
                                      {(provided) => (
                                        <li
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...(editingTaskIndices[
                                            `${listIndex}-${taskIndex}`
                                          ]
                                            ? {}
                                            : provided.dragHandleProps)}
                                          className={`${
                                            task.completed ? "completed" : ""
                                          } ${
                                            editingTaskIndices[
                                              `${listIndex}-${taskIndex}`
                                            ]
                                              ? "editing"
                                              : ""
                                          }`}
                                          onContextMenu={(e) =>
                                            handleContextMenu(
                                              e,
                                              listIndex,
                                              taskIndex
                                            )
                                          }
                                        >
                                          {list.name === "Today" && (
                                            <span className="task-number">
                                              {taskIndex + 1}.
                                            </span>
                                          )}
                                          <input
                                            type="checkbox"
                                            checked={task.completed}
                                            onChange={() =>
                                              toggleTask(listIndex, taskIndex)
                                            }
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                          {editingTaskIndices[
                                            `${listIndex}-${taskIndex}`
                                          ] ? (
                                            <input
                                              type="text"
                                              value={task.text}
                                              className="task-edit-input"
                                              onChange={(e) => {
                                                const updatedLists = [
                                                  ...taskLists,
                                                ];
                                                updatedLists[listIndex].tasks[
                                                  taskIndex
                                                ].text = e.target.value;
                                                setTaskLists(updatedLists);
                                              }}
                                              onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                  editTask(
                                                    listIndex,
                                                    taskIndex,
                                                    task.text
                                                  );
                                                }
                                              }}
                                              onBlur={() =>
                                                editTask(
                                                  listIndex,
                                                  taskIndex,
                                                  task.text
                                                )
                                              }
                                              autoFocus
                                              onClick={(e) =>
                                                e.stopPropagation()
                                              }
                                            />
                                          ) : (
                                            <span
                                              onDoubleClick={() => {
                                                setEditingTaskIndices({
                                                  ...editingTaskIndices,
                                                  [`${listIndex}-${taskIndex}`]: true,
                                                });
                                              }}
                                            >
                                              {task.text}
                                            </span>
                                          )}
                                        </li>
                                      )}
                                    </Draggable>
                                  ))}
                                </ul>
                                {provided.placeholder}
                                <div className="task-form-container">
                                  <div className="task-form">
                                    <input
                                      type="text"
                                      value={newTasks[listIndex] || ""}
                                      onChange={(e) =>
                                        setNewTasks((prev) => ({
                                          ...prev,
                                          [listIndex]: e.target.value,
                                        }))
                                      }
                                      onKeyPress={(e) =>
                                        handleKeyPress(e, listIndex)
                                      }
                                      placeholder="Add new task"
                                    />
                                    <button
                                      onClick={() => addTask(listIndex)}
                                      title="태스크 추가"
                                    >
                                      <img
                                        src="/toolbaricons/inuse/add.svg"
                                        alt="Add"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {contextMenu.visible && (
        <div
          className="task-context-menu"
          style={{ top: `${contextMenu.y}px`, left: `${contextMenu.x}px` }}
        >
          <button onClick={moveTaskToToday}>Today</button>
          <button
            onClick={() => {
              deleteTask(contextMenu.listIndex, contextMenu.taskIndex);
              setContextMenu({
                visible: false,
                x: 0,
                y: 0,
                listIndex: null,
                taskIndex: null,
              });
            }}
          >
            Delete
          </button>
        </div>
      )}

      {showConfirmDialog && (
        <div className="custom-confirm-overlay">
          <div className="custom-confirm-dialog">
            <div className="custom-confirm-message">
              Are you sure you want to delete this list? All tasks within this
              list will be permanently deleted.
            </div>
            <div className="custom-confirm-buttons">
              <button
                className="custom-confirm-button custom-confirm-cancel"
                onClick={() => setShowConfirmDialog(false)}
              >
                Cancel
              </button>
              <button
                className="custom-confirm-button custom-confirm-delete"
                onClick={handleConfirmDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ToDoList;
