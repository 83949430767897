import React, { useEffect, useState } from "react";
import { auth } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import logo from "../assets/img/logo.svg";
import "../css/EmailVerificationPage.css";
import { sendEmailVerification } from "firebase/auth";

const VerificationPage = () => {
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const sendInitialVerificationEmail = async () => {
      const user = auth.currentUser;
      if (user && !user.emailVerified && !emailSent) {
        try {
          await sendEmailVerification(user, {
            url: window.location.origin + "/email-verified",
            handleCodeInApp: true,
          });
          setEmailSent(true);
          setError("");

          // Delete unverified account after 24 hours
          setTimeout(async () => {
            const currentUser = auth.currentUser;
            if (currentUser && !currentUser.emailVerified) {
              await currentUser.delete();
            }
          }, 24 * 60 * 60 * 1000);
        } catch (error) {
          if (error.code === "auth/too-many-requests") {
            setEmailSent(true);
            setError(
              "Please check your spam folder. Email might have been already sent."
            );
          } else {
            setError("Failed to send verification email. Please try again.");
          }
        }
      }
    };

    if (!emailSent) {
      setTimeout(sendInitialVerificationEmail, 3000);
    }

    const checkVerification = async () => {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (user) {
          await user.reload();

          if (user.emailVerified) {
            navigate("/email-verified");
          }
        }
      });

      return () => unsubscribe();
    };

    const intervalId = setInterval(checkVerification, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [navigate, emailSent]);

  return (
    <div className="email-verify-page">
      <div className="email-verify-content">
        <img src={logo} alt="RedoNote Logo" className="email-verify-logo" />
        <h1 className="email-verify-title">Email Verification Required</h1>
        {error ? (
          <p className="email-verify-error">{error}</p>
        ) : (
          <>
            <p className="email-verify-text">
              {emailSent
                ? "Verification email has been sent. Please check your inbox and spam folder."
                : "Checking email..."}
            </p>
            {emailSent && (
              <p className="email-verify-warning">
                ⚠️ Your account will be automatically deleted if not verified
                within 24 hours. Service access is restricted until verification
                is complete.
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default VerificationPage;
