import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/FlyoutMenu5.css";
import { db, auth } from "../firebase/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

const FlyoutMenu5 = ({ onClose, courseId }) => {
  const navigate = useNavigate();
  const [decks, setDecks] = useState([]);
  const [currentDeck, setCurrentDeck] = useState(null);
  const [deckInput, setDeckInput] = useState("");
  const [questionInput, setQuestionInput] = useState("");
  const [answerInput, setAnswerInput] = useState("");
  const [currentCard, setCurrentCard] = useState(0);
  const [hoveredDeck, setHoveredDeck] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadFlashcards = async () => {
      setIsLoading(true);
      try {
        const user = auth.currentUser;
        if (!user || !courseId) return;

        const flashcardsRef = doc(
          db,
          "users",
          user.uid,
          "courses",
          courseId,
          "flashcards",
          "list"
        );

        const docSnap = await getDoc(flashcardsRef);
        if (docSnap.exists()) {
          setDecks(docSnap.data().decks || []);
        }
      } catch (error) {
        console.error("Error loading flashcards:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadFlashcards();
  }, [courseId]);

  const saveToFirebase = async (updatedDecks) => {
    try {
      const user = auth.currentUser;
      if (!user || !courseId) return;

      const flashcardsRef = doc(
        db,
        "users",
        user.uid,
        "courses",
        courseId,
        "flashcards",
        "list"
      );

      await setDoc(
        flashcardsRef,
        {
          decks: updatedDecks,
          updatedAt: new Date().toISOString(),
        },
        { merge: true }
      );
    } catch (error) {
      console.error("Error saving flashcards:", error);
    }
  };

  const handleAddDeck = () => {
    if (deckInput.trim()) {
      const newDecks = [...decks, { name: deckInput, cards: [] }];
      setDecks(newDecks);
      setDeckInput("");
      saveToFirebase(newDecks);
    }
  };

  const handleAddQA = () => {
    if (currentDeck !== null && questionInput.trim() && answerInput.trim()) {
      const updatedDecks = [...decks];
      updatedDecks[currentDeck].cards.push({
        question: questionInput,
        answer: answerInput,
        flipped: false,
      });
      setDecks(updatedDecks);
      setQuestionInput("");
      setAnswerInput("");
      saveToFirebase(updatedDecks);
    }
  };

  const toggleAnswer = (cardIndex) => {
    const updatedDecks = [...decks];
    const card = updatedDecks[currentDeck].cards[cardIndex];
    card.flipped = !card.flipped;
    setDecks(updatedDecks);
  };

  const handleQuestionKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("answer-input").focus();
    }
  };

  const handlePaste = (e, setter) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text");
    setter(pastedText);
  };

  const handleKeyDown = (e) => {
    if (e.ctrlKey && e.key === "c") {
      e.preventDefault();
      document.execCommand("copy");
    } else if (e.ctrlKey && e.key === "v") {
      e.preventDefault();
      document.execCommand("paste");
    }
  };

  const nextCard = () => {
    if (
      currentDeck !== null &&
      currentCard < decks[currentDeck].cards.length - 1
    ) {
      setCurrentCard(currentCard + 1);
    }
  };

  const prevCard = () => {
    if (currentDeck !== null && currentCard > 0) {
      setCurrentCard(currentCard - 1);
    }
  };

  const handleContextMenu = (e, index) => {
    e.preventDefault();
    setContextMenu({
      x: e.clientX,
      y: e.clientY,
      deckIndex: index,
    });
  };

  const handleDeleteDeck = (index) => {
    const updatedDecks = decks.filter((_, i) => i !== index);
    setDecks(updatedDecks);
    saveToFirebase(updatedDecks);
    setContextMenu(null);
  };

  const closeContextMenu = () => {
    setContextMenu(null);
  };

  const styles = {
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      marginBottom: "20px",
    },
    qaInput: {
      width: "100%",
      minHeight: "60px",
      padding: "10px",
      resize: "vertical",
      backgroundColor: "#2A2A2A",
      color: "#E0E0E0",
      border: "1px solid #404040",
      borderRadius: "4px",
    },
    addButton: {
      cursor: "pointer",
      padding: "8px 16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row-reverse",
      gap: "8px",
      border: "none",
      borderRadius: "8px",
      backgroundColor: "#4A4A4A",
      color: "#E0E0E0",
      fontSize: "14px",
      fontWeight: 500,
      transition: "background-color 0.2s",
      "&:hover": {
        backgroundColor: "#5A5A5A",
      },
    },
    addIcon: {
      width: "16px",
      height: "16px",
    },
    chapterList: {
      listStyle: "none",
      padding: 0,
      margin: "20px 0",
    },
    chapterItem: {
      marginBottom: "10px",
      border: "1px solid #404040",
      borderRadius: "8px",
      overflow: "hidden",
      backgroundColor: "#2A2A2A",
    },
    chapterHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "12px 16px",
      backgroundColor: "#333333",
      cursor: "pointer",
      transition: "background-color 0.2s",
    },
    chapterHeaderHover: {
      backgroundColor: "#404040",
    },
    arrowIcon: {
      marginLeft: "10px",
      transition: "transform 0.3s",
    },
    arrowIconOpen: {
      transform: "rotate(180deg)",
    },
    chapterContent: {
      padding: "10px",
    },
    cardContainer: {
      border: "1px solid #404040",
      borderRadius: "8px",
      padding: "20px",
      marginTop: "20px",
      textAlign: "center",
      backgroundColor: "#2A2A2A",
    },
    cardNavigation: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "10px",
    },
    navButton: {
      padding: "6px 12px",
      backgroundColor: "#404040",
      border: "none",
      borderRadius: "6px",
      cursor: "pointer",
      color: "#E0E0E0",
      transition: "background-color 0.2s",
      "&:hover": {
        backgroundColor: "#505050",
      },
      "&:disabled": {
        backgroundColor: "#2A2A2A",
        cursor: "not-allowed",
        color: "#666666",
      },
    },
    inputLabel: {
      fontWeight: "500",
      marginBottom: "6px",
      color: "#E0E0E0",
    },
    inputWrapper: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "10px",
    },
    cardLabel: {
      fontWeight: "500",
      marginBottom: "12px",
      fontSize: "14px",
      color: "#B0B0B0",
    },
    cardContent: {
      minHeight: "100px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid #404040",
      borderRadius: "8px",
      padding: "16px",
      marginBottom: "12px",
      backgroundColor: "#333333",
      color: "#E0E0E0",
    },
    flyoutContent: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    scrollableContent: {
      overflowY: "auto",
      flexGrow: 1,
    },
    contextMenu: {
      position: "fixed",
      backgroundColor: "#333333",
      border: "1px solid #404040",
      boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
      zIndex: 1000,
    },
    contextMenuItem: {
      padding: "8px 12px",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#f0f0f0",
      },
    },
    loadingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "200px",
      color: "#E0E0E0",
    },
    loadingSpinner: {
      width: "24px",
      height: "24px",
      border: "3px solid #404040",
      borderTop: "3px solid #E0E0E0",
      borderRadius: "50%",
      animation: "spin 1s linear infinite",
      marginRight: "10px",
    },
  };

  const additionalStyles = {
    loadingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "200px",
      color: "#E0E0E0",
    },
    loadingSpinner: {
      width: "24px",
      height: "24px",
      border: "3px solid #404040",
      borderTop: "3px solid #E0E0E0",
      borderRadius: "50%",
      animation: "spin 1s linear infinite",
      marginRight: "10px",
    },
  };

  return (
    <div className="flyout-panel" onClick={closeContextMenu}>
      <div className="flyout-header">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3>Flashcards</h3>
          <button
            onClick={() => navigate(`/courses/${courseId}/flashcards`)}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              marginLeft: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src="/toolbaricons/inuse/arrow-up-right.svg"
              alt="Go to Flashcards"
              className="white-icon"
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </button>
        </div>
        <button className="close-button" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
          </svg>
        </button>
      </div>
      <div className="flyout-content" style={styles.flyoutContent}>
        <div className="input-container" style={styles.inputContainer}>
          <input
            type="text"
            placeholder="New Deck Name"
            value={deckInput}
            onChange={(e) => setDeckInput(e.target.value)}
            className="qa-input"
            style={styles.qaInput}
          />
          <button onClick={handleAddDeck} style={styles.addButton}>
            Add Deck
            <img
              src="/toolbaricons/inuse/add.svg"
              alt="Add"
              style={styles.addIcon}
            />
          </button>
        </div>
        <div style={styles.scrollableContent}>
          {isLoading ? (
            <div style={additionalStyles.loadingContainer}>
              <div style={additionalStyles.loadingSpinner} />
              <span>Loading flashcards...</span>
            </div>
          ) : (
            <ul style={styles.chapterList}>
              {decks.map((deck, index) => (
                <li
                  key={index}
                  style={styles.chapterItem}
                  onContextMenu={(e) => handleContextMenu(e, index)}
                >
                  <div
                    style={{
                      ...styles.chapterHeader,
                      ...(hoveredDeck === index
                        ? styles.chapterHeaderHover
                        : {}),
                    }}
                    onClick={() => {
                      setCurrentDeck(currentDeck === index ? null : index);
                      setCurrentCard(0);
                    }}
                    onMouseEnter={() => setHoveredDeck(index)}
                    onMouseLeave={() => setHoveredDeck(null)}
                  >
                    <h4>{deck.name}</h4>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        style={{
                          ...styles.arrowIcon,
                          ...(currentDeck === index
                            ? styles.arrowIconOpen
                            : {}),
                        }}
                      >
                        <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                      </svg>
                    </div>
                  </div>
                  {currentDeck === index && (
                    <div style={styles.chapterContent}>
                      <div
                        className="input-container"
                        style={styles.inputContainer}
                      >
                        <div style={styles.inputWrapper}>
                          <label
                            htmlFor="question-input"
                            style={styles.inputLabel}
                          >
                            Front Side
                          </label>
                          <textarea
                            id="question-input"
                            placeholder=""
                            value={questionInput}
                            onChange={(e) => setQuestionInput(e.target.value)}
                            onKeyPress={handleQuestionKeyPress}
                            onKeyDown={handleKeyDown}
                            className="qa-input"
                            style={styles.qaInput}
                          />
                        </div>
                        <div style={styles.inputWrapper}>
                          <label
                            htmlFor="answer-input"
                            style={styles.inputLabel}
                          >
                            Back Side
                          </label>
                          <textarea
                            id="answer-input"
                            placeholder=""
                            value={answerInput}
                            onChange={(e) => setAnswerInput(e.target.value)}
                            onKeyDown={handleKeyDown}
                            className="qa-input"
                            style={styles.qaInput}
                          />
                        </div>
                        <button
                          onClick={handleAddQA}
                          className="add-card-button"
                        >
                          Add Card
                          <img
                            src="/toolbaricons/inuse/add.svg"
                            alt="Add"
                            style={styles.addIcon}
                          />
                        </button>
                      </div>
                      {deck.cards.length > 0 && (
                        <div style={styles.cardContainer}>
                          <div style={styles.cardLabel}>
                            {deck.cards[currentCard].flipped ? "Back" : "Front"}
                          </div>
                          <div
                            onClick={() => toggleAnswer(currentCard)}
                            style={{
                              ...styles.cardContent,
                              cursor: "pointer",
                              backgroundColor: deck.cards[currentCard].flipped
                                ? "#524B24"
                                : "#333333",
                            }}
                          >
                            {deck.cards[currentCard].flipped
                              ? deck.cards[currentCard].answer
                              : deck.cards[currentCard].question}
                          </div>
                          <div style={styles.cardNavigation}>
                            <button
                              onClick={prevCard}
                              style={styles.navButton}
                              disabled={currentCard === 0}
                            >
                              &#8592;
                            </button>
                            <span>
                              {currentCard + 1} / {deck.cards.length}
                            </span>
                            <button
                              onClick={nextCard}
                              style={styles.navButton}
                              disabled={currentCard === deck.cards.length - 1}
                            >
                              &#8594;
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {contextMenu && (
        <div
          style={{
            ...styles.contextMenu,
            top: contextMenu.y,
            left: contextMenu.x,
          }}
        >
          <div
            style={styles.contextMenuItem}
            onClick={() => handleDeleteDeck(contextMenu.deckIndex)}
          >
            Delete
          </div>
        </div>
      )}
    </div>
  );
};

export default FlyoutMenu5;
