import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase/firebase";
import { Link } from "react-router-dom";
import jumpingImage from "../assets/img/Transhumans - Mechanical Love.svg";
const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");
    setLoading(true);

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset link has been sent to your email.");
    } catch (error) {
      let errorMessage = "Failed to send password reset email. ";
      switch (error.code) {
        case "auth/user-not-found":
          errorMessage += "No account exists with this email address.";
          break;
        case "auth/invalid-email":
          errorMessage += "Invalid email address.";
          break;
        case "auth/too-many-requests":
          errorMessage += "Too many requests. Please try again later.";
          break;
        default:
          errorMessage += `Error code: ${error.code}`;
      }
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-image">
        <img src={jumpingImage} alt="Jumping Character" />
      </div>
      <div className="login-form-container">
        <div className="login-box">
          <h1 className="login-title">Forgot Password</h1>
          <form onSubmit={handleSubmit} className="login-form">
            <div className="input-group">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
            </div>
            {message && (
              <p className="success-message" style={{ color: "green" }}>
                {message}
              </p>
            )}
            {error && <p className="error-message">{error}</p>}
            <button
              type="submit"
              disabled={loading}
              style={{
                backgroundColor: "#fae150",
                color: "#231c16",
                border: "1px solid #231c16",
                borderRadius: "24px",
                padding: "10px",
                fontSize: "14px",
                fontWeight: "600",
                cursor: "pointer",
                width: "100%",
                transition: "background-color 0.2s ease",
              }}
            >
              {loading ? "Sending..." : "Send Password Reset Email"}
            </button>
          </form>
          <div className="login-options">
            <Link
              to="/login"
              style={{ color: "#007bff", textDecoration: "none" }}
            >
              Back to Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
