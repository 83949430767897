import React from "react";
import "../css/Dashboard.css";
import { useCourses } from "../contexts/CourseContext";
import { Link } from "react-router-dom";
import { useTasks } from "../contexts/TaskContext";
import { useState, useEffect, useMemo } from "react";
import { useUnitCard } from "../contexts/UnitCardContext";
import { useProgress } from "../contexts/ProgressContext";
import { auth, db } from "../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";

const CourseCard = ({ course }) => {
  const { updateLastAccessed } = useCourses();
  const [courseProgress, setCourseProgress] = useState(0);
  const [showImage, setShowImage] = useState(false);

  // 진행률 로드 및 실시간 업데이트
  useEffect(() => {
    const user = auth.currentUser;
    if (!user || !course.id) return;

    const curriculumRef = doc(
      db,
      "users",
      user.uid,
      "courses",
      course.id,
      "curriculum",
      "main"
    );

    // 실시간 구독 설정
    const unsubscribe = onSnapshot(curriculumRef, (doc) => {
      if (doc.exists()) {
        const curriculumData = doc.data();
        const units = curriculumData.units || {};

        // 진행률 계산
        const allUnits = Object.values(units).flat();
        const completedUnits = allUnits.filter(
          (unit) => unit.isCompleted
        ).length;
        const progress =
          allUnits.length > 0
            ? Math.round((completedUnits / allUnits.length) * 100)
            : 0;

        setCourseProgress(progress);
      }
    });

    // 컴포넌트 언마운트 시 구독 해제
    return () => unsubscribe();
  }, [course.id]);

  const handleClick = () => {
    updateLastAccessed(course.id);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShowImage(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    const element = document.getElementById(`course-card-${course.id}`);
    if (element) observer.observe(element);

    return () => observer.disconnect();
  }, [course.id]);

  return (
    <Link
      to={`/courses/${course.id}/curriculum/${encodeURIComponent(course.name)}`}
      id={`course-card-${course.id}`}
      className="course-card-mini"
      onClick={handleClick}
    >
      <div className="dashboard-thumbnail-container">
        {course.thumbnail ? (
          showImage ? (
            <img
              src={course.thumbnail}
              alt={course.name}
              className="dashboard-thumbnail-image"
              loading="lazy"
            />
          ) : (
            <div className="thumbnail-skeleton"></div>
          )
        ) : (
          <div className="default-thumbnail-mini">
            <img
              src="/toolbaricons/inuse/book.svg"
              alt="Default thumbnail"
              className="default-thumbnail-icon"
            />
          </div>
        )}
      </div>
      <div className="course-card-middle">
        <h3 className="dashboard-course-name">{course.name}</h3>
        <div className="progress-bar-mini">
          <div
            className="progress-mini"
            style={{ width: `${courseProgress}%` }}
          ></div>
        </div>
        <span className="progress-text-mini">
          {`${courseProgress}% Completed`}
        </span>
      </div>
    </Link>
  );
};

const Dashboard = () => {
  const { courses, isLoading } = useCourses();
  const { taskLists, toggleTask } = useTasks();
  const { fetchUnitCardsByPeriod } = useUnitCard();
  const [localTaskLists, setLocalTaskLists] = useState([]);
  const [isTaskLoading, setIsTaskLoading] = useState(true);
  const [todayUnits, setTodayUnits] = useState([]);

  // 오늘의 유닛 카드 로드
  useEffect(() => {
    const loadTodayUnits = async () => {
      const today = new Date();
      const todayData = await fetchUnitCardsByPeriod(today, today);
      const todayKey = today.toLocaleDateString("fr-CA");
      setTodayUnits(todayData[todayKey] || []);
    };

    loadTodayUnits();
  }, [fetchUnitCardsByPeriod]);

  useEffect(() => {
    if (taskLists) {
      setLocalTaskLists(taskLists);
      setIsTaskLoading(false);
    }
  }, [taskLists]);

  // 메모이제이션 추가
  const recentCourses = useMemo(() => {
    const sorted = [...courses]
      .sort((a, b) => {
        // Firestore Timestamp 처리
        const timeA = a.lastAccessedAt?.seconds
          ? new Date(a.lastAccessedAt.seconds * 1000)
          : new Date(a.openedAt);
        const timeB = b.lastAccessedAt?.seconds
          ? new Date(b.lastAccessedAt.seconds * 1000)
          : new Date(b.openedAt);

        return timeB - timeA;
      })
      .slice(0, 4);

    return sorted;
  }, [courses]);

  // 코스별로 유닛카드를 그룹화하는 함수
  const groupUnitsByCourse = (units) => {
    return units.reduce((grouped, unit) => {
      const courseId = unit.courseId;
      if (!grouped[courseId]) {
        grouped[courseId] = {
          courseName: unit.courseName || "Unnamed Course",
          units: [],
          color: unit.color, // 코스 색상 저장
        };
      }
      grouped[courseId].units.push(unit);
      return grouped;
    }, {});
  };

  return (
    <div className="dashboard">
      <div className="overview">
        <div className="overview-header">
          <img
            src="/toolbaricons/inuse/magazine.svg"
            alt="Overview"
            className="overview-icon"
          />
          <h2>Overview</h2>
        </div>
        <div className="course-cards-mini">
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            recentCourses.map((course, index) => (
              <CourseCard key={course.id} course={course} />
            ))
          )}
        </div>
      </div>
      <div className="schedule-todo-container">
        <div className="schedule">
          <div className="schedule-header">
            <img
              src="/toolbaricons/inuse/calendar-heart1.svg"
              alt="Schedule Icon"
              className="section-icon"
            />
            <h2>Study Today</h2>
          </div>
          <div className="review-units-container">
            {todayUnits.length === 0 ? (
              <div className="no-units-message">
                No units scheduled for today.
              </div>
            ) : (
              <div className="today-units-list">
                {Object.entries(groupUnitsByCourse(todayUnits)).map(
                  ([courseId, courseData]) => (
                    <div key={courseId} className="course-units-group">
                      <h3
                        className="schedule-course-name"
                        style={{ borderLeftColor: courseData.color }}
                      >
                        {courseData.courseName}
                      </h3>
                      {courseData.units.map((unit, index) => (
                        <Link
                          key={index}
                          to={`/courses/${courseId}/curriculum/${encodeURIComponent(
                            courseData.courseName
                          )}`}
                          className="today-unit-item"
                        >
                          <h4>{unit.unitName}</h4>
                          {unit.subUnits &&
                            unit.subUnits.map((subUnit, subIndex) => (
                              <div key={subIndex} className="subunit-item">
                                • {subUnit}
                              </div>
                            ))}
                        </Link>
                      ))}
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
        <div className="todo-list">
          <div className="todolist-header">
            <img
              src="/toolbaricons/inuse/caret-down-circle.svg"
              alt="Todo list icon"
              className="section-icon"
            />
            <h2>To do list</h2>
          </div>
          <div className="dashboard-todo-list">
            {isTaskLoading ? (
              <div className="loading-message">Loading data...</div>
            ) : Array.isArray(taskLists) && taskLists.length === 0 ? (
              <div className="no-tasks-message">No tasks to do.</div>
            ) : (
              taskLists.map((list, listIndex) => (
                <div key={listIndex} className="dashboard-task-list">
                  <h3>{list.name}</h3>
                  <ul>
                    {list.tasks &&
                      list.tasks.map((task, taskIndex) => (
                        <li
                          key={taskIndex}
                          className={task.completed ? "completed" : ""}
                        >
                          <input
                            type="checkbox"
                            checked={task.completed}
                            onChange={() => toggleTask(listIndex, taskIndex)}
                          />
                          <span>{task.text}</span>
                        </li>
                      ))}
                  </ul>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
