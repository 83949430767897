import React, { createContext, useContext, useState, useCallback } from "react";
import { openDB } from "idb";

const FileManagerContext = createContext();

// IndexedDB 설정
const initializeDB = async () => {
  return openDB("imageCache", 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("images")) {
        db.createObjectStore("images", { keyPath: "path" });
      }
    },
  });
};

export const FileManagerProvider = ({ children }) => {
  const [globalFiles, setGlobalFiles] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);

  // 캐시에서 이미지 가져오기
  const getFromCache = async (userId) => {
    const db = await initializeDB();
    const tx = db.transaction("images", "readonly");
    const store = tx.objectStore("images");
    return store.get(`users/${userId}/images`);
  };

  // 캐시에 이미지 저장
  const saveToCache = async (userId, files) => {
    const db = await initializeDB();
    const tx = db.transaction("images", "readwrite");
    const store = tx.objectStore("images");
    await store.put({
      path: `users/${userId}/images`,
      files,
      timestamp: Date.now(),
    });
  };

  // 글로벌 파일 상태 업데이트
  const updateGlobalFiles = useCallback((userId, files) => {
    setGlobalFiles((prev) => ({
      ...prev,
      [userId]: files,
    }));
    // IndexedDB에도 저장
    saveToCache(userId, files);
  }, []);

  // 파일 추가
  const addFiles = useCallback(
    (userId, newFiles) => {
      setGlobalFiles((prev) => ({
        ...prev,
        [userId]: [...(prev[userId] || []), ...newFiles],
      }));
      // IndexedDB 업데이트
      saveToCache(userId, [...(globalFiles[userId] || []), ...newFiles]);
    },
    [globalFiles]
  );

  // 파일 삭제
  const removeFile = useCallback(
    (userId, filePath) => {
      setGlobalFiles((prev) => ({
        ...prev,
        [userId]: (prev[userId] || []).filter((file) => file.path !== filePath),
      }));
      // IndexedDB 업데이트
      saveToCache(
        userId,
        (globalFiles[userId] || []).filter((file) => file.path !== filePath)
      );
    },
    [globalFiles]
  );

  // 초기 로드
  const initializeFiles = useCallback(
    async (userId) => {
      if (!userId || isInitialized) return;

      try {
        const cachedData = await getFromCache(userId);
        if (cachedData?.files) {
          setGlobalFiles((prev) => ({
            ...prev,
            [userId]: cachedData.files,
          }));
        }
        setIsInitialized(true);
      } catch (error) {
        console.error("Failed to initialize files:", error);
      }
    },
    [isInitialized]
  );

  return (
    <FileManagerContext.Provider
      value={{
        globalFiles,
        updateGlobalFiles,
        addFiles,
        removeFile,
        initializeFiles,
        isInitialized,
      }}
    >
      {children}
    </FileManagerContext.Provider>
  );
};

export const useFileManager = () => {
  const context = useContext(FileManagerContext);
  if (!context) {
    throw new Error("useFileManager must be used within a FileManagerProvider");
  }
  return context;
};
