import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, db } from "../firebase/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useCurriculum } from "./CurriculumContext";

const UnitCardContext = createContext(null);

export const useUnitCard = () => {
  const context = useContext(UnitCardContext);
  if (!context) {
    throw new Error("useUnitCard must be used within a UnitCardProvider");
  }
  return context;
};

export const UnitCardProvider = ({ children }) => {
  const [unitCards, setUnitCards] = useState({});
  const { courseId } = useCurriculum();

  // Load all data from Firebase
  const loadAllData = async () => {
    if (!auth.currentUser) return;

    try {
      const docRef = doc(db, `users/${auth.currentUser.uid}/Calendar/events`);
      const docSnap = await getDoc(docRef);
      return docSnap.exists() ? docSnap.data() : {};
    } catch (error) {
      console.error("Failed to load data:", error);
      return {};
    }
  };

  // Update local state with filtered data for current course
  const updateLocalState = (allData) => {
    const courseData = {};
    Object.entries(allData).forEach(([date, events]) => {
      const courseEvents = events.filter(
        (event) => event.courseId === courseId
      );
      if (courseEvents.length > 0) {
        courseData[date] = courseEvents;
      }
    });
    setUnitCards(courseData);
  };

  // 코스 정보를 가져오는 함수 추가
  const getCourseName = async () => {
    if (!auth.currentUser || !courseId) return "";

    try {
      const courseRef = doc(
        db,
        `users/${auth.currentUser.uid}/courses/${courseId}`
      );
      const courseSnap = await getDoc(courseRef);

      if (courseSnap.exists()) {
        return courseSnap.data().name || "";
      }
      return "";
    } catch (error) {
      console.error("Failed to fetch course name:", error);
      return "";
    }
  };

  // Save unit card
  const saveUnitCard = async (date, unitData, previousDate = null) => {
    if (!auth.currentUser) return false;

    try {
      const allData = await loadAllData();
      const courseName = await getCourseName();

      // 모든 날짜에서 해당 unitId를 가진 이벤트를 찾아서 제거
      Object.keys(allData).forEach((dateKey) => {
        allData[dateKey] = allData[dateKey].filter(
          (event) => event.unitId !== unitData.unitId
        );
        // 해당 날짜의 이벤트가 비어있으면 날짜 키 자체를 삭제
        if (allData[dateKey].length === 0) {
          delete allData[dateKey];
        }
      });

      // 새로운 날짜에 이벤트 추가
      const formatDate = (date) => {
        const d = new Date(date);
        return d.toLocaleDateString("fr-CA");
      };

      const newDateStr = formatDate(date);
      const courseColors = [
        "#9DB4C0",
        "#C2B0C9",
        "#86A69D",
        "#C9A9A6",
        "#A4BE7B",
        "#DEB6AB",
        "#B5C0D0",
      ];

      const colorIndex = parseInt(courseId.slice(-6), 16) % courseColors.length;
      const courseColor = courseColors[colorIndex];

      const newEvent = {
        ...unitData,
        courseId,
        courseName,
        color: courseColor,
        timestamp: new Date().toISOString(),
      };

      if (!allData[newDateStr]) {
        allData[newDateStr] = [];
      }

      allData[newDateStr].push(newEvent);

      // Firebase에 업데이트된 데이터 저장
      const docRef = doc(db, `users/${auth.currentUser.uid}/Calendar/events`);
      await setDoc(docRef, allData);

      updateLocalState(allData);
      return true;
    } catch (error) {
      console.error("Failed to save:", error);
      return false;
    }
  };

  // Load data when course changes
  useEffect(() => {
    if (!courseId || !auth.currentUser) return;

    const loadCourseData = async () => {
      const allData = await loadAllData();
      updateLocalState(allData);
    };

    loadCourseData();
  }, [courseId]);

  // Fetch unit cards by period
  const fetchUnitCardsByPeriod = async (startDate, endDate) => {
    if (!auth.currentUser) return {};

    try {
      // 1. Load all data
      const allData = await loadAllData();

      // 2. Convert date format
      const formatDate = (date) => {
        const d = new Date(date);
        return d.toLocaleDateString("fr-CA");
      };

      const start = formatDate(startDate);
      const end = formatDate(endDate);

      // 3. Filter data for all courses within period
      const filteredData = {};
      Object.entries(allData).forEach(([date, events]) => {
        if (date >= start && date <= end) {
          // Include all events without courseId filtering
          if (events.length > 0) {
            filteredData[date] = events;
          }
        }
      });

      return filteredData;
    } catch (error) {
      console.error("Failed to fetch period data:", error);
      return {};
    }
  };

  const updateUnitNameInCalendar = async (unitId, newName) => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      // Update unit name in all calendar views
      const views = ["monthly", "week1"];

      for (const view of views) {
        const calendarRef = doc(db, `users/${user.uid}/Calendar/${view}`);
        const calendarDoc = await getDoc(calendarRef);

        if (calendarDoc.exists()) {
          const calendarData = calendarDoc.data();
          let updated = false;

          // Check and update unit cards for each date
          for (const [date, content] of Object.entries(calendarData)) {
            if (content?.unitCards) {
              const updatedUnitCards = content.unitCards.map((card) => {
                if (card.unitId === unitId) {
                  return { ...card, unitName: newName };
                }
                return card;
              });

              if (
                JSON.stringify(content.unitCards) !==
                JSON.stringify(updatedUnitCards)
              ) {
                calendarData[date] = {
                  ...content,
                  unitCards: updatedUnitCards,
                };
                updated = true;
              }
            }
          }

          // Save to Firebase if changes exist
          if (updated) {
            await setDoc(calendarRef, calendarData);
          }
        }
      }
    } catch (error) {
      console.error("Error updating unit name in calendar:", error);
    }
  };

  // Delete unit card
  const deleteUnitFromCalendar = async (unitId) => {
    if (!auth.currentUser) return;

    try {
      // 1. Load current data from Firebase
      const allData = await loadAllData();

      // 2. Remove events with matching unitId from all dates
      let updated = false;
      Object.keys(allData).forEach((date) => {
        const filteredEvents = allData[date].filter(
          (event) => event.unitId !== unitId
        );

        if (filteredEvents.length !== allData[date].length) {
          if (filteredEvents.length === 0) {
            delete allData[date];
          } else {
            allData[date] = filteredEvents;
          }
          updated = true;
        }
      });

      // 3. Save to Firebase if changes exist
      if (updated) {
        const docRef = doc(db, `users/${auth.currentUser.uid}/Calendar/events`);
        await setDoc(docRef, allData);

        // 4. Update local state
        updateLocalState(allData);
      }
    } catch (error) {
      console.error("Failed to delete unit card:", error);
    }
  };

  const value = {
    unitCards,
    saveUnitCard,
    fetchUnitCardsByPeriod,
    updateUnitNameInCalendar,
    deleteUnitFromCalendar,
  };

  return (
    <UnitCardContext.Provider value={value}>
      {children}
    </UnitCardContext.Provider>
  );
};
