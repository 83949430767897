import React, { useState, useEffect, useRef } from "react";

import FlyoutMenu1 from "./FlyoutMenu1";
import FlyoutMenu2 from "./FlyoutMenu2";
import FlyoutMenu3 from "./FlyoutMenu3";
import FlyoutMenu5 from "./FlyoutMenu5";
import FlyoutMenu6 from "./FlyoutMenu6";

import FlyoutMenu8 from "./FlyoutMenu8";
import "../css/rightsidebar.css";

const RightSidebar = ({
  onSelectLayout,
  onInsertImage,
  onInsertAudio,
  onInsertVideo,
  courseId,
  currentSubunitId,
  onBackgroundChange,
  onLayoutChange,
}) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMenuClick = (menu) => {
    setActiveMenu(activeMenu === menu ? null : menu);
  };

  const handleClose = () => {
    setActiveMenu(null);
  };

  const renderFlyoutMenu = () => {
    switch (activeMenu) {
      case "menu1":
        return (
          <FlyoutMenu1
            onClose={handleClose}
            onSelectLayout={(layout) => {
              onSelectLayout(layout);
              onLayoutChange(currentSubunitId, layout);
            }}
            className="flyout-menu"
          />
        );
      case "menu2":
        return (
          <FlyoutMenu2
            onClose={handleClose}
            onBackgroundChange={onBackgroundChange}
            currentSubunitId={currentSubunitId}
            className="flyout-menu"
          />
        );
      case "menu3":
        return (
          <FlyoutMenu3
            onClose={handleClose}
            onInsertImage={onInsertImage}
            onInsertAudio={onInsertAudio}
            onInsertVideo={onInsertVideo}
            className="flyout-menu"
            subunitId={currentSubunitId}
            courseId={courseId}
          />
        );
      case "menu5":
        return (
          <FlyoutMenu5
            onClose={handleClose}
            courseId={courseId}
            className="flyout-menu"
          />
        );
      case "menu6":
        return <FlyoutMenu6 onClose={handleClose} className="flyout-menu" />;
      case "menu8":
        return (
          <FlyoutMenu8
            onClose={handleClose}
            courseId={courseId}
            className="flyout-menu"
          />
        );
      default:
        return null;
    }
  };

  const menuItems = [
    { id: "menu1", icon: "/toolbaricons/inuse/notebook.svg", label: "Layout" },
    {
      id: "menu2",
      icon: "/toolbaricons/inuse/file-image.svg",
      label: "Design",
    },
    { id: "menu3", icon: "/toolbaricons/inuse/uploads.svg", label: "Uploads" },
    { id: "menu5", icon: "/toolbaricons/inuse/cards.svg", label: "Flashcards" },
    { id: "menu6", icon: "/toolbaricons/inuse/checklist.svg", label: "Todo" },
    { id: "menu8", icon: "/toolbaricons/inuse/new-icon.svg", label: "Labels" },
  ];

  return (
    <div className="right-bar">
      <ul className="menu-list">
        {menuItems.map((item) => (
          <li
            key={item.id}
            className={`menu-item ${item.id}`}
            onClick={() => handleMenuClick(item.id)}
          >
            <div className="menu-content">
              <img src={item.icon} alt={item.label} className="menu-icon" />
              <span className="menu-label">{item.label}</span>
            </div>
          </li>
        ))}
      </ul>
      <div ref={menuRef}>{renderFlyoutMenu()}</div>
    </div>
  );
};

export default RightSidebar;
