import { createContext, useContext, useEffect } from "react";
import { auth, db } from "../firebase/firebase";
import {
  doc,
  deleteDoc,
  getDocs,
  collection,
  writeBatch,
} from "firebase/firestore";

const AuthDeleteContext = createContext();

export const AuthDeleteProvider = ({ children }) => {
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      // Save previous user ID
      const prevUserId = user?.uid;

      // Wait for next state change
      setTimeout(async () => {
        const currentUser = auth.currentUser;

        // If there was a previous user and no current user (deleted case)
        if (prevUserId && !currentUser) {
          try {
            const batch = writeBatch(db);

            // Delete subscription data
            const subscriptionsRef = collection(
              db,
              `users/${prevUserId}/subscriptions`
            );
            const subscriptionsSnapshot = await getDocs(subscriptionsRef);
            subscriptionsSnapshot.forEach((doc) => {
              batch.delete(doc.ref);
            });

            // Delete calendar data
            const calendarRef = collection(db, `users/${prevUserId}/Calendar`);
            const calendarSnapshot = await getDocs(calendarRef);
            calendarSnapshot.forEach((doc) => {
              batch.delete(doc.ref);
            });

            // Delete courses and related data
            const coursesRef = collection(db, `users/${prevUserId}/courses`);
            const coursesSnapshot = await getDocs(coursesRef);

            for (const courseDoc of coursesSnapshot.docs) {
              // Delete curriculum
              const curriculumRef = collection(courseDoc.ref, "curriculum");
              const curriculumSnapshot = await getDocs(curriculumRef);
              curriculumSnapshot.forEach((doc) => {
                batch.delete(doc.ref);
              });

              // Delete flashcards
              const flashcardsRef = collection(courseDoc.ref, "flashcards");
              const flashcardsSnapshot = await getDocs(flashcardsRef);
              flashcardsSnapshot.forEach((doc) => {
                batch.delete(doc.ref);
              });

              // Delete course document
              batch.delete(courseDoc.ref);
            }

            // Delete user document
            const userRef = doc(db, `users/${prevUserId}`);
            batch.delete(userRef);

            // Execute batch
            await batch.commit();
          } catch (error) {
            console.error("Error while deleting data:", error);
          }
        }
      }, 100); // Small delay to detect state change
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthDeleteContext.Provider value={null}>
      {children}
    </AuthDeleteContext.Provider>
  );
};
