import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { checkSubscriptionStatus } from "../utils/subscription";
import "../css/AboutPage.css";

const AboutPage = () => {
  const { user } = useAuth();
  const [hasValidSubscription, setHasValidSubscription] = useState(false);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkUserSubscription = async () => {
      if (user) {
        const subscription = await checkSubscriptionStatus();
        const isValid =
          subscription &&
          (subscription.status === "active" ||
            subscription.status === "on_trial" ||
            subscription.status === "trialing" ||
            (subscription.status === "cancelled" &&
              new Date(subscription.currentPeriodEnd) > new Date()));
        setHasValidSubscription(isValid);
      }
    };

    checkUserSubscription();
  }, [user]);

  useEffect(() => {
    const checkDevice = () => {
      const mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      const width = window.innerWidth;
      setIsMobile(mobile && width < 768);
    };

    checkDevice();
    window.addEventListener("resize", checkDevice);
    return () => window.removeEventListener("resize", checkDevice);
  }, []);

  return (
    <div className="about-container">
      <Link to="/" className="about-logo">
        RedoNote
      </Link>
      <main className="about-content">
        <section className="about-hero">
          <div className="about-hero-content">
            <h2 className="about-subtitle">About Redo Note</h2>
            <h1 className="about-title">
              A story of tools and the future of work
            </h1>
          </div>
        </section>
        <section className="about-welcome">
          <div className="welcome-content">
            <div className="welcome-image">
              <img src="/img/About/About1.svg" alt="About Redo Note" />
            </div>
            <div className="welcome-text-container">
              <p className="welcome-text">
                Welcome to REDONOTE, your comprehensive study companion designed
                to revolutionize the way you learn.
              </p>
            </div>
          </div>
        </section>
        <section className="about-journey">
          <div className="journey-content">
            <div className="journey-text-container">
              <h2 className="journey-title">Our Journey</h2>
              <p className="journey-text">
                REDONOTE was conceived with a singular vision: to simplify and
                enhance the learning experience for students and lifelong
                learners alike. Frustrated by the fragmented nature of existing
                study tools, our founders embarked on a mission to create an
                all-in-one platform that integrates note-taking, review
                scheduling, planning, and more.
              </p>
            </div>
            <div className="journey-image">
              <img src="/img/About/About2.svg" alt="Our Journey" />
            </div>
          </div>
        </section>
        <section className="about-mission">
          <div className="mission-content">
            <div className="mission-image">
              <img src="/img/About/About3.svg" alt="Our Mission" />
            </div>
            <div className="mission-text-container">
              <h2 className="mission-title">Our Mission</h2>
              <p className="mission-text">
                We aim to empower learners by providing a seamless, intuitive,
                and efficient platform that caters to all aspects of the study
                process. By combining essential study tools into one cohesive
                system, REDONOTE helps you focus on what truly matters—mastering
                your subjects.
              </p>
            </div>
          </div>
        </section>
        <section className="about-why">
          <div className="why-content">
            <h2 className="why-title">Why REDONOTE?</h2>
            <div className="why-features">
              <div className="why-feature">
                <h3>Curriculum-Based Note Organization</h3>
                <p>
                  Structure your notes according to your study plan, making it
                  easier to track your progress and understand your learning
                  journey.
                </p>
              </div>
              <div className="why-feature">
                <h3>Smart Review Scheduling</h3>
                <p>
                  Set study and review dates effortlessly, with reminders to
                  keep you on track, ensuring consistent reinforcement of
                  knowledge.
                </p>
              </div>
              <div className="why-feature">
                <h3>Integrated Planner</h3>
                <p>
                  Manage your time effectively with monthly, weekly, and daily
                  planners, along with habit and study time trackers, all within
                  the platform.
                </p>
              </div>
              <div className="why-feature">
                <h3>Analog Feel in a Digital Space</h3>
                <p>
                  Experience the warmth of handwritten notes with our carefully
                  designed interface that mimics the feel of traditional
                  note-taking.
                </p>
              </div>
              <div className="why-feature">
                <h3>Flashcard Integration</h3>
                <p>
                  Create and review flashcards directly from your notes,
                  streamlining your study process without the need for
                  additional subscriptions.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AboutPage;
