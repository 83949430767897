import React from "react";
import { useTasks } from "../contexts/TaskContext";
import "../css/CalendarTodoSidebar.css";

const CalendarTodoSidebar = ({ isOpen, onClose }) => {
  const { taskLists, toggleTask, addTask, addList } = useTasks();
  const [newTasks, setNewTasks] = React.useState({});
  const [newListName, setNewListName] = React.useState("");

  const handleSubmit = (e, listIndex) => {
    e.preventDefault();
    const taskText = newTasks[listIndex];
    if (taskText && taskText.trim()) {
      addTask(listIndex, taskText.trim());
      setNewTasks((prev) => ({
        ...prev,
        [listIndex]: "",
      }));
    }
  };

  const handleInputChange = (listIndex, value) => {
    setNewTasks((prev) => ({
      ...prev,
      [listIndex]: value,
    }));
  };

  const handleAddList = (e) => {
    e.preventDefault();
    if (newListName.trim()) {
      addList(newListName.trim());
      setNewListName("");
    }
  };

  return (
    <div className={`cal-todo-sidebar-container ${isOpen ? "open" : ""}`}>
      <div className="cal-todo-header-wrapper">
        <h3>To Do List</h3>
        <button className="close-button" onClick={onClose}>
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
          </svg>
        </button>
      </div>

      <form className="cal-todo-add-list-form" onSubmit={handleAddList}>
        <input
          type="text"
          className="cal-todo-input-field"
          value={newListName}
          onChange={(e) => setNewListName(e.target.value)}
          placeholder="New List Name"
        />
        <button type="submit" className="cal-todo-add-btn">
          +
        </button>
      </form>

      <div className="cal-todo-content-scroll">
        {taskLists.map((list, listIndex) => (
          <div key={listIndex} className="cal-todo-card-wrapper">
            <h4>{list.name}</h4>
            <ul className="cal-todo-items-list">
              {list.tasks.map((task, taskIndex) => (
                <li key={taskIndex} className="cal-todo-item-single">
                  <div className="cal-todo-item-content">
                    <input
                      type="checkbox"
                      checked={task.completed}
                      onChange={() => toggleTask(listIndex, taskIndex)}
                    />
                    <span
                      className={`cal-todo-text ${
                        task.completed ? "cal-todo-completed-text" : ""
                      }`}
                    >
                      {task.text}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
            <form
              className="cal-todo-add-form"
              onSubmit={(e) => handleSubmit(e, listIndex)}
            >
              <input
                type="text"
                className="cal-todo-input-field"
                value={newTasks[listIndex] || ""}
                onChange={(e) => handleInputChange(listIndex, e.target.value)}
              />
              <button type="submit" className="cal-todo-add-btn">
                +
              </button>
            </form>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarTodoSidebar;
