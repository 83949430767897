import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { onAuthStateChanged, signOut, getAuth } from "firebase/auth";
import { auth } from "../firebase/firebase";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    user: null,
    loading: true,
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthState({
        user,
        loading: false,
      });
    });

    return unsubscribe;
  }, []);

  const value = useMemo(() => authState, [authState]);

  const logout = async () => {
    try {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const views = [
          "month",
          "week1",
          "week2",
          "day",
          "year",
          "year12",
          "habit",
        ];
        views.forEach((v) => {
          localStorage.removeItem(`${v}_${currentUser.uid}`);
          localStorage.removeItem(`${v}_${currentUser.uid}_expiry`);
        });
      }

      await signOut(auth);
    } catch (error) {
      console.error("로그아웃 중 오류:", error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={value}>
      {!authState.loading && children}
    </AuthContext.Provider>
  );
};
