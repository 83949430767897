import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { storage } from "../firebase/firebase";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  deleteObject,
  getMetadata,
} from "firebase/storage";
import { auth } from "../firebase/firebase";
import "../css/FileManager.css";
import { useImage } from "../contexts/ImageContext";
import { serverTimestamp } from "firebase/firestore";
import imageCompression from "browser-image-compression";
import { useFileManager } from "../contexts/FileManagerContext";
import { db } from "../firebase/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { openDB } from "idb"; // IndexedDB wrapper 라이브러리 추가
import { debounce } from "lodash";

// IndexedDB 설정
const initializeDB = async () => {
  return openDB("imageCache", 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("images")) {
        db.createObjectStore("images", { keyPath: "path" });
      }
    },
  });
};

// 상수 정의 (파일 상단에 추가)
const DEFAULT_COMPRESSION_OPTIONS = {
  maxSizeMB: 0.3,
  maxWidthOrHeight: 1800,
  initialQuality: 0.7,
  useWebWorker: true,
  alwaysKeepResolution: true, // 해상도 유지
  fileType: "image/webp", // WebP 포맷 사용
};

// 최적화된 압축 옵션
const getCompressionOptions = (fileSize) => {
  const sizeInMB = fileSize / (1024 * 1024);

  // 20KB 미만은 압축하지 않음
  if (sizeInMB < 0.02) {
    return null;
  }

  if (sizeInMB <= 0.1) {
    // 100KB 이하
    return {
      ...DEFAULT_COMPRESSION_OPTIONS,
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1024,
      initialQuality: 0.85,
    };
  } else if (sizeInMB <= 0.3) {
    // 300KB 이하
    return {
      ...DEFAULT_COMPRESSION_OPTIONS,
      maxSizeMB: 0.1,
      maxWidthOrHeight: 1500,
      initialQuality: 0.8,
    };
  } else {
    // 300KB 초과
    return {
      ...DEFAULT_COMPRESSION_OPTIONS,
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1800,
      initialQuality: 0.75,
    };
  }
};

// 파일 상단에 캐시 추가
const IMAGE_CACHE = new Map();
const LOADING_IMAGES = new Set();

// 이미지 프리로드 함수 추가
const preloadImage = (url) => {
  if (IMAGE_CACHE.has(url)) {
    return Promise.resolve(IMAGE_CACHE.get(url));
  }

  if (LOADING_IMAGES.has(url)) {
    return new Promise((resolve) => {
      const checkCache = setInterval(() => {
        if (IMAGE_CACHE.has(url)) {
          clearInterval(checkCache);
          resolve(IMAGE_CACHE.get(url));
        }
      }, 50);
    });
  }

  LOADING_IMAGES.add(url);

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      IMAGE_CACHE.set(url, img);
      LOADING_IMAGES.delete(url);
      resolve(img);
    };
    img.onerror = reject;
    img.src = url;
  });
};

const FlyoutMenu3 = ({ onClose, courseId, subunitId }) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Images");
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { saveImageData } = useImage();
  const [uploading, setUploading] = useState(false);
  const [storageUsage, setStorageUsage] = useState(0);
  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 12;
  const batchSize = 8; // 배치 기 증가
  const urlCache = useRef(new Map());

  // FileManager Context 사용
  const {
    globalFiles,
    updateGlobalFiles,
    addFiles,
    removeFile,
    initializeFiles,
    isInitialized,
  } = useFileManager();

  // userId 정의
  const userId = auth.currentUser?.uid;
  const files = globalFiles[userId] || [];

  // Constants
  const MAX_STORAGE_PER_USER = 1000 * 1024 * 1024; // 1GB in bytes (1000MB)
  const ALLOWED_IMAGE_TYPES = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
    "image/jpg",
  ];

  // 이미지 압축 함수 최적화
  const compressImage = async (file) => {
    try {
      // 작은 파일은 압축하지 않음
      const options = getCompressionOptions(file.size);
      if (!options) {
        return file;
      }

      const startTime = performance.now();
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      return file;
    }
  };
  // 사용자의 전체 스토리지 사용량을 확인하는 함수
  const getUserStorageSize = async (userId) => {
    const userStorageRef = ref(storage, `users/${userId}`);
    let totalSize = 0;

    // 모든 하위 폴더와 파일을 재귀적으로 확인
    async function calculateSize(folderRef) {
      const items = await listAll(folderRef);

      // 현재 폴더의 파일들 크기 계산
      for (const item of items.items) {
        const metadata = await getMetadata(item);
        totalSize += metadata.size;
      }

      // 하위 폴더들 확인
      for (const folder of items.prefixes) {
        await calculateSize(folder);
      }
    }

    try {
      await calculateSize(userStorageRef);
      return totalSize;
    } catch (error) {
      return 0;
    }
  };

  // 캐시된 이미지 가져오기
  const getFromCache = async (path) => {
    const db = await initializeDB();
    const tx = db.transaction("images", "readonly");
    const store = tx.objectStore("images");
    return store.get(path);
  };

  // 이미지를 캐시에 저장
  const saveToCache = async (imageData) => {
    const db = await initializeDB();
    const tx = db.transaction("images", "readwrite");
    const store = tx.objectStore("images");
    await store.put({
      ...imageData,
      timestamp: Date.now(),
    });
  };

  // 1. 디바운스 추가
  const debouncedFetchFiles = useCallback(
    debounce(() => {
      fetchFiles();
    }, 300),
    []
  );

  // 2. 캐시 상태 추가
  const [cachedFiles, setCachedFiles] = useState(new Map());

  // 3. 마운트 상태 추적
  const isMounted = useRef(false);

  // Fetch file list
  const fetchFiles = async (signal) => {
    if (!userId || loading) return;

    // 이미 캐시된 이터가 있다면 사용
    if (cachedFiles.size > 0) {
      return;
    }

    const startTime = performance.now();
    setLoading(true);
    let processedFiles = []; // 여기서 정의

    try {
      const storageRef = ref(
        storage,
        `users/${userId}/${selectedTab.toLowerCase()}`
      );

      const result = await listAll(storageRef);

      // 새로운 파일이 있는지 확인
      const existingPaths = new Set(files.map((f) => f.path));
      const newItems = result.items.filter(
        (item) => !existingPaths.has(item.fullPath)
      );

      if (newItems.length === 0) {
        return;
      }

      // 새로운 파일만 처리
      for (let i = 0; i < newItems.length; i += batchSize) {
        const batch = newItems.slice(i, i + batchSize);
        const batchResults = await Promise.all(
          batch.map(async (item) => {
            try {
              // 캐시 확인
              if (cachedFiles.has(item.fullPath)) {
                return cachedFiles.get(item.fullPath);
              }

              const [url, metadata] = await Promise.all([
                getDownloadURL(item),
                getMetadata(item),
              ]);

              const fileData = {
                name: item.name,
                path: item.fullPath,
                url,
                metadata,
                timestamp: Date.now(),
              };

              // 캐시에 저장
              setCachedFiles(
                (prev) => new Map(prev.set(item.fullPath, fileData))
              );
              return fileData;
            } catch (error) {
              return null;
            }
          })
        );

        processedFiles.push(...batchResults.filter(Boolean));
      }

      // 새로운 파일이 있을 때만 업데이트
      if (processedFiles.length > 0) {
        // 기존 파일과 새 파일을 합쳐서 업데이트
        const updatedFiles = [...files, ...processedFiles];
        updateGlobalFiles(userId, updatedFiles);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  // 7. 마운트/언마운트 처리
  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  // 8. 디바운스된 fetchFiles 호출
  useEffect(() => {
    const controller = new AbortController();

    // 이미 초기화었고 파일이 있다면 fetch 하지 않음
    if (isInitialized && files.length > 0) {
      return;
    }

    if (isMounted.current && userId) {
      fetchFiles(controller.signal);
    }

    return () => {
      controller.abort();
    };
  }, [userId]); // selectedTab 제거

  // 백그라운드 업데이트
  const fetchNewFiles = async (userId, token) => {
    if (!userId) return;

    try {
      const storageRef = ref(
        storage,
        `users/${userId}/${selectedTab.toLowerCase()}`
      );

      const result = await listAll(storageRef);

      // 경로 기준으로 중복 체크
      const existingFilePaths = new Set(files.map((f) => f.path));

      const newItems = result.items
        .sort((a, b) => b.name.localeCompare(a.name))
        .filter((item) => !existingFilePaths.has(item.fullPath));

      if (newItems.length > 0) {
        const newFiles = await Promise.all(
          newItems.map(async (item) => {
            try {
              // 중복 체크 한 번 더
              if (existingFilePaths.has(item.fullPath)) {
                return null;
              }

              const url = await getDownloadURL(item);
              return {
                name: item.name,
                path: item.fullPath,
                url,
                timestamp: Date.now(),
              };
            } catch (error) {
              return null;
            }
          })
        );

        const validNewFiles = newFiles.filter(Boolean);
        if (validNewFiles.length > 0) {
          // 기존 파일 목록을 완전히 대체하는 대신 새 파일만 추가
          const uniqueFiles = [...validNewFiles, ...files].filter(
            (file, index, self) =>
              index === self.findIndex((f) => f.path === file.path)
          );
          updateGlobalFiles(userId, uniqueFiles);
        }
      }
    } catch (error) {}
  };

  // Refresh file list when tab changes
  useEffect(() => {
    fetchFiles();
  }, [selectedTab]);

  // File deletion functionality
  const handleDelete = async (filePath) => {
    if (!userId) return;

    try {
      const fileRef = ref(storage, filePath);
      await deleteObject(fileRef);

      const courseDocRef = doc(db, `users/${userId}/courses/${courseId}`);
      const courseDoc = await getDoc(courseDocRef);

      if (courseDoc.exists()) {
        const courseData = courseDoc.data();
        if (courseData.curriculum?.images?.subunits?.[subunitId]) {
          const currentImages =
            courseData.curriculum.images.subunits[subunitId];
          const updatedImages = currentImages.filter(
            (img) => img.path !== filePath
          );

          const updatedCurriculum = {
            ...courseData.curriculum,
            images: {
              ...courseData.curriculum.images,
              subunits: {
                ...courseData.curriculum.images.subunits,
                [subunitId]: updatedImages,
              },
            },
          };

          await updateDoc(courseDocRef, {
            curriculum: updatedCurriculum,
          });
        }
      }

      removeFile(userId, filePath);
    } catch (error) {
      alert(`파일 삭제 실패: ${error.message}`);
    }
  };

  // Check file extension
  const getFileType = (file) => {
    const imageTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/webp",
      "image/jpg",
    ];

    if (imageTypes.includes(file.type)) {
      return "Images";
    }

    return null;
  };

  // 파일 타입 함수 추가
  const isImageFile = (file) => {
    const imageTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/webp",
      "image/svg+xml", // SVG 타입 추가
    ];
    return imageTypes.includes(file.type);
  };

  // 압축 필요 여부 체크
  const needsCompression = (file) => {
    // SVG는 압축하지 않음
    if (file.type === "image/svg+xml") {
      return false;
    }
    return file.size > 20 * 1024; // 20KB 이상만 압축
  };

  // 섬네일 생성 함수 추가
  const generateThumbnail = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // 섬네일 크기 설정 (더 작게 설정하여 성능 향상)
          const maxSize = 200;
          const ratio = Math.min(maxSize / img.width, maxSize / img.height);
          canvas.width = img.width * ratio;
          canvas.height = img.height * ratio;

          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          resolve(canvas.toDataURL("image/webp", 0.6)); // WebP 포맷으로 압축
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  // handleFileUpload 함수 수정
  const handleFileUpload = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    setUploading(true);
    const startTime = performance.now();

    try {
      // 즉시 섬네일 생성 및 임시 표시
      const tempFiles = await Promise.all(
        files.map(async (file) => {
          const thumbnail = await generateThumbnail(file);
          return {
            name: file.name,
            url: thumbnail,
            path: `temp_${Date.now()}_${file.name}`,
            isTemp: true,
          };
        })
      );

      // 임시 파일들을 UI에 즉시 표시
      addFiles(userId, tempFiles);

      // 실제 업로드 프로세스
      const uploadPromises = files.map(async (file, index) => {
        const fileStartTime = performance.now();

        try {
          if (!isImageFile(file)) {
            return null;
          }

          let processedFile = needsCompression(file)
            ? await compressImage(file)
            : file;

          const timestamp = Date.now();
          const optimizedFileName = `${timestamp}_${file.name}`;
          const storageRef = ref(
            storage,
            `users/${userId}/${selectedTab.toLowerCase()}/${optimizedFileName}`
          );

          // 병렬 처리로 성능 향상
          const [uploadResult, thumbnail] = await Promise.all([
            uploadBytes(storageRef, processedFile, {
              contentType: file.type,
              cacheControl: "public,max-age=31536000",
              customMetadata: {
                originalSize: file.size.toString(),
                processedSize: processedFile.size.toString(),
                fileType: file.type,
              },
            }),
            generateThumbnail(file),
          ]);

          const url = await getDownloadURL(uploadResult.ref);

          // 임시 파일을 실제 파일로 교체
          removeFile(userId, tempFiles[index].path);
          addFiles(userId, [
            {
              name: file.name,
              url,
              path: uploadResult.ref.fullPath,
              thumbnail,
              timestamp: Date.now(),
            },
          ]);

          return {
            name: file.name,
            url,
            ref: uploadResult.ref,
            type: file.type,
          };
        } catch (error) {
          return null;
        }
      });

      await Promise.all(uploadPromises);
    } catch (error) {
    } finally {
      setUploading(false);
    }
  };

  // 이미지 클릭 시 바로 삽입
  const handleImageClick = async (file) => {
    if (!auth.currentUser) return;

    const startTime = performance.now();

    try {
      // 이미지 프리로드 시작
      const preloadPromise = preloadImage(file.url);

      const imageData = {
        id: Math.floor(file.id) || Math.floor(Date.now()),
        src: file.url,
        name: file.name,
        position: { x: 100, y: 100 },
        size: { width: 200, height: 200 },
        rotation: 0,
        zIndex: 1000,
        path: file.path,
        lastModified: Date.now(),
      };

      // 이미지 데이터 저장과 프리로드를 병렬로 실행
      await Promise.all([
        saveImageData(courseId, subunitId, imageData),
        preloadPromise,
      ]);

      onClose();
    } catch (error) {}
  };

  // 파일 목록을 표시할 때 백그라운드에서 이미지 프리로드
  useEffect(() => {
    if (!files.length) return;

    // 현재 보이는 이미지들만 프리로드
    const visibleFiles = files.slice(0, 12); // 첫 12개 이미지만

    visibleFiles.forEach((file) => {
      if (file.url) {
        preloadImage(file.url).catch(() => {
          // 프리로드 실패는 무시
        });
      }
    });
  }, [files]);

  // renderFileContent 함수 수정
  const renderFileContent = useCallback(
    (file) => {
      if (selectedTab === "Images") {
        return (
          <div className="image-item-container">
            <img
              src={file.thumbnail || file.url} // 섬네일 우선 사용
              alt={file.name}
              loading="lazy"
              decoding="async"
              onClick={() => handleImageClick(file)}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                cursor: "pointer",
                filter: file.isTemp ? "opacity(0.7)" : "none", // 임시 파일 표시
              }}
            />
            {file.isTemp && <div className="upload-progress">Uploading...</div>}
            {file.isLocal && (
              <div
                style={{
                  position: "absolute",
                  bottom: 5,
                  right: 5,
                  background: "rgba(0,0,0,0.5)",
                  color: "white",
                  padding: "2px 6px",
                  borderRadius: "4px",
                  fontSize: "10px",
                }}
              >
                Syncing...
              </div>
            )}
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(file.path);
              }}
              className="image-delete-button"
              title="Delete image"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="white"
              >
                <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
              </svg>
            </button>
          </div>
        );
      }
      return <span>{file.name}</span>;
    },
    [selectedTab]
  );

  // 메모이제이션된 파일 목록
  const memoizedFiles = useMemo(() => {
    return files.map((file) => renderFileContent(file));
  }, [files, selectedTab]);

  // Modify file list container section
  const getContainerStyle = () => {
    return {
      className:
        selectedTab === "Images" ? "file-manager-grid" : "file-manager-list",
    };
  };

  // 스토리지 사용량 가져오기
  const fetchStorageUsage = async () => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    try {
      const usage = await getUserStorageSize(userId);
      setStorageUsage(usage);
    } catch (error) {}
  };

  // 컴포넌트 마운트 및 파일 업로드/삭제 후 스토리지 사용량 업데이트
  useEffect(() => {
    fetchStorageUsage();
  }, [files]);

  // 컴시 정리 함수 추가 (라인 634-639 근처에 추가)
  const cleanupCache = async () => {
    const db = await initializeDB();
    const tx = db.transaction("images", "readwrite");
    const store = tx.objectStore("images");
    const items = await store.getAll();

    const now = Date.now();
    const ONE_DAY = 24 * 60 * 60 * 1000;

    for (const item of items) {
      if (now - item.timestamp > ONE_DAY) {
        await store.delete(item.path);
      }
    }
  };

  useEffect(() => {
    cleanupCache();
    return () => {
      urlCache.current.clear();
    };
  }, []);

  // 스켈레톤 로딩 컴포넌트 수정
  const ImageSkeleton = () => (
    <div
      className="skeleton-loading"
      style={{
        aspectRatio: "1/1", // 정사각형 비율 유지
        borderRadius: "8px",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <div className="skeleton-image"></div>
    </div>
  );

  // 컴포넌트 마운트 시 초기화
  useEffect(() => {
    if (userId) {
      initializeFiles(userId);
    }
  }, [userId, initializeFiles]);

  // 배치 처리 최적화
  const processInBatches = async (items) => {
    const results = [];
    const cachedResults = new Map();

    for (let i = 0; i < items.length; i += batchSize) {
      const batch = items.slice(i, i + batchSize);
      const batchPromises = batch.map(async (item) => {
        // 메모리 캐시 확인
        if (cachedFiles.has(item.fullPath)) {
          return cachedFiles.get(item.fullPath);
        }

        // IndexedDB 캐시 확인
        const cachedData = await getFromCache(item.fullPath);
        if (
          cachedData &&
          Date.now() - cachedData.timestamp < 24 * 60 * 60 * 1000
        ) {
          return cachedData;
        }

        try {
          const [url, metadata] = await Promise.all([
            getDownloadURL(item),
            getMetadata(item),
          ]);

          const fileData = {
            name: item.name,
            path: item.fullPath,
            url,
            metadata,
            size: metadata.size,
            timestamp: Date.now(),
          };

          // 캐시 업데이트
          await saveToCache(fileData);
          cachedResults.set(item.fullPath, fileData);

          return fileData;
        } catch (error) {
          return null;
        }
      });

      const batchResults = await Promise.all(batchPromises);
      results.push(...batchResults.filter(Boolean));
    }

    // 메모리 캐시 괄 업데이트
    setCachedFiles((prev) => {
      const newCache = new Map(prev);
      for (const [key, value] of cachedResults) {
        newCache.set(key, value);
      }
      return newCache;
    });

    return results;
  };

  return (
    <div
      className="flyout-panel"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      {/* Header */}
      <div className="flyout-header">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3>Uploads</h3>
        </div>
        <button className="close-button" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="white"
          >
            <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
          </svg>
        </button>
      </div>

      {/* Storage Usage Bar */}
      <div style={{ padding: "20px 20px 0 20px", marginTop: "10px" }}>
        <div
          style={{
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "12px",
            color: "#666",
          }}
        >
          <span>Storage Usage</span>
          <span>{(storageUsage / 1024 / 1024).toFixed(1)}MB / 1000MB</span>
        </div>
        <div
          style={{
            width: "100%",
            height: "4px",
            backgroundColor: "#f0f0f0",
            borderRadius: "2px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: `${(storageUsage / MAX_STORAGE_PER_USER) * 100}%`,
              height: "100%",
              backgroundColor:
                storageUsage > MAX_STORAGE_PER_USER * 0.9
                  ? "#ff4444"
                  : "#fae150",
              transition: "width 0.3s ease",
            }}
          />
        </div>
      </div>

      {/* Upload Button Area */}
      <div
        style={{
          padding: "20px",
        }}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          style={{ display: "none" }}
          multiple
          disabled={uploading}
        />
        <div
          style={{
            border: "2px dashed #e0e0e0",
            borderRadius: "24px",
            padding: "20px",
            textAlign: "center",
            backgroundColor: "#f8f8f8",
            marginBottom: "10px",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <p style={{ margin: "0 0 10px 0", color: "#666" }}>
            {uploading ? "Uploading files..." : "Select files to upload"}
          </p>
          <button
            className="custom-file-upload"
            onClick={() => fileInputRef.current.click()}
            disabled={uploading}
            style={{
              cursor: "pointer",
              padding: "0px 8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row-reverse",
              gap: "7px",
              border: "1px solid #ffffff",
              boxSizing: "border-box",
              borderRadius: "24px",
              backgroundColor: "#333333",
              color: "#ffffff",
              fontSize: "14px",
              fontFamily: "DM Sans",
              fontWeight: 700,
              lineHeight: "18px",
              outline: "none",
              width: "100%",
              maxWidth: "380px",
              height: "36px",
              margin: "0 auto",
              transition: "all 0.2s ease",
              "&:hover": {
                backgroundColor: "#444444",
              },
              opacity: uploading ? 0.5 : 1,
              cursor: uploading ? "not-allowed" : "pointer",
            }}
          >
            {uploading ? "Uploading..." : "Upload Image"}
          </button>
          {uploading && (
            <div
              style={{
                marginTop: "10px",
                color: "#666",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  border: "2px solid #f3f3f3",
                  borderTop: "2px solid #333",
                  borderRadius: "50%",
                  animation: "spin 1s linear infinite",
                }}
              />
              <style>
                {`
                  @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                  }
                `}
              </style>
              Uploading...
            </div>
          )}
        </div>
      </div>

      {/* File List Display Area */}
      <div
        className="files-container"
        style={{
          flex: 1,
          padding: "0 20px",
          overflowY: "auto",
          minHeight: 0,
        }}
      >
        <div className="file-manager-grid">
          {loading ? (
            // 스켈레톤 UI를 그리드 레이아웃으로 표시
            Array(12)
              .fill(0)
              .map((_, index) => (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <ImageSkeleton />
                </div>
              ))
          ) : files.length === 0 ? (
            <div className="no-files">
              <p>No files uploaded yet</p>
            </div>
          ) : (
            files.map((file) => (
              <div key={file.path}>{renderFileContent(file)}</div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default FlyoutMenu3;
