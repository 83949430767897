import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { checkSubscriptionStatus } from "../utils/subscription";
import "../css/TermsOfUse.css";

const TermsOfUse = () => {
  const { user } = useAuth();
  const [hasValidSubscription, setHasValidSubscription] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkUserSubscription = async () => {
      if (user) {
        const subscription = await checkSubscriptionStatus();
        const isValid =
          subscription &&
          (subscription.status === "active" ||
            subscription.status === "on_trial" ||
            subscription.status === "trialing" ||
            (subscription.status === "cancelled" &&
              new Date(subscription.currentPeriodEnd) > new Date()));
        setHasValidSubscription(isValid);
      }
    };

    checkUserSubscription();
  }, [user]);

  useEffect(() => {
    const checkDevice = () => {
      const mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      const width = window.innerWidth;
      setIsMobile(mobile && width < 768);
    };

    checkDevice();
    window.addEventListener("resize", checkDevice);
    return () => window.removeEventListener("resize", checkDevice);
  }, []);

  return (
    <div className="terms-container" style={{ backgroundColor: "#ffffff" }}>
      <Link to="/" className="about-logo">
        RedoNote
      </Link>

      <div className="terms-content">
        <h1>Terms of Use</h1>
        <section className="legal-section">
          <h2>1. Introduction</h2>
          <p>
            Welcome to Redo Note! These Terms of Use govern your use of our
            website and services (collectively referred to as the "Service"). By
            accessing or using our Service, you agree to comply with these
            Terms. If you do not agree, you may not use our Service.
          </p>
        </section>

        <section className="legal-section">
          <h2>2. Eligibility</h2>
          <p>
            You must be at least 13 years old to use this Service. If you are
            under the age of 18, you must have parental or guardian consent. By
            using our Service, you confirm that all information you provide is
            accurate and up-to-date.
          </p>
        </section>

        <section className="legal-section">
          <h2>3. Account Responsibilities</h2>
          <p>
            You are responsible for maintaining the security of your account and
            password. You agree not to share your login credentials with others.
            Any activity under your account is your responsibility.
          </p>
        </section>

        <section className="legal-section">
          <h2>4. Prohibited Activities</h2>
          <p>You agree not to use the Service for:</p>
          <ul className="legal-list">
            <li>Illegal or unauthorized purposes.</li>
            <li>Uploading harmful, abusive, or offensive content.</li>
            <li>
              Distributing or storing copyrighted material without proper
              authorization.
            </li>
            <li>Attempting to hack or disrupt the Service.</li>
          </ul>
        </section>

        <section className="legal-section">
          <h2>5. Content Ownership and Intellectual Property</h2>
          <p>
            All content you create and save within the Service remains your
            property. However, by using the Service, you grant us a
            non-exclusive license to store and display your content as part of
            the Service. The platform design, features, and code are the
            intellectual property of Redo Note and are protected under copyright
            law.
          </p>
        </section>

        <section className="legal-section">
          <h2>6. Subscription, Pricing, and Refunds</h2>
          <p>
            Our Service operates on a subscription model. Pricing details are
            available on our pricing page. Refunds are only issued for
            cancellations made within 7 days of purchase. Failure to pay
            subscription fees on time may result in suspension of your account.
          </p>
        </section>

        <section className="legal-section">
          <h2>7. Privacy Policy</h2>
          <p>
            Your privacy is important to us. Please refer to our Privacy Policy
            for detailed information on how we handle your data.
          </p>
        </section>

        <section className="legal-section">
          <h2>8. Service Availability</h2>
          <p>
            We strive to ensure uninterrupted access to the Service. However, we
            reserve the right to modify, suspend, or discontinue the Service at
            any time, with or without notice.
          </p>
        </section>

        <section className="legal-section">
          <h2>9. Contact Information</h2>
          <p>
            If you have any questions about these Terms, please contact us at:{" "}
            <a href="mailto:support@redonote.com">support@redonote.com</a>
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfUse;
