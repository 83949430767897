// src/pages/Courses.js
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "../css/courses.css"; // 상대 경로 수정
import { db, auth } from "../firebase/firebase";
import {
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  deleteDoc,
  onSnapshot,
  query,
  where,
  writeBatch,
  orderBy,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import LoadingSpinner from "../components/LoadingSpinner";
import { useCourses } from "../contexts/CourseContext";
import { useCurriculum } from "../contexts/CurriculumContext";
import { deleteCourseData } from "../utils/courseDeleteHelper";
import { useProgress } from "../contexts/ProgressContext";

const tagColors = [
  "#FFBEBA",
  "#FFDCAA",
  "#FFEEAA",
  "#C3F2CB",
  "#C8EDFD",
  "#AAD3FF",
  "#C7C7F1",
  "#FFB9C6",
];
const thumbnailOptions = Array.from({ length: 102 }, (_, i) => ({
  label: `Subject ${i + 1}`,
  src: `/img/subject/subjectimg_${i + 1}.png`,
}));

const optimizedThumbnailOptions = thumbnailOptions.map((option) => ({
  ...option,
  src: `${option.src}?w=100&q=75`,
}));

const CourseList = React.memo(
  ({ courses, onCourseClick, onEditCourse, onDeleteCourse }) => {
    const [courseProgress, setCourseProgress] = useState({});

    useEffect(() => {
      const loadProgress = async () => {
        const user = auth.currentUser;
        if (!user) return;

        courses.forEach(async (course) => {
          try {
            const curriculumRef = doc(
              db,
              "users",
              user.uid,
              "courses",
              course.id,
              "curriculum",
              "main"
            );

            const unsubscribe = onSnapshot(curriculumRef, (doc) => {
              if (doc.exists()) {
                const curriculumData = doc.data();
                const units = curriculumData.units || {};

                const allUnits = Object.values(units).flat();
                const completedUnits = allUnits.filter(
                  (unit) => unit.isCompleted
                ).length;
                const progress =
                  allUnits.length > 0
                    ? Math.round((completedUnits / allUnits.length) * 100)
                    : 0;

                setCourseProgress((prev) => ({
                  ...prev,
                  [course.id]: progress,
                }));
              }
            });

            return () => unsubscribe();
          } catch (error) {
            console.error(
              `Error loading progress for course ${course.id}:`,
              error
            );
          }
        });
      };

      loadProgress();
    }, [courses]);

    return (
      <Droppable droppableId="courseList">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="course-cards"
          >
            {courses.map((course, index) => (
              <Draggable
                key={`course-${index}`}
                draggableId={`course-${index}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={`course-card ${
                      snapshot.isDragging ? "dragging" : ""
                    }`}
                    onClick={() => onCourseClick(index)}
                  >
                    <div {...provided.dragHandleProps} className="drag-handle">
                      ☰
                    </div>
                    <div className="course-card-left">
                      {course.thumbnail && (
                        <img
                          src={course.thumbnail}
                          alt="Thumbnail"
                          className="card-thumbnail"
                        />
                      )}
                    </div>
                    <div className="course-card-middle">
                      <h3 className="course-name">{course.name}</h3>
                      <p className="course-description">{course.description}</p>
                      <div className="course-meta">
                        <span className="created-at">
                          <img
                            src="/toolbaricons/inuse/calendar.svg"
                            alt="Created at"
                            className="meta-icon"
                          />
                          {course.createdAt}
                        </span>
                      </div>
                      <div className="progress-bar">
                        <div
                          className="progress"
                          style={{
                            width: `${courseProgress[course.id] || 0}%`,
                          }}
                        ></div>
                      </div>
                      <span className="progress-text">
                        {`${courseProgress[course.id] || 0}% Completed`}
                      </span>
                      <div className="tags-container">
                        {course.tags.map((tag, tagIndex) => (
                          <div
                            key={tagIndex}
                            className="tag"
                            style={{ backgroundColor: tag.color }}
                          >
                            <span>{tag.text}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className="course-card-right"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button
                        className="course__action__button--unique"
                        onClick={(e) => {
                          e.stopPropagation();
                          onEditCourse(index);
                        }}
                      >
                        <img src="/toolbaricons/inuse/pencil.svg" alt="Edit" />
                      </button>
                      <button
                        className="course__action__button--unique"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteCourse(index);
                        }}
                      >
                        <img
                          src="/toolbaricons/inuse/delete.svg"
                          alt="Delete"
                        />
                      </button>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
);

const DragDropContextWrapper = ({ children, onDragEnd }) => (
  <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>
);

const calculateProgress = (course) => {
  if (course.curriculum && course.curriculum.units) {
    const allUnits = Object.values(course.curriculum.units).flat();
    const completedUnits = allUnits.filter((unit) => unit.isCompleted).length;
    return allUnits.length > 0 ? (completedUnits / allUnits.length) * 100 : 0;
  }
  return 0;
};

const CustomSelect = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className="custom-select-wrapper">
      <div
        className="custom-select"
        onClick={() => setIsOpen(!isOpen)}
        tabIndex={0}
        onBlur={() => setTimeout(() => setIsOpen(false), 200)}
      >
        {value || "All Tags"}
        <span>▼</span>
      </div>
      <ul className={`custom-select-options ${isOpen ? "open" : ""}`}>
        <li
          className="custom-select-option"
          onClick={() => handleOptionClick("")}
        >
          All Tags
        </li>
        {options.map((option, index) => (
          <li
            key={index}
            className="custom-select-option"
            onClick={() => handleOptionClick(option)}
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  );
};

// DeleteConfirmationModal 컴포넌트
const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  courseName,
  isDeleting,
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="delete-confirmation-content">
        {isDeleting ? (
          <div className="deleting-status">
            <LoadingSpinner />
            <p>Deleting...</p>
          </div>
        ) : (
          <>
            <p className="course-name">
              <strong className="course-name-text">{courseName}</strong>
              Are you sure you want to delete?
            </p>
            <p className="warning-text">
              ⚠️ This action cannot be undone and all related data (curriculum,
              flashcards, etc.) will be permanently deleted.
            </p>
            <div className="delete-confirmation-buttons">
              <button className="modal-button cancel-button" onClick={onClose}>
                Cancel
              </button>
              <button
                className="modal-button confirm-button"
                onClick={onConfirm}
              >
                Delete
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const deleteCollection = async (collectionRef) => {
  const snapshot = await getDocs(collectionRef);
  if (snapshot.empty) return;

  // 청크 크기를 더 크게 설정 (Firestore 제한: 500)
  const chunkSize = 450;
  const docs = snapshot.docs;

  // 청크로 나누기
  for (let i = 0; i < docs.length; i += chunkSize) {
    const chunk = docs.slice(i, i + chunkSize);
    const batch = writeBatch(db);

    chunk.forEach((doc) => {
      batch.delete(doc.ref);
    });

    await batch.commit();
  }
};

const Courses = ({ isSidebarMinimized, setIsSidebarMinimized }) => {
  const {
    courses,
    updateCourseProgress,
    setCourses,
    updateLastAccessed,
    updateCoursesOrder,
  } = useCourses();
  const [courseName, setCourseName] = useState("");
  const [courseDescription, setCourseDescription] = useState(""); // 설명 상태 추가
  const [courseTags, setCourseTags] = useState([]); // 태그 상태 추가
  const [tagInput, setTagInput] = useState(""); // 태그 입력 상태 가
  const [tagColor, setTagColor] = useState(tagColors[0]); // 태그 색상 상태 추가
  const [thumbnail, setThumbnail] = useState(""); // 썸네일 상태 추가
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상 추가
  const [error, setError] = useState(""); // 에러 상태 추가
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTag, setSelectedTag] = useState(""); // 선택된 태그 상태 추가
  const [editingCourseIndex, setEditingCourseIndex] = useState(null); // 수정 중인 코스 인덱스 상태 추가
  const [isLoading, setIsLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    courseIndex: null,
    courseName: "",
    isDeleting: false,
  });
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [visibleThumbnails, setVisibleThumbnails] = useState(
    thumbnailOptions.slice(0, 6)
  );
  const { calculateProgress } = useCurriculum();
  const [deletingCourseIds, setDeletingCourseIds] = useState(() => {
    // localStorage에서 삭제 중인 코스 ID들을 로드
    const saved = localStorage.getItem("deletingCourseIds");
    return saved ? JSON.parse(saved) : [];
  });
  const { progress } = useProgress();

  // 코스 로드 시 삭제 중인 코스 필터링
  useEffect(() => {
    const loadCourses = async () => {
      try {
        setIsInitialLoading(true);
        const user = auth.currentUser;

        if (!user) {
          const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
              const coursesRef = collection(db, "users", user.uid, "courses");
              const q = query(coursesRef, orderBy("order", "asc"));
              const querySnapshot = await getDocs(q);
              const loadedCourses = querySnapshot.docs.map((doc) => doc.data());
              // 삭제 중인 코스 필터링
              const filteredCourses = loadedCourses.filter(
                (course) => !deletingCourseIds.includes(course.id)
              );
              setCourses(filteredCourses);
              unsubscribe();
            }
          });
        } else {
          const coursesRef = collection(db, "users", user.uid, "courses");
          const q = query(coursesRef, orderBy("order", "asc"));
          const querySnapshot = await getDocs(q);
          const loadedCourses = querySnapshot.docs.map((doc) => doc.data());
          // 삭제 중인 코스 필터링
          const filteredCourses = loadedCourses.filter(
            (course) => !deletingCourseIds.includes(course.id)
          );
          setCourses(filteredCourses);
        }
      } catch (error) {
        setError("Failed to load courses");
      } finally {
        setIsInitialLoading(false);
      }
    };

    loadCourses();
  }, [setCourses, deletingCourseIds]);

  const handleAddCourse = async () => {
    if (!courseName.trim()) {
      setError("Please enter a course name.");
      return;
    }

    const isDuplicate = courses.some(
      (course) => course.name.toLowerCase() === courseName.trim().toLowerCase()
    );
    if (isDuplicate) {
      setError("Course name already exists. Please use a different name.");
      return;
    }

    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}/${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}/${String(currentDate.getDate()).padStart(2, "0")}`;

    const newCourse = {
      id: Date.now().toString(),
      name: courseName.trim(),
      description: courseDescription,
      tags: courseTags,
      thumbnail,
      createdAt: formattedDate,
      openedAt: formattedDate,
      order: courses.length,
      progress: 0,
    };

    try {
      setIsLoading(true);
      const user = auth.currentUser;
      if (!user) return;

      // UI 먼저 업데이트
      setCourses((prev) => [...prev, newCourse]);

      // 모달 즉시 닫기
      setCourseName("");
      setCourseDescription("");
      setCourseTags([]);
      setThumbnail("");
      setIsModalOpen(false);
      setError("");

      // 백그라운드에서 Firestore 업데이트
      const courseRef = doc(db, "users", user.uid, "courses", newCourse.id);
      setDoc(courseRef, newCourse).catch((error) => {
        console.error("코스 저장 중 오류:", error);
        // 필요한 경우 사용자에게 알림
      });
    } catch (error) {
      console.error("코스 추가 실패:", error);
      setError("코스 추가 중 오류가 발생했습니다.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCourseClick = async (index) => {
    setIsSidebarMinimized(true);
    const selectedCourse = courses[index];

    try {
      // 네비이션 먼저 실행
      const navigateUrl = `/courses/${
        selectedCourse.id
      }/curriculum/${encodeURIComponent(selectedCourse.name)}`;
      navigate(navigateUrl, { state: { courseId: selectedCourse.id } });

      // 그 다음 lastAccessedAt 업데이트 (백그라운드에서)
      await updateLastAccessed(selectedCourse.id);
    } catch (error) {
      console.error("코스 접근 중 오류 발생:", error);
    }
  };

  const handleTagColorSelect = (color) => {
    setTagColor(color);
  };

  const handleTagInputKeyPress = (e) => {
    if (e.key === "Enter" && tagInput.trim() && tagColor) {
      setCourseTags([
        ...courseTags,
        { text: tagInput.trim(), color: tagColor },
      ]);
      setTagInput("");
      // 태 추가 후 색상 선택을 초기화하지 않습니다.
    }
  };

  const handleRemoveTag = (index) => {
    setCourseTags((prevTags) => prevTags.filter((_, i) => i !== index));
  };

  // 실제 삭제 처리를 수행하는 함수 최적화
  const confirmDelete = async () => {
    try {
      setDeleteConfirmation((prev) => ({ ...prev, isDeleting: true }));

      const courseToDelete = courses[deleteConfirmation.courseIndex];
      const courseId = courseToDelete.id;

      // 삭제 중인 코스 ID 저장
      const newDeletingIds = [...deletingCourseIds, courseId];
      setDeletingCourseIds(newDeletingIds);
      localStorage.setItem("deletingCourseIds", JSON.stringify(newDeletingIds));

      // UI 즉시 업데이트
      setCourses(courses.filter((course) => course.id !== courseId));

      // 삭제 모달 닫기
      setDeleteConfirmation({
        isOpen: false,
        courseIndex: null,
        courseName: "",
        isDeleting: false,
      });

      // 백그라운드에서 실제 데이터 삭제
      try {
        await deleteCourseData(courseId);
        // 삭제 완료 후 deletingCourseIds에서 제거
        const updatedDeletingIds = deletingCourseIds.filter(
          (id) => id !== courseId
        );
        setDeletingCourseIds(updatedDeletingIds);
        localStorage.setItem(
          "deletingCourseIds",
          JSON.stringify(updatedDeletingIds)
        );
      } catch (error) {
        console.error("백그라운드 삭제 실패:", error);
        // 실패해도 UI에는 영향을 주지 않음
      }
    } catch (error) {
      console.error("코스 삭제 프로세스 실패:", error);
      alert("An error occurred while deleting the course.");
      setDeleteConfirmation((prev) => ({ ...prev, isDeleting: false }));
    }
  };

  const handleEditCourse = (index) => {
    const courseToEdit = courses[index];
    setCourseName(courseToEdit.name);
    setCourseDescription(courseToEdit.description);
    setCourseTags(courseToEdit.tags);
    setThumbnail(courseToEdit.thumbnail);
    setIsModalOpen(true);
    setEditingCourseIndex(index); // 수정 중인 코스 인덱스를 상태로 저장
  };

  const handleSaveEditedCourse = async () => {
    if (!courseName.trim()) {
      setError("Course name is required.");
      return;
    }

    const isDuplicate = courses.some(
      (course, index) =>
        index !== editingCourseIndex &&
        course.name.toLowerCase() === courseName.trim().toLowerCase()
    );
    if (isDuplicate) {
      setError("This course name already exists. Please use a different name.");
      return;
    }
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}/${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}/${String(currentDate.getDate()).padStart(2, "0")}`;

    const updatedCourse = {
      ...courses[editingCourseIndex],
      name: courseName.trim(),
      description: courseDescription,
      tags: courseTags,
      thumbnail,
      openedAt: formattedDate,
    };

    try {
      const user = auth.currentUser;
      if (!user) {
        return;
      }

      const courseRef = doc(db, "users", user.uid, "courses", updatedCourse.id);
      await setDoc(courseRef, updatedCourse, { merge: true });

      setCourseName("");
      setCourseDescription("");
      setCourseTags([]);
      setThumbnail("");
      setIsModalOpen(false);
      setError("");
      setEditingCourseIndex(null);
    } catch (error) {
      setError("Failed to update course. Please try again.");
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    try {
      const items = Array.from(courses);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      // 먼저 로컬 상태 업데이트
      setCourses(items);

      // Firestore 업데이트
      await updateCoursesOrder(items);
    } catch (error) {
      console.error("코스 순서 업데이트 중 오류:", error);
      // 에러 발생 시 원래 순서로 복구
      const originalCourses = Array.from(courses);
      setCourses(originalCourses);
    }
  };

  const handleSearch = () => {};

  // 모든 태그를 가져오는 함수
  const getAllTags = () => {
    const allTags = courses.flatMap((course) =>
      course.tags.map((tag) => tag.text)
    );
    return [...new Set(allTags)];
  };

  // 필터링된 코스 목록
  const filteredCourses = courses.filter((course) => {
    const searchTermLower = searchTerm.toLowerCase();
    const nameMatch = course.name.toLowerCase().includes(searchTermLower);
    const descriptionMatch = course.description
      .toLowerCase()
      .includes(searchTermLower);
    const tagMatch = course.tags.some((tag) =>
      tag.text.toLowerCase().includes(searchTermLower)
    );

    const matchesSearch = nameMatch || descriptionMatch || tagMatch;
    const matchesTag =
      selectedTag === "" || course.tags.some((tag) => tag.text === selectedTag);

    return matchesSearch && matchesTag;
  });

  // handleDeleteCourse 함수 수정
  const handleDeleteCourse = (index) => {
    setDeleteConfirmation({
      isOpen: true,
      courseIndex: index,
      courseName: courses[index].name,
      isDeleting: false,
    });
  };

  useEffect(() => {
    // 나머지 이미지는 초기 로드 후 로드
    setTimeout(() => {
      setVisibleThumbnails(thumbnailOptions);
    }, 1000);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setIsSidebarMinimized(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setIsSidebarMinimized]);

  const handleAddTag = () => {
    if (tagInput.trim() && tagColor) {
      setCourseTags([
        ...courseTags,
        { text: tagInput.trim(), color: tagColor },
      ]);
      setTagInput("");
    }
  };

  return (
    <DragDropContextWrapper onDragEnd={onDragEnd}>
      <div>
        <div className="courses-header">
          <h1
            style={{
              fontFamily: '"DM Sans", sans-serif',
              color: "#ffffff", // 다크모드용 흰색 텍스트
            }}
          >
            Courses
          </h1>
        </div>

        <div className="courses-actions">
          <button
            className="new-course-btn"
            onClick={() => setIsModalOpen(true)}
          >
            <div className="btn-content">
              <img
                src="/toolbaricons/inuse/add.svg"
                alt="Add"
                className="add-icon"
              />
              <span>New Course</span>
            </div>
          </button>
          <div className="filter-search-container">
            <CustomSelect
              options={getAllTags()}
              value={selectedTag}
              onChange={(value) => setSelectedTag(value)}
            />
            <div className="search-container">
              <input
                type="text"
                className="search-input"
                placeholder="Search courses..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <img
                src="/toolbaricons/inuse/search.svg"
                alt="Search"
                className="search-icon"
                onClick={handleSearch}
              />
            </div>
          </div>
        </div>

        {isInitialLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {filteredCourses.length === 0 && (
              <div className="empty-state">
                <h2>No courses found</h2>
                <p>Try changing your search or tag filters</p>
              </div>
            )}

            <CourseList
              courses={filteredCourses}
              onCourseClick={handleCourseClick}
              onEditCourse={handleEditCourse}
              onDeleteCourse={handleDeleteCourse}
            />
          </>
        )}

        {isModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={() => setIsModalOpen(false)}>
                &times;
              </span>
              <h2>
                {editingCourseIndex !== null ? "Edit Course" : "Add New Course"}
              </h2>
              <div className="form-group">
                <label>Thumbnail</label>
                <div className="thumbnail-options-container">
                  <div className="thumbnail-options">
                    {visibleThumbnails.map((option, index) => (
                      <img
                        key={index}
                        src={option.src}
                        alt={option.label}
                        className={`thumbnail-option ${
                          thumbnail === option.src ? "selected" : ""
                        }`}
                        onClick={() => setThumbnail(option.src)}
                        loading="lazy"
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Course Name</label>
                <input
                  type="text"
                  className="modal-input"
                  value={courseName}
                  onChange={(e) => setCourseName(e.target.value)}
                  placeholder="Enter course name"
                />
                {error && <p className="error">{error}</p>}
              </div>
              <div className="form-group">
                <label>Course Description</label>
                <textarea
                  className="modal-input"
                  value={courseDescription}
                  onChange={(e) => setCourseDescription(e.target.value)}
                  placeholder="Enter course description"
                />
              </div>
              <div className="form-group">
                <label>Course Tags</label>
                <div className="tag-colors">
                  {tagColors.map((color, index) => (
                    <span
                      key={index}
                      className={`tag-color ${
                        color === tagColor ? "selected" : ""
                      }`}
                      style={{ backgroundColor: color }}
                      onClick={() => handleTagColorSelect(color)}
                    />
                  ))}
                </div>
                <div className="tag-input-container">
                  <div className="tag-input-wrapper">
                    <input
                      type="text"
                      value={tagInput}
                      onChange={(e) => setTagInput(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleAddTag();
                        }
                      }}
                      placeholder="Enter tag name..."
                      disabled={!tagColor}
                    />
                  </div>
                  <button
                    className="tag-add-button"
                    onClick={handleAddTag}
                    disabled={!tagInput.trim() || !tagColor}
                  >
                    <img src="/toolbaricons/inuse/add.svg" alt="Add tag" />
                  </button>
                </div>
                <div className="tags-container">
                  {courseTags.map((tag, index) => (
                    <div
                      key={index}
                      className="tag"
                      style={{ backgroundColor: tag.color }}
                    >
                      <span>{tag.text}</span>
                      <button
                        className="remove-tag-btn"
                        onClick={() => handleRemoveTag(index)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <button
                className="new-course-btn"
                onClick={
                  editingCourseIndex !== null
                    ? handleSaveEditedCourse
                    : handleAddCourse
                }
              >
                <div className="btn-content">
                  <img
                    src="/toolbaricons/inuse/add.svg"
                    alt="Add"
                    className="add-icon"
                  />
                  <span>
                    {editingCourseIndex !== null
                      ? "Save Changes"
                      : "Add Course"}
                  </span>
                </div>
              </button>
            </div>
          </div>
        )}

        {isLoading && <LoadingSpinner />}

        {/* 삭제 확인 모달 */}
        <DeleteConfirmationModal
          isOpen={deleteConfirmation.isOpen}
          onClose={() =>
            setDeleteConfirmation({
              isOpen: false,
              courseIndex: null,
              courseName: "",
              isDeleting: false,
            })
          }
          onConfirm={confirmDelete}
          courseName={deleteConfirmation.courseName}
          isDeleting={deleteConfirmation.isDeleting}
        />
      </div>
    </DragDropContextWrapper>
  );
};

// CSS 파일에 스타일 추가 필요

export default Courses;
