import React from "react";
import { Link } from "react-router-dom";
import "./ReviewMode.css";

const ReviewMode = () => {
  return (
    <div className="review-mode-page" style={{ backgroundColor: "#ffffff" }}>
      <div className="header">
        <div className="nav-tabs">
          <Link to="/" className="tab">
            Main
          </Link>
          <Link to="/note-taking" className="tab">
            Note Taking
          </Link>
          <Link to="/review-mode" className="tab active">
            Review Mode
          </Link>
          <Link to="/planner" className="tab">
            Planner
          </Link>
        </div>
      </div>

      <h1>Smart Review System</h1>

      <div className="feature-container">
        <div className="feature-item">
          <video
            className="feature-image"
            src={process.env.PUBLIC_URL + "/video/instant_review.mov"}
            autoPlay
            loop
            muted
            playsInline
          />
          <div className="feature-description">
            <h2>Instant Review Mode</h2>
            <p>
              Start reviewing your notes immediately after creation. Our system
              automatically generates review materials from your fresh notes,
              helping you reinforce your learning while the information is still
              recent.
            </p>
          </div>
        </div>

        <div className="feature-item">
          <video
            className="feature-image"
            src={process.env.PUBLIC_URL + "/video/masked_review.mov"}
            autoPlay
            loop
            muted
            playsInline
          />
          <div className="feature-description">
            <h2>Smart Masking Review</h2>
            <p>
              Test your knowledge with our intelligent masking system. Key terms
              and important concepts are automatically hidden, allowing you to
              practice active recall while reviewing your notes.
            </p>
          </div>
        </div>

        <div className="feature-item">
          <video
            className="feature-image"
            src={process.env.PUBLIC_URL + "/video/flashcard.mov"}
            autoPlay
            loop
            muted
            playsInline
          />
          <div className="feature-description">
            <h2>Interactive Flashcards</h2>
            <p>
              Transform your notes into interactive flashcards instantly.
              Perfect for quick reviews and testing your understanding of key
              concepts through active recall practice.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewMode;
