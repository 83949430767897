import React, { createContext, useState, useContext, useEffect } from "react";
import { db, auth } from "../firebase/firebase";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  updateDoc,
  addDoc,
  serverTimestamp,
  setDoc,
  getDoc,
  writeBatch,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

const CourseContext = createContext();

export const CourseProvider = ({ children }) => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const coursesRef = collection(db, "users", user.uid, "courses");
        const q = query(coursesRef, orderBy("order", "asc"));

        const unsubscribeCourses = onSnapshot(
          q,
          (snapshot) => {
            const coursesData = snapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));
            setCourses(coursesData);
            setIsLoading(false);
          },
          (error) => {
            console.error("Courses listener error:", error);
            setIsLoading(false);
          }
        );

        return () => unsubscribeCourses();
      } else {
        setCourses([]);
        setIsLoading(false);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  const updateCourseProgress = async (courseId, progress) => {
    const user = auth.currentUser;
    if (user) {
      const courseRef = doc(db, "users", user.uid, "courses", courseId);
      try {
        const courseDoc = await getDoc(courseRef);
        if (courseDoc.exists() && courseDoc.data().progress !== progress) {
          await updateDoc(courseRef, { progress });
        }
      } catch (error) {}
    }
  };

  const addCourse = async (newCourse) => {
    const user = auth.currentUser;
    if (user) {
      const coursesRef = collection(db, "users", user.uid, "courses");
      try {
        const courseId = uuidv4();
        await setDoc(doc(coursesRef, courseId), {
          ...newCourse,
          id: courseId,
          createdAt: serverTimestamp(),
          progress: 0,
        });
      } catch (error) {}
    }
  };

  const updateLastAccessed = async (courseId) => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      const courseRef = doc(db, "users", user.uid, "courses", courseId);

      await setDoc(
        courseRef,
        {
          lastAccessedAt: serverTimestamp(),
        },
        { merge: true }
      );
    } catch (error) {
      console.error("lastAccessedAt 업데이트 중 오류:", error);
    }
  };

  const updateCoursesOrder = async (updatedCourses) => {
    const user = auth.currentUser;
    if (!user) return;

    const batch = writeBatch(db);

    updatedCourses.forEach((course, index) => {
      const courseRef = doc(db, "users", user.uid, "courses", course.id);
      batch.update(courseRef, {
        order: index,
      });
    });

    try {
      await batch.commit();
    } catch (error) {
      console.error("Error updating course order:", error);
      throw error;
    }
  };

  return (
    <CourseContext.Provider
      value={{
        courses,
        setCourses,
        updateCourseProgress,
        addCourse,
        isLoading,
        updateLastAccessed,
        updateCoursesOrder,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

export const useCourses = () => useContext(CourseContext);
