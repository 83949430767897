import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "../css/Flashcards.css";
import { db, auth } from "../firebase/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import ContextMenu from "../components/ContextMenu";

const Flashcards = () => {
  const { courseId } = useParams();
  const [decks, setDecks] = useState([]);
  const [currentDeck, setCurrentDeck] = useState(null);
  const [currentCard, setCurrentCard] = useState(0);
  const [deckInput, setDeckInput] = useState("");
  const [questionInput, setQuestionInput] = useState("");
  const [answerInput, setAnswerInput] = useState("");
  const [contextMenu, setContextMenu] = useState(null);
  const deckRef = useRef(null);
  const [viewMode, setViewMode] = useState("card"); // 'card' or 'list'
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    message: "",
    onConfirm: null,
  });
  const [editingDeckName, setEditingDeckName] = useState(false);
  const [newDeckName, setNewDeckName] = useState("");

  const ConfirmDialog = ({ isOpen, title, message, onConfirm, onCancel }) => {
    if (!isOpen) return null;

    return (
      <div className="confirm-dialog-overlay">
        <div className="confirm-dialog">
          <h3>{title}</h3>
          <p>{message}</p>
          <div className="confirm-dialog-buttons">
            <button className="confirm-button" onClick={onConfirm}>
              Confirm
            </button>
            <button className="cancel-button" onClick={onCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const loadFlashcards = async () => {
      try {
        const user = auth.currentUser;
        if (!user || !courseId) return;

        const flashcardsRef = doc(
          db,
          "users",
          user.uid,
          "courses",
          courseId,
          "flashcards",
          "list"
        );

        const docSnap = await getDoc(flashcardsRef);
        if (docSnap.exists()) {
          setDecks(docSnap.data().decks || []);
        }
      } catch (error) {
        console.error("Error loading flashcards:", error);
      }
    };

    loadFlashcards();
  }, [courseId]);

  const toggleAnswer = (deckIndex, cardIndex) => {
    const updatedDecks = [...decks];
    updatedDecks[deckIndex].cards[cardIndex].flipped =
      !updatedDecks[deckIndex].cards[cardIndex].flipped;
    setDecks(updatedDecks);
  };

  const nextCard = () => {
    if (
      currentDeck !== null &&
      decks[currentDeck].cards &&
      currentCard < decks[currentDeck].cards.length - 1
    ) {
      setCurrentCard(currentCard + 1);
    }
  };

  const prevCard = () => {
    if (currentDeck !== null && decks[currentDeck].cards && currentCard > 0) {
      setCurrentCard(currentCard - 1);
    }
  };

  const handleAddDeck = () => {
    if (deckInput.trim()) {
      const newDecks = [...decks, { name: deckInput, cards: [] }];
      setDecks(newDecks);
      setDeckInput("");
      saveToFirebase(newDecks);
    }
  };

  const handleAddCard = () => {
    if (currentDeck !== null && questionInput.trim() && answerInput.trim()) {
      const updatedDecks = [...decks];
      updatedDecks[currentDeck].cards.push({
        question: questionInput,
        answer: answerInput,
        flipped: false,
      });
      setDecks(updatedDecks);
      setQuestionInput("");
      setAnswerInput("");
      saveToFirebase(updatedDecks);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && answerInput.trim()) {
      handleAddCard();
    }
  };

  const onDeckDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(decks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setDecks(items);
    saveToFirebase(items);
  };

  const onCardDragEnd = (result) => {
    if (!result.destination || currentDeck === null) return;

    const updatedDecks = [...decks];
    const currentCards = [...updatedDecks[currentDeck].cards];
    const [reorderedItem] = currentCards.splice(result.source.index, 1);
    currentCards.splice(result.destination.index, 0, reorderedItem);

    updatedDecks[currentDeck].cards = currentCards;
    setDecks(updatedDecks);
    saveToFirebase(updatedDecks);
  };

  const handleContextMenu = (event, deckIndex, cardIndex = null) => {
    event.preventDefault();
    event.stopPropagation(); // 이벤트 버블링 방지
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
      deckIndex: deckIndex,
      cardIndex: cardIndex,
    });
  };
  const handleDeleteDeck = (deckIndex) => {
    setConfirmDialog({
      isOpen: true,
      title: "Delete Deck",
      message: "Are you sure you want to delete this deck?",
      onConfirm: () => {
        const updatedDecks = decks.filter((_, index) => index !== deckIndex);
        setDecks(updatedDecks);
        saveToFirebase(updatedDecks);
        setContextMenu(null);
        setConfirmDialog({ isOpen: false });
      },
    });
  };

  const handleDeleteCard = (deckIndex, cardIndex) => {
    setConfirmDialog({
      isOpen: true,
      title: "Delete Card",
      message: "Are you sure you want to delete this card?",
      onConfirm: () => {
        const updatedDecks = [...decks];
        updatedDecks[deckIndex].cards.splice(cardIndex, 1);
        setDecks(updatedDecks);
        saveToFirebase(updatedDecks);

        if (currentDeck === deckIndex) {
          if (cardIndex <= currentCard && currentCard > 0) {
            setCurrentCard(currentCard - 1);
          } else if (updatedDecks[deckIndex].cards.length === 0) {
            setCurrentCard(0);
          }
        }
        setConfirmDialog({ isOpen: false });
      },
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (deckRef.current && !deckRef.current.contains(event.target)) {
        setContextMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const saveToFirebase = async (updatedDecks) => {
    try {
      const user = auth.currentUser;
      if (!user || !courseId) return;

      const flashcardsRef = doc(
        db,
        "users",
        user.uid,
        "courses",
        courseId,
        "flashcards",
        "list"
      );

      await setDoc(
        flashcardsRef,
        {
          decks: updatedDecks,
          updatedAt: new Date().toISOString(),
        },
        { merge: true }
      );
    } catch (error) {
      console.error("플래시카드 저장 중 에러:", error);
    }
  };

  const handleDeckNameEdit = (currentName) => {
    setEditingDeckName(true);
    setNewDeckName(currentName);
  };

  const handleDeckNameSave = () => {
    if (newDeckName.trim() && currentDeck !== null) {
      const updatedDecks = [...decks];
      updatedDecks[currentDeck].name = newDeckName.trim();
      setDecks(updatedDecks);
      saveToFirebase(updatedDecks);
      setEditingDeckName(false);
    }
  };

  const handleDeckNameKeyPress = (e) => {
    if (e.key === "Enter") {
      handleDeckNameSave();
    } else if (e.key === "Escape") {
      setEditingDeckName(false);
    }
  };

  return (
    <div className="flashcards-container">
      <div className="flashcards-left">
        <div className="add-deck">
          <div className="input-button-group">
            <input
              type="text"
              value={deckInput}
              onChange={(e) => setDeckInput(e.target.value)}
              placeholder="Enter new deck name"
              className="deck-input"
            />
            <button onClick={handleAddDeck} className="add-deck-button">
              <img
                src="/toolbaricons/inuse/add.svg"
                alt="add"
                className="plus-icon"
              />
              <span className="button-text">Deck</span>
            </button>
          </div>
        </div>
        <DragDropContext onDragEnd={onDeckDragEnd}>
          <Droppable droppableId="decks">
            {(provided) => (
              <div
                className="deck-list"
                ref={(element) => {
                  deckRef.current = element;
                  provided.innerRef(element);
                }}
                {...provided.droppableProps}
              >
                {decks.map((deck, index) => (
                  <Draggable
                    key={index}
                    draggableId={`deck-${index}`}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`deck-item ${
                          currentDeck === index ? "selected" : ""
                        }`}
                        onContextMenu={(e) => handleContextMenu(e, index)}
                        onClick={() => setCurrentDeck(index)}
                      >
                        <h2>
                          {deck.name} ({deck.cards.length})
                        </h2>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {contextMenu && (
          <ContextMenu
            x={contextMenu.x}
            y={contextMenu.y}
            onClose={() => setContextMenu(null)}
            menuItems={[
              {
                label: "Delete Deck",
                onClick: () => handleDeleteDeck(contextMenu.deckIndex),
              },
            ]}
          />
        )}
      </div>
      <div className="flashcards-right">
        {currentDeck !== null && decks[currentDeck] ? (
          <div className="card-container">
            <div className="deck-header">
              {editingDeckName ? (
                <input
                  type="text"
                  value={newDeckName}
                  onChange={(e) => setNewDeckName(e.target.value)}
                  onBlur={handleDeckNameSave}
                  onKeyDown={handleDeckNameKeyPress}
                  autoFocus
                  className="deck-name-input"
                />
              ) : (
                <h3
                  onDoubleClick={() =>
                    handleDeckNameEdit(decks[currentDeck].name)
                  }
                  style={{ cursor: "pointer" }}
                >
                  {decks[currentDeck].name}
                </h3>
              )}
              <div className="view-mode-toggle">
                <button
                  className={`view-mode-button ${
                    viewMode === "card" ? "active" : ""
                  }`}
                  onClick={() => setViewMode("card")}
                >
                  Card View
                </button>
                <button
                  className={`view-mode-button ${
                    viewMode === "list" ? "active" : ""
                  }`}
                  onClick={() => setViewMode("list")}
                >
                  List View
                </button>
              </div>
            </div>
            <div className="add-card">
              <div className="card-inputs">
                <input
                  type="text"
                  value={questionInput}
                  onChange={(e) => setQuestionInput(e.target.value)}
                  placeholder="Question"
                  className="deck-input"
                />
                <input
                  type="text"
                  value={answerInput}
                  onChange={(e) => setAnswerInput(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="Answer"
                  className="deck-input"
                />
              </div>
              <button onClick={handleAddCard} className="new-card-button">
                <img
                  src="/toolbaricons/inuse/add.svg"
                  alt="add"
                  className="new-card-icon"
                />
                <span className="new-card-text">Card</span>
              </button>
            </div>
            {decks[currentDeck].cards.length > 0 ? (
              <>
                {viewMode === "card" ? (
                  <div className="flashcard">
                    <div className="flashcard-label">
                      {decks[currentDeck].cards[currentCard].flipped
                        ? "Back"
                        : "Front"}
                      <button
                        className="delete-card-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteCard(currentDeck, currentCard);
                        }}
                      >
                        Delete Card
                      </button>
                    </div>
                    <div
                      className={`flashcard-content ${
                        decks[currentDeck].cards[currentCard].flipped
                          ? "flipped-content"
                          : ""
                      }`}
                      onClick={() => toggleAnswer(currentDeck, currentCard)}
                    >
                      <div className="flip-hint">
                        <img
                          src="/toolbaricons/inuse/switch-flip.svg"
                          alt="flip"
                          className="flip-icon"
                        />
                        <span className="flip-text">Click to flip</span>
                      </div>
                      <div className="card-text">
                        {decks[currentDeck].cards[currentCard].flipped
                          ? decks[currentDeck].cards[currentCard].answer
                          : decks[currentDeck].cards[currentCard].question}
                      </div>
                    </div>
                    <div className="navigation">
                      <button
                        className="nav-button"
                        onClick={prevCard}
                        disabled={currentCard === 0}
                      >
                        Previous
                      </button>
                      <span className="card-counter">
                        {currentCard + 1} / {decks[currentDeck].cards.length}
                      </span>
                      <button
                        className="nav-button"
                        onClick={nextCard}
                        disabled={
                          currentCard === decks[currentDeck].cards.length - 1
                        }
                      >
                        Next
                      </button>
                    </div>
                  </div>
                ) : (
                  <DragDropContext onDragEnd={onCardDragEnd}>
                    <Droppable droppableId={`deck-${currentDeck}-cards`}>
                      {(provided) => (
                        <div
                          className="cards-list"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {decks[currentDeck].cards.map((card, index) => (
                            <Draggable
                              key={index}
                              draggableId={`${currentDeck}-${index}`}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="card-list-item"
                                >
                                  <div className="card-list-content">
                                    <div className="card-list-question">
                                      <strong>Q:</strong> {card.question}
                                    </div>
                                    <div className="card-list-answer">
                                      <strong>A:</strong> {card.answer}
                                    </div>
                                  </div>
                                  <button
                                    className="delete-card-button"
                                    onClick={() =>
                                      handleDeleteCard(currentDeck, index)
                                    }
                                  >
                                    Delete
                                  </button>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
              </>
            ) : (
              <p>There are no cards in this deck yet. Add a new card above!</p>
            )}
          </div>
        ) : (
          <p>Select a deck to study or add cards.</p>
        )}
      </div>
      {confirmDialog.isOpen && (
        <ConfirmDialog
          isOpen={confirmDialog.isOpen}
          title={confirmDialog.title}
          message={confirmDialog.message}
          onConfirm={confirmDialog.onConfirm}
          onCancel={() => setConfirmDialog({ isOpen: false })}
        />
      )}
    </div>
  );
};

export default Flashcards;
