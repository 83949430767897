// src/layouts/MainLayout.js
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";

const MainLayout = ({ isSidebarMinimized, setIsSidebarMinimized }) => {
  useEffect(() => {
    document.documentElement.classList.add("dashboard-page");
    document.body.classList.add("dashboard-page");

    return () => {
      document.documentElement.classList.remove("dashboard-page");
      document.body.classList.remove("dashboard-page");
    };
  }, []);

  return (
    <div className="layout">
      <Sidebar
        minimized={isSidebarMinimized}
        setMinimized={setIsSidebarMinimized}
      />
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
