import { useState, useCallback, useEffect } from "react";
import { useCurriculum } from "../contexts/CurriculumContext";

export const useCurriculumState = (initialState) => {
  const {
    curriculum,
    setCurriculum,
    handleSubunitNameChange: contextHandleSubunitNameChange,
  } = useCurriculum();

  const [currentUnitId, setCurrentUnitId] = useState(initialState?.unitId);
  const [currentSubunit, setCurrentSubunit] = useState(() => {
    if (initialState?.subunitId) {
      const subunit = curriculum.subunits[initialState.unitId]?.find(
        (s) => s.id === initialState.subunitId
      );
      return subunit
        ? { id: subunit.id, name: subunit.name }
        : { id: "", name: "" };
    }
    return { id: "", name: "" };
  });

  const [currentSubunitName, setCurrentSubunitName] = useState(
    currentSubunit.name || ""
  );

  useEffect(() => {}, [currentUnitId, currentSubunit, curriculum]);

  const handleSubunitNameChange = useCallback(
    (unitId, subunitId, newName) => {
      contextHandleSubunitNameChange(unitId, subunitId, newName);
    },
    [contextHandleSubunitNameChange]
  );

  return {
    curriculum,
    setCurriculum,
    currentUnitId,
    setCurrentUnitId,
    currentSubunit,
    setCurrentSubunit,
    currentSubunitName,
    setCurrentSubunitName,
    handleSubunitNameChange,
  };
};
