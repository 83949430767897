import React, { useState } from "react";
import "../css/FlyoutMenu6.css";
import { useTasks } from "../contexts/TaskContext";
import { useNavigate } from "react-router-dom";

const FlyoutMenu6 = ({ onClose }) => {
  const { taskLists, toggleTask, addTask, addList } = useTasks();
  const [newTasks, setNewTasks] = useState({});
  const [newListName, setNewListName] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e, listIndex) => {
    e.preventDefault();
    const taskText = newTasks[listIndex];
    if (taskText && taskText.trim()) {
      addTask(listIndex, taskText.trim());
      setNewTasks((prev) => ({
        ...prev,
        [listIndex]: "",
      }));
    }
  };

  const handleInputChange = (listIndex, value) => {
    setNewTasks((prev) => ({
      ...prev,
      [listIndex]: value,
    }));
  };

  const handleAddList = (e) => {
    e.preventDefault();
    if (newListName.trim()) {
      addList(newListName.trim());
      setNewListName("");
    }
  };

  return (
    <div className="flyout-panel">
      <div className="flyout-header">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3>To Do List</h3>
          <button
            onClick={() => navigate(`/dashboard/todo`)}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              marginLeft: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src="/toolbaricons/inuse/arrow-up-right.svg"
              alt="Go to Todo List"
              className="white-icon"
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </button>
        </div>
        <button className="close-button" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
          </svg>
        </button>
      </div>

      <div
        className="flyout-content"
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <form
          className="add-list-form"
          onSubmit={handleAddList}
          style={{
            padding: "10px",
            display: "flex",
            gap: "10px",
            borderBottom: "1px solid #eee",
          }}
        >
          <input
            type="text"
            className="add-reminder-input"
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
            placeholder="New List Name"
            style={{ flex: 1 }}
          />
          <button type="submit" className="add-reminder-button">
            <span style={{ fontSize: "20px" }}>+</span>
          </button>
        </form>

        <div style={{ overflowY: "auto", flexGrow: 1 }}>
          <div className="ReminderList">
            {taskLists.map((list, listIndex) => (
              <div key={listIndex} className="reminder-card">
                <h4>{list.name}</h4>
                <ul className="reminder-items">
                  {list.tasks.map((task, taskIndex) => (
                    <li key={taskIndex} className="reminder-item">
                      <div className="reminder-main">
                        <input
                          type="checkbox"
                          checked={task.completed}
                          onChange={() => toggleTask(listIndex, taskIndex)}
                        />
                        <span
                          className={`reminder-text ${
                            task.completed ? "completed-text" : ""
                          }`}
                        >
                          {task.text}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
                <form
                  className="add-reminder-form"
                  onSubmit={(e) => handleSubmit(e, listIndex)}
                  style={{ marginTop: "12px", padding: "0" }}
                >
                  <input
                    type="text"
                    className="add-reminder-input"
                    value={newTasks[listIndex] || ""}
                    onChange={(e) =>
                      handleInputChange(listIndex, e.target.value)
                    }
                  />
                  <button type="submit" className="add-reminder-button">
                    <span style={{ fontSize: "20px" }}>+</span>
                  </button>
                </form>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlyoutMenu6;
