import React, { createContext, useContext, useState, useEffect } from "react";
import { db, auth } from "../firebase/firebase";
import { doc, setDoc, onSnapshot, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const TaskContext = createContext();

export const TaskProvider = ({ children }) => {
  const [taskLists, setTaskLists] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  // 인증 상태 변경 감지
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (!user) {
        setTaskLists([]); // 로그아웃 시 taskLists 초기화
      }
    });

    return () => unsubscribe();
  }, []);

  // Firebase 연동 및 실시간 업데이트 로직
  useEffect(() => {
    let unsubscribe = null;

    if (currentUser) {
      unsubscribe = onSnapshot(
        doc(db, `users/${currentUser.uid}/todolist/myTodoList`),
        (doc) => {
          if (doc.exists()) {
            setTaskLists(doc.data().taskLists || []);
          } else {
            setTaskLists([{ name: "Today", tasks: [] }]); // 새 사용자를 위한 기본 리스트
          }
        },
        (error) => {
          console.error("Error fetching tasks:", error);
          setTaskLists([]); // 에러 발생 시 초기화
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser]);

  // Firebase에 저장하는 함수
  const saveToFirebase = async (newTaskLists) => {
    if (!currentUser) return;

    try {
      await setDoc(
        doc(db, `users/${currentUser.uid}/todolist/myTodoList`),
        { taskLists: newTaskLists },
        { merge: true }
      );
    } catch (error) {
      console.error("Error saving tasks:", error);
    }
  };

  // fetchTasks 함수 수정
  const fetchTasks = async () => {
    if (!currentUser) {
      setTaskLists([]);
      return;
    }

    try {
      const docRef = doc(db, `users/${currentUser.uid}/todolist/myTodoList`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setTaskLists(docSnap.data().taskLists || []);
      } else {
        setTaskLists([{ name: "Today", tasks: [] }]);
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
      setTaskLists([]);
      throw error;
    }
  };

  // 나머지 함수들은 그대로 유지
  const addTask = (listIndex, taskText) => {
    const newTaskLists = [...taskLists];
    newTaskLists[listIndex].tasks.push({
      text: taskText,
      completed: false,
    });
    setTaskLists(newTaskLists);
    saveToFirebase(newTaskLists);
  };

  const toggleTask = (listIndex, taskIndex) => {
    const newTaskLists = [...taskLists];
    newTaskLists[listIndex].tasks[taskIndex].completed =
      !newTaskLists[listIndex].tasks[taskIndex].completed;
    setTaskLists(newTaskLists);
    saveToFirebase(newTaskLists);
  };

  const addList = (listName) => {
    const newTaskLists = [
      ...taskLists,
      {
        name: listName,
        tasks: [],
      },
    ];
    setTaskLists(newTaskLists);
    saveToFirebase(newTaskLists);
  };

  return (
    <TaskContext.Provider
      value={{
        taskLists,
        setTaskLists,
        toggleTask,
        addTask,
        addList,
        fetchTasks,
        currentUser,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export const useTasks = () => {
  const context = useContext(TaskContext);
  if (!context) {
    throw new Error("useTasks must be used within a TaskProvider");
  }
  return context;
};
